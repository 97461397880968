import { NumberBox, SelectBox } from 'devextreme-react';
import GraphAdvancedSettingsButton from '../GraphAdvancedSettingsButton';
import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { SetDbHorizontalGuideValue, SetDbShowHorizontalGuideLabels, SetDbShowYAxisGridLines, SetDbYAxisLogScale, SetDbYAxisRange, SetDbYAxisShowZero, SetDbYAxisTryCombineAxes } from '../../../../../../API/CustomChartAPI';
import useKeycloak from '../../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { axisGridLineTypes, axisTypes, showHorizontalGuideLabelsTypes, yAxisCombineTypes, yAxisShowZeroTypes } from '../../../../CommonChartTypes';

interface GraphAdvancedSettingsButtonProps {
    visible: boolean,
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void,
    numericColSet?:Set<number>,
}

export default function YAxisSettingsButton({visible, graphSettings, setGraphSettings, numericColSet}: GraphAdvancedSettingsButtonProps) {
    const { token } = useKeycloak();
    const graphId = graphSettings.chartId;

    const { yAxisShowZero, yAxisTryCombineAxes, showYAxisGridLines, 
        showYAxisConstantLineLabels, yAxisConstantLineValue,
        yAxisMinValue, yAxisMaxValue, yAxisLogScaleEnabled } = graphSettings;

    // Check if first y axis is numeric before showing the horizontal guide settings.
    let firstYAxisIsNumeric = false;
    const firstYAxis = graphSettings.yAxes.length > 0 ? graphSettings.yAxes[0] : null;
    const firstYAxisCol = graphSettings.dataSourceColumns.find(i => i.id === firstYAxis?.dataSourceColumnId);
    if (firstYAxisCol != null && numericColSet != null) {
        firstYAxisIsNumeric = numericColSet.has(firstYAxisCol.id);
    }

    // Check if any numeric y axes exist.
    let numericYAxesExist = graphSettings.yAxes.find(i => numericColSet?.has(i.dataSourceColumnId));

    const showHorizontalGuideValueSetting = firstYAxisIsNumeric;
    const showHorizontalGuideLabelSetting = showHorizontalGuideValueSetting && yAxisConstantLineValue != null;

    const showCombineAxesSetting = graphSettings.yAxes.length > 1;

    const contentUI = (
        <div className='advancedSettings'>
            <div className='settingLabel'>
                Horizontal Grid Lines
            </div>
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisGridLineTypes}
                    value={showYAxisGridLines}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = showYAxisGridLines;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, showYAxisGridLines: newVal});

                        // Make the change in the DB
                        SetDbShowYAxisGridLines(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, showYAxisGridLines: oldVal});
                            }
                        });
                    }}/>
            </div>
            {numericYAxesExist &&
            <div className='settingLabel'>
                Scale
            </div>}
            {numericYAxesExist && 
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={yAxisShowZeroTypes}
                    value={yAxisShowZero}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = yAxisShowZero;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisShowZero: newVal});

                        // Make the change in the DB
                        SetDbYAxisShowZero(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisShowZero: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {numericYAxesExist &&
            <div className='settingLabel'>
                Axis Type
            </div>}
            {numericYAxesExist &&
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisTypes}
                    value={yAxisLogScaleEnabled}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = yAxisLogScaleEnabled;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisLogScaleEnabled: newVal});

                        // Make the change in the DB
                        SetDbYAxisLogScale(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisLogScaleEnabled: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {firstYAxisIsNumeric &&
            <div className='settingLabel'>
                Visible Range
            </div>}
            {firstYAxisIsNumeric &&
            <div className='settingSelector'>
                <NumberBox 
                    width={'6em'}
                    placeholder='no min'
                    value={yAxisMinValue ?? undefined}
                    onValueChange={(newVal) => {
                        const oldVal = yAxisMinValue;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisMinValue: newVal});

                        // Make the change in the DB
                        SetDbYAxisRange(token, graphId, newVal, yAxisMaxValue).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisMinValue: oldVal});
                            }
                        });
                    }}/>
                to
                <NumberBox 
                    width={'6em'}
                    placeholder='no max'
                    value={yAxisMaxValue ?? undefined}
                    onValueChange={(newVal) => {
                        const oldVal = yAxisMaxValue;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisMaxValue: newVal});

                        // Make the change in the DB
                        SetDbYAxisRange(token, graphId, yAxisMinValue, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisMaxValue: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {showHorizontalGuideValueSetting &&
            <div className='settingLabel'>
                Horizontal Guide Value
            </div>}
            {showHorizontalGuideValueSetting &&
            <div className='settingSelector'>
                <NumberBox 
                    showClearButton={true}
                    step={0}
                    placeholder={'Enter value to add guide'}
                    value={yAxisConstantLineValue ?? undefined}
                    onValueChange={(newVal) => {
                        const oldVal = yAxisConstantLineValue;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisConstantLineValue: newVal});

                        // Make the change in the DB
                        SetDbHorizontalGuideValue(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisConstantLineValue: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {showHorizontalGuideLabelSetting &&
            <div className='settingLabel'>
                Horizontal Guide Labels
            </div>}
            {showHorizontalGuideLabelSetting &&
            <div className='settingSelector'>
                <SelectBox 
                    labelMode='static'
                    dataSource={showHorizontalGuideLabelsTypes}
                    valueExpr='value'
                    displayExpr='text'
                    value={showYAxisConstantLineLabels}
                    onValueChange={(newVal) => {
                        const oldVal = showYAxisConstantLineLabels;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, showYAxisConstantLineLabels: newVal});

                        // Make the change in the DB
                        SetDbShowHorizontalGuideLabels(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, showYAxisConstantLineLabels: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {showCombineAxesSetting &&
            <div className='settingLabel'>
                Combine Axes
            </div>}
            {showCombineAxesSetting &&
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={yAxisCombineTypes}
                    value={yAxisTryCombineAxes}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = yAxisTryCombineAxes;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, yAxisTryCombineAxes: newVal});

                        // Make the change in the DB
                        SetDbYAxisTryCombineAxes(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, yAxisTryCombineAxes: oldVal});
                            }
                        });
                    }}/>
            </div>}
        </div>
    );

    return (
        <GraphAdvancedSettingsButton
            title='Y Axis Settings' 
            content={contentUI} 
            visible={visible}/>
    )
}