import { DataSourceColumn } from "../DataSourceColumn";
import { TrendlineType } from "../Enums/TrendlineType";
import { CustomSeriesData } from "./CustomSeriesData";
import { YAxis } from "./YAxis";

export enum ChartType {
    Area = 0,
    Bar = 1,
    Line = 2,
    Scatter = 3,
    Spline = 4,
    SplineArea = 5,
    StackedArea = 6,
    Bubble = 7,
    StackedBar = 8,
    FullStackedBar = 9,
    FullStackedArea = 10
}

export enum AxisLabelOverflowMode {
    Stagger = 0,
    Rotate = 1
}

export enum AxisOrderType {
    Alphabetical = 0,
    GraphSet = 1
}

export interface ChartSettings {
    chartId: number;
    title: string;
    graphSetIds: number[];
    dataSourceColumns: DataSourceColumn[];
    dataSourceCategoryIds: number[];
    stackColumnIds: number[];
    xAxisColumnId: number|null;
    yAxes: YAxis[];
    bubbleSizeColumnId: number|null;
    colorByColumnIds: number[];
    showAllSolutions: boolean;
    valuePrecision: number|undefined;
    showXAxisGridLines: boolean;
    showYAxisGridLines: boolean;
    pointLabelMode: PointLabelType;
    yAxisConstantLineValue: number|null;
    showYAxisConstantLineLabels: boolean;
    axisLabelCutoff: number|undefined;
    axisLabelOverflowMode: AxisLabelOverflowMode|undefined;
    axisOrder: AxisOrderType|undefined;
    yAxisShowZero: boolean;
    yAxisTryCombineAxes: boolean;
    customSeriesDataByKey: {[key:string]: CustomSeriesData};
    trendlineType: TrendlineType;
    trendlineOrder: number;
    xAxisLogScaleEnabled: boolean;
    yAxisLogScaleEnabled: boolean;
    xAxisMinValue: number|null;
    xAxisMaxValue: number|null;
    yAxisMinValue: number|null;
    yAxisMaxValue: number|null;
    stringFiltersByColumnId: {[key:number]: string[]};
}

export enum RecommendedType
{
    XAxis = 0,
    YAxis = 1,
    Size = 2,
    GroupSeriesBy = 3
}

export enum PointLabelType {
    None = 0,
    XAxis = 1,
    YAxis = 2,
    XAndYAxis = 3
}