import "./SizingOverridesPopup.css";

import { DataGrid, Popup } from "devextreme-react";
import DesignProperty from "../../../../../Classes/UploadSetInputs/DesignProperty/DesignProperty";
import { useEffect, useMemo, useState } from "react";
import { Column, Paging, Scrolling } from "devextreme-react/data-grid";
import Material from "../../../../../Classes/UploadSetInputs/Material/Material";
import { toSigFigs } from "../../../../../Utilities/CommonUtilities";

interface SizingOverridesPopupProps {
    design:DesignProperty;
    showOverrides:boolean;
    onHiding: () => void;
}

export default function SizingOverridesPopup(props:SizingOverridesPopupProps) {
    const sigFigs = 4;
    const defaultColumnWidth = '5rem';

    const [overrides, setOverrides] = useState<any>(null);

    useEffect(() => {
        // Format overrides object
        const newOverrides = props.design.sizingOverrides.map(o => {
            const newOverride:any = {
                zoneNumber: o.zoneNumber,
                zoneName: o.zoneName
            };

            o.dimensions.forEach((d:any) => {
                const keys = Object.keys(d);
                if (keys.includes('min'))
                    newOverride[d.dimensionName + 'Min'] = d.min;
                if (keys.includes('max'))
                    newOverride[d.dimensionName + 'Max'] = d.max;
                if (keys.includes('steps'))
                    newOverride[d.dimensionName + 'Steps'] = d.steps;
                if (keys.includes('materials'))
                    newOverride[d.dimensionName + 'Materials'] = d.materials;
            });

            return newOverride;
        });
        setOverrides(newOverrides);
    }, [props.design]);

    const renderCellSigFigs = (e:any) => {
        const val = e.value;
        const roundedVal = toSigFigs(val, sigFigs) ?? "";
        return roundedVal;
    }

    const renderHeaderCellSigFigs = (e:any) => {
        const val = e.column.caption;
        const roundedVal = toSigFigs(val, sigFigs) ?? "";
        return roundedVal;
    }
    
    const renderMaterialCell = (e:any) => {
        const mats = e.value;
        return mats?.map((i:Material) => i.name).join(',');
    }
    
    const renderMaterialHeaderCell = (e:any) => {
        const mats = e.column.caption;
        return mats?.map((i:Material) => i.name).join(',');
    }

    const renderPopupContent = () => {
        return (
            <div className="sizingOverridesPopup inputPropertyPopup">
                <DataGrid 
                    dataSource={overrides} 
                    height={'100%'} 
                    columnAutoWidth={false}
                    allowColumnResizing={true}
                    columnResizingMode="nextColumn">
                    <Scrolling useNative={true}/>
                    <Paging enabled={false}/>
                    <Column>
                        <Column cssClass={'defaultHeader'} caption={'Defaults'}>
                            <Column 
                                cssClass={'boldHeader'}
                                dataField={'zoneNumber'} 
                                caption={'Zone ID'} 
                                width={defaultColumnWidth}/>
                            <Column 
                                cssClass={'boldHeader'}
                                dataField={'zoneName'} 
                                caption={'Zone'} 
                                width={'12rem'}/>
                        </Column>
                    </Column>
                    {props.design.designVariables.map((d, index) => {
                        let variableFoundInOverrides = false;
                        overrides?.every((override:any) => {
                            // Check if the prefix of any key contains the dimension
                            const keys = Object.keys(override);
                            keys.every((key:string) => {
                                const dim = d.dimension;
                                if (key.length >= dim.length && key.slice(0, dim.length) === dim) {
                                    variableFoundInOverrides = true;
                                    return false;
                                }
                                return true;
                            });
                            return !variableFoundInOverrides;
                        });
                        return (
                            <Column 
                                caption={d.dimension} 
                                key={`dimCol${index}`} 
                                cssClass={'boldHeader'} 
                                visible={variableFoundInOverrides}>
                                <Column 
                                    caption={d.minBound} 
                                    cssClass={'defaultHeader'}
                                    dataType={'number'}
                                    headerCellRender={renderHeaderCellSigFigs}>
                                    <Column 
                                        cssClass={'boldHeader'}
                                        dataField={d.dimension + 'Min'}
                                        caption={'min'}
                                        dataType={'number'}
                                        cellRender={renderCellSigFigs}
                                        width={defaultColumnWidth}/>
                                </Column>
                                <Column 
                                    caption={d.maxbound} 
                                    cssClass={'defaultHeader'}
                                    dataType={'number'}
                                    headerCellRender={renderHeaderCellSigFigs}>
                                    <Column 
                                        cssClass={'boldHeader'}
                                        dataField={d.dimension + 'Max'}
                                        caption={'max'}
                                        dataType={'number'}
                                        cellRender={renderCellSigFigs}
                                        width={defaultColumnWidth}/>
                                </Column>
                                <Column 
                                    caption={d.steps} 
                                    cssClass={'defaultHeader'}
                                    dataType={'number'}>
                                    <Column 
                                        cssClass={'boldHeader'}
                                        dataField={d.dimension + 'Steps'}
                                        caption={'steps'}
                                        dataType={'number'}
                                        width={defaultColumnWidth}/>
                                </Column>
                                <Column 
                                    caption={d.materials} 
                                    cssClass={'defaultHeader'}
                                    headerCellRender={renderMaterialHeaderCell}>
                                    <Column 
                                        cssClass={'boldHeader'}
                                        dataField={d.dimension + 'Materials'}
                                        caption={'material'}
                                        cellRender={renderMaterialCell}
                                        width={'8rem'}/>
                                </Column>
                            </Column>
                    )})}
                </DataGrid>
            </div>
        );
    } 

    return (
        <Popup 
            visible={props.showOverrides}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={'Dimension Overrides'}
            width={'76rem'}
            height={'38rem'}>
            {renderPopupContent()}
        </Popup>
    );
}