import './ColorBySettingsButton.css';

import { Popover } from 'devextreme-react';
import GraphAdvancedSettingsButton from '../GraphAdvancedSettingsButton';
import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { SetDbSeriesColor } from '../../../../../../API/CustomChartAPI';
import useKeycloak from '../../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { GraphDataSource } from '../../../../../../Classes/Charts/GraphDataSource';
import { ChromePicker } from 'react-color';
import { useRef, useState } from 'react';
import { CustomSeriesData } from '../../../../../../Classes/Charts/CustomSeriesData';

interface GraphAdvancedSettingsButtonProps {
    visible: boolean,
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void;
    graphDataSource:GraphDataSource|null;
}

export default function ColorBySettingsButton({visible, graphSettings, setGraphSettings, graphDataSource}: GraphAdvancedSettingsButtonProps) {
    const { token } = useKeycloak();
    const graphId = graphSettings.chartId;
    const { customSeriesDataByKey } = graphSettings;

    const [currColor, setCurrColor] = useState<string|undefined>();
    const [selectedSeriesIndex, setSelectedSeriesIndex] = useState<number|undefined>();

    const selectedSeriesId = `seriesItem${selectedSeriesIndex}`;

    const setColorDebTimer = useRef<NodeJS.Timeout | undefined>();

    const contentUI = (
        <div className='advancedSettings'>
            <div className='colorBySettingsSeriesList'>
                {graphDataSource?.series.map((seriesItem, seriesIndex) => {
                    let seriesName = seriesItem.displayName;
                    if (seriesName == null || seriesName === '') {
                        seriesName = 'Series 1';
                    }
                    return (
                        <div 
                            id={`seriesItem${seriesIndex}`}
                            key={seriesIndex + seriesItem.color}
                            className='colorBySeriesItem'
                            onClick={() => {
                                setSelectedSeriesIndex(seriesIndex);
                                setCurrColor(seriesItem.color);
                            }}>
                            <div className='colorBySeriesItemIcon' style={{backgroundColor: seriesItem.color}}/>
                            <div className='colorBySeriesItemLabel'>
                                {seriesName}
                            </div>
                        </div>
                    );
                })}
            </div>
            {graphDataSource != null &&
            <Popover
                target={`#${selectedSeriesId}`}
                visible={selectedSeriesIndex != null}
                wrapperAttr={{class: 'colorBySettingsPickerWrapper'}}
                position="left"
                onHiding={() => setSelectedSeriesIndex(undefined)}
                shadingColor="rgba(0, 0, 0, 0.25)">
                    <div className='colorBySettingsPicker'>
                        <ChromePicker
                            color={currColor}
                            onChange={(e) => {
                                const hex = e.hex;
                                const alphaRgba = e.rgb.a ?? 1;
                                const alphaHex = (Math.round(alphaRgba * 255)).toString(16).padStart(2, '0');
                                const hexWithAlpha = hex + alphaHex;
                                setCurrColor(hexWithAlpha);

                                // Debounce the more intense operation
                                const timeOutMS = 200;
                                clearTimeout(setColorDebTimer.current);
                                setColorDebTimer.current = setTimeout(() => {
                                    if (selectedSeriesIndex != null && graphDataSource.series.length > selectedSeriesIndex) {
                                        const seriesItem = graphDataSource.series[selectedSeriesIndex];
                                        const seriesKey = seriesItem.key;

                                        const oldCustomSeriesDataByKey = {...customSeriesDataByKey};
                                        const newCustomSeriesDataByKey = {...oldCustomSeriesDataByKey};
                                        if (seriesKey in newCustomSeriesDataByKey) {
                                            newCustomSeriesDataByKey[seriesKey].color = hexWithAlpha;
                                        }
                                        else {
                                            const newSeriesData = new CustomSeriesData();
                                            newSeriesData.color = hexWithAlpha;
                                            newCustomSeriesDataByKey[seriesKey] = newSeriesData;
                                        }

                                        // Make the change in the UI
                                        setGraphSettings({...graphSettings, customSeriesDataByKey: newCustomSeriesDataByKey})

                                        // Make the change in the DB
                                        SetDbSeriesColor(token, graphId, seriesKey, hexWithAlpha).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, customSeriesDataByKey: oldCustomSeriesDataByKey});
                                            }
                                        });
                                    }
                                }, timeOutMS);
                            }}/>
                    </div>
            </Popover>}
        </div>
    );

    return (
        <GraphAdvancedSettingsButton
            title='Edit Colors' 
            content={contentUI} 
            visible={visible}/>
    )
}