import './UploadSetInputs.css';

import InputList from './InputList';
import { LoadPanel, TagBox } from "devextreme-react";
import { useEffect, useMemo, useRef, useState } from 'react';
import { GetInputs } from '../../../../API/DatasetAPI';
import UploadSetInputsType from '../../../../Classes/UploadSetInputs/UploadSetInputs';
import { APIRequestStatusType } from '../../../../Classes/APIRequestStatus';
import DesignProperty from '../../../../Classes/UploadSetInputs/DesignProperty/DesignProperty';
import AnalysisProperty from '../../../../Classes/UploadSetInputs/AnalysisProperty/AnalysisProperty';
import LoadProperty, { isUserFEALoadProperty } from '../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty';
import DesignPropertyPopup from './DesignProperty/DesignPropertyPopup';
import AnalysisPropertyPopup from './AnalysisProperty/AnalysisPropertyPopup';
import FEALoadPropertyPopup from './LoadProperty/FEALoadPropertyPopup';
import UserFEALoadPropertyPopup from './LoadProperty/UserFEALoadPropertyPopup';
import UserGeneralFEALoadPropertyPopup from './LoadProperty/UserGeneralLoadPropertyPopup';
import notify from 'devextreme/ui/notify';
import UserBoltedLoadPropertyPopup from './LoadProperty/UserBoltedLoadPropertyPopup';
import UserBondedLoadPropertyPopup from './LoadProperty/UserBondedLoadPropertyPopup';
import { FamilyCategory } from '../../../../Classes/Enums/FamilyCategory';
import useKeycloak from '../../../../Keycloak';

interface UploadSetInputsProps {
    uploadSetId: number|undefined;
    isVisible: boolean;
}

export default function UploadSetInputs(props:UploadSetInputsProps) {
    const keycloak = useKeycloak();
    const [inputs, setInputs] = useState<UploadSetInputsType|null>();

    const [designToView, setDesignToView] = useState<DesignProperty|null>(null);
    const [analysisPropertyToView, setAnalysisPropertyToView] = useState<AnalysisProperty|null>(null);
    const [loadPropertyToView, setLoadPropertyToView] = useState<LoadProperty|null>(null);

    const [selectedStructureNames, setSelectedStructureNames] = useState<string[]>(new Array<string>());
    const loadedInputsUploadSetId = useRef<number|undefined>(undefined);

    useEffect(() => {
        const uploadSetIsDiff = loadedInputsUploadSetId.current !== props.uploadSetId;
        if (props.isVisible && (uploadSetIsDiff || inputs == null)) {
            loadNewInputs();
        }
    }, [props.isVisible, props.uploadSetId]);

    const loadNewInputs = () => {
        setInputs(null);
        setSelectedStructureNames(new Array<string>());
        if (props.uploadSetId && keycloak.token) {
            GetInputs(keycloak.token, props.uploadSetId).then((response) => {
                if (response.type !== APIRequestStatusType.Error) {
                    setInputs(response.data);
                    loadedInputsUploadSetId.current = props.uploadSetId;
                }
            });
        }
    }

    const renderFilterTagBox = useMemo(() => {
        // No need to render if loading inputs
        if (inputs == null)
            return <div/>;

        return (
            <div style={{
                flex: '0 1',
                margin: '1rem',
                marginBottom: '0.5rem',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <h3 style={{
                    margin: '0rem',
                    marginRight: '1rem'
                }}>Filter by Structure</h3>
                <TagBox 
                    width={'40rem'}
                    applyValueMode="useButtons"
                    showSelectionControls={true}
                    dataSource={inputs.structureNames}
                    placeholder='Click to select structures...'
                    value={selectedStructureNames}
                    onValueChanged={(e) => setSelectedStructureNames(e.value)}/>
            </div>
        );
    }, [selectedStructureNames, inputs]);

    return (
        <div id={`uploadSetInputs${props.uploadSetId}`} style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
        }}>
            {renderFilterTagBox}
            <div style={{
                marginTop: '1rem',
                flex: '1 1 auto',
                justifyContent: 'space-around',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden'
            }}>
                <LoadPanel
                    container={`#uploadSetInputs${props.uploadSetId}`}
                    visible={inputs == null}/>
                <InputList 
                    title='Designs' 
                    items={inputs?.designs ?? []}
                    showViewButton={(item:DesignProperty) => {
                        // Disabling design properties for now.
                        return false;
                    }}
                    onItemClick={(item:DesignProperty) => {
                        if (item.type == FamilyCategory.Joint) {
                            notify('Joint Design Properties are not currently supported! Please let us know if this impacts your workflow.', 'warning');
                        }
                        else 
                            setDesignToView(item);
                    }}
                    selectedStructureNames={selectedStructureNames}/>
                <InputList 
                    title='Analysis Properties' 
                    items={inputs?.analysisProperties ?? []}
                    showViewButton={(item:AnalysisProperty) => {
                        // Allow all analysis properties to be viewed.
                        return true;
                    }}
                    onItemClick={(item:AnalysisProperty) => {
                        setAnalysisPropertyToView(item);
                    }}
                    selectedStructureNames={selectedStructureNames}/>
                <InputList 
                    title='Load Properties' 
                    items={inputs?.loadProperties ?? []}
                    showViewButton={(item:LoadProperty) => {
                        // Only allow user FEA load properties to be viewed.
                        // More load properties can be allowed once units are supported.
                        return isUserFEALoadProperty(item.type);
                    }}
                    onItemClick={(item:LoadProperty) => {
                        setLoadPropertyToView(item);
                    }}
                    selectedStructureNames={selectedStructureNames}/>
            </div>
            <DesignPropertyPopup
                design={designToView}
                onHiding={() => setDesignToView(null)}/>
            <AnalysisPropertyPopup
                analysisProperty={analysisPropertyToView}
                onHiding={() => setAnalysisPropertyToView(null)}/>
            <FEALoadPropertyPopup
                loadProperty={loadPropertyToView}
                onHiding={() => setLoadPropertyToView(null)}/>
            <UserFEALoadPropertyPopup
                loadProperty={loadPropertyToView}
                onHiding={() => setLoadPropertyToView(null)}/>
            <UserGeneralFEALoadPropertyPopup
                loadProperty={loadPropertyToView}
                onHiding={() => setLoadPropertyToView(null)}/>
            <UserBoltedLoadPropertyPopup
                loadProperty={loadPropertyToView}
                onHiding={() => setLoadPropertyToView(null)}/>
            <UserBondedLoadPropertyPopup
                loadProperty={loadPropertyToView}
                onHiding={() => setLoadPropertyToView(null)}/>
        </div>
    );
}