import './SetNamePopup.css';

import { ToolbarItem } from 'devextreme-react/popup';
import { Popup, TextBox } from 'devextreme-react';
import React from 'react';
import { useEffect } from 'react';

interface SetNamePopupProps {
    title:string;
    applyButtonName:string;
    oldName:string;
    showPopup:boolean;
    infoText?:string;
    placeholder?:string;
    allowEmptyName?:boolean;
    validateForDuplicate?: (name:string) => Promise<boolean>;
    hidePopup:() => void;
    applySetName:(name:string) => void;
}

const defaultProps = {
    infoText: undefined,
    placeholder: undefined,
    validateForDuplicate: undefined,
    allowEmptyName: false
}

export function SetNamePopup(props:SetNamePopupProps) {

    const [name, setName] = React.useState<string>('');
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setIsValid(true);
        setName(props.oldName);
    }, [props.oldName, props.showPopup]);

    const clearErrors = () => {
        setIsValid(true);
        setErrorMessage('');
    }

    const apply = async () => {
        let newErrorMessage = undefined;
        let trimmedName = name.trim();

        let isEmpty = !props.allowEmptyName && trimmedName === '';
        let isDuplicate = props.validateForDuplicate != null && (await props.validateForDuplicate(trimmedName)) && props.oldName !== trimmedName;
        
        if (isDuplicate)
            newErrorMessage = 'An item with that name already exists.';
        else if (isEmpty) 
            newErrorMessage = 'Name cannot be empty.';

        // No errors, apply
        if (newErrorMessage === undefined) {
            props.applySetName(trimmedName);
            props.hidePopup();
        } else {
            setIsValid(false);
            setErrorMessage(newErrorMessage);
        }
    }

    return (
        <Popup 
            title={props.title}
            wrapperAttr={{class: 'setNamePopupWrapper'}}
            width='24rem'
            height={'auto'}
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={() => {
                props.hidePopup();
            }}>
            {/* Unload content to clear validation when hidden */}
            {props.showPopup &&
            <div>
                <TextBox 
                    focusStateEnabled={true}
                    value={name}
                    placeholder={props.placeholder}
                    onEnterKey={apply}
                    isValid={isValid}
                    validationError={
                        {message: errorMessage}
                    }
                    valueChangeEvent='input'
                    onValueChanged={(e) => { 
                        setName(e.value);
                        clearErrors();
                    }}    
                    onInitialized={(e) => {
                        // We must wait a bit here, since focusing immediately does not seem to take.
                        const waitForInit = 400;   
                        setTimeout(() => {  
                            if (e.component) {
                                e.component.focus();  
                            }
                        }, waitForInit);  
                    }}>
                </TextBox>
                {props.infoText != null && 
                <div style={{
                    padding: '0.75rem',
                    paddingBottom: '0rem',
                    fontStyle: 'italic'
                }}>
                    {props.infoText}
                </div>}
            </div>}
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                disabled={!isValid}
                options={{
                    text: props.applyButtonName,
                    onClick: apply
                }}
            />
        </Popup>
    );
}
SetNamePopup.defaultProps = defaultProps;