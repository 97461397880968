import { Dataset } from './Dataset';

// Defines an instance of an upload set within a graph set
export class GraphSetUploadSet {
    uploadSet: Dataset;
    indexInGraphSet: number|undefined;

    constructor(uploadSet:Dataset, index:number|undefined) {
        this.uploadSet = uploadSet;
        this.indexInGraphSet = index;
    }
}