import configureAppStore from "./ConfigureAppStore";
import { Action, ThunkAction } from "@reduxjs/toolkit";

const GlobalStore = configureAppStore();

export type RootState = ReturnType<typeof GlobalStore.getState>;

export type AppDispatch = typeof GlobalStore.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action>

export default GlobalStore;