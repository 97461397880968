import regression, { DataPoint } from 'regression';
import { TrendlineType } from '../Enums/TrendlineType';
import { toSigFigs } from '../../Utilities/CommonUtilities';

export default class RegressionHelper {
    private regressionObj:regression.Result;
    private trendlineType: TrendlineType;

    private constructor(trendlineType:TrendlineType, seriesPoints:DataPoint[], regOptions:regression.Options) {
        this.trendlineType = trendlineType;
        switch (trendlineType) {
            case TrendlineType.Polynomial:
                this.regressionObj = regression.polynomial(seriesPoints, regOptions);
                break;
            case TrendlineType.Exponential:
                this.regressionObj = regression.exponential(seriesPoints, regOptions);
                break;
            case TrendlineType.Logarithmic:
                this.regressionObj = regression.logarithmic(seriesPoints, regOptions);
                break;
            case TrendlineType.Power:
                this.regressionObj = regression.power(seriesPoints, regOptions);
                break;
            case TrendlineType.Linear:
            default:
                this.regressionObj = regression.linear(seriesPoints, regOptions);
                break;
        }
    }

    static getRegressionHelper(trendlineType:TrendlineType, seriesPoints:DataPoint[], regOptions:regression.Options) {
        if (trendlineType !== TrendlineType.None) {
            return new RegressionHelper(trendlineType, seriesPoints, regOptions);
        }
        return null;
    }

    predict(x:number) {
        return this.regressionObj.predict(x);
    }

    getRSquaredString(sigFigs:number|undefined) {
        return toSigFigs(this.regressionObj.r2, sigFigs);
    }

    getEquationUI(sigFigs:number|undefined) {
        let equationUI = <span>Unknown</span>;

        switch (this.trendlineType) {
            case TrendlineType.Linear:
                equationUI = this.getLinearEquationUI(sigFigs);
                break;
            case TrendlineType.Polynomial:
                equationUI = this.getPolyEquationUI(sigFigs);
                break;
            case TrendlineType.Exponential:
                equationUI = this.getExponentialEquationUI(sigFigs);
                break;
            case TrendlineType.Logarithmic:
                equationUI = this.getLogEquationUI(sigFigs);
                break;
            case TrendlineType.Power:
                equationUI = this.getPowerEquationUI(sigFigs);
                break;
        }

        return equationUI;
    }

    // For a linear fit, the coefficients are [a, b] in y = a * x + b. 
    private getLinearEquationUI(sigFigs:number|undefined) {
        const equation = this.regressionObj.equation;
        const numFactors = equation.length;

        // Don't try to display equations with an invalid number of factors
        if (numFactors < 2) {
            return <span>invalid equation</span>
        }

        const m = equation[0];
        const b = equation[1];

        const bSign = b >= 0 ? '+' : '-';

        const displayM= addParensToSciNot(toSigFigs(m, sigFigs));
        const displayB = addParensToSciNot(toSigFigs(Math.abs(b), sigFigs));

        return <span>y = {displayM}x {bSign} {displayB}</span>;
    }

    // For a polynomial fit, the coefficients are [a0, a1, a2, ...aN] in: y = a0 * x ^ N + a1 * x ^ (N - 1) + ... + aN where N is the order (default 2).
    private getPolyEquationUI(sigFigs:number|undefined) {
        const equation = this.regressionObj.equation;
        const numFactors = equation.length;

        // Don't try to display very long equations
        if (numFactors > 15) {
            return <span>very long equation</span>
        }

        const factorUIList:JSX.Element[] = [];

        equation.forEach((factorCoeff, index) => {
            const power = numFactors - index - 1;

            if (index > 0) {
                // Add a + or - sign after the first index
                const sign = factorCoeff >= 0 ? '+' : '-';
                factorUIList.push(<span key={index + '_sign'}> {sign} </span>);
            }

            const coeff = index > 0 ? Math.abs(factorCoeff) : factorCoeff;

            const displayCoeff = addParensToSciNot(toSigFigs(coeff, sigFigs));
            const displayPow = power > 1 ? power : '';

            factorUIList.push(
                <span key={index + '_factor'}>
                    {displayCoeff}{power > 0 ? 'x' : ''}<sup>{displayPow}</sup>
                </span>
            );
        });

        return <span>y = {factorUIList}</span>;
    }

    // For an exponential fit, the coefficients are [a, b] in y = a * e ^ (b * x).
    private getExponentialEquationUI(sigFigs:number|undefined) {
        const equation = this.regressionObj.equation;
        const numFactors = equation.length;

        // Don't try to display equations with an invalid number of factors
        if (numFactors < 2) {
            return <span>invalid equation</span>
        }

        const a = equation[0];
        const b = equation[1];

        const displayA = addParensToSciNot(toSigFigs(a, sigFigs));
        const displayB = addParensToSciNot(toSigFigs(b, sigFigs));

        return <span>y = {displayA}e<sup>{displayB}x</sup></span>;
    }

    // For a logarithmic fit, the coefficients are [a, b] in y = a + b * ln(x). 
    private getLogEquationUI(sigFigs:number|undefined) {
        const equation = this.regressionObj.equation;
        const numFactors = equation.length;

        // Don't try to display equations with an invalid number of factors
        if (numFactors < 2) {
            return <span>invalid equation</span>
        }

        const a = equation[0];
        const b = equation[1];

        const bSign = b >= 0 ? '+' : '-';

        const displayA = addParensToSciNot(toSigFigs(a, sigFigs));
        const displayB = addParensToSciNot(toSigFigs(Math.abs(b), sigFigs));

        return <span>y = {displayA} {bSign} {displayB}ln(x)</span>;
    }

    // For a power fit, the coefficients are [a, b] in y = a * x^b.
    private getPowerEquationUI(sigFigs:number|undefined) {
        const equation = this.regressionObj.equation;
        const numFactors = equation.length;

        // Don't try to display equations with an invalid number of factors
        if (numFactors < 2) {
            return <span>invalid equation</span>
        }

        const a = equation[0];
        const b = equation[1];

        const displayA = addParensToSciNot(toSigFigs(a, sigFigs));
        const displayB = toSigFigs(b, sigFigs);

        return <span>y = {displayA}x<sup>{displayB}</sup></span>;
    }
}

const addParensToSciNot = (numbString:string|number) => {
    if (typeof(numbString) === 'string' && numbString.includes('E')) {
        return `(${numbString})`;
    }
    return numbString;
}