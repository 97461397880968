import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Stores/GlobalStore';
import { AssignUserRole, CreateProgram, DeleteProgram, GetPrograms, RemoveUsers, RenameProgram } from '../API/ProgramsAPI';
import { Program } from '../Classes/UserGroups/Program';
import { ProgramUser } from '../Classes/UserGroups/ProgramUser';
import { GroupRoleType } from '../Classes/UserGroups/GroupRole';
import { removeInvites, sendProgramUserInvitesExternal, sendProgramUserInvitesInternal } from './InvitesDataReducer';
import { Invite } from '../Classes/Invite';

export interface ProgramDataState {
    programs: Program[];
}

const initialState: ProgramDataState = {
    programs: new Array<Program>()
}

export const createProgram : any = createAsyncThunk(
    'ProgramData/createProgram',
    async ({authToken, companyId, name} : { authToken:string, companyId:number, name:string }, thunkAPI) => {
        let newProgram = await CreateProgram(authToken, companyId, name);
        return newProgram;
    }
);

export const getPrograms : any = createAsyncThunk(
    'ProgramData/getPrograms',
    async (authToken:string, thunkAPI) => {
        return await GetPrograms(authToken);
    }
);

export const renameProgram : any = createAsyncThunk(
    'ProgramData/renameProgram',
    async ({authToken, programId, name} : { authToken:string, programId:number, name:string }, thunkAPI) => {
        return await RenameProgram(authToken, programId, name);
    }
);

export const deleteProgram : any = createAsyncThunk(
    'ProgramData/deleteProgram',
    async ({authToken, programId} : { authToken:string, programId:number }, thunkAPI) => {
        return await DeleteProgram(authToken, programId);
    }
);

export const removeUsers : any = createAsyncThunk(
    'ProgramData/removeUsers',
    async ({authToken, programId, userIds, requesterId} : {authToken:string, programId:number, userIds:string[], requesterId:string}, thunkAPI) => {
        let removedUsers = await RemoveUsers(authToken, programId, userIds);
        return {programId, removedUsers, requesterId};
    }
);

export const assignUserRole : any = createAsyncThunk(
    'ProgramData/assignUserRole',
    async ({authToken, programId, userId, role} : {authToken:string, programId:number, userId:string, role:GroupRoleType}, thunkAPI) => {
        let newUser = await AssignUserRole(authToken, programId, userId, role);
        return {programId, newUser};
    }
);

export const ProgramDataReducer = createSlice({
    name: 'programData',
    initialState,
    reducers: {
        programUpdated : (state, action) => {
            if (!action.payload || !action.payload.id) { return }
            state.programs = state.programs.map(p => {
                if (p.id == action.payload.id) {
                    return action.payload;
                } else {
                    return p;
                }
            })
        },
        programRemoved : (state, action) => {
            if (!action.payload) { return }
            state.programs = state.programs.filter(p => p.id !== action.payload);
        },
        removeUIProgramsInCompany : (state, action) => {
            let companyId = action.payload
            if (companyId) {
                state.programs = state.programs.filter(p => p.companyId !== companyId);
            }
        }
    },
    extraReducers: {
        [createProgram.fulfilled] : (state, action) => {
            let newProgram = action.payload; 
            if (newProgram)
                state.programs.push(newProgram);
        },
        [getPrograms.fulfilled] : (state, action) => {
            state.programs = action.payload; 
        },
        [renameProgram.fulfilled] : (state, action) => {
            let program = action.payload as Program;

            if (program) {
                let programToEdit = state.programs.find(c => c.id === program.id);
                if (programToEdit)
                    programToEdit.name = program.name;
            }
        },
        [deleteProgram.fulfilled] : (state, action) => {
            let program = action.payload as Program;
            state.programs = state.programs.filter(c => c.id !== program.id);
        },
        [removeUsers.fulfilled] : (state, action) => {
            let programId = action.payload.programId; 
            let removedUsers = action.payload.removedUsers; 
            let requesterId = action.payload.requesterId;

            let removedUserIds = removedUsers.map((programUser:ProgramUser) => programUser.id);
            let requesterWasRemoved = removedUserIds.includes(requesterId);

            // Just remove the program if user is no longer apart of it
            if (requesterWasRemoved)
                state.programs = state.programs.filter(p => p.id !== programId);
            else {
                state.programs = state.programs.map((program) => {
                    if (program.id === programId) {
                        let newProgram = {...program};
                        newProgram.users = program.users.filter((programUser:ProgramUser) => !removedUserIds.includes(programUser.id));

                        return newProgram;
                    }
                    else {
                        return program;
                    }
                });
            }
        },
        [assignUserRole.fulfilled] : (state, action) => {
            let programId = action.payload.programId; 
            let newUser : ProgramUser = action.payload.newUser;

            if (!newUser)
                return;

            let program = state.programs.find(c => c.id === programId);
            let user = program?.users.find(u => u.id === newUser.id);

            if (user)
                user.role = newUser.role;

            if (program)
                program.users = program?.users.sort((u1, u2) => {
                    // If roles are the same, sort by name
                    if (u1.role === u2.role) {
                        return (u1.displayName > u2.displayName) ? 1 : (u1.displayName < u2.displayName) ? -1 : 0;
                    }
                    // If roles differ, sort by roles
                    return (u1.role > u2.role) ? 1 : -1;
                });
        },
        [removeInvites.fulfilled] : (state, action) => {
            if (action.payload) {

                // Iterate over each company and removed invite, delete if any match up
                action.payload.forEach((invite: Invite) => {
                    state.programs.forEach((program: Program) => {
                        if (invite.programId === program.id) {
                            program.invites = program.invites?.filter((i: Invite) => i.id !== invite.id)
                        }
                    })
                })
            }
        },
        [sendProgramUserInvitesExternal.fulfilled] : (state, action) => {
            // update state for company user list
            if (action.payload) {

                // Iterate over each company and removed invite, add if any match up
                action.payload.sentInvites.forEach((invite: Invite) => {
                    state.programs.forEach((program: Program) => {
                        if (invite.programId === program.id) {
                            program.invites?.push(invite);
                        }
                    })
                })
            }
        },
        [sendProgramUserInvitesInternal.fulfilled] : (state, action) => {
            // update state for company user list
            if (action.payload) {

                // Iterate over each company and removed invite, add if any match up
                action.payload.sentInvites.forEach((invite: Invite) => {
                    state.programs.forEach((program: Program) => {
                        if (invite.programId === program.id) {
                            program.invites?.push(invite);
                        }
                    })
                })
            }
        }
    }
}); 

export const programDataSelector = (state: RootState) => state.programData;
export const { removeUIProgramsInCompany } = ProgramDataReducer.actions;

export default ProgramDataReducer.reducer;