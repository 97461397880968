import { UsageDataPoint } from '../Classes/CollierAdmin/UsageDataPoint';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function GetGeneralCompanyUsageData(authToken:string): Promise<UsageDataPoint[]|null> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("CollierAdmin", "GetGeneralCompanyUsageData");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);

    // If the request failed, don't try to convert to JSON
    if (!res.ok) {
        return null;
    }

    const json = res.json();
    return json;
}