import { Button, Popup, List, Tooltip } from 'devextreme-react';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Stores/GlobalStore';
import { naturalCompare } from '../../Utilities/CommonUtilities';

import './FilterCategory.css';

interface FilterCategoryProps {
    filterOptionsObject: any;
    selectedItemList: string[];
    title: string;
    filterListKey: string;
    selectedConditionsChanged: (args:any, key:string) => void;
}

export function FilterCategory(props: FilterCategoryProps) {

    const loadingUploadSets = useSelector((state:RootState) => state.SetManagementReducer.loadingUploadSets);
    
    const numberOfFieldsLess = 6;
    const numberOfFieldsMore = 12;
    const numberOfItems = props.filterOptionsObject ? Object.keys(props.filterOptionsObject).length : 0;

    const [itemList, setItemList] = React.useState(new Array<string>());
    const [buttonText, setButtonText] = React.useState<string>('More');
    const [fieldsShown, setFieldsShown] = React.useState(numberOfFieldsLess);
    
    const [showPopup, setShowPopup] = React.useState(false);
    const [popupItemList, setPopupItemList] = React.useState(new Array<string>());

    const getMoreText = () => {
        return 'More (' + (numberOfItems-numberOfFieldsLess).toLocaleString() + ')';
    };

    useEffect(() => {
        if (props.filterOptionsObject) {
            const keys = Object.keys(props.filterOptionsObject);
            const sortedKeys = [...keys].sort(naturalCompare);
            setItemList(sortedKeys);
            setPopupItemList(sortedKeys);
            setButtonText(fieldsShown === numberOfFieldsLess ? getMoreText() : 'Less');
        }
    }, [props.filterOptionsObject, fieldsShown]);

    // Don't show filter categories with no available options 
    if (numberOfItems === 0) {
        return (<div/>);
    }

    const renderListItem = (item:string, index:number, isPopup:boolean) => {
        let uiKey = 'listItem_' + props.title + '_' + isPopup + '_' + index;
        return (
            <div>
                <div id={uiKey}>
                    {item}
                </div>
                <Tooltip target={'#' + uiKey} showEvent="dxhoverstart" hideEvent="dxhoverend" position='right'>
                    <div style={{textAlign: 'left'}}>
                        <div>
                            <b>{item}</b>
                        </div>
                        <div className="subtitle1">
                            <span className='small'><b>Upload Sets:</b> <em>{ props.filterOptionsObject[item] }</em></span>
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    }

    return (
        <div className='filterCategory'>
            <h5 className='filterCategoryTitle'>{props.title}</h5>
            <List   
                className='filterList'
                dataSource={itemList.slice(0, fieldsShown)}
                searchEnabled={numberOfItems > numberOfFieldsMore}
                disabled={loadingUploadSets} 
                showSelectionControls={true}
                selectedItems={props.selectedItemList}
                onSelectionChanged={(args) => {
                    props.selectedConditionsChanged(args, props.filterListKey);
                }}
                selectionMode='multiple'                
                scrollingEnabled={false}
                showScrollbar='never'
                itemRender={(item, index) => {
                    return renderListItem(item, index, false);
                }}/>
            <Button 
                className='moreLessButton'
                text={buttonText}
                onClick={() => {
                    let wasMore = buttonText.includes('More');

                    if (wasMore && numberOfItems > numberOfFieldsMore) {
                        setShowPopup(true);
                    } else {
                        setFieldsShown(wasMore ? numberOfFieldsMore : numberOfFieldsLess);
                        setButtonText(wasMore ? 'Less' : getMoreText());
                    }
                }}
                visible={numberOfItems > numberOfFieldsLess}/>
            <Popup
                width='60vw'
                height='80vh'
                visible={showPopup}
                hideOnOutsideClick={true}
                onHiding={() => {
                    setShowPopup(false);
                }}
                title={props.title}>
                <List   
                    className='popupList'
                    dataSource={popupItemList}
                    searchEnabled={true}
                    showSelectionControls={true}
                    useNativeScrolling={true}
                    selectedItems={props.selectedItemList}
                    onSelectionChanged={(args) => {
                        props.selectedConditionsChanged(args, props.filterListKey);
                    }}
                    selectionMode='all'
                    pageLoadMode='scrollBottom'
                    itemRender={(item, index) => {
                        return renderListItem(item, index, true);
                    }}/>
            </Popup>
        </div>
    );
}

export default FilterCategory;