import './GraphSetSelectorBox.css';

import { useMemo, useState } from 'react';
import { DropDownBox, TreeView } from 'devextreme-react';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import { GraphSet } from '../../../../../Classes/GraphSet';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Stores/GlobalStore';
import { GetAllGraphSets, GraphSetFolder } from '../../../../../Classes/GraphSetFolder';
import { Item as TreeItem } from 'devextreme/ui/tree_view';
import { getSortedGraphSetFolders, getSortedGraphSets } from '../GraphSetsList';

type GraphSetSelectorBoxProps = {
    className?: string;
    readOnly?: boolean;
    nullable?: boolean;
    visible?: boolean;
    selectedGraphSetIds: number[];
    onSelectionChange: (graphSetIds:number[]) => void;
    label?: string;
}

export default function GraphSetSelectorBox(props: GraphSetSelectorBoxProps) {
    const className = props.className ?? '';
    const readOnly = props.readOnly ?? false;
    const nullable = props.nullable ?? true;
    const visible = props.visible ?? true;

    const [dropDownRenderContent, setDropDownRenderContent] = useState(<div/>);

    const selectedGraphSetFolderId = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetFolderId);
    const graphSetSortOrder = useSelector((state:RootState) => state.SetManagementReducer.graphSetSortOrder);
    const rootGraphSetFolder = useSelector((state: RootState) => state.SetManagementReducer.rootGraphSetFolder);

    const treeDataSource = useMemo(() => {
        if (rootGraphSetFolder == null)
            return null;

        const convertFolderToTreeItem = (folder:GraphSetFolder) => {
            let treeItem:TreeItem = {
                id: ('folder' + folder.id) ?? -1,
                text: folder.name,
                expanded: selectedGraphSetFolderId === folder.id,
                icon: 'folder'
            };
        
            const sortedFolders = getSortedGraphSetFolders(folder.subFolders, graphSetSortOrder);
            const folderItems = sortedFolders.map((subFolder:GraphSetFolder) => {
                return convertFolderToTreeItem(subFolder);
            });

            const sortedGraphSets = getSortedGraphSets(folder.graphSets, graphSetSortOrder);
            const graphSetItems = sortedGraphSets.map((graphSet:GraphSet) => {
                return convertGraphSetToTreeItem(graphSet);
            });
            treeItem.items = folderItems.concat(graphSetItems);

            treeItem.items.forEach((item:TreeItem) => {
                if (item.expanded || item.selected) {
                    treeItem.expanded = true;
                    return;
                }
            });
    
            return treeItem;
        }
        
        const convertGraphSetToTreeItem = (graphSet:GraphSet) => {
            let treeItem:TreeItem = {
                id: graphSet.graphSetId ?? -1,
                text: graphSet.name,
                expanded: false,
                selected: props.selectedGraphSetIds.includes(graphSet.graphSetId)
            }
            return treeItem;
        }

        const sortedFolders = getSortedGraphSetFolders(rootGraphSetFolder.subFolders, graphSetSortOrder);
        const folderItems = sortedFolders.map((subFolder:GraphSetFolder) => {
            return convertFolderToTreeItem(subFolder);
        });

        const sortedGraphSets = getSortedGraphSets(rootGraphSetFolder.graphSets, graphSetSortOrder);
        const graphSetItems = sortedGraphSets.map((graphSet:GraphSet) => {
            return convertGraphSetToTreeItem(graphSet);
        });
        return folderItems.concat(graphSetItems);
    }, [rootGraphSetFolder, selectedGraphSetFolderId, graphSetSortOrder, props.selectedGraphSetIds]);

    const selectedGraphSetNames = useMemo(() => {
        const allGraphSets = GetAllGraphSets(rootGraphSetFolder);

        const newSelectedNames = new Array<string>();
        props.selectedGraphSetIds.map(id => {
            let name = null;
            allGraphSets.every(gs => {
                if (gs.graphSetId == id) {
                    name = gs.name;
                    return false;
                }
                return true;
            });
            newSelectedNames.push(name ?? 'NULL');
        });

        return newSelectedNames;
    }, [rootGraphSetFolder, props.selectedGraphSetIds]);

    const dropDownUI = useMemo(() => ( 
        <div className={'graphSetSelectorDropdown'}> 
            <TreeView 
                dataSource={treeDataSource}
                height={'100%'}
                noDataText='No Graph Sets to show.'
                rootValue={0}
                searchEnabled={true}
                searchExpr='text'
                selectionMode='multiple'
                selectByClick={true}
                showCheckBoxesMode='normal'
                onSelectionChanged={(e) => {
                    const treeView = e.component;
                    const datasource = treeView.getDataSource();
                    const datasourceItems = datasource.items();
                    const selectedGraphSetIds = getSelectedFromTreeItems(datasourceItems);
                    props.onSelectionChange(selectedGraphSetIds);
                }}/>
        </div>
    ), [treeDataSource, props.onSelectionChange]);

    const showClearButton = nullable && props.selectedGraphSetIds != null && props.selectedGraphSetIds.length > 0;
    return (
        <div className={`graphSetSelectorDropdownButtonDiv ${className}`}>
            <DropDownBox
                className='graphSetSelectorDropdownBox'
                label={props.label}
                dataSource={selectedGraphSetNames}
                value={selectedGraphSetNames}
                placeholder={'Click to select graph sets...'}
                dropDownOptions={{width: '22rem', maxHeight: '30rem'}}
                onOpened={() => setDropDownRenderContent(dropDownUI)}
                onClosed={() => setDropDownRenderContent(<div/>)}
                contentRender={() => dropDownRenderContent}
                visible={visible}
                readOnly={readOnly}>
                {showClearButton &&
                <TextBoxButton
                    name="custom_clear"
                    location="after"
                    options={{
                        icon: 'clear',
                        onClick: () => {
                            props.onSelectionChange([]);
                        }
                    }}/>}
            </DropDownBox>
        </div>
    );
}

const getSelectedFromTreeItems = (items:TreeItem[]) => {
    let selectedIds:number[] = [];
    items.forEach((item => {
        selectedIds = selectedIds.concat(getSelectedFromTreeItem(item));
    }));
    return selectedIds;
}

const getSelectedFromTreeItem = (item:TreeItem) => {
    // Item is graph set
    if (typeof(item?.id) == 'number') {
        return item.selected ? [item.id] : [];
    }
    // Item is folder
    else {
        return getSelectedFromTreeItems(item.items ?? []);
    }
}