import { GraphSet } from "./GraphSet";

export class GraphSetFolder {
    id: number;
    name: string;
    subFolders: GraphSetFolder[];
    graphSets: GraphSet[];
    dateCreated: string|undefined;
    dateModified: string|undefined;

    constructor() {
        this.id = 0;
        this.name = 'Root';
        this.subFolders = [];
        this.graphSets = [];
    } 
}

export class GraphSetSearchResult {
    id: number;
    parentFolderId: number;
    name: string;
    pathName: string;

    constructor(graphSet:GraphSet, folderPath:string, parentFolderId:number) {
        this.id = graphSet.graphSetId;
        this.name = graphSet.name;
        this.pathName = folderPath + graphSet.name;
        this.parentFolderId = parentFolderId;
    } 
}

export function FindFolderInFolder(folder:GraphSetFolder|undefined, targetFolderId:number|null) {
    if (folder == null) {
        return null;
    }
    if (folder.id == targetFolderId) {
        return folder;
    }
    if (folder.subFolders.length == 0) {
        return null;
    }

    let foundTarget = null;
    folder.subFolders.forEach((subFolder:GraphSetFolder) => {
        let tryFindTarget = FindFolderInFolder(subFolder, targetFolderId);
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    })
    return foundTarget ?? null;
}

export function FindFolderParentFolderId(rootFolder:GraphSetFolder|undefined, targetFolderId:number|null) {
    if (rootFolder == null) {
        return null;
    }
    const tryFindFolder = rootFolder.subFolders.find((folder:GraphSetFolder) => folder.id == targetFolderId);
    if (tryFindFolder) {
        return rootFolder.id;
    }
    let foundTarget = null;
    rootFolder.subFolders.forEach((subFolder:GraphSetFolder) => {
        let tryFindTarget = FindFolderParentFolderId(subFolder, targetFolderId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    });
    return foundTarget != null ? foundTarget : null;
}

export function FindGraphSetInFolder(folder:GraphSetFolder|undefined, targetGraphSetId:number|null) {
    if (folder == undefined) {
        return null;
    }
    let tryFindGraphSet = folder.graphSets.find((graphSet:GraphSet) => graphSet.graphSetId == targetGraphSetId);
    if (tryFindGraphSet) {
        return tryFindGraphSet;
    }
    if (folder.subFolders.length == 0) {
        return null;
    }
    let foundTarget = null;
    folder.subFolders.forEach((subFolder:GraphSetFolder) => {
        let tryFindTarget = FindGraphSetInFolder(subFolder, targetGraphSetId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    })
    return foundTarget ?? null;
}

export function FindGraphSetParentFolder(folder:GraphSetFolder|undefined, targetGraphSetId:number|null) {
    if (folder == undefined) {
        return null;
    }
    let tryFindGraphSet = folder.graphSets.find((graphSet:GraphSet) => graphSet.graphSetId == targetGraphSetId);
    if (tryFindGraphSet) {
        return folder;
    }
    if (folder.subFolders.length == 0) {
        return null;
    }
    let foundTarget = null;
    folder.subFolders.forEach((subFolder:GraphSetFolder) => {
        let tryFindTarget = FindGraphSetParentFolder(subFolder, targetGraphSetId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    })
    return foundTarget ?? null;
}

export function FindGraphSetParentFolderId(folder:GraphSetFolder|undefined, targetGraphSetId:number|null) {
    const parentFolder = FindGraphSetParentFolder(folder, targetGraphSetId);
    if (parentFolder == null) {
        return null;
    }
    return parentFolder.id;
}

export function GetAllGraphSets(folder:GraphSetFolder|undefined) {
    if (folder == undefined) {
        return [] as GraphSet[];
    }
    
    let graphSets:GraphSet[] = folder.graphSets as GraphSet[];

    folder.subFolders.forEach((subFolder:GraphSetFolder) => {
        const graphSetsInSubfolder = GetAllGraphSets(subFolder);
        if (graphSetsInSubfolder) {
            graphSets = [...graphSets, ...graphSetsInSubfolder];
        }
    });

    return graphSets;
}

export function CountFoldersInFolder(folder:GraphSetFolder|undefined) {
    let count = 0;
    if (folder != undefined) {
        count += folder.subFolders.length;
        folder.subFolders.forEach((subFolder:GraphSetFolder) => {
            count += CountFoldersInFolder(subFolder);
        })
    }
    return count;
}

export function CountGraphSetsInFolder(folder:GraphSetFolder|undefined) {
    let count = 0;
    if (folder != undefined) {
        count += folder.graphSets.length;
        folder.subFolders.forEach((subFolder:GraphSetFolder) => {
            count += CountGraphSetsInFolder(subFolder);
        })
    }
    return count
}

export function SearchGraphSets(baseFolderId:number, folderToSearch:GraphSetFolder, searchTerm:string, folderPath:string = '') : Array<GraphSetSearchResult> {
    let resultsInFolder = new Array<GraphSetSearchResult>();
    let updatedPath = folderPath + folderToSearch.name + ' → ';
    // No need to include the base folder name in the path.
    if (folderToSearch.id == baseFolderId) {
        updatedPath = '';
    }

    // Search through the graph sets in the current folder
    folderToSearch.graphSets.forEach((graphSet:GraphSet) => {
        const gsNameLower = graphSet.name.toLocaleLowerCase();
        const searchTermLower = searchTerm.toLocaleLowerCase();
        if (gsNameLower.includes(searchTermLower)) {
            resultsInFolder.push(new GraphSetSearchResult(graphSet, updatedPath, folderToSearch.id));
        }
    });

    // Search through child folders
    folderToSearch.subFolders.forEach((subFolder:GraphSetFolder) => {
        const subFolderResults = SearchGraphSets(baseFolderId, subFolder, searchTerm, updatedPath);
        resultsInFolder = resultsInFolder.concat(subFolderResults);
    });

    return resultsInFolder;
}