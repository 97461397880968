import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetDbColumnZoneResults, GetFilteredPlottableProperties, GetPlottableProperties, GetResultsByZone } from '../API/ResultsAPI';
import { RootState } from '../Stores/GlobalStore';
import { UnitSystemType } from '../Classes/User';
import { CachedPreviewImage } from '../Classes/Charts/CachedPreviewImage';
import { GetPreviewImageBlob } from '../API/CustomChartAPI';
import { ResultPropertyInfo } from '../Classes/ResultPropertyInfo';

interface ResultsState {
    cachedZoneResults: {[graphId: number]: any[]};
    cachedFilteredPropertyKeys: {[graphId: number]: string[]};
    cachedPreviewImageLookup: {[graphId: number]: CachedPreviewImage};
    plottablePropertyDictionary: {[propertyKey: string]: ResultPropertyInfo}
    loading: boolean;
}

const initialState: ResultsState = {
    cachedZoneResults: {},
    cachedFilteredPropertyKeys: {},
    plottablePropertyDictionary: {},
    cachedPreviewImageLookup: {},
    loading: false
}

export const fetchPlottableProperties : any = createAsyncThunk(
    'Results/fetchPlottableProperties',
    async (unitSystem:UnitSystemType) => {
        return await GetPlottableProperties(unitSystem);
    }
)

export type FetchZoneResultsProps = {
    authToken:string, 
    graphId:number
}

export const fetchZoneResultsIfNotInCache : any = createAsyncThunk('Results/fetchZoneResultsIfNotInCache',
    async ({ authToken, graphId } : FetchZoneResultsProps, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const cachedZoneResults = state.results.cachedZoneResults;

        let newZoneResults = null;
        if (!(graphId in cachedZoneResults)) {
            newZoneResults = await GetResultsByZone(authToken, graphId);
        }
        return {
            graphId: graphId,
            zoneResults: newZoneResults
        };
    }
)

export const fetchZoneResultsAndOverrideCache : any = createAsyncThunk('Results/fetchZoneResultsAndOverrideCache',
    async ({ authToken, graphId } : FetchZoneResultsProps) => {
        const newZoneResults = await GetResultsByZone(authToken, graphId)
        return {
            graphId: graphId,
            zoneResults: newZoneResults
        };
    }
)

export type FetchFilterPropertiesProps = {
    authToken:string, 
    graphId:number
}
export type FetchFilterPropertiesPayload = {
    graphId:number,
    filteredPropKeys: string[]|null
}
export const fetchFilterPropertiesIfNotInCache : any = createAsyncThunk('Results/fetchFilterPropertiesIfNotInCache',
    async ({ authToken, graphId } : FetchFilterPropertiesProps, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const cachedFilteredPropKeys = state.results.cachedFilteredPropertyKeys;

        let newFilteredPropKeys:string[]|null = null;
        if (!(graphId in cachedFilteredPropKeys)) {
            newFilteredPropKeys = await GetFilteredPlottableProperties(authToken, graphId);
        }
        return {
            graphId: graphId,
            filteredPropKeys: newFilteredPropKeys
        } as FetchFilterPropertiesPayload;
    }
)
export const fetchFilterPropertiesAndOverrideCache : any = createAsyncThunk('Results/fetchFilterPropertiesAndOverrideCache',
    async ({ authToken, graphId } : FetchFilterPropertiesProps) => {
        let newFilteredPropKeys:string[]|null = await GetFilteredPlottableProperties(authToken, graphId);
        return {
            graphId: graphId,
            filteredPropKeys: newFilteredPropKeys
        } as FetchFilterPropertiesPayload;
    }
)

export type FetchZoneResultsForColumnProps = {
    authToken:string, 
    graphId:number,
    dataSourceColumnId:number
}

export const fetchZoneResultsForColumn : any = createAsyncThunk('Results/fetchZoneResultsForColumn',
    async ({ authToken, graphId, dataSourceColumnId } : FetchZoneResultsForColumnProps, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const cachedZoneResults = state.results.cachedZoneResults;

        let newColumnResults = null;
        if (graphId in cachedZoneResults) {
            newColumnResults = await GetDbColumnZoneResults(authToken, dataSourceColumnId);
        }
        return {
            graphId: graphId,
            columnId: dataSourceColumnId,
            columnResults: newColumnResults
        };
    }
)

export interface FetchPreviewImageProps {
    authToken: string,
    graphId: number
}
interface FetchPreviewImagePayload {
    graphId: number,
    imageBlob: Blob|undefined
}
export const fetchPreviewImage: any = createAsyncThunk(
    'Results/fetchPreviewImage',
    async ({ authToken, graphId } : FetchPreviewImageProps, thunkAPI) => {
        const newPreviewImageBlob = await GetPreviewImageBlob(authToken, graphId);
        return {
            graphId: graphId,
            imageBlob: newPreviewImageBlob,
        } as FetchPreviewImagePayload;
    }
)

export const ResultsReducer = createSlice({
    name: 'results',
    initialState,
    reducers: {
        invalidateAll: (state) => {
            state.cachedZoneResults = {};
            state.cachedFilteredPropertyKeys = {};
        },
        setGraphPreviewImage: (state, action) => {
            const { graphId, imageBlob } = action.payload;
            state.cachedPreviewImageLookup[graphId] = new CachedPreviewImage(imageBlob);
        }
    },
    extraReducers : {
        [fetchPlottableProperties.fulfilled] : (state, action) => {
            const newPropDict = action.payload;
            state.plottablePropertyDictionary = newPropDict;
        },
        [fetchZoneResultsIfNotInCache.fulfilled] : (state, action) => {
            const graphId = action.payload.graphId;
            const zoneResults = action.payload.zoneResults;
            if (zoneResults != null) {
                state.cachedZoneResults[graphId] = zoneResults;
            }
        },
        [fetchZoneResultsAndOverrideCache.fulfilled] : (state, action) => {
            const graphId = action.payload.graphId;
            const zoneResults = action.payload.zoneResults;
            if (zoneResults != null) {
                state.cachedZoneResults[graphId] = zoneResults;
            }
        },
        [fetchFilterPropertiesIfNotInCache.fulfilled] : (state, action) => {
            const p = action.payload as FetchFilterPropertiesPayload;
            if (p.filteredPropKeys != null) {
                state.cachedFilteredPropertyKeys[p.graphId] = p.filteredPropKeys;
            }
        },
        [fetchFilterPropertiesAndOverrideCache.fulfilled] : (state, action) => {
            const p = action.payload as FetchFilterPropertiesPayload;
            if (p.filteredPropKeys != null) {
                state.cachedFilteredPropertyKeys[p.graphId] = p.filteredPropKeys;
            }
        },
        [fetchZoneResultsForColumn.fulfilled] : (state, action) => {
            const graphId = action.payload.graphId;
            const columnId = action.payload.columnId;
            const columnResults = action.payload.columnResults;

            if (columnResults != null) {
                const currCache = state.cachedZoneResults[graphId];
                if (currCache.length > 0) {
                    state.cachedZoneResults[graphId] = currCache.map((result, index) => {
                        const currResultToAdd = columnResults[index];
                        const propKeys = Object.keys(currResultToAdd);
                        propKeys.forEach(propKey => {
                            result[propKey] = currResultToAdd[propKey];
                        });
                        return result;
                    });
                }
                // If the current results cache is empty, add the new column as the cached array instead of grafting it on to the existing results.
                else {
                    state.cachedZoneResults[graphId] = columnResults.map((i:any) => ({ [columnId]: i }));
                }   
            }
        },
        [fetchPreviewImage.fulfilled] : (state, action) => {
            const { graphId, imageBlob } = action.payload as FetchPreviewImagePayload;
            if (imageBlob != null)
                state.cachedPreviewImageLookup[graphId] = new CachedPreviewImage(imageBlob);
        }
    }
}); 

export const resultsSelector = (state: RootState) => state.results;
export const { invalidateAll, setGraphPreviewImage } = ResultsReducer.actions;

export default ResultsReducer.reducer;