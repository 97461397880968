import './TemplatePreview.css';

import React, { useRef } from 'react';
import { ChartSettings } from '../../../Classes/Charts/ChartSettingsTypes';
import { GraphTemplate } from '../../../Classes/Charts/GraphTemplates';
import Graph from '../Graph';
import { Chart } from 'devextreme-react';
import { GraphDataSource } from '../../../Classes/Charts/GraphDataSource';

type TemplatePreviewProps = {
    chartSettings: GraphTemplate|undefined;
    savePreviewImageBlob: (imageBlob:Blob|null) => void;
    templateResultsCache: {[key:number]: GraphDataSource};
}

export function TemplatePreview(props: TemplatePreviewProps) {
    const settings = props.chartSettings as ChartSettings | undefined;
    const graphId = settings?.chartId;
    
    const graphDataSource = graphId ? props.templateResultsCache[graphId] : null;

    const chartRef = useRef<Chart>(null);

    const chartUI = () => {
        if (settings == null)
            return <div/>;

        return (
            <div className='templatePreviewDiv'>
                <Graph
                    ref={chartRef}
                    graphSettings={settings}
                    graphDataSource={graphDataSource}
                    showTitle={false}
                    enableZoom={false}
                    setPreviewImage={(imageBlob: Blob) => {
                        props.savePreviewImageBlob(imageBlob);
                    }}/>
            </div>
        );
    }

    return (
        <div className='templatePreviewWithButtonDiv'>
            {chartUI()}
        </div>
    );
}
export default TemplatePreview;