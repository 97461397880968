import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { Company } from '../Classes/UserGroups/Company';
import { CompanyUser } from '../Classes/UserGroups/CompanyUser';
import { GroupRoleType } from '../Classes/UserGroups/GroupRole';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function CreateCompany(authToken:string, name:string): Promise<Company> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", "CreateCompany?name=" + name);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetCompanies(authToken:string): Promise<Company[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", "GetCompanies");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RenameCompany(authToken:string, companyId:number, name:string): Promise<CompanyUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `SetCompanyName?companyId=${companyId}&name=${name}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function DeleteCompany(authToken:string, companyId:number): Promise<CompanyUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `DeleteCompany?companyId=${companyId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RemoveUsers(authToken:string, companyId:number, userIds:string[]): Promise<CompanyUser[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(userIds);
    
    let url = ApiFetchUtility.getFormattedUrl("Company", "RemoveUsers?companyId=" + companyId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function AssignUserRole(authToken:string, companyId:number, userId:string, role:GroupRoleType): Promise<CompanyUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `AssignUserRole?companyId=${companyId}&userId=${userId}&role=${role}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RenameCompanyTag(authToken:string, companyId:number, oldTag:string, newTag:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `RenameCompanyTag?companyId=${companyId}&oldTag=${oldTag}&newTag=${newTag}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function CreateTag(authToken:string, companyId:number, tag:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `AddTagToCompany?companyId=${companyId}&tag=${tag}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function DeleteTag(authToken:string, companyId:number, tag:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `DeleteTag?companyId=${companyId}&tag=${tag}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetTagUploadSetCount(authToken:string, companyId:number, tag:string): Promise<APIResponseWithStatus<number>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(tag);
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `GetTagUploadSetCount?companyId=${companyId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function CompanyNameIsDuplicate(authToken:string|undefined, name:string): Promise<boolean> {

    if (!authToken)
        return false;

    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Company", "IsNameDuplicate?name=" + name); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function TagNameIsDuplicate(authToken:string|undefined, companyId:number|undefined, name:string): Promise<boolean> {

    if (!authToken || !companyId)
        return false;

    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Company", `IsTagNameDuplicate?companyId=${companyId}&name=${name}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}