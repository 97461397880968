import './CreateTemplatePopup.css';

import { ToolbarItem } from 'devextreme-react/popup';
import { Popup, TextArea, TextBox } from 'devextreme-react';
import React, { useEffect } from 'react';
import { CreateGraphTemplate, IsNameDuplicate } from '../../../API/GraphTemplateAPI';
import { APIRequestStatus } from '../../../Classes/APIRequestStatus';
import useKeycloak from '../../../Keycloak';
import { GraphInfo } from '../../../Classes/Charts/GraphInfo';

interface CreateTemplatePopupProps {
    graphInfo: GraphInfo|undefined;
    hidePopup:() => void;
}

export function CreateTemplatePopup({graphInfo, hidePopup}:CreateTemplatePopupProps) {
    const showPopup = graphInfo != null;

    const keycloak = useKeycloak();

    const [name, setName] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    useEffect(() => {
        if (showPopup) {
            setName('');
            setDescription('');
            clearErrors();
        }
    }, [showPopup]);

    const clearErrors = () => {
        setErrorMessage('');
    }

    const createGraphTemplate = async() => {
        if (keycloak.token && graphInfo) {
            const response = await CreateGraphTemplate(keycloak.token, graphInfo.id, name, description);
            APIRequestStatus.showToast(response);
        }
    }

    const apply = async () => {
        // No need to proceed if no keycloak token is loaded.
        if (keycloak.token == null)
            return;

        let newErrorMessage = undefined;
        const trimmedName = name.trim();

        const isEmpty = trimmedName === '';
        const isDuplicate = (await IsNameDuplicate(keycloak.token, trimmedName));
        
        if (isDuplicate)
            newErrorMessage = 'A template with that name already exists.';
        else if (isEmpty) 
            newErrorMessage = 'Name cannot be empty.';

        // No errors, apply
        if (newErrorMessage === undefined) {
            createGraphTemplate();
            hidePopup();
        } else {
            setErrorMessage(newErrorMessage);
        }
    }

    const popupContent = () => {
        // Unload the popup to clear validation when popup is hidden
        if (!showPopup) {
            return <div/>
        }

        return (
            <div className='createTemplateOptions'>
                <TextBox 
                    className='templateNameBox'
                    focusStateEnabled={true}
                    placeholder='Enter a template name...'
                    value={name}
                    onEnterKey={apply}
                    isValid={errorMessage === ''}
                    validationError={
                        {message: errorMessage}
                    }
                    valueChangeEvent='input'
                    onValueChanged={(e) => { 
                        setName(e.value);
                        clearErrors();
                    }}    
                    onInitialized={(e) => {
                        // We must wait a bit here, since focusing immediately does not seem to take.
                        const waitForInit = 400;   
                        setTimeout(() => {  
                            if (e.component) {
                                e.component.focus();  
                            }
                        }, waitForInit);  
                    }}/>
                <TextArea 
                    className='templateDescriptionBox'
                    placeholder='Enter a template description...'
                    value={description}
                    valueChangeEvent='input'
                    onValueChanged={(e) => {
                        setDescription(e.value);
                    }}/>
            </div>
        );
    }

    return (
        <Popup 
            title={'Save as Template'}
            width='24rem'
            height='20rem'
            visible={graphInfo != null}
            hideOnOutsideClick={true}
            onHiding={() => {
                hidePopup();
            }}
            onContentReady={(e) => {
                var html = e.component.content();
                html.style.paddingBottom = '0rem';
            }}>
                {popupContent()}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={errorMessage !== ''}
                    options={{
                        text: 'Save',
                        onClick: apply
                    }}
                />
        </Popup>
    );
}