import './GraphSetManager.css';

import { Button, DropDownButton, RadioGroup } from 'devextreme-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GraphSetNameIsDuplicate, SelectSortOrder } from '../../../../API/GraphSetAPI';
import { GraphSet, SortOrder } from '../../../../Classes/GraphSet';
import { createGraphSet, createUIFolder, setGraphSetSortOrder } from '../../../../Reducers/SetManagementReducer';
import { SetNamePopup } from '../../../SetNamePopup';
import GraphSetFolderBreadcrumbs from './GraphSetFolderBreadcrumbs';
import GraphSetsList from './GraphSetsList';
import GraphSetUploadSets from './GraphSetUploadSets';
import notify from 'devextreme/ui/notify';
import { RootState } from '../../../../Stores/GlobalStore';
import { FindFolderInFolder, GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { CreateFolder, FolderNameIsDuplicate } from '../../../../API/GraphSetFolderAPI';
import { APIResponseWithStatus } from '../../../../Classes/APIResponseWithStatus';
import { APIRequestStatus, APIRequestStatusType } from '../../../../Classes/APIRequestStatus';
import useKeycloak from '../../../../Keycloak';

interface GraphSetManagerProps {
}

export function SetManager(props:GraphSetManagerProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const user = useSelector((state:RootState) => state.userData.user);
    const rootGraphSetFolder = useSelector((state:RootState) => state.SetManagementReducer.rootGraphSetFolder);
    const selectedGraphSetFolderId = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetFolderId);
    const graphSetSortOrder = useSelector((state:RootState) => state.SetManagementReducer.graphSetSortOrder);
    const selectedFolder = FindFolderInFolder(rootGraphSetFolder, selectedGraphSetFolderId);

    const [showCreateGraphSetPopup, setShowCreateGraphSetPopup] = useState<boolean>(false);
    const [showCreateFolderPopup, setShowCreateFolderPopup] = useState<boolean>(false);
    
    const addGraphSet = (name:string) => {
        const newGraphSet = new GraphSet();
        newGraphSet.name = name;
        newGraphSet.dateCreated = (new Date()).toUTCString();
        newGraphSet.dateModified = (new Date()).toUTCString();
        if (user)
            newGraphSet.user = user;

        dispatch(createGraphSet({authToken: keycloak.token, graphSet: newGraphSet})).then((result:any) => {
            const addedGraphSet:GraphSet|undefined = result.payload;
            if (!addedGraphSet) {
                notify('Graph set could not be created.', 'error');
            }
        });
    }

    const isGraphSetNameDuplicate = async (name:string) : Promise<boolean> => {
        if (selectedFolder == null || keycloak.token == null)
            return false;
        return GraphSetNameIsDuplicate(keycloak.token, selectedFolder.id, name);
    }

    const addFolder = (name:string) => {
        if (keycloak.token) {
            CreateFolder(keycloak.token, selectedGraphSetFolderId, name).then((response:APIResponseWithStatus<number>) => {
                if (response.type != APIRequestStatusType.Success) {
                    APIRequestStatus.showToast(response);
                }
                if (response.type != APIRequestStatusType.Error) {
                    const newFolder = new GraphSetFolder();
                    newFolder.id = response.data ?? 0;
                    newFolder.name = name;
                    dispatch(createUIFolder(newFolder));
                }
            });
        }
    }

    const isFolderNameDuplicate = async (name: string): Promise<boolean> => {
        return FolderNameIsDuplicate(keycloak.token, name, selectedGraphSetFolderId);
    }

    return (
        <div className='graphSetManager'>
            <h3 className='graphSetManagerHeader'>
                Graph Sets
                <Button
                    icon="add"
                    type='success'
                    onClick={() => setShowCreateGraphSetPopup(true)}
                    hint='Create graph set'/>
                <Button
                    icon="newfolder"
                    type='success'
                    onClick={() => setShowCreateFolderPopup(true)}
                    hint='Create graph set folder'/>
                <DropDownButton
                    icon="sorted"
                    dropDownContentRender={() => {
                        return (
                            <RadioGroup 
                                style={{
                                    padding: '0.25rem'
                                }}
                                items={[
                                    {
                                        id: SortOrder.Alphabetical,
                                        name: "Alphabetical"
                                    },
                                    {
                                        id: SortOrder.DateModified,
                                        name: "Date Modified"
                                    }
                                ]}
                                value={graphSetSortOrder}
                                displayExpr='name'
                                valueExpr='id'
                                onValueChange={(newVal) => {    
                                    dispatch(setGraphSetSortOrder(newVal));
                                    if (keycloak.token) {
                                        // Update the db
                                        SelectSortOrder(keycloak.token, newVal);
                                    }
                                }}/>
                        );
                    }}
                    dropDownOptions={{
                        width: '9rem'
                    }}
                    hint='Sort order'/>
            </h3>
            {selectedGraphSetFolderId !== 0 && rootGraphSetFolder &&
            <GraphSetFolderBreadcrumbs rootFolder={rootGraphSetFolder} currentFolderId={selectedGraphSetFolderId}/>} 
            <div className='graphSetAndUploadSetLists'>
                {selectedFolder &&
                    <GraphSetsList 
                        selectedFolder={selectedFolder}
                        sortOrder={graphSetSortOrder}/>}
                <GraphSetUploadSets/>
            </div>
            <SetNamePopup
                title='New Graph Set Name'
                validateForDuplicate={isGraphSetNameDuplicate}
                applyButtonName='Create'
                oldName={''}
                showPopup={showCreateGraphSetPopup}
                hidePopup={() => setShowCreateGraphSetPopup(false)}
                applySetName={addGraphSet}/>
            <SetNamePopup
                title='New Folder Name'
                validateForDuplicate={isFolderNameDuplicate}
                applyButtonName='Create'
                oldName={''}
                showPopup={showCreateFolderPopup}
                hidePopup={() => setShowCreateFolderPopup(false)}
                applySetName={addFolder}/>
        </div>
    );
}
export default SetManager;