import './UploadSetList.css'

interface UploadSetTagBoxProps {
    tags: Array<string>;
}

function UploadSetTagBox(props:UploadSetTagBoxProps) {

    if (props.tags.length == 0) {
        return (
            <div className='uploadSetTagBoxEmptyMessage'>
                There are no tags associated with this set
            </div>
        );
    }

    return (
        <div className='uploadSetTagBox'>
            {props.tags.map((tag:string, index:number) => (
                <div className="uploadSetTag" key={index}>{tag}</div>
            ))}
        </div>
    );
    
}
export default UploadSetTagBox;