import './SharedInfoPopup.css';

import { List, Popup } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { GetGraphSetUsers } from '../../../../API/GraphSetAPI';
import { APIRequestStatusType } from '../../../../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../../../../Classes/APIResponseWithStatus';
import { GraphSet } from '../../../../Classes/GraphSet';
import useKeycloak from '../../../../Keycloak';

interface SharedInfoPopupProps {
    graphSet: GraphSet;
    showPopup: boolean;
    hidePopup: () => void;
}

export function SharedInfoPopup(props:SharedInfoPopupProps) {
    const keycloak = useKeycloak();

    const [users, setUsers] = useState<any>();

    useEffect(() => {
        if (props.showPopup) {
            if (keycloak.token) {
                GetGraphSetUsers(keycloak.token, props.graphSet.graphSetId).then((response:APIResponseWithStatus<string[]>) => {
                    if (response.type !== APIRequestStatusType.Error) {
                        const newUsers = response.data;
                        newUsers?.sort();
                        setUsers(newUsers);
                    }
                });
            }
        }
        else {
            setUsers(null);
        }
    }, [props.showPopup]);

    return (
        <Popup 
            wrapperAttr={{class: 'sharingInformationWrapper'}}
            title='Sharing Information'
            width={'32rem'}
            height={'auto'}
            visible={props.showPopup}
            onHiding={() => props.hidePopup()}
            hideOnOutsideClick={true}>
            <div>
                <p style={{marginTop: '0rem'}}>
                    Users who can edit your graphs can also view and edit related graph sets. Therefore, you indirectly share graph sets when you share graphs. Below are all users who can access this graph set.
                </p>
                <List 
                    dataSource={users} 
                    height={'26rem'}
                    searchEnabled={true}
                    useNativeScrolling={true}/>
            </div>
        </Popup>
    );
}
export default SharedInfoPopup;