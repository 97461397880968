import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function GetVersion(): Promise<string> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions();
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl(null, "GetVersion"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}