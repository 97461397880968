import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Stores/GlobalStore';
import { AssignUserRole, CreateCompany, CreateTag, DeleteCompany, DeleteTag, GetCompanies, RemoveUsers, RenameCompany } from '../API/CompaniesAPI';
import { Company } from '../Classes/UserGroups/Company';
import { CompanyUser } from '../Classes/UserGroups/CompanyUser';
import { GroupRoleType } from '../Classes/UserGroups/GroupRole';
import { Invite } from '../Classes/Invite';
import { removeInvites, sendCompanyUserInvites } from './InvitesDataReducer';

export interface CompanyDataState {
    companies: Company[];
}

const initialState: CompanyDataState = {
    companies: new Array<Company>()
}

export const createCompany : any = createAsyncThunk(
    'CompanyData/createCompany',
    async ({authToken, name} : { authToken:string, name:string }, thunkAPI) => {
        let newCompany = await CreateCompany(authToken, name);
        return newCompany;
    }
);

export const getCompanies : any = createAsyncThunk(
    'CompanyData/getCompanies',
    async (authToken:string, thunkAPI) => {
        return await GetCompanies(authToken);
    }
);

export const renameCompany : any = createAsyncThunk(
    'CompanyData/renameCompany',
    async ({authToken, companyId, name} : { authToken:string, companyId:number, name:string }, thunkAPI) => {
        return await RenameCompany(authToken, companyId, name);
    }
);

export const deleteCompany : any = createAsyncThunk(
    'CompanyData/deleteCompany',
    async ({authToken, companyId} : { authToken:string, companyId:number }, thunkAPI) => {
        return await DeleteCompany(authToken, companyId);
    }
);

export const removeUsers : any = createAsyncThunk(
    'CompanyData/removeUsers',
    async ({authToken, companyId, userIds, requesterId} : {authToken:string, companyId:number, userIds:string[], requesterId:string}, thunkAPI) => {
        let removedUsers = await RemoveUsers(authToken, companyId, userIds);
        return {companyId, removedUsers, requesterId};
    }
);

export const assignUserRole : any = createAsyncThunk(
    'CompanyData/assignUserRole',
    async ({authToken, companyId, userId, role} : {authToken:string, companyId:number, userId:string, role:GroupRoleType}, thunkAPI) => {
        let newUser = await AssignUserRole(authToken, companyId, userId, role);
        return {companyId, newUser};
    }
);

export const createTag : any = createAsyncThunk(
    'CompanyData/createTag',
    async ({authToken, companyId, tag} : {authToken:string, companyId:number, tag:string}, thunkAPI) => {
        let createdTag = await CreateTag(authToken, companyId, tag) ? tag : undefined;
        return {companyId, createdTag};
    }
);

export const deleteTag : any = createAsyncThunk(
    'CompanyData/deleteTag',
    async ({authToken, companyId, tag} : {authToken:string, companyId:number, tag:string}, thunkAPI) => {
        let deletedTag = await DeleteTag(authToken, companyId, tag) ? tag : undefined;
        return {companyId, deletedTag};
    }
);

export const CompanyDataReducer = createSlice({
    name: 'companyData',
    initialState,
    reducers: {
        outgoingInviteRemoved : (state, action) => {
            /* TODO: remove this
            // removed invite from array
            if (!action.payload || !action.payload.id) { return }
            //state.sentInvites = state.receivedInvites.filter(i => i.id != action.payload.id)
            const company = state.companies.find(c => action.payload.companyId == c.id);
            if (company) {
                company.invites = company.invites?.filter(i => i.id != action.payload.id)
            }
            */
        },
        companyUpdated : (state, action) => {
            if (!action.payload || !action.payload.id) { return }
            state.companies = state.companies.map(c => {
                if (c.id == action.payload.id) {
                    return action.payload;
                } else {
                    return c;
                }
            })
        },
        companyRemoved : (state, action) => {
            if (!action.payload) { return }
            state.companies = state.companies.filter(c => c.id !== action.payload);
        }
    },
    extraReducers: {
        [createCompany.fulfilled] : (state, action) => {
            let newCompany = action.payload; 
            if (newCompany)
                state.companies.push(newCompany);
        },
        [getCompanies.fulfilled] : (state, action) => {
            state.companies = action.payload; 
        },
        [renameCompany.fulfilled] : (state, action) => {
            let company = action.payload as Company;

            if (company) {
                let companyToEdit = state.companies.find(c => c.id === company.id);
                if (companyToEdit)
                    companyToEdit.name = company.name;
            }
        },
        [deleteCompany.fulfilled] : (state, action) => {
            let company = action.payload as Company;
            state.companies = state.companies.filter(c => c.id !== company.id);
        },
        [removeUsers.fulfilled] : (state, action) => {
            let companyId = action.payload.companyId; 
            let removedUsers = action.payload.removedUsers; 
            let requesterId = action.payload.requesterId;

            let removedUserIds = removedUsers.map((companyUser:CompanyUser) => companyUser.id);
            let requesterWasRemoved = removedUserIds.includes(requesterId);

            // Just remove the company if user is no longer apart of it
            if (requesterWasRemoved)
                state.companies = state.companies.filter(c => c.id !== companyId);
            else {
                state.companies = state.companies.map((company) => {
                    if (company.id === companyId) {
                        let newCompany = {...company};
                        newCompany.users = newCompany.users.filter((companyUser:CompanyUser) => !removedUserIds.includes(companyUser.id));
                        return newCompany;
                    }
                    else {
                        return company;
                    }
                });
            }
        },
        [assignUserRole.fulfilled] : (state, action) => {
            let companyId = action.payload.companyId; 
            let newUser : CompanyUser = action.payload.newUser;

            if (!newUser)
                return;

            let company = state.companies.find(c => c.id === companyId);
            let user = company?.users.find(u => u.id === newUser.id);

            if (user)
                user.role = newUser.role;

            if (company)
                company.users = company?.users.sort((u1, u2) => {
                    // If roles are the same, sort by name
                    if (u1.role === u2.role) {
                        return (u1.displayName > u2.displayName) ? 1 : (u1.displayName < u2.displayName) ? -1 : 0;
                    }
                    // If roles differ, sort by roles
                    return (u1.role > u2.role) ? 1 : -1;
                });
        },
        [createTag.fulfilled] : (state, action) => {
            let companyId = action.payload.companyId; 
            let createdTag : string|undefined = action.payload.createdTag;

            if (!createdTag)
                return;

            // Update company tags
            state.companies.forEach((company:Company) => {
                if (company.id === companyId) {
                    company.tags.push(createdTag!);
                }
            });
        },
        [deleteTag.fulfilled] : (state, action) => {
            let companyId = action.payload.companyId; 
            let deletedTag : string|undefined = action.payload.deletedTag;

            if (deletedTag == null)
                return;

            // Update company tags
            state.companies.forEach((company:Company) => {
                if (company.id === companyId) {
                    company.tags = company.tags.filter(tag => tag !== deletedTag);
                }
            });
        },
        [removeInvites.fulfilled] : (state, action) => {
            if (action.payload) {

                // Iterate over each company and removed invite, delete if any match up
                action.payload.forEach((invite: Invite) => {
                    state.companies.forEach((company: Company) => {
                        if (invite.companyId === company.id) {
                            company.invites = company.invites?.filter((i: Invite) => i.id !== invite.id)
                        }
                    })
                })
            }
        },
        [sendCompanyUserInvites.fulfilled] : (state, action) => {
            // update state for company user list

            if (action.payload) {

                // Iterate over each company and removed invite, add if any match up
                action.payload.sentInvites.forEach((invite: Invite) => {
                    state.companies.forEach((company: Company) => {
                        if (invite.companyId === company.id) {
                            company.invites.push(invite);
                        }
                    })
                })
            }
        },
    }
}); 

export const companyDataSelector = (state: RootState) => state.companyData;

export default CompanyDataReducer.reducer;