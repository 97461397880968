import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { GraphSet } from '../Classes/GraphSet';
import { GraphSetUploadSet } from '../Classes/GraphSetUploadSet';
import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';

export async function CreateGraphSet(authToken:string, graphSet:GraphSet, parentFolderId:number): Promise<number> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(graphSet);
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `Create?parentFolderId=${parentFolderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetGraphSetsByUserId(authToken:string): Promise<Array<GraphSet>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", "GetGraphSets"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetGraphSetUploadSets(authToken:string, graphSetId:number): Promise<Array<GraphSetUploadSet>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `GetGraphSetUploadSets?graphSetId=${graphSetId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RenameGraphSet(authToken:string, graphSetId:number, name:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `RenameGraphSet?graphSetId=${graphSetId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteGraphSet(authToken:string, graphSetId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", "Delete?graphSetId=" + graphSetId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetGraphSetUploadSetCount(authToken:string, graphSetId:number): Promise<APIResponseWithStatus<number>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", "GetGraphSetUploadSetCount?graphSetId=" + graphSetId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GraphSetNameIsDuplicate(authToken:string, parentFolderId:number, name:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `IsNameDuplicate?parentFolderId=${parentFolderId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function MoveUploadSets(authToken:string, sourceGraphSetId:number, destinationGraphSetId:number, 
    destinationIndex:number, uploadSetIdsToMove:number[], addNewToEnd:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(uploadSetIdsToMove);

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `MoveUploadSets`
        + `?sourceGraphSetId=${sourceGraphSetId}`
        + `&destinationGraphSetId=${destinationGraphSetId}`
        + `&destinationIndex=${destinationIndex}`
        + `&addNewToEnd=${addNewToEnd}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveUploadSetsFromGraphSet(authToken:string, graphSetId:number, uploadSetIds:number[]): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(uploadSetIds);

    let url = ApiFetchUtility.getFormattedUrl("GraphSet", "RemoveUploadSetsFromGraphSet?graphSetId=" + graphSetId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function MoveGraphSet(authToken:string, graphSetId:number, destinationFolderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `MoveGraphSet?graphSetId=${graphSetId}&destinationFolderId=${destinationFolderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetGraphSetUsers(authToken:string, graphSetId:number): Promise<APIResponseWithStatus<string[]>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `GetGraphSetUsers?graphSetId=${graphSetId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetSortOrder(authToken:string): Promise<APIResponseWithStatus<number>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `GetSortOrder`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function SelectSortOrder(authToken:string, sortOrder:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSet", `SelectSortOrder?sortOrder=${sortOrder}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}