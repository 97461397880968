import './OptionSelector.css';

import { useEffect, useMemo, useRef, useState } from 'react';
import { RadioGroup, ScrollView, SelectBox, TextBox } from 'devextreme-react';
import { ResultPropertyInfo } from '../../../../../Classes/ResultPropertyInfo';
import OptionGroupSelector from './OptionGroupSelector';
import { distance } from 'fastest-levenshtein';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';
import { AggregationType, AggregationTypeListItem, aggregationTypeDict, aggregationTypeList } from '../../../../../Classes/Enums/AggregationType';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Stores/GlobalStore';

type OptionSelectorPopupProps = {
    show: boolean;
    onHiding: () => void;
    graphId: number;
    currentKey: string|undefined;
    selectedAggType: AggregationType;
    propertyKeyLookup: {[key: string]: ResultPropertyInfo};
    numericOnly: boolean;
    onApply: (newKey: string | undefined, newAggType: AggregationType) => void;
    aggregationSettingsNeeded: boolean;
}

const defaultProps = {
    defaultSelectedGroupName: [],
    aggregationSettingsNeeded: true
}

export default function OptionSelectorPopup(props: OptionSelectorPopupProps) {
    const searchDebounceTime = 250;

    const [filteredAggTypeList, setFilteredAggTypeList] = useState<AggregationTypeListItem[]>([]);
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [selectedAggType, setSelectedAggType] = useState<AggregationType>(AggregationType.First);

    const [orgHierarchy, setOrgHierarchy] = useState<any>({});
    const [selectedGroupSections, setSelectedGroupSections] = useState<Array<OptionSelectorSection>>(new Array<OptionSelectorSection>());

    const cachedFilteredPropertyKeys = useSelector((state:RootState) => state.results.cachedFilteredPropertyKeys);
    const filteredPropertyKeys = cachedFilteredPropertyKeys[props.graphId];

    const specialAggregationMessage = useMemo(() => {
        if (selectedKey != null && selectedKey in props.propertyKeyLookup) {
            return props.propertyKeyLookup[selectedKey].specialAggregationDescription;
        }
        else {
            return null;
        }
    }, [props.propertyKeyLookup, selectedKey]);
    
    useEffect(() => {
        const newTypeList = getNewFilteredAggTypeList(selectedKey, props.propertyKeyLookup, props.numericOnly);
        setFilteredAggTypeList(newTypeList);
    }, [props.numericOnly, props.propertyKeyLookup, selectedKey]);

    const [searchText, setSearchText] = useState<string>('');
    const [searchResultList, setSearchResultList] = useState<Array<any>>(new Array<any>());
    
    const searchDebounceTimer = useRef<any | undefined>();
    const searchBoxRef = useRef<TextBox>(null);

    const [selectedSubgroupPath, setSelectedSubgroupPath] = useState(['Upload Metadata']);

    useEffect(() => {
        if (selectedKey && selectedKey in props.propertyKeyLookup) {
            const selectedPropInfo = props.propertyKeyLookup[selectedKey] as ResultPropertyInfo;
            setSelectedSubgroupPath([...selectedPropInfo.groupPath]);
        }
    }, [selectedKey, props.propertyKeyLookup]);

    // Auto-focus the search bar when this popup opens. We need to wait a bit before focusing for it to actually take.
    useEffect(() => {
        setTimeout(function () {  
            if (props.show && searchBoxRef.current != null) {
                searchBoxRef.current.instance.focus();
            }
        }, 400);
    }, [props.show])

    // Update the selected values from the props whenever the popup is opened.
    useEffect(() => {
        if (props.show) {
            setSelectedKey(props.currentKey);
            setSelectedAggType(props.selectedAggType);
        }
    }, [props.currentKey, props.selectedAggType, props.show])

    useEffect(() => {
        let newOrgHierarchy:any = {};

        const propKeys = Object.keys(props.propertyKeyLookup);
        const irrelKeys = new Set<string>(filteredPropertyKeys);
        propKeys.forEach(k => {
            // If this option should be filtered out, just return to skip it.
            if (shouldFilterOutOption(props.currentKey, k, props.propertyKeyLookup, props.numericOnly, irrelKeys))
            {
                return;
            }

            const resPropInfo:ResultPropertyInfo = props.propertyKeyLookup[k];
            if (resPropInfo.groupPath && resPropInfo.groupPath.length > 0) {
                // Create group hierarchy
                let orgTier:any = newOrgHierarchy;
                const lastGroupIndex = resPropInfo.groupPath.length - 1;
                resPropInfo.groupPath.forEach((g:string, index:number) => {
                    if (!(g in orgTier)) {
                        orgTier[g] = {};
                    }

                    if (index === lastGroupIndex) {
                        // At this point, orgTier is the last group in the hierarchy. 
                        // If the group is not a list of sections, make it a list of sections.
                        if (!Array.isArray(orgTier[g])) {
                            orgTier[g] = new Array<OptionSelectorSection>();
                        }

                        // Search the group to see if the current section exists.
                        let foundSection = false;
                        orgTier[g].forEach((section:OptionSelectorSection) => {
                            if (section.name === resPropInfo.sectionName) {
                                foundSection = true;
                                section.options.push(k);
                            }
                        });

                        // If no section was found, create one.
                        if (!foundSection) {
                            let newSection = new OptionSelectorSection(resPropInfo.sectionName);
                            newSection.options.push(k);
                            orgTier[g].push(newSection);
                        }
                    }

                    orgTier = orgTier[g];
                });
            }
        });

        setOrgHierarchy(newOrgHierarchy);
    }, [props.propertyKeyLookup, props.numericOnly, props.currentKey, filteredPropertyKeys]);

    useEffect(() => {
        const orderOptionsBySearch = () => {
            const sortablePropsList = new Array<any>();
    
            const propKeys = Object.keys(props.propertyKeyLookup);
            const irrelKeys = new Set<string>(filteredPropertyKeys);
            propKeys.forEach((key:string) => {
                // If this option should be filtered out, just return to skip it.
                if (shouldFilterOutOption(props.currentKey, key, props.propertyKeyLookup, props.numericOnly, irrelKeys))
                {
                    return;
                }
    
                const prop:ResultPropertyInfo = props.propertyKeyLookup[key];

                const lowerDisplayName = prop.displayName.toLowerCase();
                const lowerSearchText = searchText.toLowerCase();

                let propListItem = {
                    key: key,
                    displayName: prop.displayName,
                    isExactMatch: lowerDisplayName === lowerSearchText,
                    containsSearchText: lowerDisplayName.includes(lowerSearchText),
                    percentageMatch: percentMatch(lowerSearchText, lowerDisplayName)
                } as any;

                const shortenedDisplayName = lowerDisplayName.substring(0, Math.min(searchText.length, prop.displayName.length));
                propListItem.isExactMatchSoFar = searchText === shortenedDisplayName;
    
                const percentToMatch = 65;
                if (propListItem.isExactMatch || propListItem.isExactMatchSoFar || propListItem.containsSearchText || propListItem.percentageMatch >= percentToMatch) {
                    sortablePropsList.push(propListItem);
                }
            });
    
            function sortByBooleanProperty(list:any[], propKey:string) {
                return list.sort((a,b) => {
                    if (a[propKey] && !b[propKey])
                        return -1;
                    if (!a[propKey] && b[propKey])
                        return 1;
                    return 0;
                });
            }

            // Tie break alphabetically
            let sortedPropsList = sortablePropsList.sort((a,b) => naturalCompare(a,b));

            // Sort by percentage match (least important after the alphabetical sort)
            sortedPropsList.sort((a,b) => b.percentageMatch - a.percentageMatch)

            // Sort the list using various criteria, sorting by the most important criteria last.
            sortByBooleanProperty(sortedPropsList, 'containsSearchText');
            sortByBooleanProperty(sortedPropsList, 'isExactMatchSoFar');
            sortByBooleanProperty(sortedPropsList, 'isExactMatch');
    
            // Limit the search results to a max number of results.
            const maxResults = 50;
            const slicedPropsList = sortedPropsList.slice(0, Math.min(maxResults, sortedPropsList.length));

            setSearchResultList(slicedPropsList);
        }

        if (searchText != null && searchText !== '') {
            if (searchDebounceTimer !== undefined)
                clearTimeout(searchDebounceTimer.current);

            searchDebounceTimer.current = setTimeout(() => {
                orderOptionsBySearch();
            }, searchDebounceTime);
        }
    }, [props.numericOnly, props.propertyKeyLookup, searchText, props.currentKey, filteredPropertyKeys])

    // Get the Levenshtein distance normalized by the length of the strings
    const percentMatch = (s1:string, s2:string) => {
        const maxLen = Math.max(s1.length, s2.length);
        const pMatch = ((maxLen - distance(s1.toLowerCase(), s2.toLowerCase())) * 100) / maxLen;

        return pMatch;
    }

    const getSectionUI = (section:OptionSelectorSection, key:string) => {
        const sortedOptions = section.options.sort((opt1:string, opt2:string) => {
            // If the options cannot be found in the lookup, just don't sort them.
            if (!(opt1 in props.propertyKeyLookup && opt2 in props.propertyKeyLookup)) {
                return 0;
            }
            const name1 = props.propertyKeyLookup[opt1].shortName;
            const name2 = props.propertyKeyLookup[opt2].shortName;
            return naturalCompare(name1, name2);
        });
        return (
            <div key={key} className='optionsSelectorBodySection'>
                <h5>{section.name}</h5>
                <RadioGroup 
                    dataSource={sortedOptions}
                    itemRender={(key:any) => {
                        let name:string = 'Option not found';
                        if (key in props.propertyKeyLookup) {
                            name = props.propertyKeyLookup[key].shortName;
                        }
                        return <div>{name}</div>;
                    }}
                    value={selectedKey}
                    onValueChange={(val) => {
                        setSelectedKey(val);
                        assignDefaultAggregationType(val);
                    }}/>
            </div>
        );
    }

    // Update the aggregation type when the selected key changes. Since different properties have different aggregation types available to them, 
    // we'll want to set the aggregation mode to the recommend value for a given type when it is selected.
    const assignDefaultAggregationType = (newSelectedKey:string|undefined) => {
        // If this property has a default aggregation type specified, use that type.
        const defAggType = newSelectedKey != null ? props.propertyKeyLookup[newSelectedKey]?.defaultAggregationType : null;
        if (defAggType != null && defAggType !== AggregationType.First) {
            setSelectedAggType(defAggType);
        }
        else {
            // We've got to use getNewFilteredAggTypeList instead of filteredAggTypeList since filteredAggTypeList may not be updated in time after selectedKey updates.
            const newTypeList = getNewFilteredAggTypeList(newSelectedKey, props.propertyKeyLookup, props.numericOnly);
            if (newTypeList.length > 0) {
                const firstAggType = newTypeList[0].type;
                setSelectedAggType(firstAggType);
            }
        }
    }

    const sortedGroupSections = selectedGroupSections.sort((a:OptionSelectorSection, b:OptionSelectorSection) => {
        return (a.name > b.name) ? 1 : -1;
    });
    
    // No need to show aggregation controls if pointType is zone, since the only options in that case are raw values.
    const showAggregationTypeControls = props.aggregationSettingsNeeded && filteredAggTypeList.length > 1;
    const aggregationTypeDescription = specialAggregationMessage != null ? specialAggregationMessage : aggregationTypeDict.get(selectedAggType)?.description;

    return (
        <Popup
            title={'Data Type Selector'}
            visible={props.show}
            onHiding={props.onHiding}
            hideOnOutsideClick={true}
            wrapperAttr={{class: 'optionSelectorPopupWrapper'}}
            width={'auto'}
            height={'auto'}>
            <div
                className='optionSelectorPopupContent'>
                <div 
                    className='optionsSelectorDiv' 
                    style={{
                        width: '35em', 
                        height: '35em'
                    }}>
                    <div className='optionsSelectorHeader'>
                        <TextBox 
                            ref={searchBoxRef}
                            mode='search' 
                            placeholder='Search data types across all dropdowns...'
                            value={searchText} 
                            onValueChange={(val) => setSearchText(val)}
                            valueChangeEvent='keyup'/>
                        {searchText === '' &&
                        <OptionGroupSelector 
                            optionGroup={orgHierarchy}
                            setSelectedGroupSections={setSelectedGroupSections}
                            selectedSubgroupPath={selectedSubgroupPath}/>}
                    </div>
                    <div className='optionsSelectorBody'>
                        <ScrollView height={'100%'} useNative={true}>
                            <div className='optionsSelectorSectionsList'>
                                {searchText === '' && sortedGroupSections.map((section:OptionSelectorSection, index:number) => 
                                    getSectionUI(section, `optionsSelectorBodySection${index}`))}
                                {searchText !== '' &&
                                <RadioGroup
                                    dataSource={searchResultList}
                                    displayExpr={'displayName'}
                                    valueExpr={'key'}
                                    value={selectedKey}
                                    onValueChange={(newVal: string | undefined) => {
                                        setSelectedKey(newVal);
                                        assignDefaultAggregationType(newVal);
                                    }}/>}
                            </div>
                        </ScrollView>
                    </div>
                    <div className='aggregationFunctionDiv'>
                        {showAggregationTypeControls &&
                        <div className='aggregationFunctionSelectorDiv'>
                            <div>Aggregation Function</div>
                            <SelectBox
                                dataSource={filteredAggTypeList}
                                valueExpr={'type'}
                                displayExpr={'name'}
                                disabled={selectedKey == null}
                                value={selectedAggType}
                                onValueChange={(newVal) => {
                                    setSelectedAggType(newVal);
                                }}/>
                        </div>}
                        {props.aggregationSettingsNeeded && selectedKey != null && 
                        <div className='aggregationFunctionDescription'>{aggregationTypeDescription}</div>}
                    </div>
                </div>
            </div>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                disabled={selectedKey == null}
                options={{
                    text: 'Select',
                    onClick: () => {
                        props.onApply(selectedKey, selectedAggType);
                        props.onHiding();
                    }
                }}/>
        </Popup>
    );
}
OptionSelectorPopup.defaultProps = defaultProps;

export class OptionSelectorSection {
    name:string;
    options: Array<string>;

    constructor(name:string) {
        this.name = name;
        this.options = new Array<string>();
    }
}

const shouldFilterOutOption = (currKey:string|undefined, optionKey:string, propertyKeyLookup:{[key: string]: ResultPropertyInfo}, 
    numericOnly:boolean, irrelevantKeys:Set<string>) => {

    // If this option is selected, never filter it out.
    if (currKey === optionKey) {
        return false;
    }

    // If this key is not found in the graph set data, don't show it as an option
    if (irrelevantKeys.has(optionKey)) {
        return true;
    }

    const propInfo = propertyKeyLookup[optionKey];

    // Filter out non-numeric values if numericOnly is true.
    const isNumeric = propInfo.isNumeric;
    if (numericOnly && !isNumeric) {
        return true;
    }

    // Don't show misc category options by default (only if one is selected already).
    if (propInfo.groupPath.length > 0 && propInfo.groupPath[0].includes('Misc')) {
        return true;
    }

    return false;
}

const getNewFilteredAggTypeList = (currSelectedKey:string|undefined, propertyKeyLookup:any, numericOnly:boolean) => {
    // Just use "First" if no key is selected.
    if (currSelectedKey == null) {
        const defaultAggType = aggregationTypeDict.get(AggregationType.First) ?? aggregationTypeList[0];
        return [defaultAggType];
    }

    const currKeyIsNumeric = currSelectedKey != null && propertyKeyLookup != null 
    && currSelectedKey in propertyKeyLookup && propertyKeyLookup[currSelectedKey].isNumeric;

    const newAggTypeList = aggregationTypeList.filter(i => {
        var filterOutKey = false;

        // If this property has a special aggregation description, only allow the aggregation mode to be custom.
        // Do this by filtering out all non-custom modes.
        if (currSelectedKey in propertyKeyLookup && propertyKeyLookup[currSelectedKey].specialAggregationDescription != null) {
            return i.type === AggregationType.Aggregate;
        }

        // If the curr key is not numeric but the aggregation type is numeric only, filter out the type.
        if (!currKeyIsNumeric && i.numericOnly) {
            filterOutKey = true;
        }

        // If we only want to allow numeric results, we need to filter out aggregation types that do not always produce numeric results.
        if (numericOnly && !i.alwaysProducesNumericResult) {
            filterOutKey = true;
        }

        return i.showByDefault && !filterOutKey;
    });
    return newAggTypeList;
}