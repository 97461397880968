import "./AnalysisPropertyPopup.css";

import { useState } from "react";
import AnalysisProperty from "../../../../../Classes/UploadSetInputs/AnalysisProperty/AnalysisProperty";
import FailureMode from "../../../../../Classes/UploadSetInputs/AnalysisProperty/FailureMode";
import { Popup, ScrollView, TextBox } from "devextreme-react";
import FailureModePopup from "./FailureModePopup";
import { FamilyCategory } from "../../../../../Classes/Enums/FamilyCategory";

interface AnalysisPropertyPopupProps {
    analysisProperty:AnalysisProperty|null;
    onHiding: () => void;
}

export default function AnalysisPropertyPopup(props:AnalysisPropertyPopupProps) {
    const [failureModeToShow, setFailureModeToShow] = useState<FailureMode|null>(null);

    const failureModeListItem = (f:FailureMode, index:number) => {
        return (
            <div 
                className="failureModeListItem" 
                key={index}
                onClick={() => {
                    // PG Note: disabling this for now until the failure mode form is ready.
                    //setFailureModeToShow(f)
                }}>
                <span>{f.name}</span>
                <span style={{
                    marginLeft: '0.5rem',
                    color: 'gray'
                }}>{f.category}</span>
            </div>
        );
    }

    const renderPopupContent = () => {
        // No need to render anything if there is no Analysis selected.
        if (props.analysisProperty == null)
            return <div/>;

        return (
            <div className="analysisPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.analysisProperty.name}
                    readOnly={true}/>
                <h3>Failure Modes</h3>
                <div className='analysisPropertyFailureModes'>
                    <ScrollView height={'100%'}>
                        {props.analysisProperty.failureModes.map((f:FailureMode, i:number) => failureModeListItem(f, i))}
                    </ScrollView>
                </div>
                <FailureModePopup
                    failureMode={failureModeToShow}
                    onHiding={() => setFailureModeToShow(null)}/>
            </div>
        );
    } 

    let familyCategory = null;
    switch (props.analysisProperty?.type) {
        case FamilyCategory.Beam:
            familyCategory = 'Beam';
            break;
        case FamilyCategory.Panel:
            familyCategory = 'Panel';
            break;
        case FamilyCategory.Joint:
            familyCategory = 'Joint';
            break;
    }

    return (
        <Popup 
            visible={props.analysisProperty != null}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={`Analysis Property - ${familyCategory ?? ''}`}
            width={'22rem'}
            height={'28rem'}>
            {renderPopupContent()}
        </Popup>
    );
}