import './AddProgramUserPopup.css';

import { ToolbarItem } from 'devextreme-react/popup';
import { List, Popup, TabPanel, TextBox } from 'devextreme-react';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Program } from '../../Classes/UserGroups/Program';
import { CompanyUser } from '../../Classes/UserGroups/CompanyUser';
import { Item } from 'devextreme-react/tab-panel';
import { GetInvitableInternalUsers } from '../../API/ProgramsAPI';
import useKeycloak from '../../Keycloak';
import { naturalCompare } from '../../Utilities/CommonUtilities';

interface AddProgramUserPopupProps {
    program:Program|undefined;
    showPopup:boolean;
    hidePopup:() => void;
    applyAddInternal:(userIds:string[]) => Promise<boolean>;
    applyAddExternal:(name:string) => Promise<boolean>;
}

export function AddProgramUserPopup(props:AddProgramUserPopupProps) {

    const internalTabTitle = 'Internal';
    const externalTabTitle = 'External';

    const [usersNotInProgram, setUsersNotInProgram] = React.useState<CompanyUser[]>(new Array<CompanyUser>());
    const [selectedTabTitle, setSelectedTabTitle] = React.useState<string>();

    const selectedUserIds = useRef<string[]>(new Array<string>());

    const [email, setEmail] = React.useState<string>();
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const keycloak = useKeycloak();

    useEffect(() => {
        setIsValid(true);
        setEmail('');
        setSelectedTabTitle(internalTabTitle);
    }, [props.showPopup]);

    useEffect(() => {
        fetchInvitableInternalUsers();
    }, [keycloak.token, props.program]);

    const fetchInvitableInternalUsers = async() => {
        if (keycloak.token && props.program) {
            let invitableInternalUsers = await GetInvitableInternalUsers(keycloak.token, props.program.id)
            setUsersNotInProgram(invitableInternalUsers)
        }
    }

    const clearErrors = () => {
        setIsValid(true);
        setErrorMessage('');
    }

    const apply = async () => {
        let success = false;

        if (selectedTabTitle === internalTabTitle) {
            success = await props.applyAddInternal(selectedUserIds.current);
        }
        else if (selectedTabTitle === externalTabTitle){
            let trimmedName = email?.trim() ?? '';
            success = await props.applyAddExternal(trimmedName);
        }

        // No errors, apply
        if (success) {
            setIsValid(true);
            props.hidePopup();
        } else {
            setIsValid(false);
            setErrorMessage(`The specified user(s) could not be added to the program.`);
        }
    }

    // Unload the popup to clear validation when popup is hidden
    if (!props.showPopup) {
        return <div/>
    }

    return (
        <Popup 
            title={`Add Users`}
            width='25vw'
            height='60vh'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={() => {
                props.hidePopup();
            }}>
                <TabPanel 
                    height='100%' 
                    className='addProgramUsersTabPanel' 
                    onSelectedItemChange={(newItem) => {
                        setSelectedTabTitle(newItem.title);
                        clearErrors();
                    }}>
                    <Item title={internalTabTitle}>
                        <div className='panelItemDiv'>
                            <List
                                dataSource={usersNotInProgram.sort((a,b) => naturalCompare(a.displayName, b.displayName))}
                                displayExpr='displayName'
                                searchExpr={['displayName', 'email']}
                                selectionMode='multiple'
                                showSelectionControls={true}
                                searchEnabled={true}
                                useNativeScrolling={true}
                                onSelectedItemsChange={(newSelectedItems:CompanyUser[]) => {
                                    let newKeys = newSelectedItems.map((item:CompanyUser) => item.id);
                                    let itemsHaveChanged = newKeys.length !== selectedUserIds.current.length;
                                    if (!itemsHaveChanged) {
                                        for (let i:number = 0; i < newKeys.length; i++) {
                                            if (newKeys[i] !== selectedUserIds.current[i]) {
                                                itemsHaveChanged = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (itemsHaveChanged) {
                                        selectedUserIds.current = newKeys;
                                        clearErrors();
                                    }
                                }}/>
                        </div>
                    </Item>
                    <Item title={externalTabTitle}>
                        <div className='panelItemDiv'>
                            <TextBox 
                                focusStateEnabled={true}
                                placeholder='Email'
                                value={email}
                                onEnterKey={apply}
                                isValid={isValid}
                                validationError={
                                    {message: errorMessage}
                                }
                                valueChangeEvent='keyup'
                                onValueChanged={(e) => { 
                                    setEmail(e.value);
                                    clearErrors();
                                }}>
                            </TextBox>
                        </div>
                    </Item>
                </TabPanel>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={!isValid}
                    options={{
                        text: 'Add',
                        onClick: apply
                    }}
                />
        </Popup>
    );
}