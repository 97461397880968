import "./UserGeneralLoadPropertyPopup.css";

import { CheckBox, DataGrid, Popup, RadioGroup, TextBox } from "devextreme-react";
import LoadProperty, { LoadPropertyType } from "../../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty";
import { useMemo } from "react";
import { FamilyCategory } from "../../../../../Classes/Enums/FamilyCategory";
import { Column } from "devextreme-react/data-grid";
import GeneralLoadProperty from "../../../../../Classes/UploadSetInputs/LoadProperty/UserGeneral/UserGeneralLoadProperty";
import GeneralLoadChoice from "../../../../../Classes/UploadSetInputs/LoadProperty/UserGeneral/GeneralLoadChoice";

interface UserGeneralLoadPropertyPopupProps {
    loadProperty:LoadProperty|null;
    onHiding: () => void;
}

export default function UserGeneralFEALoadPropertyPopup(props:UserGeneralLoadPropertyPopupProps) {
    const lightBlueColor = '#F0F8FF';

    const isUserGeneralLoadProperty = (type:LoadPropertyType) => {
        return type == LoadPropertyType.UserGeneral;
    }

    const radioButtonData = useMemo(() => {
        return [
            {
                text: 'panel reference plane',
                value: false
            },
            {
                text: 'zero curvature',
                value: true
            }
        ];
    }, []);

    const renderPanelLoadCategoriesTable = useMemo(() => {
        return (
            <table className='loadCategoriesTable'>
                <tbody>
                    <tr>
                        <td style={{width: '7.525rem'}}/>
                        <td style={{width: '15rem'}}>In-Plane</td>
                        <td style={{width: '15rem'}}>Moment/Curvature</td>
                        <td style={{width: '10rem'}}>Out-of-plane</td>
                        <td>Temperatures, Pressures</td>
                    </tr>
                </tbody>
            </table>);
    }, []);

    const renderBeamLoadCategoriesTable = useMemo(() => {
        return (
            <table className='loadCategoriesTable'>
                <tbody>
                    <tr>
                        <td style={{width: '7.525rem'}}/>
                        <td style={{width: '20rem'}}>Moment/Curvature</td>
                        <td style={{width: '10rem'}}>Shear</td>
                        <td style={{width: '5rem'}}>Axial</td>
                        <td style={{width: '5rem'}}>Torque</td>
                        <td>Temperatures, Pressures</td>
                    </tr>
                </tbody>
            </table>);
    }, []);

    const renderLoadChoice = (l:GeneralLoadChoice) => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserGeneralLoadProperty(props.loadProperty.type))
            return <div/>;

        const generalLoadProp = props.loadProperty as GeneralLoadProperty;

        const mechVal = l.mechanical ?? '';
        const thermVal = l.thermal ?? '';
        return (
            <table className='loadChoiceTable'>
                <tbody>
                    <tr>
                        <td style={{
                            backgroundColor: lightBlueColor
                        }}>{l.represents}</td>
                    </tr>
                    <tr>
                        <td>{mechVal}</td>
                    </tr>
                    {generalLoadProp.includeThermalLoads &&
                    <tr>
                        <td>{thermVal}</td>
                    </tr>}
                </tbody>
            </table>
        );
    }

    const renderMechTherm = () => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserGeneralLoadProperty(props.loadProperty.type))
            return <div/>;

        const generalLoadProp = props.loadProperty as GeneralLoadProperty;

        return (
            <table className='loadChoiceTable'>
                <tbody>
                    <tr>
                        <td style={{
                            backgroundColor: lightBlueColor
                        }}/>
                    </tr>
                    <tr>
                        <td>Mechanical</td>
                    </tr>
                    <tr>
                        {generalLoadProp.includeThermalLoads && <td>Thermal</td>}
                    </tr>
                </tbody>
            </table>
        );
    }

    const renderPopupContent = () => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserGeneralLoadProperty(props.loadProperty.type))
            return <div/>;

        const generalLoadProp = props.loadProperty as GeneralLoadProperty;

        let keyColumnsToRender = null;
        if (generalLoadProp.scenarios.length > 0) {
            const firstScenario = generalLoadProp.scenarios[0];
            const keys = Object.keys(firstScenario);
            const columnKeysToSkip = ['scenarioId','referenceTemperature', 'pressure', 'limitFactor', 'ultimateFactor'];
            keyColumnsToRender = keys.map(i => {
                if (!columnKeysToSkip.includes(i)) {
                    return (
                        <Column 
                            dataField={i} 
                            key={i}
                            width={'5rem'}
                            cellRender={(e) => renderLoadChoice(e.value)}/>
                    );
                }
            });
        }

        const showZeroCurvSelect = generalLoadProp.isZeroCurvature != null && generalLoadProp.category == FamilyCategory.Panel;
        const showThermalSelect = generalLoadProp.includeThermalLoads != null;

        return (
            <div className="generalLoadPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.loadProperty.name}
                    readOnly={true}/>
                <div className='generalLoadPropertyTopOptions'>
                    <div className='generalLoadPropertyRadioButtons'>
                        {showZeroCurvSelect && <h4>Moment reference relative to</h4>}
                        <RadioGroup 
                            dataSource={radioButtonData} 
                            visible={showZeroCurvSelect}
                            valueExpr={'value'}
                            value={generalLoadProp.isZeroCurvature} 
                            readOnly={true}
                            layout="horizontal"/>
                    </div>
                    <div className='generalLoadPropertyShowThermal'>
                        <CheckBox 
                            value={generalLoadProp.includeThermalLoads}
                            visible={showThermalSelect}
                            readOnly={true} />
                        {showThermalSelect && <h4>Include thermal loads</h4>}
                    </div>
                </div>
                {generalLoadProp.category == FamilyCategory.Panel && renderPanelLoadCategoriesTable}
                {generalLoadProp.category == FamilyCategory.Beam && renderBeamLoadCategoriesTable}
                <div className='generalLoadPropertyContent'>
                    <DataGrid 
                        dataSource={generalLoadProp.scenarios} 
                        wordWrapEnabled={true} 
                        height={'100%'}>
                        <Column 
                            dataField={'scenarioId'} 
                            caption={''} 
                            width={'1.5rem'}
                            cellRender={(e) => <div style={{width: '100%', textAlign: 'center'}}>{e.value}</div>}/>
                        <Column 
                            caption={''} 
                            width={'6rem'}
                            cellRender={(e) => renderMechTherm()}/>
                        {keyColumnsToRender}
                        <Column 
                            dataField={'referenceTemperature'} 
                            caption={'Reference Temperature'}
                            cellRender={(e) => renderLoadChoice(e.value)}/>
                        <Column 
                            dataField={'pressure'} 
                            caption={generalLoadProp.includeThermalLoads ? 'Pressure / Temperature' : 'Pressure'}
                            cellRender={(e) => renderLoadChoice(e.value)}/>
                        <Column 
                            dataField={'limitFactor'} 
                            caption={generalLoadProp.includeThermalLoads ? 'Limit / Help' : 'Limit'}
                            cellRender={(e) => renderLoadChoice(e.value)}/>
                        <Column 
                            dataField={'ultimateFactor'} 
                            caption={generalLoadProp.includeThermalLoads ? 'Ultimate / Hurt' : 'Ultimate'}
                            cellRender={(e) => renderLoadChoice(e.value)}/>
                    </DataGrid>
                </div>
            </div>
        );
    } 

    let title = 'User General Panel Loads';
    if (props.loadProperty?.category == FamilyCategory.Beam) {
        title = 'User General Beam Loads';
    }

    return (
        <Popup 
            visible={props.loadProperty != null && isUserGeneralLoadProperty(props.loadProperty.type)}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={title}
            width={'80rem'}
            height={'40rem'}>
            {renderPopupContent()}
        </Popup>
    );
}