import { GroupUser } from "./GroupUser";
import { SharedGraphRoleType } from "./SharedGraphRole";

export class SharedGraphUser {
    userId: string;
    role: SharedGraphRoleType;
    userDisplayName: string;
    userEmail: string;
    dateAdded: string;

    constructor() {
        this.userId = '';
        this.role = SharedGraphRoleType.ReadOnly;
        this.userDisplayName = '';
        this.userEmail = '';
        this.dateAdded = '';
    }

    static constructFromGroupUser(user:GroupUser, role:SharedGraphRoleType) {
        let newObject = new SharedGraphUser();
        newObject.userId = user.id;
        newObject.userDisplayName = user.displayName;
        newObject.userEmail = user.email;
        newObject.dateAdded = (new Date()).toUTCString();
        newObject.role = role;

        return newObject;
    }
}