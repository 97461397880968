import './ColumnBox.css';

import { DataSourceColumn } from '../../../../../Classes/DataSourceColumn';
import { getColumnDisplayName } from '../../../../../Classes/ResultPropertyInfo';
import { ContextMenu } from 'devextreme-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import useKeycloak from '../../../../../Keycloak';
import { DeleteDbDataSourceColumn } from '../../../../../API/CustomChartAPI';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Stores/GlobalStore';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

type ColumnBoxProps = {
    dataSourceColumn: DataSourceColumn;
    deleteColumn: () => void;
    readOnly: boolean;
    numericColumnIds?: Set<number>;
}

const defaultProps = {
    deleteColumn: () => {},
    readOnly: false
}

export function ColumnBox({ dataSourceColumn, deleteColumn, readOnly, numericColumnIds }: ColumnBoxProps) {
    const { token } = useKeycloak();
    const propertyKeyDictionary = useSelector((state:RootState) => state.results.plottablePropertyDictionary);
    const resultPropertyInfo = propertyKeyDictionary[dataSourceColumn.propertyKey];
    
    const [isDragging, setIsDragging] = useState(false);
    
    const dragRef = useRef<any>(null);

    useEffect(() => {
        const el = dragRef.current;

        return draggable({
            element: el,
            getInitialData: () => ({ dataSourceColumn: dataSourceColumn, type: CustomChartDragTypes.DATA_SOURCE_COLUMN }),
            onDragStart: () => {
                setIsDragging(true);
            }, 
            onDrop: () => {
                setIsDragging(false);
            }
        });
    }, [dataSourceColumn]);

    const contextMenuItems = useMemo(() => {
        return [
            {
                text: 'Delete Column',
                action: async () => {
                    DeleteDbDataSourceColumn(token, dataSourceColumn.id).then(response => {
                        if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                            deleteColumn();
                        }
                    });
                }
            }
        ];
    }, [token, dataSourceColumn.id, deleteColumn]);

    const columnBoxId = `dataSourceColumnBox_${dataSourceColumn.id}`;

    let displayName = 'Unknown Type';
    let symbol = '?';
    let extraClassName = 'unknownTypeColumnBox';

    if (resultPropertyInfo != null) {
        displayName = getColumnDisplayName(dataSourceColumn, propertyKeyDictionary);

        let isNumeric = resultPropertyInfo.isNumeric;
        if (numericColumnIds != null && numericColumnIds.has(dataSourceColumn.id)) {
            isNumeric = true;
        }
        symbol = isNumeric ? '#' : 'abc';
        extraClassName = isNumeric ? 'numericColumnBox' : '';
    }

    if (readOnly) {
        return (
            <div
                id={columnBoxId} 
                className={`columnBox ${extraClassName} readOnlyColumnBox`}>
                <span className='columnBoxSymbol'>{symbol}</span>
                {displayName}
            </div>
        );
    }

    let extraStyles = {};
    if (isDragging) {
        extraStyles = {
            opacity: 0.4
        }
    }

    return (
        <div
            id={columnBoxId} 
            ref={dragRef}
            style={extraStyles}
            className={`columnBox ${extraClassName}`}>
            <span className='columnBoxSymbol'>{symbol}</span>
            {displayName}
            <ContextMenu
                dataSource={contextMenuItems}
                target={`#${columnBoxId}`}
                onItemClick={(e:any) => e.itemData.action()}/>
        </div>
    );
}
ColumnBox.defaultProps = defaultProps;
export default ColumnBox;