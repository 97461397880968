import { CSSProperties, useMemo } from 'react';
import './UploadSetInputs.css';
import { Button, ScrollView } from "devextreme-react";

interface InputListProps {
    title: string;
    items: any[];
    onItemClick: (item:any) => void;
    showViewButton: (item:any) => boolean;
    selectedStructureNames: string[];
}

export default function InputList(props:InputListProps) {
    
    const renderListItem = (item:any, index:number) => {
        const divStyle = {
            width:'100%',
            height: '2.8rem',
            padding: '0.5rem',
            paddingLeft: '0.75rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflow: 'hidden',
        } as CSSProperties;
        const spanStyle = {
            flex: '0 1 auto',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        } as CSSProperties;
        return (
            <div 
                key={`inputListItem_${props.title}_${index}`}
                style={divStyle}>
                <span style={spanStyle}>{item.name}</span>
                <Button 
                    text='View'
                    visible={props.showViewButton(item)}
                    onClick={() => props.onItemClick(item)}/>
            </div>
        );
    };

    const renderMemoInputList = useMemo(() => {
        const filteredList = props.items.filter((i => {
            // If no structure names given, don't do any filtering
            if (props.selectedStructureNames.length === 0)
                return true;

            const structuresIntersect = i.structureNames.some((structName:string) => props.selectedStructureNames.includes(structName));
            return structuresIntersect;
        }));
        const sortedList = filteredList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '18.75rem',
                paddingBottom: '1rem'
            }}>
                <h4>{`${props.title} (${sortedList.length})`}</h4>
                <div style={{
                    flex: '1 1 auto',
                    overflowY: 'hidden',
                    border: '0.05rem solid lightgray',
                    backgroundColor: 'rgb(250, 250, 250)',
                    borderRadius: '0.25rem'
                }}>
                    <ScrollView height={'100%'} useNative={true}>
                        <div>
                            {sortedList.map((item, index) => renderListItem(item, index))}
                        </div>
                    </ScrollView>
                </div>
            </div>
        );
    }, [props.items, props.selectedStructureNames]);

    return renderMemoInputList;
}