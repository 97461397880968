import { ContextMenu } from 'devextreme-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { CountChartsInFolder, CountFoldersInFolder, CustomChartFolder, FindChartInFolder, FindFolderParentFolder, MoveFolderToFolderWithinRoot } from '../../../../../Classes/Charts/CustomChartFolder';
import { setSelectedCustomChartId, setSelectedFolderId } from '../../../../../Reducers/CustomChartsReducer';
import { DeleteDbFolder, MoveDbFoldersToFolder } from '../../../../../API/CustomChartFolderAPI';
import useKeycloak from '../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import { confirm } from 'devextreme/ui/dialog';  
import notify from 'devextreme/ui/notify';
import { RootState } from '../../../../../Stores/GlobalStore';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
    dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

interface ChartFolderListItemProps {
    customChartFolder: CustomChartFolder,
    rootFolder: CustomChartFolder,
    setRootFolder: (newVal:CustomChartFolder) => void,
    onRenameClicked: () => void
}
function ChartFolderListItem({ customChartFolder, rootFolder, setRootFolder, onRenameClicked } :ChartFolderListItemProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();
    
    const selectedGraphId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 

    const [isDragging, setIsDragging] = useState(false);
    const [isDragHovered, setIsDragHovered] = useState(false);
    
    const dndRef = useRef<any>(null);

    const move = useCallback((destinationFolderId:number|null|undefined) => {
        const folderId = customChartFolder.id;
        if (folderId != null && folderId !== destinationFolderId) {
            const parentFolderOfFolderToMove = FindFolderParentFolder(rootFolder, folderId);
            const newRootFolder = MoveFolderToFolderWithinRoot(rootFolder, folderId, destinationFolderId);
            if (newRootFolder != null && parentFolderOfFolderToMove != null) {
                // Make the change in the UI
                setRootFolder(newRootFolder);
    
                // Make the change in the DB
                MoveDbFoldersToFolder(token, destinationFolderId ?? null, [folderId])
                .then(response => {
                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                        // Revert the UI change if the db save fails
                        const revertedRootFolder = MoveFolderToFolderWithinRoot(newRootFolder, folderId, parentFolderOfFolderToMove.id);
                        if (revertedRootFolder) {
                            setRootFolder(revertedRootFolder);
                        }
                    }
                });
            }
        }
    }, [customChartFolder, rootFolder, setRootFolder, token]);

    useEffect(() => {
        const dndElement = dndRef.current;

        return combine(
            draggable({
                element: dndElement,
                getInitialData: () => ({ folder: customChartFolder, type: CustomChartDragTypes.FOLDER }),
                onDragStart: () => {
                    setIsDragging(true);
                }, 
                onDrop: ({ location }) => {
                    setIsDragging(false);

                    if (location.current.dropTargets.length > 0) {
                        const firstDropTarget = location.current.dropTargets[0];
                        const dropFolder = firstDropTarget.data.folder as CustomChartFolder;
                        move(dropFolder.id);
                    }
                }
            }),
            dropTargetForElements({
                element: dndElement,
                getData: () => ({ folder: customChartFolder }),
                onDragEnter: () => {
                    setIsDragHovered(true);
                },
                onDragLeave: () => {
                    setIsDragHovered(false);
                },
                onDrop: () => {
                    setIsDragHovered(false);
                },
                canDrop: ({ source }) => {
                    return source.data.type === CustomChartDragTypes.FOLDER || source.data.type === CustomChartDragTypes.CHART;
                }
            }),
        )   
    }, [customChartFolder, move]);


    const draggingOverClass = isDragHovered ? 'canDropInto' : '';

    let extraStyles = {};
    if (isDragging) {
        extraStyles = {
            opacity: 0
        }
    }

    const contextMenuItems = useMemo(() => [
        {
            text: 'Rename',
            action: () => {
                onRenameClicked();
            }
        },
        {
            text: 'Delete',
            action: () => {
                const numFoldersInFolder = CountFoldersInFolder(customChartFolder); 
                const numChartsInFolder = CountChartsInFolder(customChartFolder);
                let result = confirm(`Are you sure you want to delete ${customChartFolder.name}? All graphs (${numChartsInFolder}) and folders (${numFoldersInFolder}) 
                    within this folder will be deleted.`, "Confirm Delete");  
                result.then((confirmDelete) => {
                    if (confirmDelete && token && customChartFolder.id) { 
                        // Make the change in the DB
                        DeleteDbFolder(token, customChartFolder.id).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Let the user know the folder was deleted.
                                notify(customChartFolder.name + ' has been deleted.', 'success');

                                // Make the change in the UI
                                const newRootFolder = {...rootFolder};

                                // Clear the selected graph id if its parent folder was deleted.
                                const doomedGraphInfo = FindChartInFolder(newRootFolder, selectedGraphId);
                                if (doomedGraphInfo != null) {
                                    dispatch(setSelectedCustomChartId(null));
                                }

                                const folderParentFolder = FindFolderParentFolder(newRootFolder, customChartFolder.id) ?? newRootFolder;
                                if (folderParentFolder) {
                                    folderParentFolder.subFolders = folderParentFolder.subFolders.filter(i => i.id !== customChartFolder.id);
                                    setRootFolder({...newRootFolder});
                                }
                            }
                        });
                    }
                });
            }
        }
    ], [customChartFolder, dispatch, onRenameClicked, rootFolder, selectedGraphId, setRootFolder, token]);

    const folderClass = 'graphFolderTemplate'
    const idString = `customChartFolderID${customChartFolder.id}`
    return (
        <div 
            className={`${folderClass} ${draggingOverClass}`} 
            style={extraStyles}
            ref={dndRef} 
            id={idString}
            onClick={() => {
                dispatch(setSelectedFolderId(customChartFolder.id));
            }}>
            <i className="dx-icon-folder"/>
            <span>{customChartFolder.name}</span>
            <ContextMenu
                dataSource={contextMenuItems}
                target={`#${idString}`}
                onItemClick={(e:any) => e.itemData.action()}/>
        </div>
    );
}

export default ChartFolderListItem;