import './ViewerItem.css';

import { useMemo, useRef } from "react";
import { GraphInfo } from "../../../Classes/Charts/GraphInfo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Stores/GlobalStore";
import { FetchPreviewImageProps, fetchPreviewImage } from "../../../Reducers/ResultsReducer";
import useKeycloak from "../../../Keycloak";

interface ViewerGraphItemProps {
    graphInfo:GraphInfo;
    onClick: () => void;
}

export default function ViewerGraphItem({ graphInfo, onClick }: ViewerGraphItemProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();

    const graphId = graphInfo.id;

    const cachedPreviewImageLookup = useSelector((state:RootState) => state.results.cachedPreviewImageLookup);

    const loadingPreviewImage = useRef<boolean>(false);

    const renderPreviewImage = useMemo(() => {
        if (!(graphId in cachedPreviewImageLookup)) {
            // Kick off a function to fetch this image from the server.
            if (!loadingPreviewImage.current) {
                loadingPreviewImage.current = true;
                dispatch(fetchPreviewImage({
                    authToken: token,
                    graphId: graphId
                } as FetchPreviewImageProps))
                .then(() => {
                    loadingPreviewImage.current = false;
                });
            }
            return <div/>;
        }

        const imageURL = cachedPreviewImageLookup[graphId].imageURL;
        return (
            <img 
                src={imageURL} 
                loading={'lazy'}
                draggable={false}
                alt=''/>
        );
    }, [cachedPreviewImageLookup, dispatch, graphId, token])

    return (
        <div className="viewerItem graphViewerItem" onClick={onClick}>
            <span>{graphInfo.title}</span>
            {renderPreviewImage}
        </div>
    );
}