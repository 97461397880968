import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { CustomChartFolder } from '../Classes/Charts/CustomChartFolder';
import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';

export async function GetCustomChartFolders(authToken:string): Promise<CustomChartFolder> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", "GetCustomChartFolders"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function FolderNameIsDuplicate(authToken:string|undefined, name:string, parentFolderId:number|null|undefined): Promise<boolean> {
    if (!authToken)
        return false;

    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let queryString = `IsFolderNameDuplicate?name=${name}`;
    if (parentFolderId) {
        queryString = `IsFolderNameDuplicate?name=${name}&parentFolderId=${parentFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", queryString); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetDbSelectedGraphFolderId(authToken:string): Promise<APIResponseWithStatus<number|null>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", "GetSelectedFolderId"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function CreateDbFolder(authToken:string, name:string, parentFolderId:number|null): Promise<APIResponseWithStatus<CustomChartFolder>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    let queryString = `CreateFolder`;
    if (parentFolderId) {
        queryString = `CreateFolder?parentFolderId=${parentFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", queryString); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RenameDbFolder(authToken:string, name:string, folderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", `RenameFolder?&folderId=${folderId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteDbFolder(authToken:string, folderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", "DeleteFolder?folderId=" + folderId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function MoveDbFoldersToFolder(authToken:string, destinationFolderId:number|null, folderIdsToMove:Array<number>): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(folderIdsToMove);

    let queryString = ``;
    if (destinationFolderId) {
        queryString = `?destinationFolderId=${destinationFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", `MoveFoldersToFolder${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function MoveDbChartsToFolder(authToken:string, destinationFolderId:number|null|undefined, chartIdsToMove:Array<number>): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(chartIdsToMove);

    let queryString = ``;
    if (destinationFolderId) {
        queryString = `?destinationFolderId=${destinationFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChartFolder", `MoveChartsToFolder${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}