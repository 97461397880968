import { ToolbarItem } from 'devextreme-react/popup';
import { Popup, TextBox } from 'devextreme-react';
import React from 'react';
import { useEffect } from 'react';

interface AddCompanyUserPopupProps {
    showPopup:boolean
    hidePopup:() => void;
    applyAdd:(name:string) => Promise<boolean>;
}

export function AddCompanyUserPopup(props:AddCompanyUserPopupProps) {

    const [email, setEmail] = React.useState<string>();
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setIsValid(true);
        setEmail('');
    }, [props.showPopup]);

    const clearErrors = () => {
        setIsValid(true);
        setErrorMessage('');
    }

    const apply = async () => {
        let trimmedName = email?.trim() ?? '';
        let success = await props.applyAdd(trimmedName);

        // No errors, apply
        if (success) {
            setIsValid(true);
            props.hidePopup();
        } else {
            setIsValid(false);
            setErrorMessage(`\"${trimmedName}\" could not be added to the company.`);
        }
    }

    // Unload the popup to clear validation when popup is hidden
    if (!props.showPopup) {
        return <div/>
    }

    return (
        <Popup 
            title='Add User by Email'
            wrapperAttr={{class: 'addUserByEmailPopupWrapper'}}
            width='24rem'
            height='auto'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={() => {
                props.hidePopup();
            }}>
                <TextBox 
                    focusStateEnabled={true}
                    placeholder='Email'
                    value={email}
                    onEnterKey={apply}
                    isValid={isValid}
                    validationError={
                        {message: errorMessage}
                    }
                    valueChangeEvent='keyup'
                    onValueChanged={(e) => { 
                        setEmail(e.value);
                        clearErrors();
                    }}>
                </TextBox>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={!isValid}
                    options={{
                        text: 'Add',
                        onClick: apply
                    }}
                />
        </Popup>
    );
}