import './CompanyProgram.css';
import './Programs.css';

import { Button, DropDownButton, List, Tooltip } from 'devextreme-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProgram, getPrograms, removeUsers, renameProgram } from '../../Reducers/ProgramDataReducer';
import { SetNamePopup } from '../SetNamePopup';
import { Program } from '../../Classes/UserGroups/Program';
import { AddProgramUserPopup } from './AddProgramUserPopup';
import { EditProgramUserPopup } from './EditProgramUserPopup';
import { ProgramUser } from '../../Classes/UserGroups/ProgramUser';
import { confirm } from 'devextreme/ui/dialog';  
import { canAddRemoveUsers, canAssignRoles, canDeleteProgram, canRenameProgram, getRoleNamePlural, GroupRoleType } from '../../Classes/UserGroups/GroupRole';
import { getCompanies } from '../../Reducers/CompanyDataReducer';
import { CreateProgramPopup } from './CreateProgramPopup';
import { GroupAction } from '../../Classes/UserGroups/Group';
import { fetchSummaryData, removeUIProgramUploadSets } from '../../Reducers/SetManagementReducer';
import { ProgramNameIsDuplicate } from '../../API/ProgramsAPI';
import { Invite } from '../../Classes/Invite';
import { removeInvites, sendProgramUserInvitesExternal, sendProgramUserInvitesInternal } from '../../Reducers/InvitesDataReducer';
import DataSource from 'devextreme/data/data_source';
import { invalidateAll } from '../../Reducers/ResultsReducer';
import { RootState } from '../../Stores/GlobalStore';
import { naturalCompare } from '../../Utilities/CommonUtilities';
import useKeycloak from '../../Keycloak';

type ProgramsProps = {
    popupIsOpen: boolean;
}

export function Programs(props: ProgramsProps) {

    function GroupTemplate(item: DataSource) {
        return (
            <div>
                {getRoleNamePlural(item.key as unknown as GroupRoleType)}
            </div>
        );
    }

    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const programs = useSelector((state:RootState) => state.programData.programs);
    const userId = useSelector((state:RootState) => state.userData.user?.id);

    const [showCreateProgramPopup, setShowCreateProgramPopup] = React.useState<boolean>(false);
    const [showAddUserPopup, setShowAddUserPopup] = React.useState<boolean>(false);
    const [showRenameProgramPopup, setShowRenameProgramPopup] = React.useState<boolean>(false);
    const [userToEdit, setUserToEdit] = React.useState<ProgramUser|undefined>(undefined);

    const [selectedProgram, setSelectedProgram] = React.useState<Program|undefined>(undefined);
    const [currentProgramRole, setCurrentProgramRole] = React.useState<GroupRoleType>(GroupRoleType.Member);

    // track user invites for selected program
    const sentInvites = useSelector((state:RootState) => state.inviteData.sentInvites);
    const [selectedProgramUsers, setSelectedProgramUsers] = React.useState<DataSource<ProgramUser>>();

    const sortedPrograms = useMemo(() => {
        return [...programs].sort((a, b) => naturalCompare(a.name, b.name));
    }, [programs]);

    const moreButtonActions = useMemo(() => {
        let newMoreButtonActions = new Array<any>();

        newMoreButtonActions.push({ id: GroupAction.Leave, text: 'Leave', icon: 'hidepanel' });
        if (canRenameProgram(currentProgramRole))
            newMoreButtonActions.push({ id: GroupAction.Rename, text: 'Rename', icon: 'edit'});
        if (canDeleteProgram(currentProgramRole))
            newMoreButtonActions.push({ id: GroupAction.Delete, text: 'Delete', icon: 'trash'});

        return newMoreButtonActions;
    }, [currentProgramRole]);

    useEffect(() => {
        // Reload the group data from the server when the form is opened
        let token = keycloak.token;
        if (props.popupIsOpen && token) {
            clearSelectedProgram();
            dispatch(getCompanies(token));
            dispatch(getPrograms(token));
        }
    }, [props.popupIsOpen]);

    useEffect(() => {
        // Update selectedProgram when the program objects change
        if (selectedProgram) {
            let newSelectedProgram = sortedPrograms.find((program:Program) => program.id === selectedProgram.id);
            setSelectedProgram(newSelectedProgram);

            if (newSelectedProgram) {
                // Format invited users and combine with program user list
                const invitedUsers: ProgramUser[] | undefined = newSelectedProgram.invites?.map((i: Invite) => { 
                    const user: ProgramUser = new ProgramUser();
                    user.role = GroupRoleType.Invited;
                    user.displayName = i.recipient.first + ' ' + i.recipient.last;
                    user.email = i.recipient.email;
                    user.id = i.id.toString();

                    return user;
                });
                const users: ProgramUser[] = newSelectedProgram.users.concat(invitedUsers ? invitedUsers : [])
                const groupedUsers = new DataSource<ProgramUser>({
                    searchExpr: ['email', 'displayName'],
                    store: {
                        type: 'array',
                        data: users
                      }
                });
                groupedUsers.group('role');

                setSelectedProgramUsers(groupedUsers)
            }
        }
    }, [selectedProgram, sortedPrograms]);

    useEffect(() => {
        let token = keycloak.token;
        if (selectedProgram?.id && token) {
            let programUser = selectedProgram.users.find(u => u.id === userId);

            if (programUser?.role !== undefined && programUser.role !== currentProgramRole)
                setCurrentProgramRole(programUser?.role);
        }
    }, [selectedProgram?.id, sentInvites, keycloak.token, userId, currentProgramRole, dispatch, selectedProgram?.users]);

    const clearSelectedProgram = () => {
        setSelectedProgram(undefined);
        setSelectedProgramUsers(undefined);
    }

    const emptyProgramMessage = 'You are not a member of any programs. You can use the + button above to create a program.';

    let adminRoleWillTransfer = selectedProgram && currentProgramRole === GroupRoleType.Administrator && selectedProgram.users.length > 1
        && selectedProgram.users.filter(u => u.role === GroupRoleType.Administrator).length === 1;
    let appendToLeaveProgramMessage = adminRoleWillTransfer ? ' Your administrator role will be transferred to someone else in the program.' : '';
    return (
        <div className='mainProgramsDiv'>
            <div className='programsDiv'>
                <h3>
                    My Programs
                    <Button
                        style={{marginLeft: '0.5vw'}}
                        icon="add" 
                        type='success'
                        hint='Add program'
                        onClick={() => setShowCreateProgramPopup(true)}/>
                </h3>
                <List
                    dataSource={sortedPrograms}
                    useNativeScrolling={true}
                    selectionMode='single'
                    searchEnabled={sortedPrograms.length > 0}
                    searchExpr={['name', 'companyName']}
                    selectedItem={selectedProgram}
                    noDataText={emptyProgramMessage}
                    onSelectedItemsChange={(items) => {
                        if(items.length > 0) {
                            let newProgram = items[0];
                            setSelectedProgram(sortedPrograms.find((program:Program) => program.id === newProgram.id));
                        }
                    }}
                    itemRender={(item:Program) =>
                        <div className='programListItem' id={`programListItem${item.id}`}>
                            <div className='programListItemLeftDiv'>
                                {item.name}
                            </div>
                            <div className='programListItemRightDiv'>
                                <div className='companyName'>
                                    {item.companyName}
                                </div>
                            </div>
                            <Tooltip 
                                target={`#programListItem${item.id}`}
                                showEvent="dxhoverstart" 
                                hideEvent="dxhoverend" 
                                position='left' 
                                contentRender={() => 
                                    <div>
                                        {item.uploadSetCount?.toLocaleString()} Upload Sets
                                    </div>} />
                        </div>
                    }/>
            </div>
            <div className='programUsersDiv'>
                <h3>
                    {selectedProgram?.name ?? 'No Program Selected'}
                    {canAddRemoveUsers(currentProgramRole) && selectedProgram != null && 
                    <Button
                        style={{marginLeft: '0.5vw'}}
                        icon="add" 
                        type='success'
                        hint='Add user to program'
                        onClick={() => setShowAddUserPopup(true)}/>}
                    {selectedProgram != null &&
                    <DropDownButton
                        icon='more'
                        hint='More options'
                        showArrowIcon={false}
                        style={{marginLeft: '0.5vw', borderColor: 'transparent'}}
                        dataSource={moreButtonActions}
                        displayExpr='text'
                        dropDownOptions={{ width: '8em' }}
                        onItemClick={(args) => {
                            let id = args.itemData.id;
                            switch (id) {
                                case GroupAction.Leave: {
                                    let token = keycloak.token;
                                    if (token && selectedProgram) {
                                        let result = confirm(`Are you sure you want to leave "${selectedProgram?.name}"?${appendToLeaveProgramMessage}`, "Confirm Leave");
                                        result.then(async (confirmLeave) => {
                                            if (confirmLeave) { 
                                                // PG Note: Invalidate results for all graph sets, since graph sets could contain upload sets associated with the 
                                                // soon-to-be-left program. We could query the backend here to know which graph sets need to be invalidated, but 
                                                // since users won't be leaving their programs often I don't think this will be much of an issue.
                                                clearSelectedProgram()
                                                dispatch(removeUIProgramUploadSets(selectedProgram.id))
                                                await dispatch(removeUsers({authToken: token, programId: selectedProgram.id, userIds: [userId], requesterId: userId}))
                                                .then(() => {
                                                    dispatch(invalidateAll())
                                                })
                                                dispatch(fetchSummaryData({authToken: token, filterConditions: filterConditions}))
                                            }
                                        });
                                    }
                                    break;
                                }
                                case GroupAction.Rename:
                                    setShowRenameProgramPopup(true);
                                    break;
                                case GroupAction.Delete: {
                                    let token = keycloak.token;
                                    if (token && selectedProgram) {
                                        let result = confirm(`Are you sure you want to delete ${selectedProgram.name}? This action will delete ${selectedProgram.uploadSetCount} upload set(s) associated with ${selectedProgram.uploadSetUserCount} user(s).`, "Confirm Delete");  
                                        result.then(async (confirmDelete) => {
                                            if (confirmDelete) { 
                                                // PG Note: Invalidate results for all graph sets, since graph sets could contain upload sets associated with the 
                                                // soon-to-be-left program. We could query the backend here to know which graph sets need to be invalidated, but 
                                                // since users won't be deleting their programs often I don't think this will be much of an issue.
                                                clearSelectedProgram()
                                                dispatch(removeUIProgramUploadSets(selectedProgram.id))
                                                await dispatch(deleteProgram({authToken:token, programId:selectedProgram.id}))
                                                .then(() => {
                                                    dispatch(invalidateAll())
                                                })
                                                dispatch(fetchSummaryData({authToken: token, filterConditions: filterConditions}))
                                            }
                                        });
                                    }
                                    break;
                                }
                            }
                        }}/>}
                </h3> 
                <List
                    dataSource={selectedProgramUsers}
                    useNativeScrolling={true}
                    searchEnabled={selectedProgram != null && selectedProgram.users.length > 0}
                    grouped={true}
                    collapsibleGroups={true}
                    groupRender={GroupTemplate}
                    disabled={!selectedProgram}
                    itemRender={(item: ProgramUser) => {
                        return (
                            <div className='userListItem' id={`userListItem${item.id}`}>
                                <div className='userListItemLeftDiv'>
                                    {item.displayName}
                                </div>
                                <div className='userListItemRightDiv'>
                                    {
                                        canAssignRoles(currentProgramRole) && item.role != GroupRoleType.Invited &&
                                        <Button 
                                            icon='edit'
                                            disabled={item.id === userId || item.role === GroupRoleType.External}
                                            onClick={() => setUserToEdit(item)}
                                        />
                                    }
                                    {
                                        canAddRemoveUsers(currentProgramRole) && 
                                        <Button 
                                            icon='remove'
                                            disabled={item.id === userId}
                                            onClick={async () => {
                                                if (item.role == GroupRoleType.Invited) {
                                                    let result = confirm(`Cancel invite for ${item.displayName}?`, "Confirm Cancellation");  
                                                    result.then((confirmed) => {
                                                        let token = keycloak.token;
                                                        if (confirmed && token && selectedProgram) {
                                                            dispatch(removeInvites({ authToken: token, inviteIds: [item.id] }));
                                                        }
                                                    })
                                                } else {
                                                    let result = confirm(`Remove ${item.displayName} from ${selectedProgram?.name}?`, "Confirm Remove");  
                                                    result.then(async (confirmed) => {
                                                        let token = keycloak.token;
                                                        if (confirmed && token && selectedProgram) {
                                                            dispatch(removeUsers({authToken: token, programId: selectedProgram.id, userIds: [item.id], requesterId: userId}));
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    <Tooltip 
                                        target={`#userListItem${item.id}`}
                                        showEvent="dxhoverstart" 
                                        hideEvent="dxhoverend" 
                                        position='right' 
                                        contentRender={() => 
                                            <div>
                                                {item.email}
                                        </div>} />
                                </div>
                            </div>
                        )
                    }}/>
            </div>
            <CreateProgramPopup
                showPopup={showCreateProgramPopup}
                setShowPopup={(show) => setShowCreateProgramPopup(show)}
                onProgramCreated={(program:Program) => {
                    // Auto select the new program if it exists
                    setSelectedProgram(program);
                }}/>
            <SetNamePopup
                title='Rename Program'
                validateForDuplicate={(name:string) => ProgramNameIsDuplicate(keycloak.token, selectedProgram?.companyId, name)}
                applyButtonName='Apply'
                oldName={selectedProgram?.name ?? ''}
                showPopup={showRenameProgramPopup}
                hidePopup={() => setShowRenameProgramPopup(false)}
                applySetName={(newName:string) => {
                    let token = keycloak.token;
                    if (token)
                        dispatch(renameProgram({authToken:token, programId:selectedProgram?.id, name:newName}));
                }}/>
            <AddProgramUserPopup
                program={selectedProgram}
                showPopup={showAddUserPopup}
                hidePopup={() => setShowAddUserPopup(false)}
                applyAddInternal={async (userIds:string[]) => {
                    let token = keycloak.token;

                    if (token && selectedProgram) {
                        let result = await dispatch(sendProgramUserInvitesInternal({authToken: token, programId: selectedProgram.id, userIds: userIds}));
                        let sentInvites = result.payload.sentInvites;
                        return sentInvites.length > 0;
                    } else {
                        return false;
                    }
                }}
                applyAddExternal={async (email:string) => {
                    let token = keycloak.token;

                    if (token && selectedProgram) {
                        let result = await dispatch(sendProgramUserInvitesExternal({authToken: token, programId: selectedProgram.id, userEmails: [email]}));
                        let sentInvites = result.payload.sentInvites;
                        return sentInvites.length > 0;
                    } else {
                        return false;
                    }
                }}/>
            <EditProgramUserPopup
                showPopup={userToEdit !== undefined}
                program={selectedProgram}
                programUser={userToEdit}
                onHiding={() => setUserToEdit(undefined)}/>
        </div>
    );
}
export default Programs;