import tinyColor from 'tinycolor2';

export function generateColorGradient(baseColor: string|null, numSteps: number): string[] {
    if (baseColor == null || numSteps <= 0)
        return [];

    if (numSteps === 1) {
        return [baseColor];
    }

    const tinyColorObj = tinyColor(baseColor);

    // Take the base color and get numSteps number of colors between the min and max brightness
    const minLightnessPercentage = 30;
    const maxLightnessPercentage = 80;
    const lightnessGap = maxLightnessPercentage - minLightnessPercentage;

    const stepSize = lightnessGap / (numSteps - 1);
    const gradientColors: string[] = [];
  
    for (let stepIndex = 0; stepIndex < numSteps; stepIndex++) {
        const currLightness = minLightnessPercentage + (stepIndex * stepSize);
        const gradColor = tinyColorObj.toHsl();
        gradColor.l = currLightness;

        gradientColors.push(tinyColor(gradColor).toHex8String());
    }
  
    return gradientColors;
}