import { FilterRange } from './FilterRange';

export class FilterConditions {
    filtersHaveChanged: boolean;
    packetIndex: number;
    searchTerm: string;
    users: string[];
    companies: string[];
    programs: string[];
    tags: string[];
    databases: string[];
    projects: string[];
    structures: string[];
    displaySets: string[];
    designs: string[];
    materials: string[];
    zones: string[];
    date: FilterRange<string>;

    constructor() {
        this.filtersHaveChanged = true;
        this.packetIndex = 0;
        this.searchTerm = '';
        this.users = new Array<string>();
        this.companies = new Array<string>();
        this.programs = new Array<string>();
        this.tags = new Array<string>();
        this.databases = new Array<string>(); 
        this.projects = new Array<string>(); 
        this.structures = new Array<string>(); 
        this.displaySets = new Array<string>(); 
        this.designs = new Array<string>(); 
        this.materials = new Array<string>();
        this.zones = new Array<string>();
        this.date = new FilterRange<string>();
    }
}