import "./UserFEALoadPropertyPopup.css";

import { DataGrid, Popup, RadioGroup, TextBox } from "devextreme-react";
import LoadProperty, { LoadPropertyType } from "../../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty";
import { useMemo } from "react";
import { FamilyCategory } from "../../../../../Classes/Enums/FamilyCategory";
import UserFEALoadProperty from '../../../../../Classes/UploadSetInputs/LoadProperty/UserFEA/UserFEALoadProperty';
import { Column } from "devextreme-react/data-grid";

interface UserFEALoadPropertyPopupProps {
    loadProperty:LoadProperty|null;
    onHiding: () => void;
}

export default function UserFEALoadPropertyPopup(props:UserFEALoadPropertyPopupProps) {

    const isUserFEALoadProperty = (type:LoadPropertyType) => {
        return type == LoadPropertyType.UserFEA;
    }

    const radioButtonData = useMemo(() => {
        return [
            {
                text: 'panel reference plane',
                value: false
            },
            {
                text: 'zero curvature',
                value: true
            }
        ];
    }, []);

    const renderPopupContent = () => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserFEALoadProperty(props.loadProperty.type))
            return <div/>;

        const userFeaLoadProp = props.loadProperty as UserFEALoadProperty;

        let keyColumnsToRender = null;
        if (userFeaLoadProp.scenarios.length > 0) {
            const firstScenario = userFeaLoadProp.scenarios[0];
            const keys = Object.keys(firstScenario);
            keyColumnsToRender = keys.map(i => {
                if (i != 'scenarioId') {
                    return (
                        <Column dataField={i} key={i}/>
                    );
                }
            });
        }

        return (
            <div className="userFeaLoadPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.loadProperty.name}
                    readOnly={true}/>
                <div className='userFeaLoadPropertyTopOptions'>
                    {userFeaLoadProp.isZeroCurvature != null && userFeaLoadProp.category == FamilyCategory.Panel &&
                    <div className='feaLoadPropertyRadioButtons'>
                        <h4>Moment reference relative to</h4>
                        <RadioGroup 
                            dataSource={radioButtonData} 
                            valueExpr={'value'}
                            value={userFeaLoadProp.isZeroCurvature} 
                            readOnly={true}
                            layout="horizontal"/>
                    </div>}
                </div>
                <div className='userFeaLoadPropertyContent'>
                    <DataGrid dataSource={userFeaLoadProp.scenarios} wordWrapEnabled={true} height={'100%'}>
                        <Column dataField={'scenarioId'} caption={''}/>
                        {keyColumnsToRender}
                    </DataGrid>
                </div>
            </div>
        );
    } 

    let title = 'User FEA Loads';
    if (props.loadProperty?.category == FamilyCategory.Beam) {
        title = 'User FEA Beam Loads';
    }

    return (
        <Popup 
            visible={props.loadProperty != null && isUserFEALoadProperty(props.loadProperty.type)}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={title}
            width={'80rem'}
            height={'40rem'}>
            {renderPopupContent()}
        </Popup>
    );
}