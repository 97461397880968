import './ChartNotes.css';

import { useEffect, useRef, useState } from 'react';
import { TextArea } from 'devextreme-react';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import useKeycloak from '../../../../../Keycloak';
import { SaveCustomChartNotes } from '../../../../../API/CustomChartAPI';

type ChartNotesProps = {
    chartSettings: CustomChartSettings|undefined;
    readOnly: boolean;
}

export function ChartNotes(props: ChartNotesProps) {
    const debounceTime = 500;

    const keycloak = useKeycloak();
    const settings = props.chartSettings as CustomChartSettings|undefined;

    const [text, setText] = useState<string>(props.chartSettings?.notes ?? '');
    const [saving, setSaving] = useState<boolean>(false);
    const textTimer = useRef<any|undefined>();

    useEffect(() => {
        if (textTimer !== undefined)
            clearTimeout(textTimer.current);
        setText(settings?.notes ?? '');
        setSaving(false);
    }, [props.chartSettings?.chartId]);

    useEffect(() => {
        if (textTimer !== undefined)
            clearTimeout(textTimer.current);

        if (keycloak.token && settings?.chartId) {
            textTimer.current = setTimeout(() => {
                setSaving(true);
                SaveCustomChartNotes(keycloak.token, settings?.chartId, text).then(() => {
                    setSaving(false);
                });
            }, debounceTime);
        }
    }, [text]);

    return (
        <div className='chartNotesDiv'>
            <TextArea 
                className='notesTextArea'
                valueChangeEvent='input'
                value={text} 
                onValueChange={(val) => {
                    setText(val);
                }}
                placeholder={props.readOnly ? 'No notes to show...' : 'Type notes here...'}
                readOnly={props.readOnly}/>
            {!props.readOnly &&
            <span>{saving ? 'Saving...' : 'Saved'}</span>}
        </div>
    );
}
export default ChartNotes;