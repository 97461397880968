import './SetManager.css';

import GraphSetManager from './GraphSetManager/GraphSetManager';
import UploadSetList from './UploadSetFilterableList/UploadSetList';
import { DragDropContext, DraggableLocation, DragStart, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedUploadSets, endUploadSetDrag, getGraphSetUploadSetCount, moveSelectedUploadSetsInTheUI, MoveSelectedUploadSetsProps, startUploadSetDrag, StartUploadSetDragPayload, UploadSetListType, SetManagementSelector } from '../../../Reducers/SetManagementReducer';
import { GraphSetUploadSet } from '../../../Classes/GraphSetUploadSet';
import { MoveUploadSets } from '../../../API/GraphSetAPI';
import { APIRequestStatus, APIRequestStatusType } from '../../../Classes/APIRequestStatus';
import { RootState } from '../../../Stores/GlobalStore';
import { invalidateAll } from '../../../Reducers/ResultsReducer';
import useKeycloak from '../../../Keycloak';

interface SetManagerProps {
}

export function SetManager(props:SetManagerProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const selectedUploadSetIds = useSelector((state:RootState) => state.SetManagementReducer.selectedUploadSetIds);
    const draggingUploadSetId = useSelector((state:RootState) => state.SetManagementReducer.draggingUploadSetId);
    const selectedGraphSetUploadSets = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetUploadSets);
    const uploadSets = useSelector((state:RootState) => state.SetManagementReducer.uploadSets);

    useEffect(() => {
        window.addEventListener('keydown', onWindowKeyDown);

        return () => {
            window.removeEventListener('keydown', onWindowKeyDown);
        }
    }, [])

    const onDragStart = (start: DragStart) => {
        const draggableId: string = start.draggableId;
        const droppableId: string = start.source.droppableId;

        const uploadSetIdString = draggableId.replace(/\D/g,'');
        const uploadSetId = parseInt(uploadSetIdString);

        let draggingFromContainerType = UploadSetListType.UploadSetFilterableList;
        if (droppableId.includes('graphSet')) {
            draggingFromContainerType = UploadSetListType.GraphSetUploadSetList;
        }

        dispatch(startUploadSetDrag({
            uploadSetId: uploadSetId,
            selectedContainerType: draggingFromContainerType
        } as StartUploadSetDragPayload));
    }

    const onDragEnd = (result:DropResult, provided:ResponderProvided) => {
        const source:DraggableLocation = result.source;
        const destination:DraggableLocation|null|undefined = result.destination;

        if (destination == null || result.reason === 'CANCEL' || !keycloak.token) {
            dispatch(endUploadSetDrag(undefined));
            return;
        }

        const sourceDroppableId = source.droppableId;
        const destinationDroppableId = destination.droppableId;

        const addNewToEnd = destinationDroppableId.includes('graphSetDroppable');

        const destinationGraphSetIdString = destinationDroppableId.replace(/\D/g,'');
        const destinationGraphSetId = parseInt(destinationGraphSetIdString);

        let uploadSetIdsToMove = selectedUploadSetIds;
        if (draggingUploadSetId && !uploadSetIdsToMove.includes(draggingUploadSetId))
            uploadSetIdsToMove = [draggingUploadSetId, ...uploadSetIdsToMove];

        if (!sourceDroppableId.includes('uploadSetList')) {
            const sourceGraphSetIdString = sourceDroppableId.replace(/\D/g,'');
            const sourceGraphSetId = parseInt(sourceGraphSetIdString);

            dispatch(moveSelectedUploadSetsInTheUI({
                sourceUploadSetList: selectedGraphSetUploadSets.map((gsus:GraphSetUploadSet) => gsus.uploadSet),
                sourceGraphSetId: sourceGraphSetId,
                destinationGraphSetId: destinationGraphSetId,
                destinationIndex: destination.index,
                addNewToEnd: addNewToEnd
            } as MoveSelectedUploadSetsProps));

            MoveUploadSets(
                keycloak.token, 
                sourceGraphSetId, 
                destinationGraphSetId, 
                destination.index, 
                uploadSetIdsToMove,
                addNewToEnd).then((status:APIRequestStatus) => {
                    if (status.type !== APIRequestStatusType.Error) {
                        // Update destination graph set object. Only the destination needs to go to the DB for the upload set count, since the source upload sets are always 
                        // loaded. No need to update upload set count if reordering.
                        const sourceIsDestination = destinationGraphSetId === sourceGraphSetId;
                        if (!sourceIsDestination) {
                            dispatch(getGraphSetUploadSetCount({ 
                                authToken: keycloak.token, 
                                graphSetId: destinationGraphSetId }));
                        }

                        // Invalidate results when upload sets are moved or reordered.
                        dispatch(invalidateAll());
                    }
                    if (status.type !== APIRequestStatusType.Success)
                        APIRequestStatus.showToast(status);
                });
        }
        else {
            dispatch(moveSelectedUploadSetsInTheUI({
                sourceUploadSetList: uploadSets,
                sourceGraphSetId: null,
                destinationGraphSetId: destinationGraphSetId,
                destinationIndex: destination.index,
                addNewToEnd: addNewToEnd
            } as MoveSelectedUploadSetsProps));

            const uploadSetInfiniteListId = 0;
            MoveUploadSets(
                keycloak.token, 
                uploadSetInfiniteListId, 
                destinationGraphSetId, 
                destination.index,
                uploadSetIdsToMove,
                addNewToEnd).then((status:APIRequestStatus) => {
                    if (status.type !== APIRequestStatusType.Error) {
                        // Update destination graph set object.
                        dispatch(getGraphSetUploadSetCount({ 
                            authToken: keycloak.token, 
                            graphSetId: destinationGraphSetId }));

                        // Invalidate results when upload sets are moved or reordered.
                        dispatch(invalidateAll());
                    }
                    if (status.type !== APIRequestStatusType.Success)
                        APIRequestStatus.showToast(status);
                });
        }

        // Clear the "dragging" upload set id
        dispatch(endUploadSetDrag(undefined));
    }

    const onWindowKeyDown = (event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }
    
        if (event.key === 'Escape') {
            dispatch(clearSelectedUploadSets(undefined));
        }
    }

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className='setManager'>
                <div className='uploadSetFilterableListSection'>
                    <UploadSetList/>
                </div>
                <div className='graphSetSection'>
                    <GraphSetManager/>
                </div>
            </div>
        </DragDropContext>
    );
}
export default SetManager;