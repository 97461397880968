import "./QuickComparePopup.css";

import { CheckBox, DataGrid, Popup } from "devextreme-react";
import { Column, ColumnChooser, Item, Paging, Scrolling, Sorting, Toolbar } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { GetQuickComparison } from "../../../../../API/DatasetAPI";
import { APIRequestStatusType } from "../../../../../Classes/APIRequestStatus";
import { UploadSetQuickComparison } from "../../../../../Classes/UploadSetInputs/QuickCompare/UploadSetQuickComparison";
import { Change, diffWordsWithSpace } from 'diff';
import QuickComparisonItem from "../../../../../Classes/UploadSetInputs/QuickCompare/QuickComparisonItem";
import { naturalCompare } from "../../../../../Utilities/CommonUtilities";
import useKeycloak from "../../../../../Keycloak";

interface QuickComparePopupProps {
    uploadSetIds: number[]|null;
    onHiding: () => void;
}

export default function QuickComparePopup(props:QuickComparePopupProps) {
    const keycloak = useKeycloak();
    const [comparisons, setComparisons] = useState<UploadSetQuickComparison[]|null>();
    const [showChanges, setShowChanges] = useState<boolean>(false);
    const [hideUnchanged, setHideUnchanged] = useState<boolean>(false);
    const [showDates, setShowDates] = useState<boolean>(false);

    const tableRef = useRef<any>(null);
    
    useEffect(() => {
        if (props.uploadSetIds != null && keycloak.token != null) {
            // Fetch input data to populate the popup
            GetQuickComparison(keycloak.token, props.uploadSetIds).then((response) => {
                if (response.type != APIRequestStatusType.Error) {
                    setComparisons(response.data);
                }
            });
        }
    }, [props.uploadSetIds, keycloak.token]);

    const singleLineCellRender = useCallback((e:any) => {
        const grid = tableRef.current.instance;
        const filterExpr = grid.getCombinedFilter(true);
        const dataSource = grid.getDataSource();
        const loadOptions = dataSource.loadOptions();

        let sortedTableData:any = [];
        dataSource.store()
            .load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group })
            .then((result:any) => {
                sortedTableData = result;
            });

        const currItem = e.value;

        // Compare differences with previous row if one exists
        let currIndex = e.rowIndex + (grid.pageIndex() * grid.pageSize());
        const dataField = e.column.dataField;
        const prevIndex = currIndex - 1;
        let prevItem:string = currItem;
        if (showChanges && typeof sortedTableData[prevIndex] !== 'undefined') {
            prevItem = (sortedTableData[prevIndex] as any)[dataField];
        }
        const hideLinesIfUnchanged = hideUnchanged && showChanges;

        const diffSections = diffWordsWithSpace(prevItem, currItem);
        const currLineUI = renderChanges(diffSections);
        
        // Hide this line if need be
        let itemsUI = new Array<any>();
        if (!hideLinesIfUnchanged || detectChangeColorsInLineUI(currLineUI))
            currLineUI.forEach(i => itemsUI.push(i));

        return (
            <div style={{whiteSpace: 'pre-line'}}>
                {itemsUI}
            </div>
        );
    }, [comparisons, showChanges, showDates, hideUnchanged]);

    const multiLineCellRender = useCallback((e:any) => {
        const grid = tableRef.current.instance;
        const filterExpr = grid.getCombinedFilter(true);
        const dataSource = grid.getDataSource();
        const loadOptions = dataSource.loadOptions();

        let sortedTableData:any = [];
        dataSource.store()
            .load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group })
            .then((result:any) => {
                sortedTableData = result;
            });

        const comparisonItems = e.value;

        // Compare differences with previous row if one exists
        let currIndex = e.rowIndex + (grid.pageIndex() * grid.pageSize());
        const dataField = e.column.dataField;
        const prevIndex = currIndex - 1;
        let prevComparisonItems:QuickComparisonItem[]|null = null;
        if (showChanges && typeof sortedTableData[prevIndex] !== 'undefined') {
            prevComparisonItems = (sortedTableData[prevIndex] as any)[dataField];
        }
        const hideLinesIfUnchanged = hideUnchanged && showChanges;

        const currNames = comparisonItems.map((i:QuickComparisonItem) => i.name).join('\n');
        const prevNames = prevComparisonItems?.map((i:QuickComparisonItem) => i.name).join('\n');
        const diffNames = diffWordsWithSpace(prevNames ?? currNames, currNames);
        const diffNamesUI = renderChanges(diffNames);

        const currDates = comparisonItems.map((i:QuickComparisonItem) => (new Date(i.modificationDate + ' Z')).toLocaleString()).join('\n');
        const prevDates = prevComparisonItems?.map((i:QuickComparisonItem) => (new Date(i.modificationDate + ' Z')).toLocaleString()).join('\n');
        const diffDates = diffWordsWithSpace(prevDates ?? currDates, currDates);
        const diffDatesUI = renderChanges(diffDates);

        let itemsUI = new Array<any>();
        diffNamesUI.forEach((nameElement, nIndex) => {
            let currLineUI = new Array<any>();

            currLineUI.push(nameElement);
            currLineUI.push(<br key={`nameBottomBreak${nIndex}`}/>);
            if (showDates && diffDatesUI.length > nIndex) {
                let dateStyle:any = {fontSize: '0.8em', color: 'gray', marginLeft: '0.5em'};
                // Add bottom margin if we're not adding the last item.
                if (nIndex !== diffDatesUI.length - 1) {
                    dateStyle.marginBottom = '0.5em';
                }
                currLineUI.push(
                    <span style={dateStyle} key={`dateStyleSpan${nIndex}`}>
                        {diffDatesUI[nIndex]}
                    </span>);
                currLineUI.push(<br key={`dateBottomBreak${nIndex}`}/>);
            }

            // Hide this name/date line if need be
            if (!hideLinesIfUnchanged || detectChangeColorsInLineUI(currLineUI))
                currLineUI.forEach(i => itemsUI.push(i));
        });

        return (
            <div>
                {itemsUI}
            </div>
        );
    }, [comparisons, showChanges, showDates, hideUnchanged]);

    const detectChangeColorsInLineUI = (lineUI:any[]) => {
        return lineUI.some(lineUIItem => {
            // Some line UI elements are arrays of spans. Others are just spans or breaks.
            if (Array.isArray(lineUIItem))
                return lineUIItem.some(i => detectChangeColorsInElement(i));
            else 
                return detectChangeColorsInElement(lineUIItem);
        });
    }

    const detectChangeColorsInElement = (element:JSX.Element) => {
        if (element.type === 'span') {
            let color = element.props?.style?.color; 
            let children = element.props?.children;

            if (color === 'red' || color === 'green') {
                return true;
            }
            if (children != null && Array.isArray(children) && children?.length > 0) {
                let foundChildWithChanges = children.some((child:JSX.Element) => detectChangeColorsInElement(child));
                return foundChildWithChanges;
            }
        }
        return false;
    }

    const renderChanges = (changes:Change[]) => {
        let linesUI = new Array<any>();

        let currLineSpans = new Array<any>();
        changes.forEach((change, index) => {
            let style:any = {};

            if (change.added) {
                style.color = 'green';
                style.backgroundColor = '#d9fcd9';
                style.textDecoration = 'none';
            }
            else if (change.removed) {
                style.color = 'red';
                style.backgroundColor = '#fcdbd9';
                style.textDecoration = 'line-through red';
            }

            const lines = change.value.split('\n');

            // If just one line, just add a span to current line
            if (lines.length === 1) {
                currLineSpans.push(
                    <span 
                        style={style} 
                        key={`span${index}`}>
                        {change.value}
                    </span>
                );
            }
            // Multiple lines
            else if (lines.length > 0) {
                lines.forEach((line, lIndex) => {
                    // Don't add spans for blank lines
                    if (line.length !== 0) {
                        currLineSpans.push(
                            <span 
                                style={style} 
                                key={`span${index}`}>
                                {line}
                            </span>
                        );
                    }

                    if (lIndex != lines.length - 1) {
                        linesUI.push(currLineSpans);
                        currLineSpans = new Array<any>();
                    }
                });
            }
        });

        // Append any remaining curr line spans
        if (currLineSpans.length > 0)
            linesUI.push(currLineSpans);

        return linesUI;
    }

    const renderPopupContent = useCallback(() => {
        // No need to proceed if no upload set ids are present.
        if (comparisons == null)
            return <div/>;

        return (
            <div className={'quickComparePopup'}>
                <div className='quickCompareTable'>
                    <DataGrid 
                        ref={tableRef}
                        height={'100%'}
                        dataSource={comparisons} 
                        allowColumnResizing={true}
                        showRowLines={true}>
                        <Toolbar>
                            <Item location="before">
                                <div className='quickCompareHeader'>
                                    <CheckBox 
                                        text="Show modification dates" 
                                        value={showDates}
                                        onValueChange={(val) => setShowDates(val ?? false)}/>
                                    <CheckBox 
                                        text="Highlight changes between rows" 
                                        value={showChanges}
                                        onValueChange={(val) => setShowChanges(val ?? false)}/>
                                    <CheckBox 
                                        text="Hide unchanged lines" 
                                        visible={showChanges}
                                        value={hideUnchanged}
                                        onValueChange={(val) => setHideUnchanged(val ?? false)}/>
                                </div>
                            </Item>
                            <Item name='columnChooserButton'/>
                        </Toolbar>
                        <ColumnChooser 
                            enabled={true} 
                            mode={'select'}/>
                        <Sorting mode='multiple'/>
                        <Scrolling useNative={true}/>
                        <Paging enabled={true} pageSize={50}/>
                        <Column 
                            dataField={'uploadSetName'}
                            sortingMethod={naturalCompare}/>
                        <Column 
                            dataField={'databaseName'} 
                            cellRender={singleLineCellRender}
                            sortingMethod={naturalCompare}
                            defaultVisible={false}/>
                        <Column 
                            dataField={'projectName'} 
                            cellRender={singleLineCellRender}
                            sortingMethod={naturalCompare}
                            defaultVisible={false}/>
                        <Column 
                            dataField={'designs'}
                            cellRender={multiLineCellRender}
                            sortingMethod={naturalCompare}
                            calculateSortValue={(item:UploadSetQuickComparison) => {
                                return item.designs.map(i => i.name).join('\n');
                            }}/>
                        <Column 
                            dataField={'analysisProperties'}
                            cellRender={multiLineCellRender}
                            sortingMethod={naturalCompare}
                            calculateSortValue={(item:UploadSetQuickComparison) => {
                                return item.analysisProperties.map(i => i.name).join('\n');
                            }}/>
                        <Column 
                            dataField={'loadProperties'}
                            cellRender={multiLineCellRender}
                            sortingMethod={naturalCompare}
                            calculateSortValue={(item:UploadSetQuickComparison) => {
                                return item.loadProperties.map(i => i.name).join('\n');
                            }}/>
                    </DataGrid>
                </div>
            </div>
        );
    }, [comparisons, showChanges, showDates, hideUnchanged, singleLineCellRender, multiLineCellRender]);

    return (
        <Popup 
            visible={props.uploadSetIds != null}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={`Quick Compare - ${props.uploadSetIds?.length ?? 0} Upload Sets`}
            defaultWidth={'64rem'}
            defaultHeight={'40rem'}
            resizeEnabled={false}
            onContentReady={(e) => {
                var html = e.component.content();
                html.style.padding = '0.75rem';
                html.style.paddingTop = '0.5rem';
            }}>
            {renderPopupContent()}
        </Popup>
    );
}