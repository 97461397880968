import './GraphSettingsRemoveButton.css';

type GraphSettingsRemoveButtonProps = {
    onClick: () => void;
}

export function GraphSettingsRemoveButton({ onClick }: GraphSettingsRemoveButtonProps) {
    return (
        <div className='graphSettingsRemoveButton' onClick={onClick}>
            {xSvg}
        </div>
    );
}
export default GraphSettingsRemoveButton;

const xSvg = (
    <svg
        width="1.0em"
        height="1.0em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="x" stroke="black">
        <path
            id="left-to-right"
            d="M5 19L19 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            id="right-to-left"
            d="M5 5L19 19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        </g>
    </svg>
);