import './Invites.css';

import { useEffect, useState } from 'react';
import DataSource from 'devextreme/data/data_source';
import { Button, List, TabPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/tab-panel';
import notify from 'devextreme/ui/notify';
import { Invite, InviteCategory } from '../../Classes/Invite';
import { getUserInvites, inviteDataSelector, removeInvites, addUserToCompany, addUserToProgram, getSentInvites } from '../../Reducers/InvitesDataReducer';
import { useDispatch, useSelector } from 'react-redux';
import useKeycloak from '../../Keycloak';

function GroupTemplate(item: DataSource) {
    return (
        <div>
            {parseInt(item.key as unknown as string) == InviteCategory.Company ? 'Company Invites' : 'Program Invites'}
        </div>
    );
}

type InvitesProps = {
    popupIsOpen: boolean;
}

export function Invites(props: InvitesProps) {

    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const inviteSelectorObject = useSelector(inviteDataSelector);
    const [receivedInvites, setReceivedInvites] = useState<any | null>(null);
    const [sentInvites, setSentInvites] = useState<any | null>(null);

    useEffect(() => {
        // Reload the invite from the server when the form is opened
        let token = keycloak.token;
        if (props.popupIsOpen && token) {
            dispatch(getUserInvites(token));
            dispatch(getSentInvites(token));
        }
    }, [props.popupIsOpen, keycloak.token]);
    
    useEffect(() => {
        const received = new DataSource(inviteSelectorObject.receivedInvites);
        received.group('inviteCategory');
        setReceivedInvites(received);
    }, [inviteSelectorObject.receivedInvites])

    useEffect(() => {
        const sent =  new DataSource(inviteSelectorObject.sentInvites);
        sent.group('inviteCategory');
        setSentInvites(sent);
    }, [inviteSelectorObject.sentInvites])

    return (
        <div className='invitesDiv'>
            <TabPanel 
                height='100%' 
                className='addProgramUsersTabPanel' 
            >
                <Item 
                    title={'Incoming'}
                >
                    <div className='panelItemDiv'>
                        <List 
                            id="list"
                            searchEnabled={true}
                            dataSource={receivedInvites} 
                            searchExpr={['sender.first_name', 'sender.last_name', 'companyName', 'programName']}
                            grouped={true}
                            collapsibleGroups={true}
                            groupRender={GroupTemplate}
                            noDataText='No incoming invites were found.'
                            itemRender={(e: Invite) => {
                                return (
                                    <div className='inviteListItem'>
                                        <div className='inviteListItemLeftDiv'>
                                            <p style={{'fontWeight': 'bold'}}>
                                                {
                                                    e.inviteCategory == InviteCategory.Company ? 
                                                    (e.companyName ? e.companyName : 'Company Not Found')
                                                    : 
                                                    (e.programName ? e.programName : 'Program Not Found')
                                                }
                                            </p>
                                            <p style={{'marginTop': '-0.5rem'}}>
                                                {
                                                    'from: ' +
                                                    (
                                                        e.inviteCategory == InviteCategory.Company ?
                                                        (e.sender ? e.sender.first + ' ' + e.sender.last : 'Sender Not Found')
                                                        :
                                                        (e.sender ? (e.sender.first + ' ' + e.sender.last + ', ' + e.companyName) : 'Sender Not Found')
                                                    )
                                                }
                                            </p>
                                        </div>
                                        <div className='inviteListItemRightDiv'>
                                            <Button 
                                                id="acceptButton"
                                                icon='check'
                                                type='success'
                                                hint='Accept invitation'
                                                onClick={async () => {
                                                    let token = keycloak.token;
                                                    if (token) {
                                                        if (e.inviteCategory == InviteCategory.Company) {
                                                            const result = await dispatch(addUserToCompany({ authToken: token, invite: e }));
                                                            const cName = (e.companyName ? e.companyName : 'Company Not Found');
                                                            if (result && result.payload && result.payload.addedUser) { 
                                                                notify('Successfully joined ' + cName + '!', 'success')
                                                            } else {
                                                                notify('Failed to join ' + cName + '.', 'error')
                                                            }
                                                        } else {
                                                            const result = await dispatch(addUserToProgram({ authToken: token, invite: e }));
                                                            const pName = (e.programName ? e.programName : 'Program Not Found');
                                                            if (result && result.payload && result.payload.addedUser) { 
                                                                notify('Successfully joined ' + pName + '!', 'success')
                                                            } else {
                                                                notify('Failed to join ' + pName + '.', 'error')
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                            <Button 
                                                id="declineButton"
                                                icon='remove'
                                                type='danger'
                                                hint='Decline invitation'
                                                onClick={() => {
                                                    let token = keycloak.token;
                                                    if (token) {
                                                        dispatch(removeInvites({ authToken: token, inviteIds: [e.id] }));
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </div>
                </Item>
                <Item title={'Outgoing'}>
                    <div className='panelItemDiv'>
                        <List 
                            id="list"
                            searchEnabled={true}
                            dataSource={sentInvites} 
                            searchExpr={['recipient.first_name', 'recipient.last_name', 'companyName', 'programName']}
                            grouped={true}
                            collapsibleGroups={true}
                            groupRender={GroupTemplate}
                            noDataText='No outgoing invites were found.'
                            itemRender={(e: Invite) => {
                                return (
                                    <div className='inviteListItem'>
                                        <div className='inviteListItemLeftDiv'>
                                            <p style={{'fontWeight': 'bold'}}>
                                                {
                                                    e.inviteCategory == InviteCategory.Company ? 
                                                    (e.companyName ? e.companyName : 'Company Not Found')
                                                    : 
                                                    (e.programName ? e.programName : 'Program Not Found')
                                                }
                                            </p>
                                            <p style={{'marginTop': '-0.5rem'}}>
                                                {
                                                    'to: ' +
                                                    (
                                                        e.inviteCategory == InviteCategory.Company ?
                                                        (e.recipient ? e.recipient.first + ' ' + e.recipient.last : 'Sender Not Found')
                                                        :
                                                        (e.recipient ? (e.recipient.first + ' ' + e.recipient.last + ', ' + e.companyName) : 'Sender Not Found')
                                                    )
                                                }
                                            </p>
                                        </div>
                                        <div className='inviteListItemRightDiv'>
                                            <Button 
                                                id="declineButton"
                                                icon='remove'
                                                type='danger'
                                                onClick={async () => {
                                                    let token = keycloak.token;
                                                    if (token) {
                                                        const result = await dispatch(removeInvites({ authToken: token, inviteIds: [e.id] }));
                                                        if (result && result.payload && result.payload.length > 0) {
                                                            notify('Successfully removed invite.', 'success')
                                                        } else {
                                                            notify('Failed to remove invite.', 'error')
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </div>
                </Item>
            </TabPanel>
        </div>
    )
}

export default Invites;