import * as signalR from "@microsoft/signalr";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Invite } from "../Classes/Invite";
import { Company } from "../Classes/UserGroups/Company";
import { CompanyDataReducer } from "../Reducers/CompanyDataReducer";
import { InvitesDataReducer } from "../Reducers/InvitesDataReducer";
import { ProgramDataReducer } from "../Reducers/ProgramDataReducer";
import useKeycloak from "../Keycloak";
import { Program } from "../Classes/UserGroups/Program";

const SignalRContext = createContext<signalR.HubConnection | undefined>(undefined);

export function useSignalR() {
    return useContext(SignalRContext);
}

export function SignalRProvider(props: any) {

    const [connection, setConnection] = useState<signalR.HubConnection | undefined>(undefined)
    const keycloak = useKeycloak();

    const dispatch = useDispatch();

    // Connect to signal r hub
    useEffect(() => {
        if (keycloak.authenticated && keycloak.token) {
            const token = keycloak.token;

            let isProd = process.env.NODE_ENV === 'production';
            let apiUrlElement: HTMLInputElement = document.getElementById(isProd ? "apiUrl" : 'devApiUrl') as HTMLInputElement;
            let apiUrl: string = apiUrlElement.value;
            let url = apiUrl + "/hub"

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(url, {
                    accessTokenFactory: () => token
                })
                .build()

            connection.on("inviteReceived", (invite: Invite) => {
                dispatch(InvitesDataReducer.actions.inviteReceived(invite));
            });

            connection.on("incomingInviteRemoved", (invite: Invite) => {
                dispatch(InvitesDataReducer.actions.incomingInviteRemoved(invite));
            });

            connection.on("outgoingInviteRemoved", (invite: Invite) => {
                dispatch(InvitesDataReducer.actions.outgoingInviteRemoved(invite));
            });

            connection.on("companyUpdated", (company: Company) => {
                dispatch(CompanyDataReducer.actions.companyUpdated(company))
            })

            // used for both company deleton and user removal
            connection.on("companyRemoved", (companyId: number) => {
                dispatch(CompanyDataReducer.actions.companyRemoved(companyId))
            })

            connection.on("programUpdated", (program: Program) => {
                dispatch(ProgramDataReducer.actions.programUpdated(program))
            })

            // used for both program deleton and user removal
            connection.on("programRemoved", (programId: number) => {
                dispatch(ProgramDataReducer.actions.programRemoved(programId))
            })

            connection.start().catch((err) => console.log(err));

            setConnection(connection);
        }
    }, [keycloak.authenticated])

    return (
        <SignalRContext.Provider value={connection}>
            {props.children}
        </SignalRContext.Provider>
    );
}