import './InviteToast.css';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Stores/GlobalStore';
import { Button, Toast } from "devextreme-react";
import { InvitesDataReducer } from '../../Reducers/InvitesDataReducer';
import { useEffect, useState } from 'react';

type InviteToastProps = {
    viewInvites: () => void;
}

export function InviteToast(props:InviteToastProps) {
    const toastDisplayTimeMillisec = 10 * 1000;

    const dispatch = useDispatch();
    const message = useSelector((state: RootState) => state.inviteData.inviteToastMessage);

    const [displayMessage, setDisplayMessage] = useState<string>('');

    useEffect(() => {
        if (message !== '') {
            setDisplayMessage(message);
        }
    }, [message]);

    const clearToast = () => {
        dispatch(InvitesDataReducer.actions.clearInviteNotification(null));
    }

    const showToast = message !== '';
    return (
        <Toast
            visible={showToast}
            displayTime={toastDisplayTimeMillisec}
            width={'27rem'}
            position={'bottom right'}
            onHiding={() => {
                clearToast();
            }}
            type='custom'
            contentRender={() => 
                <div className='inviteToastVertContent'>
                    <div className='inviteToastHorizContent'>
                        <div className='inviteToastIcon'>
                            <span className='dx-icon-email'/>
                        </div>
                        <span className='inviteToastText'>
                            {displayMessage}
                        </span>
                        <div className='inviteToastButtons'>
                            <Button 
                                text='View' 
                                onClick={async() => {
                                    props.viewInvites();
                                    clearToast();
                                }}/>
                            <Button 
                                text='Clear' 
                                onClick={() => clearToast()}/>
                        </div>
                    </div>
                    <div className='inviteToastLoadingBar'>
                        <div/>
                    </div>
                </div>
            }/>
    );
}

export default InviteToast;