import { AggregationType } from "../../Classes/Enums/AggregationType";
import { ResultPropertyInfo, getPropDisplayName } from "../../Classes/ResultPropertyInfo";

export const previewImageWidth = 200;
export const previewImageHeight = 120;

export const getUnits = (propertyKeyLookup:any, key:string) => {
    if (propertyKeyLookup && key !== null && key in propertyKeyLookup) {
        let propertyInfo:ResultPropertyInfo = propertyKeyLookup[key]
        // If beam units exist, display both units
        if (propertyInfo.beamUnitLabel)
            return `Panels: ${propertyInfo.unitLabel}, Beams: ${propertyInfo.beamUnitLabel}`
        else
            return propertyInfo.unitLabel;
    } else {
        return undefined;
    }
};

export const nameWithUnits = (propertyKeyLookup:any, key:string, aggregationType:AggregationType|undefined) => {
    // Return an empty string if the lookup is not loaded.
    if (propertyKeyLookup == null) {
        return '';
    }
    const label = getPropDisplayName(key, aggregationType, propertyKeyLookup);
    const units = getUnits(propertyKeyLookup, key);
    if (units) {
        return label + ' (' + units + ')';
    } else { 
        return label;
    }
};

export const isNumber = (value:any) => { 
    return (value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString()));
}