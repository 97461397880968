import "./FolderNavigation.css"

import { Button } from "devextreme-react"
import { useEffect, useState } from "react"
import { CustomChartFolder } from "../../../../../Classes/Charts/CustomChartFolder"
import FolderNavigationButtion from "./FolderNavigationButton"

type FolderNavigationProps = {
    rootFolder: CustomChartFolder|undefined
    currentFolder: CustomChartFolder|undefined
    setCurrentFolder: (newFolder:CustomChartFolder) => void
}

export function FolderNavigation(props: FolderNavigationProps) {

    const [path, setPath] = useState<Array<CustomChartFolder>>()

    useEffect(() => {
        if (props.rootFolder != null && props.currentFolder != null) {
            let newPath = getPath(props.rootFolder, props.currentFolder.id)
            if (newPath)
                setPath(newPath)
        }
    }, [props.rootFolder, props.currentFolder])

    const getPath = (folder:CustomChartFolder, targetId:number|null) => {
        // If the folder is the target, just return the folder
        if (folder.id == targetId) {
            return [folder]
        }
        // If there are no more subfolders to search, and the folder is not the target, return null indicating that this path does not contain the target folder.
        if (folder.subFolders.length == 0) {
            return null
        }
        // Otherwise, search subfolders for the path
        let path = null
        folder.subFolders.forEach((subFolder:CustomChartFolder) => {
            let subPath = getPath(subFolder, targetId)
            if (subPath != null) {
                path = [folder, ...subPath]
                // Break out of foreach once target path is found
                return
            }
        })
        return path
    }

    return (
        <div className="folderNavigationDiv">
            {path?.map((folder:CustomChartFolder, index:number) => (
                <div key={index} style={{display: 'flex'}}>
                    {folder.id != props.rootFolder?.id &&
                    <Button
                        className="breadCrumbArrow"
                        icon="spinright"
                        hoverStateEnabled={false}
                        disabled={true}/>
                    }
                    <FolderNavigationButtion folder={folder} setCurrentFolder={props.setCurrentFolder}/>
                </div>
            ))}
        </div>
    )
}
export default FolderNavigation