import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { GraphSetFolder } from '../Classes/GraphSetFolder';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function GetFolderTree(authToken:string): Promise<APIResponseWithStatus<GraphSetFolder>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `GetFolderTree`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function CreateFolder(authToken:string, parentFolderId:number, name:string): Promise<APIResponseWithStatus<number>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `Create?parentFolderId=${parentFolderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RenameFolder(authToken:string, folderId:number, name:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `Rename?folderId=${folderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function DeleteFolder(authToken:string, folderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `Delete?folderId=${folderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function MoveFolder(authToken:string, folderId:number, destinationFolderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `MoveFolder?folderId=${folderId}&destinationFolderId=${destinationFolderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function FolderNameIsDuplicate(authToken:string|undefined, name:string, parentFolderId:number): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `FolderNameIsDuplicate?parentFolderId=${parentFolderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetSelectedFolder(authToken:string): Promise<APIResponseWithStatus<number|null>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `GetSelected`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function SelectFolder(authToken:string, folderId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("GraphSetFolder", `Select?folderId=${folderId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}