import './ShareSettings.css';

import { List, Popup, SelectBox } from 'devextreme-react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRoleDescription, SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { Program } from '../../../../../Classes/UserGroups/Program';
import { ToolbarItem } from 'devextreme-react/popup';
import { SharedGraphProgram } from '../../../../../Classes/UserGroups/SharedGraphProgram';
import { RootState } from '../../../../../Stores/GlobalStore';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';
import { sharedGroupRolesList } from './ShareSettings';

type ShareSettingsAddProgramsPopupProps = {
    sharedGraphPrograms:SharedGraphProgram[]|undefined;
    showPopup:boolean;
    onHiding:() => void;
    applyAdd:(selectedPrograms:Array<Program>, selectedRole:SharedGraphRoleType) => Promise<boolean>;
}

export function ShareSettingsAddProgramsPopup(props: ShareSettingsAddProgramsPopupProps) {
    const showPopup = props.showPopup;
    const sharedGraphPrograms = props.sharedGraphPrograms;

    const programs = useSelector((state:RootState) => state.programData.programs);
    const [selectedPrograms, setSelectedPrograms] = useState<Program[]>([]);
    const [selectedRole, setSelectedRole] = useState<SharedGraphRoleType>(SharedGraphRoleType.ReadOnly);

    const programsListSearchExpr = useMemo(() => ['name', 'companyName'], []);

    const selectablePrograms:Array<Program> = useMemo(() => {
        if (showPopup) {
            const sharedGraphIds = sharedGraphPrograms?.map((sharedGraphProgram) => sharedGraphProgram.programId);
            const newSelectablePrograms = programs.filter((program:Program) => {
                return !sharedGraphIds?.includes(program.id);
            })
            return newSelectablePrograms.sort((a,b) => naturalCompare(a.name, b.name));
        }
        else {
            return [];
        }
    }, [showPopup, sharedGraphPrograms, programs]);

    // Clear the selected programs when the selectable programs change
    useEffect(() => {
        setSelectedPrograms(new Array<Program>());
    }, [selectablePrograms]);

    return (
        <Popup 
            wrapperAttr={{class: 'shareGraphWithList'}}
            title={`Share With Programs`}
            width='34rem'
            height='auto'
            maxHeight='40rem'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
            <div className='shareGraphWithPopupContent'>
                <SelectBox 
                    dataSource={sharedGroupRolesList}
                    selectedItem={selectedRole}
                    onValueChanged={(e:any) => setSelectedRole(e.value as SharedGraphRoleType)}
                    displayExpr='label'
                    valueExpr='value'
                    defaultValue={SharedGraphRoleType.ReadOnly}/>
                <p>
                    {getRoleDescription(selectedRole)}
                </p>
                <List
                    dataSource={selectablePrograms}
                    searchEnabled={selectablePrograms.length > 5}
                    searchExpr={programsListSearchExpr}
                    selectionMode='multiple'
                    showSelectionControls={true}
                    selectedItems={selectedPrograms}
                    onSelectedItemsChange={(newItems) => {
                        setSelectedPrograms(newItems);
                    }}
                    noDataText='No programs to display'
                    useNativeScrolling={true}
                    itemRender={(item:Program) => (
                        <div className='sharingListItem'>
                            <div className='sharingListItemLeftDiv'>
                                {item.name}
                            </div>
                            <div className='sharingListItemRightDiv' style={{fontStyle: 'italic'}}>
                                {item.companyName}
                            </div>
                        </div>
                    )} 
                    pageLoadMode='scrollBottom'/>
            </div>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                disabled={selectedPrograms.length === 0}
                options={{
                    text: 'Share',
                    onClick: () => props.applyAdd(selectedPrograms, selectedRole)
                }}/>
        </Popup>
    );
}
export default ShareSettingsAddProgramsPopup;