import './DataTable.css';

import { ResultPropertyInfo, getColumnDisplayName } from '../../../../../Classes/ResultPropertyInfo';
import { Button, DataGrid, Popover, Popup } from 'devextreme-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import useKeycloak from '../../../../../Keycloak';
import { Column, Paging, Scrolling, Sorting } from 'devextreme-react/data-grid';
import { AddDbDataSourceCategory, DeleteDbDataSourceColumn, RemoveDbDataSourceCategory, SetDbColumnCustomName } from '../../../../../API/CustomChartAPI';
import { APIRequestStatus, APIRequestStatusType } from '../../../../../Classes/APIRequestStatus';
import { getEmptySectionMessageUI } from '../GraphSettings';
import GraphSettingsAddButton from '../GraphSettingsAddButton/GraphSettingsAddButton';
import GraphSettingsRemoveButton from '../GraphSettingsRemoveButton/GraphSettingsRemoveButton';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import { exportDataGridToExcel, naturalCompare, toSigFigs } from '../../../../../Utilities/CommonUtilities';
import { DataSourceColumn } from '../../../../../Classes/DataSourceColumn';
import { ContextMenuPreparingEvent } from 'devextreme/ui/data_grid';
import ColumnFilter from './ColumnFilter/ColumnFilter';
import { SetNamePopup } from '../../../../SetNamePopup';

type DataTableProps = {
    graphSettings:CustomChartSettings;
    setGraphSettings: (newVal:CustomChartSettings) => void;
    dataSourceTable: any[];
    propertyKeyDictionary: {[key: string]: ResultPropertyInfo};
    showColumnSelectorPopup: () => void;
    deleteUIColumn: (column:DataSourceColumn) => void;
}

const addCategoryButtonId = 'addCategoryButton';

export function DataTable({dataSourceTable, graphSettings, setGraphSettings, propertyKeyDictionary, showColumnSelectorPopup, deleteUIColumn}: DataTableProps) {
    const { token } = useKeycloak();
    const dataTableRef = useRef(null);

    const [addCategoryPopoverVisible, setAddCategoryPopoverVisible] = useState<boolean>(false);
    const [columnToFilter, setColumnToFilter] = useState<DataSourceColumn|null>(null);
    const [columnToEdit, setColumnToEdit] = useState<DataSourceColumn|undefined>(undefined);

    const { dataSourceColumns, dataSourceCategoryIds, stringFiltersByColumnId } = graphSettings;
    const sigFigs = graphSettings.valuePrecision;

    const potentialCategoryColumns = useMemo(() => {
        return dataSourceColumns.filter(i => !dataSourceCategoryIds.includes(i.id));
    }, [dataSourceCategoryIds, dataSourceColumns]);

    // Sort categories before other columns
    const sortedColumns = useMemo(() => {
        const categoryImportance = new Map<number, number>();
        const categoryLength = dataSourceCategoryIds.length;
        dataSourceCategoryIds.forEach((item, index) => {
            categoryImportance.set(item, categoryLength - index);
        });
        const newSortedDSCols = [...dataSourceColumns].sort((a, b) => {
            const aCatIndex = categoryImportance.get(a.id) ?? 0;
            const bCatIndex = categoryImportance.get(b.id) ?? 0;
            return bCatIndex - aCatIndex;
        });
        return newSortedDSCols;
    }, [dataSourceCategoryIds, dataSourceColumns]);

    const getDataCategoryDisplayName = (categoryId:number) => {
        const column = dataSourceColumns.find(i => i.id === categoryId);
        if (column) {
            return getColumnDisplayName(column, propertyKeyDictionary);
        }
        return 'Unknown';
    }

    const deleteColumn = useCallback((column:DataSourceColumn) => {
        DeleteDbDataSourceColumn(token, column.id).then(response => {
            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                deleteUIColumn(column);
            }
        });
    }, [deleteUIColumn, token]);

    const addColumnAsCategory = useCallback((column:DataSourceColumn) => {
        const oldValue = [...dataSourceCategoryIds];
        const newValue = [...dataSourceCategoryIds, column.id];

        // Make the change in the UI
        setGraphSettings({...graphSettings, dataSourceCategoryIds: newValue});

        // Make the change in the DB
        AddDbDataSourceCategory(token, column.id).then(response => {
            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                // Revert the UI change if things go south
                setGraphSettings({...graphSettings, dataSourceCategoryIds: oldValue});
            }
        });
    }, [dataSourceCategoryIds, graphSettings, setGraphSettings, token]);

    const removeColumnAsCategory = useCallback((categoryId:number) => {
        const oldVal = [...dataSourceCategoryIds];
        const newVal = dataSourceCategoryIds.filter(i => i !== categoryId);

        // Make the change in the UI
        setGraphSettings({...graphSettings, dataSourceCategoryIds: newVal});

        // Make the change in the DB
        RemoveDbDataSourceCategory(token, categoryId).then(response => {
            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                // Revert the UI change if things go south
                setGraphSettings({...graphSettings, dataSourceCategoryIds: oldVal});
            }
        });
    }, [dataSourceCategoryIds, graphSettings, setGraphSettings, token]);

    const onContextMenuPreparing = useCallback((e: ContextMenuPreparingEvent<any, any>):void => {
        const columnId = e.column?.dataField;
        const rowType = e.row?.rowType;
        if (columnId != null && rowType === 'header') {
            const column = graphSettings.dataSourceColumns.find(i => i.id.toString() === columnId);
            if (column != null) {
                // e.items can be undefined
                if (!e.items) {
                    e.items = [];
                }

                // Add a custom menu item
                e.items.push({
                    text: `Delete Column`,
                    icon: 'trash',
                    onItemClick: () => deleteColumn(column)
                });
            }
        }
    }, [deleteColumn, graphSettings.dataSourceColumns]);

    const columnHeaderCellRender = useCallback((column:DataSourceColumn) => {
        const id = 'getColumnOptionButtonUI_' + column.id;
        const columnDisplayName = getColumnDisplayName(column, propertyKeyDictionary);
        const isCategory = graphSettings.dataSourceCategoryIds.find(catId => catId === column.id) != null;
        const propInfo = propertyKeyDictionary[column.propertyKey];
        const isStringType = !propInfo?.isNumeric;

        const hasFilters = stringFiltersByColumnId != null && stringFiltersByColumnId[column.id] != null && stringFiltersByColumnId[column.id].length > 0;
        const hasCustomName = column.customName != null;

        return (
            <div className='columnHeaderDiv' key={id}>
                {columnDisplayName}
                <div
                    title='Delete Column'
                    className='columnOptionsButton'
                    onClick={() => deleteColumn(column)}>
                    <div className='columnActionButton'>
                        <i className="dx-icon-trash"/>
                    </div>
                </div>
                {isCategory &&
                <div
                    title='Remove from Categories'
                    className='columnOptionsButton'
                    onClick={() => removeColumnAsCategory(column.id)}>
                    <div className='columnActionButton'>
                        <i className="dx-icon-showpanel"/>
                    </div>
                </div>}
                {!isCategory &&
                <div
                    title='Add to Categories'
                    className='columnOptionsButton'
                    onClick={() => addColumnAsCategory(column)}>
                    <div className='columnActionButton'>
                        <i className="dx-icon-hidepanel"/>
                    </div>
                </div>}
                {isStringType &&
                <div
                    title={hasCustomName ? 'Edit Custom Name' : 'Add Custom Name'}
                    className={'columnOptionsButton'}
                    onClick={() => setColumnToEdit(column)}>
                    <div className={hasCustomName ? 'columnActionButton buttonHasFilters' : 'columnActionButton'}>
                        <i className="dx-icon-edit"/>
                    </div>
                </div>}
                {isStringType &&
                <div
                    title={hasFilters ? 'Has Filters Applied' : 'Filter Zone Values'}
                    className={'columnOptionsButton'}
                    onClick={() => setColumnToFilter(column)}>
                    <div className={hasFilters ? 'columnActionButton buttonHasFilters' : 'columnActionButton'}>
                        <i className="dx-icon-filter" style={{fontSize: '0.8em'}}/>
                    </div>
                </div>}
            </div>
        );
    }, [propertyKeyDictionary, graphSettings.dataSourceCategoryIds, stringFiltersByColumnId, deleteColumn, removeColumnAsCategory, addColumnAsCategory]);

    const dataTableUI = useMemo(() => (
        <DataGrid 
            ref={dataTableRef}
            dataSource={sortedColumns.length > 0 ? dataSourceTable : null}
            showBorders={true}
            height={'100%'}
            columnAutoWidth={true}
            allowColumnResizing={true}
            onContextMenuPreparing={onContextMenuPreparing}>
            {sortedColumns.map((column) => 
                <Column 
                    key={column.id}
                    cssClass={graphSettings.dataSourceCategoryIds.find(catId => catId === column.id) != null ? 'categoryColumn' : ''}
                    dataField={column.id.toString()}
                    caption={getColumnDisplayName(column, propertyKeyDictionary)}
                    sortingMethod={naturalCompare}
                    calculateCellValue={(rowData:any) => calculateCellValue(rowData, column.id.toString(), sigFigs)}
                    headerCellRender={() => columnHeaderCellRender(column)}/>
            )}
            <Paging enabled={true}/>
            <Scrolling useNative={true}/>
            <Sorting mode="none"/>
        </DataGrid>
    ), [columnHeaderCellRender, dataSourceTable, graphSettings.dataSourceCategoryIds, onContextMenuPreparing, propertyKeyDictionary, sigFigs, sortedColumns]);

    return (
        <div className='dataTableWrapper'>
            <div className='dataTableHeader'>
                <div className='dataTableHeaderLeft'>
                    Categorize zone data for each
                    {getEmptySectionMessageUI('No categories selected', dataSourceCategoryIds.length === 0)}
                    {dataSourceCategoryIds.map((catId) => (
                        <div className='columnSelectionBox categoryColumn' key={catId}>
                            {getDataCategoryDisplayName(catId)}
                            <GraphSettingsRemoveButton onClick={() => {
                                removeColumnAsCategory(catId);
                            }}/>
                        </div>
                    ))}
                    {potentialCategoryColumns.length > 0 &&
                    <GraphSettingsAddButton
                        id={addCategoryButtonId}
                        onClick={() => {
                            setAddCategoryPopoverVisible(true);
                        }}/>}
                </div>
                <div className='dataTableHeaderRight'>
                    <Button
                        icon={'xlsxfile'}
                        text='Export'
                        hint='Export Data to Excel'
                        type='default'
                        onClick={() => {
                            if (dataTableRef.current != null) {
                                const dataGridInstance = (dataTableRef.current as any).instance;
                                exportDataGridToExcel(dataGridInstance, graphSettings.title, 'HyperX Dashboard Graph Data.xlsx');
                            }
                        }}/>
                    <Button
                        icon={'add'}
                        text='Add Column'
                        type='success'
                        onClick={() => {
                            showColumnSelectorPopup();
                        }}/>
                </div>
            </div>
            <div className='dataTable'>
                {dataTableUI}
            </div>
            <Popover
                target={`#${addCategoryButtonId}`}
                visible={addCategoryPopoverVisible}
                wrapperAttr={{class: 'addCategoryPopoverWrapper'}}
                position="bottom"
                width={'12em'}
                onHiding={() => setAddCategoryPopoverVisible(false)}
                shadingColor="rgba(0, 0, 0, 0.25)">
                    <div className='addCategoryButtonPopoverList'>
                        {potentialCategoryColumns.map((column, index) => (
                            <div
                                key={column.id}
                                className={'addCategoryButtonPopoverListItem' + (index > 0 ? ' addCategoryButtonPopoverListItemWithBorder' : '')}
                                onClick={() => {
                                    setAddCategoryPopoverVisible(false);
                                    addColumnAsCategory(column);
                                }}>
                                {getColumnDisplayName(column, propertyKeyDictionary)}
                            </div>
                        ))}
                    </div>
            </Popover>
            <Popup
                title={`Values to Include`}
                width={'32em'}
                height={'38em'}
                wrapperAttr={{class: 'columnFilterPopupWrapper'}}
                visible={columnToFilter != null}
                hideOnOutsideClick={true}
                onHiding={() => setColumnToFilter(null)}>
                <div style={{height: '100%'}}>
                    {columnToFilter != null && 
                    <ColumnFilter
                        graphSettings={graphSettings}
                        setGraphSettings={setGraphSettings}
                        columnToFilter={columnToFilter}/>}
                </div>
            </Popup>
            <SetNamePopup
                title='Custom Column Name'
                applyButtonName='Apply'
                oldName={columnToEdit?.customName ?? ''}
                showPopup={columnToEdit != null}
                hidePopup={() => setColumnToEdit(undefined)}
                infoText={`If you are filtering a column, you may want to add a custom name to better describe the column's data. For example, you could use "Skin Structure Name" instead of "Structure Name" when only showing skin structures.`}
                placeholder={`Default name is "${getDefaultColumnName(columnToEdit, propertyKeyDictionary)}"`}
                allowEmptyName={true}
                applySetName={(newName:string) => {
                    if (columnToEdit != null) {
                        // Make the change in the DB
                        SetDbColumnCustomName(token, columnToEdit.id, newName).then(response => {
                            if (response.type === APIRequestStatusType.Success) {
                                // Make the change in the UI
                                const newDataSourceColumns = graphSettings.dataSourceColumns.map(column => {
                                    if (column.id === columnToEdit.id) {
                                        return {...column, customName: newName ? newName : undefined};
                                    }
                                    return column;
                                });
                                setGraphSettings({...graphSettings, dataSourceColumns: newDataSourceColumns});
                            }
                        });
                    }
                }}/>
        </div>
    );
}
export default DataTable;

function calculateCellValue(rowData: any, key: string, sigFigs:number|undefined) {
    const rawValue = rowData[key];
    if (rawValue instanceof Date) {
        return rawValue.toLocaleString();
    }
    else if (typeof(rawValue) === 'number' && sigFigs != null && !Number.isInteger(rawValue)) {
        return toSigFigs(rawValue, sigFigs);
    }
    return rawValue;
}

function getDefaultColumnName(column:DataSourceColumn|undefined, propertyKeyDict:{[propertyKey: string]: ResultPropertyInfo}) {
    if (column && column.propertyKey in propertyKeyDict) {
        const propInfo = propertyKeyDict[column.propertyKey];
        return propInfo.displayName;
    }
    return 'Unknown';
}