import './ViewerItem.css';

import { CustomChartFolder } from '../../../Classes/Charts/CustomChartFolder';

interface ViewerFolderItemProps {
    folderInfo:CustomChartFolder;
    onClick: () => void;
}

export default function ViewerFolderItem({ folderInfo, onClick }: ViewerFolderItemProps) {
    return (
        <div className="viewerItem folderViewerItem" onClick={onClick}>
            <i className='dx-icon-folder'/>
            <span>{folderInfo.name}</span>
        </div>
    );
}