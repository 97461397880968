import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { CustomChartSettings } from '../Classes/Charts/CustomChartSettings';
import { UnitSystemType } from '../Classes/User';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function GetDbGraphBySharedLinkId(sharedLinkId:string, unitSystem:UnitSystemType): Promise<APIResponseWithStatus<CustomChartSettings|undefined>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions('');
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("ReadOnlyChart", `GetGraphBySharedLinkId?sharedLinkId=${sharedLinkId}&unitSystem=${unitSystem}`); 
    
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetDbResultsByZone(sharedLinkId: string, unitSystem:UnitSystemType): Promise<any[]|null> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions();
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("ReadOnlyChart", `GetResultsByZone?sharedLinkId=${sharedLinkId}&unitSystem=${unitSystem}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();
    
    return json;
}