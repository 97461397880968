import { Button } from "devextreme-react"
import { CustomChartFolder } from "../../../../../Classes/Charts/CustomChartFolder"
import { CustomChartDragTypes } from "../../../../../Classes/Charts/CustomChartDragTypes"
import { useEffect, useRef, useState } from "react"
import {
    dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

type FolderNavigationButtionProps = {
    folder: CustomChartFolder
    setCurrentFolder: (newFolder:CustomChartFolder) => void
}

export function FolderNavigationButtion(props: FolderNavigationButtionProps) {
    const [isDragHovered, setIsDragHovered] = useState(false);

    const dndRef = useRef<any>(null);

    useEffect(() => {
        const dndElement = dndRef.current;

        return dropTargetForElements({
                element: dndElement,
                getData: () => ({ folder: props.folder }),
                onDragEnter: () => {
                    setIsDragHovered(true);
                },
                onDragLeave: () => {
                    setIsDragHovered(false);
                },
                onDrop: () => {
                    setIsDragHovered(false);
                },
                canDrop: ({ source }) => {
                    return source.data.type === CustomChartDragTypes.FOLDER || source.data.type === CustomChartDragTypes.CHART;
                }
            })
    }, [props.folder]);
    
    const draggingOverClass = isDragHovered ? 'canDropInto' : '';
    return (
        <div ref={dndRef}>
            <Button 
                className={`breadCrumbButton ${draggingOverClass}`}
                text={props.folder.name}
                onClick={() => {
                    props.setCurrentFolder(props.folder)
                }}/>
        </div>
    )
}
export default FolderNavigationButtion