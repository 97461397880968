import './index.css';

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SignalRProvider } from './Utilities/SignalRProvider';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { oidcConfig } from './Keycloak';
import RootStore from './Stores/GlobalStore';
// import reportWebVitals from './reportWebVitals';

const rootContainer = document.getElementById('rootDiv');
const root = rootContainer != null ? ReactDOMClient.createRoot(rootContainer) : null;

root?.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <Provider store={RootStore}>
            <SignalRProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </SignalRProvider>
            </Provider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
