import './GraphSetManager.css';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { useEffect, useRef, useState } from 'react';
import { ContextMenu } from 'devextreme-react';
import { GraphSetDragTypes } from '../../../../Classes/GraphSetDragTypes';
import { useDispatch } from 'react-redux';
import { moveGraphSetFolder } from '../../../../Reducers/SetManagementReducer';
import useKeycloak from '../../../../Keycloak';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
    dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

interface GraphSetListItemProps {
    folder:GraphSetFolder;
    setSelectedFolderId:(newId:number) => void;
    folderContextMenuItems: any[];
}

export function GraphSetFolderListItem(props:GraphSetListItemProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    
    const [isDragHovered, setIsDragHovered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const dndRef = useRef<any>(null);
    
    useEffect(() => {
        const dndElement = dndRef.current;

        return combine(
            draggable({
                element: dndElement,
                getInitialData: () => ({ folder: props.folder, type: GraphSetDragTypes.FOLDER }),
                onDragStart: () => {
                    setIsDragging(true);
                }, 
                onDrop: ({ location }) => {
                    setIsDragging(false);

                    if (location.current.dropTargets.length > 0) {
                        const firstDropTarget = location.current.dropTargets[0];
                        const dropFolder = firstDropTarget.data.folder as GraphSetFolder;
                        const destinationFolderId = dropFolder.id;
                        const folderToMove = props.folder;
                        
                        if (destinationFolderId != null && destinationFolderId !== folderToMove.id) {
                            dispatch(moveGraphSetFolder({
                                authToken: keycloak.token, 
                                folderId: folderToMove.id, 
                                destinationFolderId: destinationFolderId
                            }));
                        }
                    }
                }
            }),
            dropTargetForElements({
                element: dndElement,
                getData: () => ({ folder: props.folder }),
                onDragEnter: () => {
                    setIsDragHovered(true);
                },
                onDragLeave: () => {
                    setIsDragHovered(false);
                },
                onDrop: () => {
                    setIsDragHovered(false);
                },
                canDrop: ({ source }) => {
                    return source.data.type === GraphSetDragTypes.FOLDER || source.data.type === GraphSetDragTypes.GRAPH_SET;
                }
            }),
        )   
    }, [dispatch, keycloak.token, props.folder]);

    const draggingOverClass = isDragHovered ? 'canDropInto' : '';

    let extraStyles = {};
    if (isDragging) {
        extraStyles = {
            opacity: 0
        }
    }

    const folderClass = 'graphSetFolderTemplate';
    const idString = `graphSetFolderID${props.folder.id}`;
    return (
        <div 
            ref={dndRef} 
            style={extraStyles}
            className={folderClass + ' ' + draggingOverClass}
            id={idString}
            onClick={() => {
                props.setSelectedFolderId(props.folder.id);
            }}>
            <i className="dx-icon-folder"/>
            <span>{props.folder.name}</span>
            <ContextMenu
                dataSource={props.folderContextMenuItems}
                target={`#${idString}`}
                onItemClick={(e:any) => e.itemData.action()}/>
        </div>
    );
}
export default GraphSetFolderListItem;