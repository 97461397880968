import './CompanyProgram.css';
import './Companies.css';

import { Button, DropDownButton, List, Tooltip } from 'devextreme-react';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCompany, deleteCompany, getCompanies, removeUsers, renameCompany } from '../../Reducers/CompanyDataReducer';
import { removeInvites, sendCompanyUserInvites } from '../../Reducers/InvitesDataReducer';
import { SetNamePopup } from '../SetNamePopup';
import { Company } from '../../Classes/UserGroups/Company';
import { AddCompanyUserPopup } from './AddCompanyUserPopup';
import { EditCompanyUserPopup } from './EditCompanyUserPopup';
import { TagsPopup } from './TagsPopup';
import { CompanyUser } from '../../Classes/UserGroups/CompanyUser';
import { confirm } from 'devextreme/ui/dialog';  
import { canAddRemoveUsers, canAssignRoles, canAuditCompany, canDeleteCompany, canRenameCompany, getRoleNamePlural, GroupRoleType } from '../../Classes/UserGroups/GroupRole';
import { GroupAction } from '../../Classes/UserGroups/Group';
import { fetchSummaryData, removeUICompanyUploadSets } from '../../Reducers/SetManagementReducer';
import { CompanyNameIsDuplicate } from '../../API/CompaniesAPI';
import { Invite } from '../../Classes/Invite';
import DataSource from 'devextreme/data/data_source';
import { removeUIProgramsInCompany } from '../../Reducers/ProgramDataReducer';
import { RootState } from '../../Stores/GlobalStore';
import { AuditPopup } from './AuditPopup';
import { invalidateAll } from '../../Reducers/ResultsReducer';
import { naturalCompare } from '../../Utilities/CommonUtilities';
import useKeycloak from '../../Keycloak';

function GroupTemplate(item: DataSource) {
    return (
        <div>
            {getRoleNamePlural(item.key as unknown as GroupRoleType)}
        </div>
    );
}

type CompaniesProps = {
    popupIsOpen: boolean;
}

export function Companies(props: CompaniesProps) {

    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const userCanCreateCompany = useSelector((state:RootState) => state.userData.user?.isCompanyAdmin);
    const companies = useSelector((state:RootState) => state.companyData.companies);
    const userId = useSelector((state:RootState) => state.userData.user?.id);

    const [showCreateCompanyPopup, setShowCreateCompanyPopup] = React.useState<boolean>(false);
    const [showAddUserPopup, setShowAddUserPopup] = React.useState<boolean>(false);
    const [showTagsPopup, setShowTagsPopup] = React.useState<boolean>(false);
    const [showAuditPopup, setShowAuditPopup] = React.useState<boolean>(false);
    const [showRenameCompanyPopup, setShowRenameCompanyPopup] = React.useState<boolean>(false);
    const [userToEdit, setUserToEdit] = React.useState<CompanyUser|undefined>(undefined);

    const [selectedCompany, setSelectedCompany] = React.useState<Company|undefined>(undefined);
    const [currentRole, setCurrentRole] = React.useState<GroupRoleType>(GroupRoleType.Member);
    const [selectedCompanyUsers, setSelectedCompanyUsers] = React.useState<DataSource<CompanyUser>>();

    const [moreButtonActions, setMoreButtonActions] = React.useState<any[]>(new Array<any>());

    const sortedCompanies = useMemo(() => {
        return [...companies].sort((a, b) => naturalCompare(a.name, b.name));
    }, [companies]);

    useEffect(() => {
        // Reload the group data from the server when the form is opened
        let token = keycloak.token;
        if (props.popupIsOpen && token) {
            dispatch(getCompanies(token));
            clearSelectedCompany();
        }
    }, [dispatch, props.popupIsOpen]);

    useEffect(() => {
        // Update selectedCompany when the company objects change
        if (selectedCompany) {
            let newSelectedCompany = sortedCompanies.find((company:Company) => company.id === selectedCompany.id);
            setSelectedCompany(newSelectedCompany);

            if (newSelectedCompany) {
                // Format invited users and combine with comany user list
                const invitedUsers: CompanyUser[] | undefined = newSelectedCompany.invites?.map((i: Invite) => { 
                    const user: CompanyUser = new CompanyUser();
                    user.role = GroupRoleType.Invited;
                    user.displayName = i.recipient.first + ' ' + i.recipient.last;
                    user.email = i.recipient.email;
                    user.id = i.id.toString();

                    return user;
                });
                const users: CompanyUser[] = newSelectedCompany.users.concat(invitedUsers ? invitedUsers : [])
                const groupedUsers = new DataSource<CompanyUser>({
                    searchExpr: ['email', 'displayName'],
                    store: {
                        type: 'array',
                        data: users
                      }
                });
                groupedUsers.group('role');

                setSelectedCompanyUsers(groupedUsers)
            }
        }
    }, [selectedCompany, sortedCompanies]);

    useEffect(() => {
        let token = keycloak.token;
        if (selectedCompany?.id && token) {
            let companyUser = selectedCompany.users.find(u => u.id === userId);
            if (companyUser?.role !== undefined)
                setCurrentRole(companyUser?.role);
        }
    }, [keycloak.token, selectedCompany, selectedCompany?.id, selectedCompany?.users, userId]);

    useEffect(() => {
        let newMoreButtonActions = new Array<any>();

        newMoreButtonActions.push({ id: GroupAction.Tags, text: 'View Tags', icon: 'tags'});
        if (canAuditCompany(currentRole))
            newMoreButtonActions.push({ id: GroupAction.Audit, text: 'Audit Logs', icon: 'doc'});
        newMoreButtonActions.push({ id: GroupAction.Leave, text: 'Leave', icon: 'hidepanel' });
        if (canRenameCompany(currentRole))
            newMoreButtonActions.push({ id: GroupAction.Rename, text: 'Rename', icon: 'edit'});
        if (canDeleteCompany(currentRole))
            newMoreButtonActions.push({ id: GroupAction.Delete, text: 'Delete', icon: 'trash'});

        setMoreButtonActions(newMoreButtonActions);
    }, [currentRole]);

    const clearSelectedCompany = () => {
        setSelectedCompany(undefined);
        setSelectedCompanyUsers(undefined);
    }

    const emptyCompanyMessage = 'You are not a member of any companies. Please request a company invite from the person who manages your company in the system.';
    const emptyCompanyAdminMessage = emptyCompanyMessage + ' If your organization has not yet created a company, you can create one using the + button above.';

    let adminRoleWillTransfer = selectedCompany && currentRole === GroupRoleType.Administrator && selectedCompany.users.length > 1
        && selectedCompany.users.filter(u => u.role === GroupRoleType.Administrator).length === 1;
    let appendToLeaveCompanyMessage = adminRoleWillTransfer ? ' Your administrator role will be transferred to someone else in the company.' : '';
    return (
        <div className='mainCompaniesDiv'>

            <div className='companiesDiv'>
                <h3>
                    My Companies
                    <Button
                        style={{marginLeft: '0.5vw'}}
                        icon="add" 
                        type='success'
                        hint='Create company'
                        onClick={() => setShowCreateCompanyPopup(true)}
                        visible={userCanCreateCompany}/>
                </h3>
                <List
                    dataSource={sortedCompanies}
                    useNativeScrolling={true}
                    selectionMode='single'
                    searchEnabled={sortedCompanies.length > 0}
                    searchExpr='name'
                    selectedItem={selectedCompany}
                    noDataText={userCanCreateCompany ? emptyCompanyAdminMessage : emptyCompanyMessage}
                    onSelectedItemsChange={(items) => {
                        if(items.length > 0)
                            setSelectedCompany(items[0]);
                    }}
                    itemRender={(item:Company) =>
                        <div id={`companyListItem${item.id}`}>
                            <div>{item.name}</div>
                            <Tooltip 
                                target={`#companyListItem${item.id}`}
                                showEvent="dxhoverstart" 
                                hideEvent="dxhoverend" 
                                position='left' 
                                contentRender={() => 
                                    <div>
                                        {item.uploadSetCount?.toLocaleString()} Upload Sets
                                    </div>} />
                        </div>
                    }/>
            </div>
            <div className='companyUsersDiv'>
                <h3>
                    {selectedCompany?.name ?? 'No Company Selected'}
                    {canAddRemoveUsers(currentRole) && selectedCompany != null &&
                    <Button
                        style={{marginLeft: '0.5vw'}}
                        icon="add" 
                        type='success'
                        hint='Add user to company'
                        onClick={() => setShowAddUserPopup(true)}
                        disabled={!selectedCompany}/>}
                    {selectedCompany != null &&
                    <DropDownButton
                        icon='more'
                        hint='More options'
                        showArrowIcon={false}
                        style={{marginLeft: '0.5vw', borderColor: 'transparent'}}
                        dataSource={moreButtonActions}
                        displayExpr='text'
                        dropDownOptions={{ width: '8vw' }}
                        disabled={!selectedCompany}
                        onItemClick={(args) => {
                            let id = args.itemData.id;
                            switch (id) {
                                case GroupAction.Tags:
                                    setShowTagsPopup(true);
                                    break;
                                case GroupAction.Audit:
                                    setShowAuditPopup(true);
                                    break;
                                case GroupAction.Leave: {
                                    let token = keycloak.token;
                                    if (token && selectedCompany) {
                                        let result = confirm(`Are you sure you want to leave "${selectedCompany?.name}"?${appendToLeaveCompanyMessage}`, "Confirm Leave");
                                        result.then(async (confirmLeave) => {
                                            if (confirmLeave) { 
                                                // PG Note: Invalidate results for all graph sets, since graph sets could contain upload sets associated with the 
                                                // soon-to-be-left company. We could query the backend here to know which graph sets need to be invalidated, but 
                                                // since users won't be leaving their company often I don't think this will be much of an issue.
                                                clearSelectedCompany()
                                                dispatch(removeUsers({authToken: token, companyId: selectedCompany.id, userIds: [userId], requesterId: userId}))
                                                .then(() => {
                                                    dispatch(invalidateAll())
                                                })
                                                // No need to remove any upload sets here, since the user remains an external user of company programs even after leaving
                                                // the company
                                            }
                                        });
                                    }
                                    break;
                                }
                                case GroupAction.Rename:
                                    setShowRenameCompanyPopup(true);
                                    break;
                                case GroupAction.Delete: {
                                    let token = keycloak.token;
                                    if (token && selectedCompany) {
                                        let result = confirm(`Are you sure you want to delete ${selectedCompany.name}? This action will delete ${selectedCompany.uploadSetCount} upload set(s) associated with ${selectedCompany.uploadSetUserCount} user(s).`, "Confirm Delete");  
                                        result.then(async (confirmDelete) => {
                                            if (confirmDelete) { 
                                                // PG Note: Invalidate results for all graph sets, since graph sets could contain upload sets associated with the 
                                                // soon-to-be-left company. We could query the backend here to know which graph sets need to be invalidated, but 
                                                // since users won't be deleting their company often I don't think this will be much of an issue.
                                                clearSelectedCompany()
                                                dispatch(deleteCompany({authToken:token, companyId:selectedCompany.id}))
                                                .then(() => {
                                                    dispatch(invalidateAll())
                                                })
                                                dispatch(removeUICompanyUploadSets(selectedCompany.id))
                                                dispatch(removeUIProgramsInCompany(selectedCompany.id))
                                                dispatch(fetchSummaryData({ authToken: token, filterConditions: filterConditions }))
                                            }
                                        });
                                    }
                                    break;
                                }
                            }
                        }}/>}
                </h3> 
                <List
                    dataSource={selectedCompanyUsers}
                    useNativeScrolling={true}
                    searchEnabled={selectedCompany != null && selectedCompany.users.length > 0}
                    grouped={true}
                    collapsibleGroups={true}
                    groupRender={GroupTemplate}
                    disabled={!selectedCompany}
                    itemRender={(item: CompanyUser) => {
                        return (
                            <div className='userListItem' id={`userListItem${item.id}`}>
                                <div className='userListItemLeftDiv'>
                                    {item.displayName}
                                </div>
                                <div className='userListItemRightDiv'>
                                    {
                                        canAssignRoles(currentRole) && item.role !== GroupRoleType.Invited &&
                                        <Button 
                                            icon='edit'
                                            disabled={item.id === userId}
                                            onClick={() => setUserToEdit(item)}
                                        />
                                    }
                                    {
                                        canAddRemoveUsers(currentRole) &&
                                        <Button 
                                            icon='remove'
                                            disabled={item.id === userId}
                                            onClick={() => {
                                                if (item.role == GroupRoleType.Invited) {
                                                    let result = confirm(`Cancel invite for ${item.displayName}?`, "Confirm Cancellation");  
                                                    result.then((confirmed) => {
                                                        let token = keycloak.token;
                                                        if (confirmed && token && selectedCompany) {
                                                            dispatch(removeInvites({ authToken: token, inviteIds: [item.id] }))
                                                        }
                                                    })
                                                } else {
                                                    let result = confirm(`Remove ${item.displayName} from ${selectedCompany?.name}?`, "Confirm Remove");  
                                                    result.then((confirmed) => {
                                                        let token = keycloak.token;
                                                        if (confirmed && token && selectedCompany) {
                                                            dispatch(removeUsers({authToken: token, companyId: selectedCompany.id, userIds: [item.id], requesterId: userId}));
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    <Tooltip 
                                        target={`#userListItem${item.id}`}
                                        showEvent="dxhoverstart" 
                                        hideEvent="dxhoverend" 
                                        position='right' 
                                        contentRender={() => 
                                            <div>
                                                {item.email}
                                        </div>} />
                                </div>
                            </div>
                        )
                    }}/>
            </div>
            <TagsPopup
                showPopup={showTagsPopup}
                authToken={keycloak.token}
                company={selectedCompany}
                onHiding={() => setShowTagsPopup(false)}
                userRole={currentRole}/>
            <AuditPopup
                showPopup={showAuditPopup}
                authToken={keycloak.token}
                company={selectedCompany}
                onHiding={() => setShowAuditPopup(false)}/>
            <SetNamePopup
                title='Create Company'
                validateForDuplicate={(name:string) => CompanyNameIsDuplicate(keycloak.token, name)}
                applyButtonName='Create'
                oldName={''}
                showPopup={showCreateCompanyPopup}
                hidePopup={() => setShowCreateCompanyPopup(false)}
                applySetName={(newName:string) => {
                    let token = keycloak.token;
                    if (token) {
                        dispatch(createCompany({authToken:token, name:newName})).then((createCompanyResponse:any) => {
                            const newCompany = createCompanyResponse?.payload;
                            if (newCompany) {
                                // Auto-select the newly created company.
                                setSelectedCompany(newCompany);
                            }
                        });
                    }
                }}/>
            <SetNamePopup
                title='Rename Company'
                validateForDuplicate={(name:string) => CompanyNameIsDuplicate(keycloak.token, name)}
                applyButtonName='Apply'
                oldName={selectedCompany?.name ?? ''}
                showPopup={showRenameCompanyPopup}
                hidePopup={() => setShowRenameCompanyPopup(false)}
                applySetName={(newName:string) => {
                    let token = keycloak.token;
                    if (token)
                        dispatch(renameCompany({authToken:token, companyId:selectedCompany?.id, name:newName}));
                }}/>
            <AddCompanyUserPopup
                showPopup={showAddUserPopup}
                hidePopup={() => setShowAddUserPopup(false)}
                applyAdd={async (userEmail:string) => {
                    let token = keycloak.token;

                    if (token && selectedCompany) {
                        let result = await dispatch(sendCompanyUserInvites({authToken: token, companyId: selectedCompany.id, userEmails: [userEmail]}));
                        let sentInvites = result.payload.sentInvites;
                        return sentInvites.length > 0;
                    } else {
                        return false;
                    }
                }}/>
            <EditCompanyUserPopup
                showPopup={userToEdit !== undefined}
                company={selectedCompany}
                companyUser={userToEdit}
                onHiding={() => setUserToEdit(undefined)}/>
        </div>
    );
}
export default Companies;