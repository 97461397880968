import { Invite } from '../Classes/Invite';
import { CompanyUser } from '../Classes/UserGroups/CompanyUser';
import { ProgramUser } from '../Classes/UserGroups/ProgramUser';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function SendCompanyUserInvites(
    authToken: string,
    companyId: Number,
    userEmails: string[]
): Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(userEmails);
    
    let url = ApiFetchUtility.getFormattedUrl("Company", `SendUserInvites?companyId=` + companyId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function SendProgramUserInvitesById(
    authToken: string,
    programId: Number,
    userIds:string[]
): Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(userIds);
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `SendInvitesById?programId=` + programId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function SendProgramUserInvitesByEmail(
    authToken: string,
    programId: Number,
    emails:string[]
): Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(emails);
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `SendInvitesByEmail?programId=` + programId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetUserInvites(
    authToken: string
) : Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    const url = ApiFetchUtility.getFormattedUrl("Invite", "GetUserInvites");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetSentInvites(
    authToken: string
) : Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    const url = ApiFetchUtility.getFormattedUrl("Invite", "GetSentInvites");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveInvites(
    authToken: string,
    inviteIds: Number[]
) : Promise<Invite[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(inviteIds);

    const url = ApiFetchUtility.getFormattedUrl("Invite", "RemoveInvites");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddUserToCompany(authToken:string, companyId:Number): Promise<CompanyUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Company", "AddUser?companyId=" + companyId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddUserToProgram(authToken:string, programId:Number): Promise<ProgramUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", "AddUser?programId=" + programId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}