import { Button, Chart } from 'devextreme-react';
import { Label, Legend } from 'devextreme-react/bar-gauge';
import { CommonAxisSettings, CommonSeriesSettings, Grid, Point, Series, Tick } from 'devextreme-react/chart';
import { createRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userDataSelector } from '../../Reducers/UserDataReducer';
import './LandingPage.css';
import useKeycloak from '../../Keycloak';

interface LandingPageProps {
}

export function LandingPage(props:LandingPageProps) {
    const keycloak = useKeycloak();
    const userData = useSelector(userDataSelector);
    const user = userData.user;

    // Randomly generate data for nice-looking chart to show in the background
    const generateChartData = (pointNumber:number) => {
        let newPoints = new Array<BasicPoint>();
        for (let i = 1; i < pointNumber; i++) {
            let sineFunc = 20 + 2*Math.sin(i/10);
            let curveFunc = -5*Math.pow(i, 0.5) + 65;
            let combined = sineFunc + curveFunc;
            let randomness = 5;

            newPoints.push({ 
                    x: i, 
                    y: combined + ((Math.random()*2) - 1) * randomness, 
                    z: combined + ((Math.random()*2) - 1) * randomness, 
                    a: combined + ((Math.random()*2) - 1) * randomness
                } as BasicPoint);
        }

        return newPoints;
    };

    const chartPoints = 100;
    const [chartData] = useState<BasicPoint[]>(generateChartData(chartPoints));    
    const chartRef = createRef<Chart>();

    useEffect(() => {
        // Render chart on page load
        chartRef.current?.instance.render();
    }, []);

    return (
        <div className='landingPageDiv'>
            {!keycloak.authenticated &&
            <div className='welcomeMessageDiv'>
                <h1>HyperX Dashboard</h1>
                <h4>Data Drives Decisions</h4>
                <p style={{textAlign: 'left', marginBottom: '3em'}}>
                    With the HyperX Dashboard, you can upload your HyperX results to plot your data in a meaningful way.
                    You can share your data and plots to pass insights along to your colleagues.
                </p>
                <p>
                    Create a Collier Aerospace account to begin!
                </p>
                <div>
                    <Button 
                        style={{marginRight: '1em'}}
                        onClick={() => window.location.href = keycloak.createRegisterUrl()}>
                        Sign Up
                    </Button>
                    <Button onClick={() => keycloak.login()}>
                        Log In
                    </Button>
                </div>
                <div style={{
                    padding: '1rem',
                    paddingTop: '2rem'
                }}>
                    <Link to="/documents/privacy-policy" target="_blank">Privacy Policy</Link>
                    <Link to="/documents/terms-and-conditions" target="_blank">Terms and Conditions</Link>
                    <Link to="https://support.collieraerospace.com/hc/en-us/articles/20781943450651-Dashboard-Security-Guide" target="_blank">Security Details</Link>
                </div>
            </div>}
            {keycloak.authenticated && user != null && !user.isDashboardUser &&
            <div className='welcomeMessageDiv'>
                <h1>HyperX Dashboard</h1>
                <h4>Account Not Authorized</h4>
                <p className='unauthorizedText'>
                Welcome, {user?.first}. Your account has not been authorized for dashboard use. If this is a new account, an admin will verify your access.
                You will receive an email once this is complete.
                </p>
                <p className='unauthorizedText'>
                If this is not a new account, or if you have questions about this process,
                please contact support.
                </p>
            </div>}
            <div className='landingPageChartDiv'>
                <Chart 
                    ref={chartRef}
                    dataSource={chartData}
                    width='100%'
                    height='100%'>
                    <CommonSeriesSettings hoverMode={'none'}>
                        <Point hoverMode={'none'}/>
                    </CommonSeriesSettings>
                    <CommonAxisSettings visible={false}>
                        <Label visible={false}/>
                        <Grid visible={false}/>
                        <Tick visible={false}/>
                    </CommonAxisSettings>
                    <Legend visible={false}/>
                    <Series 
                        type={'stackedarea'}
                        color='#004cff60'
                        argumentField={'x'}
                        valueField={'y'}/>
                    <Series 
                        type={'stackedarea'}
                        color='#004cff40'
                        argumentField={'x'}
                        valueField={'z'}/>
                    <Series 
                        type={'stackedarea'}
                        color='#004cff20'
                        argumentField={'x'}
                        valueField={'a'}/>
                </Chart>
            </div>
        </div>
    );
}

interface BasicPoint {
    x: number;
    y: number;
    z: number;
    a: number;
}

export default LandingPage;