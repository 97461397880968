import { FamilyCategory } from "../../Enums/FamilyCategory";

export default class DesignProperty {
    name: string;
    zoneIds:number[];
    selectedAnalysisTab:AnalysisTab|null;
    analysisMode:AnalysisMode|null = null;
    designVariables:any[];
    sizingOverrides:any[];
    desiredWorkableCombinations:number|null = null;
    type:FamilyCategory = FamilyCategory.Unknown;

    constructor() {
        this.name = '';
        this.zoneIds = [];
        this.selectedAnalysisTab = null;
        this.designVariables = [];
        this.sizingOverrides = [];
    }
}

export enum AnalysisTab
{
    Sizing = 0,
    Analysis = 1
}

export enum AnalysisMode {
    Unknown = -1,
    None = 0,
    Analysis = 1,
    RapidSizing = 2,
    DetailedSizing = 3,
    ElementOptimization = 4
}