export default class Material {
    name: string = '';
    type: MaterialType = MaterialType.Foam;
}

export enum MaterialType
{
    Foam = 0,
    Honeycomb = 1,
    Isotropic = 2,
    Laminate = 3,
    // Layup = 4,
    Orthotropic = 5,
    // Thermal = 6,

    // This enum mirrors VB HyperSizerMaterialType.
    // Layup and Thermal are deprecated types present in older versions of HyperSizer.
}

// Returns the CSS color for a material type
export const GetMaterialColor = (mat:Material) => {
    switch (mat.type) {
        case MaterialType.Foam:
            return 'rgb(63, 127, 127)';
        case MaterialType.Honeycomb:
            return 'rgb(162, 63, 255)';
        case MaterialType.Isotropic:
            return 'rgb(0, 0, 255)';
        case MaterialType.Laminate:
            return 'rgb(189, 63, 0)';
        case MaterialType.Orthotropic:
            return 'black';
        default:
            return 'black';
    }
}

export const GetMaterialTypeName = (mat:Material) => {
    switch (mat.type) {
        case MaterialType.Foam:
            return 'Foam';
        case MaterialType.Honeycomb:
            return 'Honeycomb';
        case MaterialType.Isotropic:
            return 'Metal';
        case MaterialType.Laminate:
            return 'DL';
        case MaterialType.Orthotropic:
            // We would need the OrthotropicType to determine the name here
            return null;
        default:
            return null;
    }
}

export const GetMaterialCandidateTypeName = (mat:Material) => {
    switch (mat.type) {
        case MaterialType.Foam:
            return 'foam';
        case MaterialType.Honeycomb:
            return 'honeycomb';
        case MaterialType.Isotropic:
            return 'metallic';
        case MaterialType.Laminate:
            return 'discrete laminate';
        case MaterialType.Orthotropic:
            return 'effective laminate';
        default:
            return 'unknown';
    }
}