import './GraphList.css';

import { useEffect, useMemo, useRef } from 'react';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { CustomChartFolder, FindFolderInFolder } from '../../../../../Classes/Charts/CustomChartFolder';
import { GraphInfo } from '../../../../../Classes/Charts/GraphInfo';
import CustomChartListItem from './CustomChartListItem';
import ChartFolderListItem from './ChartFolderListItem';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Stores/GlobalStore';

interface GraphListProps {
    rootFolder: CustomChartFolder|undefined,
    setRootFolder: (rootFolder:CustomChartFolder) => void,
    onGraphRename: (graphInfo:GraphInfo) => void,
    onDuplicate: (graphInfo:GraphInfo) => void,
    onSaveAsTemplate: (graphInfo:GraphInfo) => void,
    onGraphDelete: (graphInfo:GraphInfo) => void,
    onFolderRename: (graphInfo:CustomChartFolder) => void,
}

export default function GraphList(props: GraphListProps) {
    const selectedFolderId = useSelector((state:RootState) => state.customCharts.selectedFolderId); 
    const dndGraphScrollRef = useRef<any>();

    const currentFolder = useMemo(() => {
        return FindFolderInFolder(props.rootFolder, selectedFolderId) ?? props.rootFolder;
    }, [props.rootFolder, selectedFolderId]);

    const sortedCharts = useMemo(() => {
        if (currentFolder?.graphInfos != null) {
            return [...currentFolder.graphInfos].sort((a, b) => naturalCompare(a.title, b.title));
        }
        return [];
    }, [currentFolder?.graphInfos]);

    const sortedSubFolders = useMemo(() => {
        if (currentFolder?.subFolders != null) {
            return [...currentFolder.subFolders].sort((a, b) => naturalCompare(a.name, b.name));
        }
        return [];
    }, [currentFolder?.subFolders]);

    // Init dnd scroll element on component load
    useEffect(() => {
        const dndElement = dndGraphScrollRef.current;
        return autoScrollForElements({
            element: dndElement,
        });
    }, []);

    return (
        <div ref={dndGraphScrollRef} className={'savedGraphsList'}>
            {props.rootFolder != null && sortedSubFolders?.map((folder:CustomChartFolder, index:number) => 
                <ChartFolderListItem 
                    customChartFolder={folder} 
                    rootFolder={props.rootFolder!}
                    setRootFolder={props.setRootFolder}
                    onRenameClicked={() => props.onFolderRename(folder)}
                    key={`folder${folder.id}`}/>)}
            {props.rootFolder != null && sortedCharts?.map((graphInfo:GraphInfo) =>
                <CustomChartListItem 
                    graphInfo={graphInfo}
                    rootFolder={props.rootFolder!}
                    setRootFolder={props.setRootFolder}
                    onRenameClicked={() => props.onGraphRename(graphInfo)}
                    onDuplicateClicked={() => props.onDuplicate(graphInfo)}
                    onSaveAsTemplateClicked={() => props.onSaveAsTemplate(graphInfo)}
                    onDeleteClicked={() => props.onGraphDelete(graphInfo)}
                    key={`chart${graphInfo.id}`}/>)}
        </div>
    );
}