import './UploadSetInfoPopup.css';

import { useEffect, useMemo, useState } from "react";
import { Dataset, Structure, Zone } from '../../../Classes/Dataset';
import { Box, List, Button, Popup, TextBox, TextArea, TagBox, SelectBox } from 'devextreme-react';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Scrolling,
    SearchPanel
} from 'devextreme-react/data-grid';
import * as DatasetAPI from '../../../API/DatasetAPI';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Item as BoxItem } from "devextreme-react/box";
import { useDispatch } from 'react-redux';
import { updateUploadSet } from '../../../Reducers/SetManagementReducer';
import { confirm } from 'devextreme/ui/dialog';  
import UploadSetInputs from './UploadSetInputs/UploadSetInputs';
import { Program } from '../../../Classes/UserGroups/Program';
import { GetPrograms } from '../../../API/ProgramsAPI';
import { naturalCompare } from '../../../Utilities/CommonUtilities';
import useKeycloak from '../../../Keycloak';
import { invalidateAll } from '../../../Reducers/ResultsReducer';

interface UploadSetInfoProps {
    hidePopup:() => void;
    uploadSet:Dataset | undefined;
}

export default function UploadSetInfo(props:UploadSetInfoProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const userId = keycloak.userId;

    const [selectedDesign, setSelectedDesign] = useState<any>(undefined);
    const [showMaterialsPopup, setShowMaterialsPopup] = useState(false);
    const [showZonesPopup, setShowZonesPopup] = useState(false);

    const [flattenedStructures, setFlattenedStructures] = useState<string[]|null>(null);
    const [potentialTags, setPotentialTags] = useState<string[]|null>(null);
    const [currentTags, setCurrentTags] = useState<string[]|null>(null);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [notes, setNotes] = useState<string|null>(props.uploadSet?.notes ?? null);
    const [potentialPrograms, setPotentialPrograms] = useState<Program[]|null>(null);
    const [currentProgramId, setCurrentProgramId] = useState<number|null>(null);

    const [selectedTab, setSelectedTab] = useState<number>(0);

    useEffect(() => {
        if (props.uploadSet && keycloak.token) {
            loadUploadSetData();
        } else {
            // Clear the form data when hidden
            setFlattenedStructures(null);
            setPotentialTags(null);
            setCurrentTags(null);
            setNotes(null);
            setPotentialPrograms(null);
            setCurrentProgramId(null);
        }
    }, [props.uploadSet, keycloak.token]);

    const loadUploadSetData = async() => {
        if (props.uploadSet && keycloak.token) {
            setNotes(props.uploadSet?.notes);
            setCurrentTags(props.uploadSet?.tags);
            setCurrentProgramId(props.uploadSet.programId);

            // Update Structures
            DatasetAPI.GetUploadSetStructures(keycloak.token, props.uploadSet.datasetId).then((result) => {
                let newFlattenedStructures = new Array<any>();;
                result.forEach((s:Structure) => {
                    s.designs.forEach((d) => {
                        newFlattenedStructures.push({
                            structure: s.name,
                            design: d.name,
                            materials: d.materials,
                            zones: d.zones
                        });
                    });
                });
                setFlattenedStructures(newFlattenedStructures);
            });

            // Get tags
            DatasetAPI.GetPotentialUploadSetTags(keycloak.token, props.uploadSet.datasetId).then((result) => {
                const sortedPotentialTags = result ? [...result].sort(naturalCompare) : null;
                setPotentialTags(sortedPotentialTags);
            });

            // Get programs
            GetPrograms(keycloak.token).then((result) => {
                const newPotentialPrograms = result.filter(i => i.companyId === props.uploadSet?.companyId);
                const sortedPotentialPrograms = newPotentialPrograms ? [...newPotentialPrograms].sort((a, b) => naturalCompare(a.name, b.name)) : null;
                setPotentialPrograms(sortedPotentialPrograms);
            });
        }
    };

    const saveForm = async() => {
        // Do not proceed without keycloak token
        if (keycloak.token == null)
            return;

        let newUploadSet = {...props.uploadSet};

        const requiredFieldsExists = notes != null && currentTags != null && currentProgramId != null;
        const updateIsNeeded = notes !== newUploadSet.notes || currentTags !== newUploadSet.tags || currentProgramId != newUploadSet.programId;
        if (requiredFieldsExists && updateIsNeeded) {
            newUploadSet.notes = notes;
            newUploadSet.tags = currentTags;
            newUploadSet.programId = currentProgramId;
            newUploadSet.programName = potentialPrograms?.find(i => i.id === currentProgramId)?.name;
            dispatch(updateUploadSet({authToken: keycloak.token, uploadSet: newUploadSet})).then(() => {
                dispatch(invalidateAll());
            });
        }

        setFormIsDirty(false);
    }

    const cellRenderMaterials = (data:any) => {
        let design = data.row.data; 
        return (
            <Button className="cellButton" text={design.materials.length.toString()} onClick={() => { 
                setSelectedDesign(design);
                setShowMaterialsPopup(true);
             }}/>
        );
    };

    const cellRenderZones = (data:any) => {
        let design = data.row.data; 
        return (
            <Button className="cellButton" text={design.zones.length.toString()} onClick={() => {
                setSelectedDesign(design);
                setShowZonesPopup(true);
             }}/>
        );
    };

    const generalInformationUI = useMemo(() => {
        let uploadSetLocalDateString = "";
        if (props.uploadSet !== undefined) {
            uploadSetLocalDateString = new Date(props.uploadSet.date).toLocaleString();
        }

        const showProgramAsEditable = potentialPrograms != null && props.uploadSet?.userId === userId;

        return (
            <Box 
                direction="row" 
                style={{justifyContent: 'space-around'}}>
                <BoxItem ratio={1}>
                    <div className="dx-fieldset">
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Upload Set</div>
                            <TextBox 
                                className="dx-field-value mainDataTextBox" 
                                readOnly={true} 
                                value={props.uploadSet?.datasetName}/>
                        </div>
                        <div className="dx-field mainDataField" >
                            <div className="dx-field-label mainDataLabel">Company</div>
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={props.uploadSet?.companyName}/>
                        </div>
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Program</div>
                            {!showProgramAsEditable &&
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={props.uploadSet?.programName}/>}
                            {showProgramAsEditable &&
                            <SelectBox 
                                className="dx-field-value mainDataTextBox" 
                                dataSource={potentialPrograms}
                                valueExpr={'id'}
                                displayExpr={'name'}
                                value={currentProgramId}
                                onValueChange={(newValue) => {
                                    setCurrentProgramId(newValue);
                                    if (!formIsDirty) {
                                        setFormIsDirty(true);
                                    }
                                }}/>}
                        </div>
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Tags {`(${currentTags?.length ?? 0})`}</div>
                            {potentialTags != null && currentTags != null &&
                            <TagBox 
                                className="dx-field-value mainDataTextBox"
                                dataSource={potentialTags}
                                value={currentTags}
                                placeholder='No tags to show...'
                                showClearButton={false}
                                showSelectionControls={true}
                                multiline={false}
                                searchEnabled={true}
                                onValueChange={(newValues) => {
                                    setCurrentTags(newValues);
                                    if (!formIsDirty) {
                                        setFormIsDirty(true);
                                    }
                                }}
                                readOnly={props.uploadSet?.userId != userId}
                                disabled={props.uploadSet?.userId != userId}/>}
                        </div>
                    </div>
                </BoxItem>
                <BoxItem ratio={1}>
                    <div className="dx-fieldset">
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Database</div>
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={props.uploadSet?.databaseName}/>
                        </div>
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Project</div>
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={props.uploadSet?.projectName}/>
                        </div>
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">User</div>
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={props.uploadSet?.userDisplayName}/>
                        </div>
                        <div className="dx-field mainDataField">
                            <div className="dx-field-label mainDataLabel">Upload Date</div>
                            <TextBox className="dx-field-value mainDataTextBox" readOnly={true} value={uploadSetLocalDateString}/>
                        </div>
                    </div>
                </BoxItem>
            </Box>
        );
    }, [props.uploadSet, potentialTags, currentTags, potentialPrograms, currentProgramId, formIsDirty]);

    let popupTitle = 'Upload Set Information';
    if (props.uploadSet)
        popupTitle += ` - ${props.uploadSet?.datasetName}`;

    return (
        <Popup 
            id='uploadSetInfoPopup'
            title={popupTitle}
            width={'60rem'}
            height={'40rem'}
            visible={props.uploadSet !== undefined}
            hideOnOutsideClick={true}
            onContentReady={(e) => {
                var html = e.component.content();  
                html.style.padding = '0rem';
            }}
            onHiding={() => {
                props.hidePopup();

                if (formIsDirty && props.uploadSet?.userId == userId) {
                    let result = confirm(`Would you like to save the edits you made to ${props.uploadSet?.datasetName}?`, "Unsaved Changes");  
                    result.then((saveChanges) => {
                        if (saveChanges) { 
                            saveForm();
                        }
                        setFormIsDirty(false);
                    });
                }
            }}>
            <div style={{width: '100%', height: '100%'}}>
                <TabPanel 
                    height='100%' 
                    className='otherInformationTabPanel' 
                    selectedIndex={selectedTab}
                    onSelectedIndexChange={index => setSelectedTab(index)}>
                    <Item title={'General Information'} icon='info'>
                        {generalInformationUI}
                        <h4>Notes</h4>
                        {notes != null &&
                        <TextArea 
                            className='notesTextArea'
                            readOnly={props.uploadSet?.userId != userId}
                            value={notes}
                            valueChangeEvent='keyup'
                            onValueChange={(newVal) => {
                                setNotes(newVal);
                                if (!formIsDirty) {
                                    setFormIsDirty(true);
                                }
                            }}/>}
                        {props.uploadSet?.userId == userId &&
                        <Button
                            className='notesSaveButton'
                            disabled={!formIsDirty}
                            onClick={() => saveForm()}
                            text='Save'
                            icon='save'/>}
                    </Item>
                    <Item title='Inputs' icon='fields'>
                        <UploadSetInputs 
                            uploadSetId={props.uploadSet?.datasetId} 
                            isVisible={selectedTab == 1}/>
                    </Item>
                    <Item title='Structures' icon='bulletlist'>
                        <div className="structuresTableDiv">
                            <DataGrid 
                                className='structuresTable'
                                dataSource={flattenedStructures}
                                columnHidingEnabled={false}
                                visible={selectedTab == 2}>
                                <SearchPanel visible={true}/>
                                <Scrolling mode="virtual" useNative={true}/>
                                <GroupPanel visible={true} />
                                <Grouping autoExpandAll={true}/>
                                <Column dataField="structure" groupIndex={0} />
                                <Column
                                    dataField="design"
                                    caption="Design"
                                    dataType="string"/>
                                <Column
                                    dataField="materials.length"
                                    caption="Materials"
                                    dataType="number"
                                    cellRender={cellRenderMaterials}/>
                                <Column
                                    dataField="zones.length"
                                    caption="Zones"
                                    dataType="number"
                                    cellRender={cellRenderZones}/>
                            </DataGrid>
                        </div>
                    </Item>
                </TabPanel>
                <Popup
                    visible={showMaterialsPopup}
                    hideOnOutsideClick={true}
                    onHiding={() => {
                        setShowMaterialsPopup(false);
                        setSelectedDesign(undefined);
                    }}
                    title={selectedDesign ? "Materials (" + selectedDesign.design + ")" : 'Materials'}
                    width={500}
                    height={500}>
                    <List 
                        dataSource={selectedDesign?.materials}
                        displayExpr="name"
                        selectionMode="none"
                        searchEnabled={selectedDesign?.materials.length > 5}
                        searchExpr={'name'}
                        noDataText='No materials to display'
                        useNativeScrolling={true}/>
                </Popup>
                <Popup
                    visible={showZonesPopup}
                    hideOnOutsideClick={true}
                    onHiding={() => {
                        setShowZonesPopup(false);
                        setSelectedDesign(undefined);
                    }}
                    title={selectedDesign ? "Zones (" + selectedDesign.design + ")" : "Zones"}
                    width={500}
                    height={500}>
                    <List 
                        dataSource={selectedDesign?.zones.sort((a:Zone, b:Zone) => naturalCompare(a.name, b.name))}
                        displayExpr="name"
                        selectionMode="none"
                        searchEnabled={selectedDesign?.zones.length > 5}
                        searchExpr={'name'}
                        noDataText='No zones to display'
                        useNativeScrolling={true}/>
                </Popup>
            </div>
        </Popup>
    );
}