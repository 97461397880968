import { IsNullOrUndefined } from "./CommonUtilities";

export function GetFetchOptions(authToken:string|undefined = undefined, contentType:string = 'application/json'): RequestInit {

    let headers = {
        'Accept': contentType,
        'Content-Type': contentType,
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
    };

    if (authToken) {
        (headers as any)['Authorization'] = `Bearer ${authToken}`;
    }
    
    let fetchCredentialsElement: HTMLInputElement = document.getElementById("fetchCredentialsDefault") as HTMLInputElement;
    let fetchCredentialsValue: RequestCredentials = 'same-origin';
    let fetchModeElement: HTMLInputElement = document.getElementById("fetchModeDefault") as HTMLInputElement;
    let fetchModeValue: RequestMode = 'same-origin';

    if (!IsNullOrUndefined(fetchCredentialsElement) && fetchCredentialsElement.value !== "") {
        fetchCredentialsValue = fetchCredentialsElement.value as RequestCredentials;
    }

    if (!IsNullOrUndefined(fetchModeElement) && fetchModeElement.value !== "") {
        fetchModeValue = fetchModeElement.value as RequestMode;
    }
    
    return {
        headers: headers,
        // credentials: fetchCredentialsValue,
        credentials: 'omit',
        mode: fetchModeValue
    };
}

export function getFormattedUrl(controllerName: string|null, methodName: string): string {
    let isProd = process.env.NODE_ENV === 'production';
    let apiUrlElement: HTMLInputElement = document.getElementById(isProd ? "apiUrl" : 'devApiUrl') as HTMLInputElement; 
    let apiUrl: string = apiUrlElement.value;

    if (controllerName == null) {
        return apiUrl + "/" + methodName;
    }

    return apiUrl + "/" + controllerName + "/" + methodName;
}

export async function FetchApi(url: string, options?: any): Promise<Response> {
    try {
        const res = await fetch(url, options);
        if (res.status === 401) {
            window.location.reload();
        }
        return res;
    } catch (err) {
        // TODO PG -> Show a "server is down or cannot be contacted" page when the server cannot be reached 
        // instead of throwing an error here.
        throw err;
    }
}