import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useKeycloak from '../../Keycloak';
import './MobileViewer.css';
import { GetCustomChartFolders, GetDbSelectedGraphFolderId } from '../../API/CustomChartFolderAPI';
import { CustomChartFolder, FindFolderInFolder, FindFolderParentFolder } from '../../Classes/Charts/CustomChartFolder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Stores/GlobalStore';
import { CustomChartSettings } from '../../Classes/Charts/CustomChartSettings';
import { setSelectedCustomChartId, setSelectedFolderId } from '../../Reducers/CustomChartsReducer';
import { GetDbGraph, SelectDbGraph, SetDbSeriesVisibility } from '../../API/CustomChartAPI';
import { APIRequestStatus, APIRequestStatusType } from '../../Classes/APIRequestStatus';
import Graph from '../Charts/Graph';
import { Button, Chart } from 'devextreme-react';
import { FetchZoneResultsProps, fetchFilterPropertiesIfNotInCache, fetchPlottableProperties, fetchZoneResultsIfNotInCache } from '../../Reducers/ResultsReducer';
import { getDataSourceTable } from '../../Utilities/ZoneResultAggregator';
import { GraphDataSource } from '../../Classes/Charts/GraphDataSource';
import { GetUser } from '../../API/UserAPI';
import ViewerGraphItem from './ViewerItems/ViewerGraphItem';
import ViewerFolderItem from './ViewerItems/ViewerFolderItem';
import { CustomSeriesData } from '../../Classes/Charts/CustomSeriesData';
import { SharedGraphRoleType } from '../../Classes/UserGroups/SharedGraphRole';

interface MobileViewerProps {
    isLandscape:boolean|undefined;
}

export default function MobileViewer({ isLandscape }: MobileViewerProps) {
    const { token, authenticated, loading, login } = useKeycloak();
    const dispatch = useDispatch();

    const [rootFolder, setRootFolder] = useState<CustomChartFolder|undefined>();

    const selectedGraphId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 
    const selectedFolderId = useSelector((state:RootState) => state.customCharts.selectedFolderId); 

    const [graphSettings, setGraphSettings] = useState<CustomChartSettings|null>(null);
    const [graphDataSource, setGraphDataSource] = useState<GraphDataSource|null>(null);

    const readOnly = graphSettings?.userRole === SharedGraphRoleType.ReadOnly;

    const propertyKeyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);
    const cachedZoneResults = useSelector((state:RootState) => state.results.cachedZoneResults);
    const selectedZoneResults = selectedGraphId ? cachedZoneResults[selectedGraphId] : null;
    
    const chartRef = useRef<Chart>(null);

    const currentFolder = useMemo(() => {
        return FindFolderInFolder(rootFolder, selectedFolderId) ?? rootFolder;
    }, [rootFolder, selectedFolderId]);

    useEffect(() => {
        if (!loading && !authenticated) {
            login();
        }
    }, [authenticated, loading, login]);

    useEffect(() => {
        if (token) {
            GetCustomChartFolders(token).then(result => setRootFolder(result));
            GetUser(token).then(user => {
                if (user != null) {
                    dispatch(fetchPlottableProperties(user.unitSystem));
                }
            });
            GetDbSelectedGraphFolderId(token).then((response) => {
                if (response.type !== APIRequestStatusType.Error && response.data != null) {
                    dispatch(setSelectedFolderId(response.data));
                }
            });
        }
    }, [dispatch, token]);

    const fetchGraphSettings = async (graphId:number) => {
        // Fetch the graph
        const graphResponse = await GetDbGraph(token, graphId);

        // Set graph settings in the UI
        if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(graphResponse) && graphResponse.data) {
            const newGraphSettingsObject = graphResponse.data;
            setGraphSettings(newGraphSettingsObject);
        }
        else {
            setGraphSettings(null);
        }
    }

    const loadingZoneResults = useRef<boolean>(false);
    const loadingFilteredProperties = useRef<boolean>(false);
    useEffect(() => {
        const fetchResultsIfNeeded = (graphId:number) => {
            if (graphId != null && !loadingZoneResults.current && token != null) {
                loadingZoneResults.current = true;
                dispatch(fetchZoneResultsIfNotInCache({authToken: token, graphId } as FetchZoneResultsProps))
                .then(() => {
                    loadingZoneResults.current = false;
                });
            }
        }

        const fetchFilterPropertiesIfNeeded = (graphId:number) => {
            if (graphId != null && !loadingFilteredProperties.current) {
                loadingFilteredProperties.current = true;
                dispatch(fetchFilterPropertiesIfNotInCache({authToken: token, graphId} as FetchZoneResultsProps))
                .then(() => {
                    loadingFilteredProperties.current = false;
                });
            }
        }

        if (graphSettings != null) {
            fetchResultsIfNeeded(graphSettings.chartId);
            fetchFilterPropertiesIfNeeded(graphSettings.chartId);
        }
    }, [dispatch, graphSettings, token]);

    // Recompute the graph results whenever the graph settings change or the results change.
    useEffect(() => {
        if (graphSettings && selectedZoneResults) {
            const cols = graphSettings.dataSourceColumns;
            const cats = graphSettings.dataSourceCategoryIds;
            const filters = graphSettings.stringFiltersByColumnId;
            const newTableDataSource = getDataSourceTable(selectedZoneResults, cols, cats, filters);
            const newGraphDataSource = new GraphDataSource(newTableDataSource, propertyKeyDict, graphSettings);
            setGraphDataSource(newGraphDataSource);
        }
        else {
            setGraphDataSource(null);
        }
    }, [graphSettings, propertyKeyDict, selectedZoneResults]);

    const toggleSeriesVisibility = useCallback((seriesKey:string) => {
        if (graphSettings) {
            const oldCustomSeriesDataByKey = {...graphSettings.customSeriesDataByKey};
            const newCustomSeriesDataByKey = {...oldCustomSeriesDataByKey};
            let newValue = newCustomSeriesDataByKey[seriesKey]?.visible ?? false;

            const currSeriesData = newCustomSeriesDataByKey[seriesKey];
            if (currSeriesData == null) {
                const newCustSeriesData = new CustomSeriesData();
                newCustSeriesData.visible = newValue;
                newCustomSeriesDataByKey[seriesKey] = newCustSeriesData;
            }
            else {
                newValue = !currSeriesData.visible;
                currSeriesData.visible = newValue;
            }

            // Make the change in the UI
            setGraphSettings({...graphSettings, customSeriesDataByKey: newCustomSeriesDataByKey});
            
            // Make the change in the database (if the graph is not read only)
            if (!readOnly) {
                SetDbSeriesVisibility(token, graphSettings.chartId, seriesKey, newValue).then(response => {
                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                        // Revert this change if the DB step fails
                        setGraphSettings({...graphSettings, customSeriesDataByKey: oldCustomSeriesDataByKey});
                    }
                });
            }
        }
    }, [graphSettings, readOnly, token]);

    const currFolderEmpty = currentFolder != null && currentFolder.subFolders.length === 0 && currentFolder.graphInfos.length === 0;
    const currFolderIsRoot = graphSettings == null && (selectedFolderId == null || selectedFolderId <= 0);

    const viewerContent = graphSettings ? (
        (
            <div className='mobileViewerBody'>
                <Graph 
                    ref={chartRef}
                    graphSettings={graphSettings}
                    graphDataSource={graphDataSource}
                    showTitle={false}
                    enableZoom={false}
                    setPreviewImage={undefined}
                    toggleSeriesVisibility={toggleSeriesVisibility}
                    legendHorizontalAlignment={isLandscape ? 'right' : 'center'}/>
            </div>
        )
    ) : (
        <div className='mobileViewerBody mobileViewerNavigation'>
            {currentFolder?.subFolders.map(folder => (
                <ViewerFolderItem 
                    key={`folder${folder.id}`}
                    folderInfo={folder}
                    onClick={() => {
                        dispatch(setSelectedFolderId(folder.id));
                    }}/>
            ))}
            {currentFolder?.graphInfos.map(graphInfo => (
                <ViewerGraphItem
                    key={`graph${graphInfo.id}`}
                    graphInfo={graphInfo}
                    onClick={() => {
                        dispatch(setSelectedCustomChartId(graphInfo.id));
                        SelectDbGraph(token, graphInfo.id);
                        fetchGraphSettings(graphInfo.id);
                    }}/>
            ))}
            {currFolderEmpty &&
            <div className='emptyMessage'>
                This folder is empty. Please use the Dashboard website on a computer to create graphs.
            </div>}
        </div>
    );

    let pageTitle = 'Mobile Graph Viewer';
    if (graphSettings) {
        pageTitle = graphSettings.title;
    }
    else if (!currFolderIsRoot && currentFolder) {
        pageTitle = currentFolder.name;
    }

    return (
        <div className="mobileViewer">
            <div className='mobileViewerHeader'>
                <Button 
                    icon={graphSettings ? 'back' : 'arrowup'}
                    disabled={currFolderIsRoot}
                    onClick={() => {
                        if (graphSettings) {
                            setGraphSettings(null);
                        }
                        else if (rootFolder) {
                            const parentFolder = FindFolderParentFolder(rootFolder, selectedFolderId);
                            dispatch(setSelectedFolderId(parentFolder?.id));
                        }
                    }}/>
                {pageTitle}
            </div>
            {viewerContent}
        </div>
    );
}