import './VirtualList.css';

import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DraggableProvided, DraggableStateSnapshot, DroppableProvided, DraggableRubric, Draggable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { CSSProperties, memo } from 'react';
import { Button } from 'devextreme-react';
import StrictModeDroppable from './StrictModeDroppable';

interface VirtualListDragAndDropProps {
    getItemDragId:(item:any) => string;
    listDropId:string;
    isInfiniteList:boolean;
    items:any[];
    itemHeight:number;
    renderRow:(item:any) => JSX.Element;
    loadMoreEnabled:boolean;
    loadMoreFunction:() => void;
    totalItems:number;
    numberOfItemsBeingDragged:number;
}

export function VirtualListDragAndDrop(props:VirtualListDragAndDropProps) {
    const itemCount = props.items.length;

    const getExtraDroppableStyle = (isDraggingOver:boolean) => {
        if (isDraggingOver) {
            return {
                backgroundColor: '#00000005'
            };
        }
        return {};
    }

    return (
        <StrictModeDroppable 
            droppableId={props.listDropId}
            mode="virtual"
            isDropDisabled={props.isInfiniteList}
            renderClone={(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot,
                rubric: DraggableRubric) => (
                <div 
                    ref={provided.innerRef} 
                    {...provided.draggableProps} 
                    {...provided.dragHandleProps} 
                    style={getItemStyle(provided.draggableProps.style, undefined, snapshot.isDragging)}>
                    {props.numberOfItemsBeingDragged > 1 &&
                        <div className='virtualFixedSizeListDraggingNumberCircle'>{props.numberOfItemsBeingDragged}</div>
                    } 
                    {props.renderRow(props.items[rubric.source.index])}
                </div>
            )}>
            {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <div className='virtualList' ref={droppableProvided.innerRef} style={getExtraDroppableStyle(snapshot.isDraggingOver)}>
                    <AutoSizer className='virtualListAutoSizer'>
                        {({height, width} : any) => (
                            <FixedSizeList
                                className='virtualFixedSizeList'
                                height={isNaN(height) ? 0 : height}
                                itemCount={itemCount}
                                itemSize={props.itemHeight}
                                width={isNaN(width) ? 0 : width}
                                outerRef={droppableProvided.innerRef}
                                itemData={props}>
                                {ListRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            )}
        </StrictModeDroppable>
    );
}
export default VirtualListDragAndDrop;

const ListRow = memo((rowProps : any) => {
    const props:VirtualListDragAndDropProps = rowProps.data;
    const index:any = rowProps.index;
    const style:any = rowProps.style;

    // Render a load more button / loading indicator in the last row if need be.
    const itemCount = props.items.length;
    const isLastRow = index === itemCount - 1;
    const moreLoadableItemsExist = props.totalItems > itemCount;
    if (props.loadMoreEnabled && moreLoadableItemsExist && isLastRow) {
        return (
            <div className='virtualFixedSizeListLoadMoreDiv' style={style}>
                <Button text='Load More' onClick={props.loadMoreFunction}/>
            </div>
        );
    }

    // Otherwise, render a normal item
    const draggableId = props.getItemDragId(props.items[index]);
    return (
        <Draggable draggableId={draggableId} index={index} key={draggableId}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => { 
                return (
                    <div 
                        {...provided.draggableProps} 
                        {...provided.dragHandleProps} 
                        ref={provided.innerRef} 
                        style={getItemStyle(provided.draggableProps.style, style, snapshot.isDragging)}>
                        {props.renderRow(props.items[index])}
                    </div>
                )
            }}
        </Draggable>
    );
}, areEqual);

const getItemStyle = (draggableStyle:CSSProperties|undefined, virtualizedStyle:CSSProperties|undefined, isDragging:boolean) => {
    // This method exists to put spacing between list items.
    const combined = {
        ...virtualizedStyle,
        ...draggableStyle
    } as CSSProperties;

    const vertMargin = '0.1rem';
    const horiMargin = '0.2rem';
    const widthMinusMargin = `calc(${combined.width} - 2*${horiMargin})`;
    const heightMinusMargin = `calc(${combined.height}px - 2*${vertMargin})`;
    const withMargin = {
        ...combined,
        width: isDragging ? combined.width : widthMinusMargin,
        height: isDragging ? combined.height : heightMinusMargin,
        marginLeft: horiMargin,
        marginRight: horiMargin,
        marginTop: vertMargin,
        marginBottom: vertMargin
    };

    return withMargin;
}