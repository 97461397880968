import { FamilyCategory } from "../../Enums/FamilyCategory";

export default class LoadProperty {
    name: string = '';
    type: LoadPropertyType = LoadPropertyType.None;
    category: FamilyCategory = FamilyCategory.Unspecified;
}

export enum LoadPropertyType {
    None = 0,
    Average = 1,
    Statistical = 2,
    PeakLoad = 3,
    NeighborAverage = 4,
    ElementBased = 5,
    UserFEA = 6,
    UserBonded = 7,
    UserBolted = 8,
    UserGeneral = 9
}

export const isUserFEALoadProperty = (type:LoadPropertyType) => {
    switch (type) {
        case LoadPropertyType.Average:
        case LoadPropertyType.ElementBased:
        case LoadPropertyType.NeighborAverage:
        case LoadPropertyType.PeakLoad:
        case LoadPropertyType.Statistical:
            return true;
        default:
            return false;
    }
}

export const getLoadPropertyTypeName = (type: LoadPropertyType|null) => {
    switch (type) {
        case LoadPropertyType.Average:
            return 'Average';
        case LoadPropertyType.Statistical:
            return 'Statistical';
        case LoadPropertyType.PeakLoad:
            return 'Peak Load';
        case LoadPropertyType.NeighborAverage:
            return 'Neighbor Average';
        case LoadPropertyType.ElementBased:
            return 'Element Based';
        case LoadPropertyType.UserFEA:
            return 'User FEA';
        case LoadPropertyType.UserBonded:
            return 'User Bonded';
        case LoadPropertyType.UserBolted:
            return 'User Bolted';
        case LoadPropertyType.UserGeneral:
            return 'User General';
        case LoadPropertyType.None:
        default:
            return null;
    }
};

export enum LoadPropertyAverageElementType {
    TensionCompressionAverage = 0,
    TrueAverage = 1,
}

export enum LoadPropertyPeakElementScope {
    PeakDesignCase = 1,
    AllDesignCases = 2,
}