import "./UserBoltedLoadPropertyPopup.css";

import { CheckBox, DataGrid, Popup, TextBox } from "devextreme-react";
import LoadProperty, { LoadPropertyType } from "../../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty";
import { useMemo } from "react";
import { Column } from "devextreme-react/data-grid";
import UserBoltedLoadProperty from "../../../../../Classes/UploadSetInputs/LoadProperty/UserBolted/UserBoltedLoadProperty";

interface UserBoltedLoadPropertyPopupProps {
    loadProperty:LoadProperty|null;
    onHiding: () => void;
}

export default function UserBoltedLoadPropertyPopup(props:UserBoltedLoadPropertyPopupProps) {
    const isUserBoltedLoadProperty = (type:LoadPropertyType) => {
        return type == LoadPropertyType.UserBolted;
    }

    const renderLoadCategoriesTable = useMemo(() => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserBoltedLoadProperty(props.loadProperty.type))
            return <div/>;

        const boltedLoadProp = props.loadProperty as UserBoltedLoadProperty;
        return (
            <table className='loadCategoriesTable'>
                <tbody>
                    <tr>
                        <td style={{width: '1.525rem'}}/>
                        <td style={{width: '30rem'}}>Fastener Forces and Moments</td>
                        {boltedLoadProp.includeBypassLoads &&
                        <td style={{width: '15rem'}}>Bypass</td>}
                        <td/>
                    </tr>
                </tbody>
            </table>);
    }, [props.loadProperty]);

    const renderPopupContent = () => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserBoltedLoadProperty(props.loadProperty.type))
            return <div/>;

        const boltedLoadProp = props.loadProperty as UserBoltedLoadProperty;

        const bypassLoadColumns = [
            <Column dataField={'nx'} width={'5rem'} key='nx'/>,
            <Column dataField={'ny'} width={'5rem'} key='ny'/>,
            <Column dataField={'nxy'} width={'5rem'} key='nxy'/>
        ]

        return (
            <div className="boltedLoadPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.loadProperty.name}
                    readOnly={true}/>
                <div className='boltedLoadPropertyTopOptions'>
                    <CheckBox 
                        value={boltedLoadProp.includeBypassLoads}
                        readOnly={true} />
                    <h4>Include bypass loads</h4>
                </div>
                {renderLoadCategoriesTable}
                <div className='boltedLoadPropertyContent'>
                    <DataGrid 
                        dataSource={boltedLoadProp.scenarios} 
                        wordWrapEnabled={true} 
                        height={'100%'}>
                        <Column 
                            dataField={'scenarioId'} 
                            caption={''} 
                            width={'1.5rem'}
                            cellRender={(e) => <div style={{width: '100%', textAlign: 'center'}}>{e.value}</div>}/>
                        <Column dataField={'fx'} width={'5rem'}/>
                        <Column dataField={'fy'} width={'5rem'}/>
                        <Column dataField={'fz'} width={'5rem'}/>
                        <Column dataField={'mx'} width={'5rem'}/>
                        <Column dataField={'my'} width={'5rem'}/>
                        <Column dataField={'mz'} width={'5rem'}/>
                        {boltedLoadProp.includeBypassLoads && bypassLoadColumns}
                        <Column dataField={'limitFactor'}/>
                        <Column dataField={'ultimateFactor'}/>
                    </DataGrid>
                </div>
            </div>
        );
    } 

    return (
        <Popup 
            visible={props.loadProperty != null && isUserBoltedLoadProperty(props.loadProperty.type)}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={'User Bolted Joint Load'}
            width={'60rem'}
            height={'34rem'}>
            {renderPopupContent()}
        </Popup>
    );
}