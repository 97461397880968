import { combineReducers } from '@reduxjs/toolkit';
import ResultsReducer from './ResultsReducer';
import UserDataReducer from './UserDataReducer';
import CustomChartsReducer from './CustomChartsReducer';
import CompanyDataReducer from './CompanyDataReducer';
import ProgramDataReducer from './ProgramDataReducer';
import InvitesDataReducer from './InvitesDataReducer';
import SetManagementReducer from './SetManagementReducer';

const RootReducer = combineReducers({
    results: ResultsReducer,
    userData: UserDataReducer,
    customCharts: CustomChartsReducer,
    companyData: CompanyDataReducer,
    programData: ProgramDataReducer,
    inviteData: InvitesDataReducer,
    SetManagementReducer: SetManagementReducer
});

export default RootReducer;