import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { UnitSystemType } from '../Classes/User';
import { ResultPropertyInfo } from '../Classes/ResultPropertyInfo';

export async function GetPlottableProperties(unitSystem:UnitSystemType): Promise<{[key: string]: ResultPropertyInfo}> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions();
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("PlottableProperties", `GetPlottableProperties?unitSystem=${unitSystem}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetFilteredPlottableProperties(authToken: string, graphId: number): Promise<string[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("PlottableProperties", `GetFilteredPlottableProperties?graphId=${graphId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();
    
    return json;
}

export async function GetResultsByZone(authToken: string, graphId: number): Promise<any[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("ZoneResults", `GetResultsByZone?graphId=${graphId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();
    
    return json;
}

export async function GetDbTemplateResultsByZone(authToken: string, templateId: number, graphSetIds:number[]): Promise<any[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(graphSetIds);

    let url = ApiFetchUtility.getFormattedUrl("ZoneResults", `GetTemplateResultsByZone?templateId=${templateId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();
    
    return json;
}

export async function GetDbColumnZoneResults(authToken: string, dataSourceColumnId:number): Promise<any[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("ZoneResults", `GetColumnZoneResults?dataSourceColumnId=${dataSourceColumnId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetUniqueResultValues(authToken: string, graphId: number, propertyKey: string): Promise<string[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("ZoneResults", `GetUniqueResultValues?graphId=${graphId}&propertyKey=${propertyKey}`)

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}