import './LoadingSpinner.css';

// This code is referenced from https://loading.io/css/
export default function LoadingSpinner() { 
    return (
        <div className='loadingSpinnerWrapper'>
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>);
}