import './GraphSetManager.css';
import { GraphSet } from '../../../../Classes/GraphSet';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { moveGraphSet, selectGraphSet } from '../../../../Reducers/SetManagementReducer';
import { GraphSetDragTypes } from '../../../../Classes/GraphSetDragTypes';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { useEffect, useRef, useState } from 'react';
import { ContextMenu } from 'devextreme-react';
import useKeycloak from '../../../../Keycloak';
import StrictModeDroppable from '../StrictModeDroppable';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

interface GraphSetListItemProps {
    graphSet:GraphSet;
    isSelected:boolean;
    contextMenuItems:any;
}

export function GraphSetListItem(props:GraphSetListItemProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const numberOfUploadSets = props.graphSet.numberOfUploadSets;
    
    const [isDragging, setIsDragging] = useState(false);

    const dragRef = useRef<any>(null);

    useEffect(() => {
        const el = dragRef.current;

        return draggable({
            element: el,
            getInitialData: () => ({ graphSet: props.graphSet, type: GraphSetDragTypes.GRAPH_SET }),
            onDragStart: () => {
                setIsDragging(true);
            }, 
            onDrop: ({ location }) => {
                setIsDragging(false);

                if (location.current.dropTargets.length > 0) {
                    const firstDropTarget = location.current.dropTargets[0];
                    const dropFolder = firstDropTarget.data.folder as GraphSetFolder;

                    let destinationFolderId = dropFolder.id;
                    let graphSetToMove = props.graphSet;
                    if (destinationFolderId != null && graphSetToMove != null) {
                        dispatch(moveGraphSet({
                            authToken: keycloak.token, 
                            graphSetId: graphSetToMove.graphSetId, 
                            destinationFolderId: destinationFolderId
                        }));
                    }
                }
            }
        });
    }, [dispatch, keycloak.token, props.graphSet]);
    
    const getGraphSetListItemStyle = () => {
        const opacity = isDragging ? 0 : 1;
        let style = {
            backgroundColor: props.isSelected ? '#D0E9FF' : '#fdfdfd',
            borderColor: props.isSelected ? 'black' : "#ddd",
            opacity: opacity
        }
        return style
    }

    const getGraphSetListItemDropIndicatorStyle = (isDraggingOver:boolean) => {
        if (isDraggingOver) {
            return {
                backgroundColor: '#f5f5f5'
            };
        }
        return {};
    }

    let sharedMessage = '';
    if (props.graphSet.isSharedByMe && props.graphSet.isSharedWithMe) {
        sharedMessage = 'shared';
    }
    else if (props.graphSet.isSharedByMe) {
        sharedMessage = 'shared by me';
    }
    else if (props.graphSet.isSharedWithMe) {
        sharedMessage = 'shared with me';
    }

    const idString = `graphSetID${props.graphSet.graphSetId}`;
    return (
        <div 
            ref={dragRef}
            id={idString}
            className='graphSetListItem'
            style={getGraphSetListItemStyle()}
            onClick={() => dispatch(selectGraphSet(props.graphSet.graphSetId))}>
            <div className='graphSetListItemTitle'>
                <b>{ props.graphSet.name }</b>
                {sharedMessage &&
                <div className='sharedIndicator'>
                    <span>{sharedMessage}</span>
                    <i className='dx-icon-share'/>
                </div>}
            </div>
            <StrictModeDroppable 
                droppableId={`graphSetDroppable${props.graphSet.graphSetId}`}
                mode="standard"
                renderClone={() => <div/>}>
                    {(droppableProvided: DroppableProvided, snapshot:DroppableStateSnapshot) => (
                    <div 
                        className='graphSetListItemDropIndicator'
                        style={getGraphSetListItemDropIndicatorStyle(snapshot.isDraggingOver)}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                            {numberOfUploadSets.toLocaleString()} upload sets
                            {droppableProvided.placeholder}
                    </div>
                )}
            </StrictModeDroppable>
             <ContextMenu
                dataSource={props.contextMenuItems}
                target={`#${idString}`}
                onItemClick={(e:any) => e.itemData.action()}/> 
        </div>
    );
}
export default GraphSetListItem;