import './FilterUsageOverview.css';

import TagBox from 'devextreme-react/tag-box';
import { LoadIndicator, TextBox } from 'devextreme-react';
import React from 'react';
import { useEffect } from 'react';
import { FilterConditions } from '../../Classes/FilterConditions';
import { FilterRange } from '../../Classes/FilterRange';
import { FilterTag } from '../../Classes/FilterTag';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFirstUploadSetsPacket, setFilterConditions } from '../../Reducers/SetManagementReducer';
import { RootState } from '../../Stores/GlobalStore';
import useKeycloak from '../../Keycloak';

export function FilterUsageOverview() {

    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const summaryData = useSelector((state:RootState) => state.SetManagementReducer.summaryData);
    const loadingUploadSets = useSelector((state:RootState) => state.SetManagementReducer.loadingUploadSets);
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const [filterConditionsList, setFilterConditionsList] = React.useState(new Array<FilterTag>());

    useEffect(() => {
        let newList = filterConditionsToList(filterConditions);
        
        if (!areFilterListsEqual(filterConditionsList, newList)) {
            setFilterConditionsList(newList);
        }
    }, [filterConditions]);

    const areFilterListsEqual = (oldList:FilterTag[], newList:FilterTag[]) => {
        if (oldList.length !== newList.length)
            return false;

        for (let i = 0; i < oldList.length; i++) {
            if (oldList[i].value !== newList[i].value)
                return false;
        }

        return true;
    }

    const onFilterTagsChange = (args: any) => {
        setFilterConditionsList(args);

        let filter = listToFilterConditions(args);
        dispatch(setFilterConditions({authToken: keycloak.token, newFilterConditions: filter}));
        dispatch(fetchFirstUploadSetsPacket({authToken: keycloak.token, filterConditions: filter}));
    };

    const filterConditionsToList = (conditions:any) => {
        let f = conditions;
        let newList = new Array<FilterTag>();

        for (let key in f) {
            let p = (f as any)[key];
            if (p == undefined) {
                continue;
            } 
            else if (p instanceof Array) {
                p.forEach((item) => {
                    let valueString = item === "unknown" ? key + ": " + item : item;
                    newList.push(new FilterTag(valueString, key, item));
                });
            }
            else if (key.toLowerCase().includes("date")) {
                let date = (p as FilterRange<string>);
                if (date.start) {
                    let tag = new FilterTag("After " + dateShorthand(date.start), key, date.start);
                    tag.rangeKey = 'start';
                    newList.push(tag);
                }
                if (date.end) {
                    let tag = new FilterTag("Before " + dateShorthand(date.end), key, date.end);
                    tag.rangeKey = 'end';
                    newList.push(tag);
                }
            }
        }
        return newList;
    }

    const dateShorthand = (dateString:string) => {
        var d = new Date(dateString);
        return (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
    }

    const listToFilterConditions = (list:FilterTag[]) => {
        let filter = new FilterConditions();
        // Loop through each condition in the list
        list.forEach((item:FilterTag) => {
            // Populate new filter object using list data
            if ((filter as any)[item.originKey] instanceof Array) {
                (filter as any)[item.originKey].push(item.originValue);
            }
            else if (item.rangeKey !== '') {
                (filter as any)[item.originKey][item.rangeKey] = item.originValue;
            } else {
                (filter as any)[item.originKey] = item.originValue;
            }
        });

        return filter;
    }

    const renderFilterCount = (title:string, count:number|undefined) => {
       let countUI;
       if (loadingUploadSets) {
           countUI = (
               <div className='loadingIndicatorDiv'>
                   <LoadIndicator className='loadingIndicator' height={'1.5rem'} width={'1.5rem'} />
               </div>
           );
       }
       else {
           countUI = <TextBox className="countTextBox" readOnly={true} value={count?.toLocaleString()}/>
       }

       return (
           <div className="count">
               <div className="countLabel">{title}</div>
               {countUI}
           </div>
       );
    }

    return (
        <div className='filterUsageOverview'>
            <div className='filters'>
                <h3 className='filterHeader'>Filters</h3>
                <div className='tagBox'>
                    <TagBox 
                        width={'100%'}
                        height={'2.2rem'}
                        dataSource={filterConditionsList}
                        showClearButton={true}
                        placeholder="No active filters"
                        noDataText='Use the sidebar on the left to select upload set filters.'
                        showSelectionControls={false}
                        applyValueMode="instantly"
                        searchEnabled={false}
                        multiline={false}
                        value={filterConditionsList}
                        maxDisplayedTags={12}
                        showMultiTagOnly={false}
                        displayExpr={"value"}
                        onValueChange={onFilterTagsChange}/>
                </div>
            </div>
            <div className="counts">
                {renderFilterCount('Count', summaryData.filteredUploadSets)}
                {renderFilterCount('Filters', filterConditionsList.length)}
                {renderFilterCount('Databases', summaryData.filteredDatabases)}
                {renderFilterCount('Projects', summaryData.filteredProjects)}
                {renderFilterCount('Structures', summaryData.filteredStructures)}
                {renderFilterCount('Designs', summaryData.filteredDesigns)}
                {renderFilterCount('Zones', summaryData.filteredZones)}
            </div>
        </div>
        
    );
}

export default FilterUsageOverview;