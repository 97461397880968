import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { Dataset } from '../Classes/Dataset';
import { GraphSet } from '../Classes/GraphSet';
import { FilterConditions } from '../Classes/FilterConditions';
import { UploadSetsPacket } from '../Classes/UploadSetsPacket';
import { SummaryData } from '../Classes/SummaryData';
import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import UploadSetInputs from '../Classes/UploadSetInputs/UploadSetInputs';
import { UploadSetQuickComparison } from '../Classes/UploadSetInputs/QuickCompare/UploadSetQuickComparison';

export async function GetUploadSetsPacket(authToken:string, filter:FilterConditions): Promise<UploadSetsPacket> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(filter);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "GetUploadSetsPacket"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetSummaryData(authToken:string, filters:FilterConditions): Promise<SummaryData> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(filters);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "GetSummaryData"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetUploadSetStructures(authToken:string, uploadSetId:number): Promise<any> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "GetUploadSetStructures?uploadSetId=" + uploadSetId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetQuickComparison(authToken:string, uploadSetIds:number[]): Promise<APIResponseWithStatus<UploadSetQuickComparison[]>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(uploadSetIds);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "GetQuickComparison"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetInputs(authToken:string, uploadSetId:number): Promise<APIResponseWithStatus<UploadSetInputs>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "GetInputs?uploadSetId=" + uploadSetId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function UpdateUploadSet(authToken:string, uploadSet: Dataset): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(uploadSet);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteUploadSets(authToken:string, uploadSetIds: Array<number>): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';
    fetchOptions['body'] = JSON.stringify(uploadSetIds);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    
    const json = await res.json();

    return json;
}

export async function GetPotentialUploadSetTags(authToken:string, uploadSetId:number): Promise<Array<string>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Dataset", `GetPotentialUploadSetTags?uploadSetId=${uploadSetId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    
    const json = await res.json();

    return json;
}

export async function UploadSetNameIsDuplicate(authToken:string, name:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", "IsNameDuplicate"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RenameUploadSet(authToken:string, uploadSetId:number, name:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("Dataset", `RenameUploadSet?uploadSetId=${uploadSetId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}