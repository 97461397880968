import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReadOnlyDashboard from './Components/Dashboard/ReadOnlyDashboard';
import Document from './Components/Documents/Document';
import logicalSecurityDocument from './Documents/Logical Security Architecture.pdf';
import privacyPolicy from './Documents/Privacy Policy.pdf';
import termsAndConditions from './Documents/Terms and Conditions.pdf';
import UsageMonitor from './Components/CollierAdmin/UsageMonitor';
import SignInRedirectPage from './Components/Dashboard/SignInRedirectPage';
import { useEffect, useState } from 'react';
import MobileViewer from './Components/MobileViewer/MobileViewer';
import MobileReadOnlyGraph from './Components/MobileViewer/MobileReadOnlyGraph';

function App() {
    const [isMobile, setIsMobile] = useState<boolean|undefined>();
    const [isLandscape, setIsLandscape] = useState<boolean|undefined>();

    useEffect(() => {
        const handleResize = () => {
            let newMobileVal = isMobile;
            const mobileDimensionThreshold = 500;
            if (window.innerWidth < mobileDimensionThreshold || window.innerHeight < mobileDimensionThreshold) {
                newMobileVal = true;
            } else {
                newMobileVal = false;
            }

            if (isMobile !== newMobileVal) {
                setIsMobile(newMobileVal);
            }

            const newIsLandscape = window.innerWidth >= window.innerHeight;
            if (isLandscape !== newIsLandscape) {
                setIsLandscape(newIsLandscape);
            }
        }

        // Check the display size to see if we're on a very small device
        handleResize();

        // Re-check the display size whenever a resize even happens
        window.addEventListener("resize", handleResize);
    }, [isLandscape, isMobile]);

    // Don't show UI until we know if the screen is mobile or not.
    if (isMobile == null)
        return <div/>;

    if (isMobile) {
        return (
            <div style={{height: '100%'}}>
                <Routes>
                    <Route path="/sign-in" element={<SignInRedirectPage/>}/>
                    <Route path="/graphs" element={<MobileViewer isLandscape={isLandscape}/>} />
                    <Route path="/graph/:sharedLinkId" element={<MobileReadOnlyGraph isLandscape={isLandscape}/>} />
                    <Route path="*" element={<Navigate to='/graphs'/>}/>
                </Routes>
            </div>
        );
    }

    return (
        <div style={{height: '100%'}}>
            <Routes>
                <Route path="/collier-admin/usage-monitor" element={<UsageMonitor/>}/>
                <Route path="/documents/logical-security-architecture" element={<Document data={logicalSecurityDocument}/>}/>
                <Route path="/documents/privacy-policy" element={<Document data={privacyPolicy}/>}/>
                <Route path="/documents/terms-and-conditions" element={<Document data={termsAndConditions}/>}/>
                <Route path="/graph/:sharedLinkId" element={<ReadOnlyDashboard />} />
                <Route path="*" element={<Dashboard/>} />
            </Routes>
        </div>
    );
}

export default App;