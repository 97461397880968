import './ShareSettings.css';

import { Button, List, Tooltip } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import { getRoleName, SharedGraphRoleType, canManageGraphSharing } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { confirm } from 'devextreme/ui/dialog';
import { Program } from '../../../../../Classes/UserGroups/Program';
import ShareSettingsAddProgramsPopup from './ShareSettingsAddProgramsPopup';
import { SharedGraphProgram } from '../../../../../Classes/UserGroups/SharedGraphProgram';
import DataSource from 'devextreme/data/data_source';
import { AddSharedGraphPrograms, EditSharedGraphProgramRole, GetSharedGraphPrograms, RemoveSharedGraphProgram } from '../../../../../API/GraphSharingAPI';
import ShareSettingsEditRolePopup from './ShareSettingsEditRolePopup';
import { useSelector } from 'react-redux';
import { programDataSelector } from '../../../../../Reducers/ProgramDataReducer';
import useKeycloak from '../../../../../Keycloak';

type ShareSettingsProgramsProps = {
    chartSettings: CustomChartSettings | undefined;
    sharedGraphPrograms: Array<SharedGraphProgram>|undefined;
    setSharedGraphPrograms: (newSharedGraphPrograms:Array<SharedGraphProgram>|undefined) => void;
    sharedGraphRole: SharedGraphRoleType;
    updateSharedByMe: (programs:SharedGraphProgram[]|undefined) => void;
}

export function ShareSettingsPrograms(props: ShareSettingsProgramsProps) {
    const keycloak = useKeycloak()
    const programSelectorObject = useSelector(programDataSelector)

    const [showShareSettingsAddProgramsPopup, setShowShareSettingsAddProgramsPopup] = useState<boolean>(false)
    const [selectedSharedProgram, setSelectedSharedProgram] = useState<SharedGraphProgram|undefined>(undefined)
    const [sharedGraphProgramsDS, setSharedGraphProgramsDS] = useState<DataSource<SharedGraphProgram>>()

    useEffect(() => {
        if (props.sharedGraphPrograms) {
            let dataSource = new DataSource(props.sharedGraphPrograms)
            dataSource.group('role')
            setSharedGraphProgramsDS(dataSource)
        }
    }, [props.sharedGraphPrograms])

    useEffect(() => {
        updateSharedGraphPrograms()
    }, [props.chartSettings?.chartId])

    const updateSharedGraphPrograms = async () => {
        let token = keycloak.token;
        if (token && props.chartSettings) {
            let newSharedGraphPrograms = await GetSharedGraphPrograms(token, props.chartSettings?.chartId)
            props.setSharedGraphPrograms(newSharedGraphPrograms)
        }
    }

    let sharedProgramsDataSource = sharedGraphProgramsDS
    let sharedPrograms = props.sharedGraphPrograms
    let sharedProgramsCount = sharedPrograms?.length ?? 0
    return (
        <div>
            <List
                searchEnabled={sharedPrograms ? sharedProgramsCount > 5 : false}
                dataSource={sharedProgramsDataSource}
                displayExpr='programName'
                grouped={true}
                collapsibleGroups={true}
                groupTemplate={(item) => getRoleName(item.key as SharedGraphRoleType)}
                noDataText='This graph has not been shared with any programs.'
                itemRender={(item:SharedGraphProgram) => 
                    <div className='sharingListItem' id={`sharingListItem${item.programId}`}>
                        <div className='sharingListItemLeftDiv'>
                            {item.programName}
                        </div>
                        <div className='sharingListItemRightDiv'>
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='edit'
                                onClick={() => setSelectedSharedProgram(item)}/>}
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='remove'
                                onClick={async () => {
                                    let confirmDelete = await confirm(`Are you sure you want to remove ${item.programName}?`, "Confirm Remove");
                                    if (confirmDelete && props.chartSettings && keycloak.token) {
                                        let deleteWasSuccessful = await RemoveSharedGraphProgram(keycloak.token, props.chartSettings.chartId, item.programId);
                                        if (deleteWasSuccessful) {
                                            let newSharedGraphPRograms = props.sharedGraphPrograms?.filter(i => i.programId !== item.programId);
                                            props.setSharedGraphPrograms(newSharedGraphPRograms);
                                            props.updateSharedByMe(newSharedGraphPRograms);
                                        }
                                    }
                                }}
                            />}
                            <Tooltip 
                                target={`#sharingListItem${item.programId}`}
                                showEvent="dxhoverstart" 
                                hideEvent="dxhoverend" 
                                position='right' 
                                contentRender={() => 
                                    <div>
                                        Company: {item.companyName}
                                    </div>
                                }/>
                        </div>
                    </div>
                }/>
            {canManageGraphSharing(props.sharedGraphRole) &&
            <Button
                className='graphSharingAddButton'
                text='Add Program'
                disabled={programSelectorObject.programs.length - sharedProgramsCount == 0}
                icon="add" 
                type='success'
                onClick={() => {
                    setShowShareSettingsAddProgramsPopup(true)
                }}
                style={{float: 'right'}}/>}
            <ShareSettingsAddProgramsPopup 
                sharedGraphPrograms={props.sharedGraphPrograms} 
                showPopup={showShareSettingsAddProgramsPopup} 
                onHiding={() => setShowShareSettingsAddProgramsPopup(false)}
                applyAdd={async (programs: Array<Program>, selectedRole: SharedGraphRoleType) => {
                    if (sharedPrograms && keycloak.token && props.chartSettings && props.sharedGraphPrograms) {
                        let sharedGraphProgramsToAdd = programs.map((program:Program) => {
                            return SharedGraphProgram.constructFromProgram(program, selectedRole);
                        })
                        let postWasSuccessful = await AddSharedGraphPrograms(keycloak.token, props.chartSettings.chartId, sharedGraphProgramsToAdd);
                        if (postWasSuccessful) {
                            const newPrograms = [...props.sharedGraphPrograms, ...sharedGraphProgramsToAdd];
                            props.setSharedGraphPrograms(newPrograms);
                            props.updateSharedByMe(newPrograms);
                        }

                        setShowShareSettingsAddProgramsPopup(false);
                        return true;
                    }
                    return false;
                }}/>
            <ShareSettingsEditRolePopup 
                currentRole={selectedSharedProgram?.role} 
                showPopup={selectedSharedProgram != undefined} 
                onHiding={() => setSelectedSharedProgram(undefined)}
                applyAdd={async (selectedRole: SharedGraphRoleType) => {
                    if (keycloak.token && selectedSharedProgram != undefined && props.chartSettings && props.sharedGraphPrograms) {
                        let putWasSuccessful = await EditSharedGraphProgramRole(keycloak.token, props.chartSettings.chartId, selectedSharedProgram.programId, selectedRole)
                        if (putWasSuccessful) {
                            selectedSharedProgram.role = selectedRole
                            setSelectedSharedProgram(undefined)
                            props.setSharedGraphPrograms([...props.sharedGraphPrograms])
                            return true
                        }
                    }
                    return false
                }}/>
        </div>)
}
export default ShareSettingsPrograms;