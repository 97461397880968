import { useEffect, useState } from "react";
import { Droppable, DroppableProps } from "react-beautiful-dnd";

// This component wraps Droppable and modifies it to make it work with strict mode enabled on React 18.
// PG Note: uncomment the lines below when updating to React 18.
export default function StrictModeDroppable({ children, ...props }: DroppableProps) {
    const [ enabled, setEnabled ] = useState(false);
    const isProd = process.env.NODE_ENV === 'production';
  
    useEffect(() => {
        // Strict mode is only enabled in dev mode, so disable this workaround in production.
        if (!isProd) {
            const animation = requestAnimationFrame(() => setEnabled(true));
  
            return () => {
               cancelAnimationFrame(animation);
               setEnabled(false);
            };
        }
    }, []);
  
    if (!isProd && !enabled) {
        return null;
    }
  
    return <Droppable {...props}>{children}</Droppable>;
}