import './QuickGraphPopup.css';

import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GraphSet } from '../../../../../Classes/GraphSet';
import { Button, ContextMenu, LoadPanel, Popup, ScrollView, TabPanel, TextBox, Tooltip } from 'devextreme-react';
import TemplatePreview from '../../../../Charts/GraphTemplates/TemplatePreview';
import { DeleteGraphTemplate, GetDbCustomGraphTemplates, GetDbDefaultGraphTemplates, GetTemplateRelevanceById } from '../../../../../API/GraphTemplateAPI';
import { APIRequestStatusType } from '../../../../../Classes/APIRequestStatus';
import { GraphTemplate, GraphTemplateRelevance } from '../../../../../Classes/Charts/GraphTemplates';
import GraphSetSelectorBox from './GraphSetSelectorBox';
import { CustomChartNameIsDuplicate } from '../../../../../API/CustomChartAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Stores/GlobalStore';
import { Item } from 'devextreme-react/tab-panel';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import useKeycloak from '../../../../../Keycloak';
import { GraphDataSource } from '../../../../../Classes/Charts/GraphDataSource';
import { GetDbTemplateResultsByZone } from '../../../../../API/ResultsAPI';
import { getDataSourceTable } from '../../../../../Utilities/ZoneResultAggregator';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';

interface QuickGraphPopupProps {
    show: boolean;
    graphSet: GraphSet|null;
    hidePopup: () => void;
    createFromTemplate: (template:GraphTemplate|undefined, graphSetIds:number[], previewImageBlob:Blob|null) => void;
}

export function QuickGraphPopup(props:QuickGraphPopupProps) {
    const keycloak = useKeycloak();
    
    const selectedCustomGraphFolderId = useSelector((state:RootState) => state.customCharts.selectedFolderId);
    const plottablePropertyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);
    
    const [selectedSettings, setSelectedSettings] = useState<GraphTemplate|undefined>();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedGraphSetIds, setSelectedGraphSetIds] = useState<number[]>([]);
    const [templateName, setTemplateName] = useState<string>('');
    const [templateNameErrorMessage, setTemplateNameErrorMessage] = useState<string>('');
    const [customTabSelected, setCustomTabSelected] = useState<boolean>(false);

    const [defaultTemplates, setDefaultTemplates] = useState<GraphTemplate[]|null>(null);
    const [customTemplates, setCustomTemplates] = useState<GraphTemplate[]|null>(null);

    const [templateRelevanceById, setTemplateRelevanceById] = useState<any>({});
    const [templateResultsCache, setTemplateResultsCache] = useState<{[key:number]: GraphDataSource}>({});

    const lastLoadedPreviewImage = useRef<Blob|null>(null);

    useEffect(() => {
        if (props.show) {
            const fetchFormData = async () => {
                let newGraphSetIds:number[] = selectedGraphSetIds;
                if (props.graphSet) {
                    newGraphSetIds = [props.graphSet.graphSetId];
                }
    
                // Update the templates list
                const defaultTemplatesResult = await GetDbDefaultGraphTemplates(keycloak.token);
                const customTemplatesResult = await GetDbCustomGraphTemplates(keycloak.token);
                const templateRelevanceByIdResult = await GetTemplateRelevanceById(keycloak.token, newGraphSetIds);

                let newDefaultTemplates:GraphTemplate[] = [];
                let newCustomTemplates:GraphTemplate[] = [];
                let newTemplateRelevanceById = {};

                if (defaultTemplatesResult != null) {
                    newDefaultTemplates = defaultTemplatesResult;
                }
                if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(customTemplatesResult) && customTemplatesResult.data != null) {
                    newCustomTemplates = customTemplatesResult.data;
                }
                if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(templateRelevanceByIdResult) && templateRelevanceByIdResult.data != null) {
                    newTemplateRelevanceById = templateRelevanceByIdResult.data;
                }

                let currSelectedTabTemplates = customTabSelected ? newCustomTemplates : newDefaultTemplates;

                let newSelectedSettings = currSelectedTabTemplates.find(i => i.chartId === selectedSettings?.chartId);
                if (newSelectedSettings == null) {
                    const sortedTabTemplates = sortTemplates(currSelectedTabTemplates, customTabSelected, newTemplateRelevanceById) ?? [];
                    if (sortedTabTemplates.length > 0) {
                        newSelectedSettings = sortedTabTemplates[0];
                    }
                }
    
                setDefaultTemplates(newDefaultTemplates);
                setCustomTemplates(newCustomTemplates);
                setSelectedSettings(newSelectedSettings);
                setTemplateRelevanceById(newTemplateRelevanceById);
                refreshDataSource({}, newSelectedSettings, newGraphSetIds);
            }

            fetchFormData();
        }
        else {
            // Clear out the cache when closing the form
            setTemplateResultsCache({});
            setTemplateName('');
        }
    }, [props.show]);

    useEffect(() => {
        if (props.graphSet)
            setSelectedGraphSetIds([props.graphSet.graphSetId]);
        else 
            setSelectedGraphSetIds([]);
    }, [props.graphSet]);

    const refreshDataSource = (newCache:{[key:number]: GraphDataSource}, newTemplate:GraphTemplate|undefined, newGraphSetIds: number[]) => {
        if (newTemplate != null && plottablePropertyDict != null) {
            const currTempId = newTemplate.chartId;
            if (!(currTempId in newCache)) {
                GetDbTemplateResultsByZone(keycloak.token, currTempId, newGraphSetIds).then((results) => {
                    const newResults = results;
                    if (newTemplate && newResults) {
                        const cols = newTemplate.dataSourceColumns;
                        const cats = newTemplate.dataSourceCategoryIds;
                        const filters = newTemplate.stringFiltersByColumnId;
                        const newTableDataSource = getDataSourceTable(newResults, cols, cats, filters);
                        const newGraphDataSource = new GraphDataSource(newTableDataSource, plottablePropertyDict, newTemplate);

                        const cacheToSet = {
                            ...newCache,
                            [currTempId]: newGraphDataSource
                        }

                        setTemplateResultsCache(cacheToSet);
                    }
                });
            }
        }
    }

    const templateContextMenuItems = useCallback((template:GraphTemplate) => {
        // If the template is a default template, just return an empty context menu.
        if (template.chartId < 0) {
            return [];
        }
        return [
            {
                text: 'Delete',
                action: () => {
                    if (keycloak.token) {
                        DeleteGraphTemplate(keycloak.token, template.chartId).then(response => {
                            APIRequestStatus.showToast(response);
                            if (response.type !== APIRequestStatusType.Error && customTemplates != null) {
                                // Clear selected template if it no longer exists.
                                if (selectedSettings?.chartId === template.chartId) {
                                    setSelectedSettings(undefined);
                                }
                                // Remove the deleted template from the list of templates. This is only relevant for custom graphs templates,
                                // as default graph templates cannot be deleted.
                                if (customTabSelected) {
                                    const newTemplates = customTemplates.filter(i => i.chartId !== template.chartId);
                                    setCustomTemplates(newTemplates);
                                }
                            }
                        });
                    }
                }
            }
        ];
    }, [customTabSelected, customTemplates, keycloak.token, selectedSettings?.chartId]);

    const graph = useMemo(() => {
        // If no graph sets selected, display a message telling the user to select graph sets
        if (selectedGraphSetIds.length === 0) {
            return (
                <div className='quickGraphNoGraphSetsSelectedMessage'>
                    Use the <span className='templatesFormInfoHighlight'>Graph Sets</span> box below to select the data for your graph.
                </div>);
        }
        // If there is no template selected, tell the user to select a template.
        if (selectedSettings == null) {
            return (
                <div className='quickGraphNoGraphSetsSelectedMessage'>
                    Click on a <span className='templatesFormInfoHighlight'>template</span> from the list on the left to see a graph preview.
                </div>);
        }
        return (
            <TemplatePreview 
                chartSettings={selectedSettings}
                templateResultsCache={templateResultsCache}
                savePreviewImageBlob={(imageBlob:Blob|null) => {
                    lastLoadedPreviewImage.current = imageBlob;
                }}/>
        );
    }, [selectedGraphSetIds.length, selectedSettings, templateResultsCache]);

    const templateListItem = (item:GraphTemplate) => {
        let relevanceToData = null;
        if (item.chartId in templateRelevanceById) {
            relevanceToData = new GraphTemplateRelevance(templateRelevanceById[item.chartId]);
        }
        let style = {} as CSSProperties;
        if (selectedSettings?.chartId === item.chartId) {
            style.borderColor = 'black';
            style.backgroundColor = 'rgb(208, 233, 255)';
        }
        else if (relevanceToData && relevanceToData.grade <= GraphTemplateRelevance.badRelevanceGrade) {
            style.borderColor = 'gray';
            style.color = 'gray';
            style.backgroundColor = '#ddd';
        }

        let compatString = relevanceToData?.categoryName;
        let compatColor = relevanceToData?.categoryColor;
        const relevanceMatchMessage = (
            <div>
                Data is <span className='templateRelevanceScore' style={{color: compatColor}}>{compatString}</span> compatible
            </div>);

        const uniqueKey = `quickGraphTemplateListItem${item.chartId}`;
        return (
            <div 
                className={'quickGraphTemplateListItem ' + relevanceToData?.categoryClassName}
                id={uniqueKey}
                key={uniqueKey}
                style={style}
                onClick={() => {
                    setSelectedSettings(item);
                    refreshDataSource(templateResultsCache, item, selectedGraphSetIds);
                }}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                {selectedGraphSetIds.length > 0 &&
                <Tooltip
                    target={`#${uniqueKey}`}
                    showEvent="dxhoverstart" 
                    hideEvent="dxhoverend" 
                    position='left' 
                    contentRender={() => 
                        <div>
                            {relevanceMatchMessage}
                        </div>
                    }/>}
                <ContextMenu
                    target={`#${uniqueKey}`}
                    dataSource={templateContextMenuItems(item)}
                    onItemClick={(e:any) => e.itemData.action()}/>
            </div>
        );
    };

    const createTemplate = async() => {
         // Validate for duplicate graph names
         let newErrorMessage = undefined;
        let trimmedName = templateName.trim();

        const isDuplicate:boolean = await CustomChartNameIsDuplicate(keycloak.token, trimmedName, selectedCustomGraphFolderId);
        if (isDuplicate)
            newErrorMessage = 'A graph with that name already exists in the selected graph folder.';

        // No errors, apply
        if (newErrorMessage === undefined) {
            let templateToCreate = {...selectedSettings} as GraphTemplate;
            if (trimmedName !== '') {
                templateToCreate.title = trimmedName;
            }
            props.createFromTemplate(templateToCreate, selectedGraphSetIds, lastLoadedPreviewImage.current);
        } else {
            setTemplateNameErrorMessage(newErrorMessage);
        }
    }

    const searchTemplates = useCallback((tempList:GraphTemplate[]|null) => {
        if (searchTerm === '')
            return tempList;

        const lowerTerm = searchTerm.toLocaleLowerCase();
        return tempList?.filter(temp => temp.title.toLocaleLowerCase().includes(lowerTerm) 
            || temp.description.toLocaleLowerCase().includes(lowerTerm));
    }, [searchTerm]);

    const templatesListUI = (templates:GraphTemplate[]|null) => {
        const sortedTemplates = sortTemplates(templates, customTabSelected, templateRelevanceById);
        const searchedTemplates = searchTemplates(sortedTemplates);
        const noTemplates = searchedTemplates == null || searchedTemplates.length === 0;
        const graphSetsAreSelected = selectedGraphSetIds != null && selectedGraphSetIds.length > 0;
        const loadingPanelVisible = templates == null && selectedGraphSetIds.length > 0;
        return (
            <div className='quickGraphTemplateList'>
                <TextBox
                    value={searchTerm}
                    onValueChange={(newVal) => setSearchTerm(newVal)}
                    valueChangeEvent='keyup'
                    mode='search' 
                    placeholder='Search by title or description...'/>
                <ScrollView useNative={true} className='quickGraphTemplateListScrollView'>
                    <div>
                        {!loadingPanelVisible && !graphSetsAreSelected &&
                        <div className='templateListInfoMessage'>
                            You must select one or more <span className='templatesFormInfoHighlight'>Graph Sets</span> before templates will appear.
                        </div>}
                        {!loadingPanelVisible && noTemplates && graphSetsAreSelected &&
                        <div className='templateListInfoMessage'>
                            No templates were found.
                        </div>}
                        {graphSetsAreSelected && searchedTemplates?.map(g => templateListItem(g))}
                    </div>
                </ScrollView>
            </div>
        );
    };

    return (
        <Popup 
            title={`Create Graph From Template`}
            width={'70rem'}
            height={'38rem'}
            visible={props.show}
            onHiding={() => props.hidePopup()}
            hideOnOutsideClick={true}
            onContentReady={(e) => {
                var html = e.component.content();
                html.style.padding = '0rem';
            }}>
            <div className='quickGraphPopup'>
                <TabPanel
                    selectedIndex={customTabSelected ? 1 : 0}
                    onSelectedItemChange={(args:any) => {
                        const newCustomTabSelected = args.title === 'Custom';
                        setCustomTabSelected(newCustomTabSelected);
                    }}>
                    <Item
                        title={'Default'}>
                        {templatesListUI(defaultTemplates)}
                    </Item>
                    <Item
                        title={'Custom'}>
                        {templatesListUI(customTemplates)}
                    </Item>
                </TabPanel>
                <div className='quickGraphRightContent'>
                    <div className='quickGraphPreview' id={'quickGraphPreview'}>
                        {graph}
                        <LoadPanel
                            container={`#quickGraphPreview`}
                            visible={(defaultTemplates == null || customTemplates == null) && selectedGraphSetIds.length > 0}/>
                    </div>
                    <div className='quickGraphBottomBar'>
                        <Button
                            className='quickGraphCreateButton'
                            type='success'
                            text='Create'
                            icon='add'
                            disabled={selectedSettings == null || selectedGraphSetIds == null || selectedGraphSetIds.length == 0 || templateNameErrorMessage != ''}
                            onClick={createTemplate}/>
                        <div className='quickGraphBottomBarLeftContent'>
                            <GraphSetSelectorBox 
                                className='quickGraphTagBox'
                                label='Data Source'
                                selectedGraphSetIds={selectedGraphSetIds}
                                onSelectionChange={function (graphSetIds: number[]): void {
                                    setSelectedGraphSetIds(graphSetIds);
                                    
                                    // Update relevance scores
                                    GetTemplateRelevanceById(keycloak.token, graphSetIds).then((result) => {
                                        if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(result) && result.data) {
                                            setTemplateRelevanceById(result.data);
                                        }
                                    });

                                    const clearedCache = {};
                                    refreshDataSource(clearedCache, selectedSettings, graphSetIds);
                                }}/>
                            <TextBox 
                                className='quickGraphNameBox'
                                label='Graph Name'
                                value={templateName}
                                placeholder={selectedSettings?.title ?? 'Enter graph name here...'}
                                valueChangeEvent='keyup'
                                onValueChange={(newVal) => {
                                    setTemplateName(newVal);
                                    setTemplateNameErrorMessage('');
                                }}
                                isValid={templateNameErrorMessage === ''}
                                validationMessagePosition={'top'}
                                validationError={
                                    {message: templateNameErrorMessage}
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}
export default QuickGraphPopup;

const sortTemplates = (templates:GraphTemplate[]|null, addAlphaSort:boolean, templateRelevanceById:any) => {
    if (templates == null)    
        return null;

    const sortedTemps = [...templates];

    // Sort alphabetically first (only for custom templates)
    if (addAlphaSort) {
        sortedTemps?.sort((a,b) => naturalCompare(a.title, b.title));
    }

    // Then sort by relevance category
    sortedTemps?.sort((a,b) => {
        const aRelScore = templateRelevanceById[a.chartId] ?? 0;
        const bRelScore = templateRelevanceById[b.chartId] ?? 0;

        const aRelObj = new GraphTemplateRelevance(aRelScore);
        const bRelObj = new GraphTemplateRelevance(bRelScore);

        return aRelObj.categoryNumber - bRelObj.categoryNumber;
    });

    return sortedTemps;
};