import { User } from './User';

export class GraphSet {
    graphSetId: number;
    name: string;
    user: User;
    dateCreated: string|undefined;
    dateModified: string|undefined;
    isSharedWithMe: boolean;
    isSharedByMe: boolean;
    numberOfUploadSets: number;

    constructor() {
        this.graphSetId = 0;
        this.name = '';
        this.user = new User();
        this.isSharedWithMe = false;
        this.isSharedByMe = false;
        this.numberOfUploadSets = 0;
    } 
}

export enum SortOrder {
    Alphabetical = 0,
    DateModified = 1
}