import { ContextMenu } from 'devextreme-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { CustomChartFolder, FindChartParentFolder, MoveGraphToFolderWithinRoot } from '../../../../../Classes/Charts/CustomChartFolder';
import { setSelectedCustomChartId } from '../../../../../Reducers/CustomChartsReducer';
import { RootState } from '../../../../../Stores/GlobalStore';
import { FetchPreviewImageProps, fetchPreviewImage } from '../../../../../Reducers/ResultsReducer';
import useKeycloak from '../../../../../Keycloak';
import { GraphInfo } from '../../../../../Classes/Charts/GraphInfo';
import { MoveDbChartsToFolder } from '../../../../../API/CustomChartFolderAPI';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import { SelectDbGraph } from '../../../../../API/CustomChartAPI';
import { SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

interface CustomChartListItemProps {
    graphInfo: GraphInfo,
    rootFolder: CustomChartFolder,
    setRootFolder: (newVal:CustomChartFolder) => void,
    onRenameClicked: () => void;
    onDuplicateClicked: () => void;
    onSaveAsTemplateClicked: () => void;
    onDeleteClicked: () => void;
}

function CustomChartListItem({ graphInfo, rootFolder, setRootFolder, onRenameClicked, onDuplicateClicked, 
    onSaveAsTemplateClicked, onDeleteClicked } :CustomChartListItemProps) {

    const dispatch = useDispatch();
    const { token } = useKeycloak();
    const dragRef = useRef<any>(null);

    const graphId = graphInfo.id;

    const selectedCustomChartId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 
    const cachedPreviewImageLookup = useSelector((state:RootState) => state.results.cachedPreviewImageLookup);
    const loadingPreviewImage = useRef<boolean>(false);

    const [isDragging, setIsDragging] = useState(false);

    const isSelected = selectedCustomChartId === graphId;
    const idString = `customChartID${graphId}`

    const sharedMessage = useMemo(() => {
        let sharedMessage = ''
        if (graphInfo.isSharedWithMe && graphInfo.isSharedByMe) {
            sharedMessage = 'shared'
        }
        else if (graphInfo.isSharedWithMe) {
            sharedMessage = 'shared with me'
        }
        else if (graphInfo.isSharedByMe) {
            sharedMessage = 'shared by me'
        }
        return sharedMessage;
    }, [graphInfo.isSharedWithMe, graphInfo.isSharedByMe]);

    const move = useCallback((destinationFolderId:number|null) => {
        const parentFolderOfGraphToMove = FindChartParentFolder(rootFolder, graphInfo.id);
        const newRootFolder = MoveGraphToFolderWithinRoot(rootFolder, graphInfo.id, destinationFolderId);
        if (newRootFolder != null && parentFolderOfGraphToMove != null) {
            // Make the change in the UI
            setRootFolder(newRootFolder);

            // Make the change in the DB
            MoveDbChartsToFolder(token, destinationFolderId, [graphInfo.id])
            .then(response => {
                if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                    // Revert the UI change if the db save fails
                    const revertedRootFolder = MoveGraphToFolderWithinRoot(newRootFolder, graphInfo.id, parentFolderOfGraphToMove.id);
                    if (revertedRootFolder) {
                        setRootFolder(revertedRootFolder);
                    }
                }
            });
        }
    }, [graphInfo.id, rootFolder, setRootFolder, token]);

    useEffect(() => {
        const el = dragRef.current;

        return draggable({
            element: el,
            getInitialData: () => ({ graph: graphInfo, type: CustomChartDragTypes.CHART }),
            onDragStart: () => {
                setIsDragging(true);
            }, 
            onDrop: ({ location }) => {
                setIsDragging(false);

                if (location.current.dropTargets.length > 0) {
                    const firstDropTarget = location.current.dropTargets[0];
                    const dropFolder = firstDropTarget.data.folder as CustomChartFolder;
                    move(dropFolder.id);
                }
            }
        });
    }, [graphInfo, move]);

    const contextMenuItems = useMemo(() => {
        return [
            {
                text: 'Rename',
                disabled: graphInfo.userRole < SharedGraphRoleType.Admin,
                action: () => {
                    onRenameClicked();
                }
            },
            {
                text: 'Duplicate',
                disabled: graphInfo.userRole < SharedGraphRoleType.Collaborator,
                action: () => {
                    onDuplicateClicked();
                }
            },
            {
                text: 'Save as Template',
                action: () => {
                    onSaveAsTemplateClicked();
                }
            },
            {
                text: 'Delete',
                disabled: graphInfo.userRole < SharedGraphRoleType.Admin,
                action: () => {
                    onDeleteClicked();
                }
            }
        ];
    }, [graphInfo.userRole, onRenameClicked, onDuplicateClicked, onSaveAsTemplateClicked, onDeleteClicked]);

    const renderPreviewImage = useMemo(() => {
        if (!(graphId in cachedPreviewImageLookup)) {
            // Kick off a function to fetch this image from the server.
            if (!loadingPreviewImage.current) {
                loadingPreviewImage.current = true;
                dispatch(fetchPreviewImage({
                    authToken: token,
                    graphId: graphId
                } as FetchPreviewImageProps))
                .then(() => {
                    loadingPreviewImage.current = false;
                });
            }
            return <div/>;
        }

        const imageURL = cachedPreviewImageLookup[graphId].imageURL;
        return (
            <img 
                src={imageURL} 
                width={'100%'}
                height={'100%'}
                loading={'lazy'}
                draggable={false}
                alt=''/>
        );
    }, [cachedPreviewImageLookup, dispatch, graphId, token])

    let style = {};
    if (isDragging) {
         style = {
            opacity: 0
         };
    }

    let chartClass = isSelected ? 'savedGraphTemplate selectedSavedGraph' : 'savedGraphTemplate'
    return (
        <div 
            className={chartClass} 
            ref={dragRef} 
            id={idString} 
            style={style} 
            onClick={() => {
                if (!isSelected && !isDragging) {
                    dispatch(setSelectedCustomChartId(graphId));
                    SelectDbGraph(token, graphId);
                }
            }}>
            <h5>{graphInfo.title}</h5>
            <div style={{width:'100%', height:'7rem'}}>
                {renderPreviewImage}
            </div>
            {sharedMessage &&
            <div className='sharedIndicator'>
                <span>{sharedMessage}</span>
                <i className='dx-icon-share'/>
            </div>}
            <ContextMenu
                dataSource={contextMenuItems}
                target={`#${idString}`}
                onItemClick={(e:any) => {
                    e.itemData.action()
                }}/>
        </div>
    );
}

export default CustomChartListItem;