import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DeleteUploadSets, GetSummaryData, GetUploadSetsPacket, RenameUploadSet, UpdateUploadSet } from '../API/DatasetAPI';
import { CreateGraphSet, DeleteGraphSet, GetGraphSetUploadSetCount, GetGraphSetUploadSets, MoveGraphSet, RemoveUploadSetsFromGraphSet, 
    RenameGraphSet } from '../API/GraphSetAPI';
import { GetFolderTree, MoveFolder } from '../API/GraphSetFolderAPI';
import { GetFilters, UpdateFilters } from '../API/UploadSetFilterAPI';
import { APIRequestStatus, APIRequestStatusType } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { Dataset } from '../Classes/Dataset';
import { FilterConditions } from '../Classes/FilterConditions';
import { GraphSet, SortOrder } from '../Classes/GraphSet';
import { FindFolderInFolder, FindFolderParentFolderId, FindGraphSetInFolder, FindGraphSetParentFolder, GraphSetFolder } from '../Classes/GraphSetFolder';
import { GraphSetUploadSet } from '../Classes/GraphSetUploadSet';
import { SummaryData } from '../Classes/SummaryData';
import { getSelectedIdsAfterSelectingUploadSet } from '../Components/Dashboard/SetManager/SelectionUtils';
import { RootState } from '../Stores/GlobalStore';

export const enum UploadSetListType {
    UploadSetFilterableList,
    GraphSetUploadSetList
}

export interface SetManagementState {
    uploadSets: Dataset[];
    summaryData: SummaryData;
    loadingUploadSets: boolean;
    filterConditions: FilterConditions;
    rootGraphSetFolder: GraphSetFolder|undefined;
    selectedGraphSetFolderId: number;
    graphSetSortOrder: SortOrder;
    loadingGraphSets: boolean;
    selectedUploadSetIds: number[];
    selectedGraphSetId: number|undefined;
    selectedGraphSetUploadSets: GraphSetUploadSet[];
    selectedContainerType: UploadSetListType;
    draggingUploadSetId: number|undefined;
    numberOfUploadSetsBeingDragged: number;
}

const initialState: SetManagementState = {
    uploadSets: new Array<Dataset>(),
    summaryData: new SummaryData(),
    loadingUploadSets: false,
    filterConditions: new FilterConditions(),
    rootGraphSetFolder: undefined,
    selectedGraphSetFolderId: 0,
    graphSetSortOrder: SortOrder.DateModified,
    loadingGraphSets: false,
    selectedUploadSetIds: new Array<number>(),
    selectedGraphSetId: undefined,
    selectedGraphSetUploadSets: new Array<GraphSetUploadSet>(),
    selectedContainerType: UploadSetListType.UploadSetFilterableList,
    draggingUploadSetId: undefined,
    numberOfUploadSetsBeingDragged: 0
}

export type SelectUploadSetPayload = {
    uploadSetIdList: number[], 
    newUploadSetId: number, 
    event: React.MouseEvent<HTMLElement>,
    selectedContainerType: UploadSetListType
}

export type StartUploadSetDragPayload = {
    uploadSetId: number,
    selectedContainerType: UploadSetListType
}

// Upload Sets
export const fetchFirstUploadSetsPacket : any = createAsyncThunk( 
    'SetManagement/fetchFirstUploadSetsPacket',
    async ({authToken, filterConditions} : {authToken:string, filterConditions:FilterConditions}, thunkAPI) => {
        // Using the ellipses operator to trigger an update event for filterConditions
        let newFilterConditions = {...filterConditions};

        // Clear the search term and reset the packet index when changing filters
        newFilterConditions.searchTerm = '';
        newFilterConditions.packetIndex = 0;
        newFilterConditions.filtersHaveChanged = true;

        const response = await GetUploadSetsPacket(authToken, newFilterConditions);
        newFilterConditions.filtersHaveChanged = false;

        return {...response, newFilterConditions: newFilterConditions};
    }
);
export const refreshUploadSetPacket : any = createAsyncThunk( 
    'SetManagement/refreshUploadSetPacket',
    async ({authToken, filterConditions} : {authToken:string, filterConditions:FilterConditions}, thunkAPI) => {
        let newFilterConditions = {...filterConditions};
        newFilterConditions.filtersHaveChanged = true;
        newFilterConditions.packetIndex = 0;
        const uploadSetsPacketData = await GetUploadSetsPacket(authToken, newFilterConditions);
        newFilterConditions.filtersHaveChanged = false;

        return {...uploadSetsPacketData, newFilterConditions};
    }
);
export const appendUploadSetsPacket : any = createAsyncThunk(
    'SetManagement/appendUploadSetsPacket',
    async ({authToken, filterConditions} : {authToken:string, filterConditions:FilterConditions}, thunkAPI) => {
        let appendFilterConditions = {...filterConditions};
        appendFilterConditions.packetIndex += 1;

        const response = await GetUploadSetsPacket(authToken, appendFilterConditions);

        return {...response, appendFilterConditions: appendFilterConditions};
    }
);
export const fetchSummaryData : any = createAsyncThunk( 
    'SetManagement/fetchSummaryData',
    async ({authToken, filterConditions} : {authToken:string, filterConditions:FilterConditions}, thunkAPI) => {
        const response = await GetSummaryData(authToken, filterConditions);
        return response;
    }
);
export const getUploadSetsBySearchTerm : any = createAsyncThunk(
    'SetManagement/getUploadSetsBySearchTerm',
    async ({authToken, filterConditions, term} : {authToken:string, filterConditions:FilterConditions, term:string}, thunkAPI) => {

        let searchFilterConditions = {...filterConditions};
        searchFilterConditions.searchTerm = term;

        // Reset the packet index when searching
        searchFilterConditions.packetIndex = 0;
        searchFilterConditions.filtersHaveChanged = true;

        const response = await GetUploadSetsPacket(authToken, searchFilterConditions);
        searchFilterConditions.filtersHaveChanged = false;

        return {...response, searchFilterConditions: searchFilterConditions};
    }
);
export const updateUploadSet : any = createAsyncThunk(
    'SetManagement/updateUploadSet',
    async ({authToken, uploadSet} : {authToken:string, uploadSet:Dataset}, thunkAPI) => {
        const updated = await UpdateUploadSet(authToken, uploadSet);
        return updated ? uploadSet : null;
    }
);
export const deleteUploadSets : any = createAsyncThunk( 
    'SetManagement/deleteUploadSet',
    async ({authToken, uploadSetIds} : {authToken:string, uploadSetIds:number[]}, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const filterConditions = state.SetManagementReducer.filterConditions;

        // First, do the deletion in the UI
        thunkAPI.dispatch(deleteUploadSetsInTheUI(uploadSetIds));

        // Then, delete the sets in the database
        const deleteRequestStatus = await DeleteUploadSets(authToken, uploadSetIds);
        APIRequestStatus.showToast(deleteRequestStatus);

        // Refresh graph set counts and summary data
        const newRootFolderResponse = await GetFolderTree(authToken);
        if (newRootFolderResponse.type != APIRequestStatusType.Error) {
            await thunkAPI.dispatch(setRootGraphSetFolder(newRootFolderResponse.data));
        }
        await thunkAPI.dispatch(fetchSummaryData({authToken: authToken, filterConditions: filterConditions}));
    }
);
export const renameUploadSet : any = createAsyncThunk(
    'SetManagement/renameUploadSet',
    async ({authToken, name, uploadSetId} : {authToken:string, uploadSetId:number, name:string}, thunkAPI) => {
        // Rename the upload set in the UI
        thunkAPI.dispatch(renameUploadSetInTheUI({uploadSetId, name}));

        // Rename the upload set in the database and show the result if there are errors
        const renameStatus = await RenameUploadSet(authToken, uploadSetId, name);
        if (renameStatus.type !== APIRequestStatusType.Success) {
            APIRequestStatus.showToast(renameStatus);
        }

        return renameStatus;
    }
);

// Graph Sets
export const fetchGraphSetUploadSets: any = createAsyncThunk(
    'SetManagement/fetchGraphSetUploadSets',
    async ({authToken, graphSetId } : {authToken:string, graphSetId:number}, thunkAPI) => {
        const response = await GetGraphSetUploadSets(authToken, graphSetId);
        return response;
    }
);
export const createGraphSet : any = createAsyncThunk(
    'SetManagement/createGraphSet',
    async ({authToken, graphSet} : {authToken:string, graphSet:GraphSet}, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const selectedFolderId = state.SetManagementReducer.selectedGraphSetFolderId;
        const newId = await CreateGraphSet(authToken, graphSet, selectedFolderId);
        graphSet.graphSetId = newId;
        return newId !== 0 ? graphSet : null;
    }
);
export const renameGraphSet : any = createAsyncThunk(
    'SetManagement/renameGraphSet',
    async ({authToken, name, graphSetId} : {authToken:string, graphSetId:number, name:string}, thunkAPI) => {
        // Rename the graph set in the UI
        thunkAPI.dispatch(renameGraphSetInTheUI({graphSetId, name}));

        // Rename the graph set in the database and show the result if there are errors
        const renameStatus = await RenameGraphSet(authToken, graphSetId, name);
        if (renameStatus.type !== APIRequestStatusType.Success) {
            APIRequestStatus.showToast(renameStatus);
        }

        return renameStatus;
    }
);
export const deleteGraphSet : any = createAsyncThunk(
    'SetManagement/deleteGraphSet',
    async ({authToken, graphSetId} : {authToken:string, graphSetId:number}, thunkAPI) => {
        // Delete the graph set in the UI
        thunkAPI.dispatch(deleteGraphSetInTheUI(graphSetId));

        // Delete the graph set in the database and show the result if there are errors
        const deleteStatus = await DeleteGraphSet(authToken, graphSetId);
        APIRequestStatus.showToast(deleteStatus);
    }
);
export const getGraphSetUploadSetCount : any = createAsyncThunk(
    'SetManagement/updateGraphSetUploadSetCount',
    async ({authToken, graphSetId} : { authToken:string, graphSetId:number }, thunkAPI) => {
        const response = await GetGraphSetUploadSetCount(authToken, graphSetId);
        return { response, graphSetId };
    }
);
export const removeUploadSetsFromSelectedGraphSet: any = createAsyncThunk(
    'SetManagement/removeUploadSetsFromSelectedGraphSet',
    async ({ authToken, uploadSetIds }: { authToken: string, uploadSetIds: number[] }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const selectedGraphSetId = state.SetManagementReducer.selectedGraphSetId;

        if (selectedGraphSetId) {
            // First, do the removal in the UI
            thunkAPI.dispatch(removeUploadSetsFromSelectedGraphSetInTheUI(uploadSetIds));

            // Then, change the DB
            const removeRequestStatus = await RemoveUploadSetsFromGraphSet(authToken, selectedGraphSetId, uploadSetIds);
            APIRequestStatus.showToast(removeRequestStatus);

            return removeRequestStatus.type;
        }
    }
);
// Graph set folders
export const moveGraphSetFolder: any = createAsyncThunk(
    'SetManagement/moveGraphSetFolder',
    async ({ authToken, folderId, destinationFolderId }: { authToken: string, folderId: number, destinationFolderId:number }, thunkAPI) => {
        // Attempt to move the folder in the db
        const response = await MoveFolder(authToken, folderId, destinationFolderId);
        if (response.type == APIRequestStatusType.Error) {
            APIRequestStatus.showToast(response);
        }
        else {
            // Do the move in the UI
            thunkAPI.dispatch(moveUIFolder({folderId, destinationFolderId}));
        }
    }
);
export const moveGraphSet: any = createAsyncThunk(
    'SetManagement/moveGraphSet',
    async ({ authToken, graphSetId, destinationFolderId }: { authToken: string, graphSetId: number, destinationFolderId:number }, thunkAPI) => {
        // Attempt to move the folder in the db
        const response = await MoveGraphSet(authToken, graphSetId, destinationFolderId);
        if (response.type == APIRequestStatusType.Error) {
            APIRequestStatus.showToast(response);
        }
        else {
            // Do the move in the UI
            thunkAPI.dispatch(moveUIGraphSet({graphSetId, destinationFolderId}));
        }
    }
);

// Upload Set Filters
export const fetchUploadSetFilters : any = createAsyncThunk(
    'SetManagement/fetchUploadSetFilters',
    async (authToken : string, thunkAPI) => {
        const response = await GetFilters(authToken);
        return response;
    }
);

export type MoveSelectedUploadSetsProps = {
    sourceUploadSetList:Dataset[],
    sourceGraphSetId: number|null,
    destinationGraphSetId: number,
    destinationIndex:number,
    addNewToEnd:boolean
}

export const SetManagementReducer = createSlice({
    name: 'SetManagement',
    initialState,
    reducers: {
        setFilterConditions: (state, action) => {
            let { authToken, newFilterConditions } : { authToken:string, newFilterConditions:FilterConditions } = action.payload;
            newFilterConditions.filtersHaveChanged = true;
            state.filterConditions = newFilterConditions;

            // Save new filter conditions to the db
            UpdateFilters(authToken, newFilterConditions);
        },
        removeUIProgramUploadSets: (state, action) => {
            let programId = action.payload

            // Remove upload sets associated with the given program from the upload sets list. Note their ids so we can remove them from graph sets later.
            let uploadSetIdsToDelete:Set<number> = new Set<number>()
            state.uploadSets = state.uploadSets.filter((uploadSet:Dataset) => {
                if (uploadSet.programId == programId) {
                    uploadSetIdsToDelete.add(uploadSet.datasetId)
                    return false
                }
                return true
            })

            // Remove upload sets from graph sets
            // TODO PG -> Update the graph set upload sets of the selected graph set
        },
        removeUICompanyUploadSets: (state, action) => {
            let companyId = action.payload

            // Remove upload sets associated with the given company from the upload sets list. Note their ids so we can remove them from graph sets later.
            let uploadSetIdsToDelete:Set<number> = new Set<number>()
            state.uploadSets = state.uploadSets.filter((uploadSet:Dataset) => {
                if (uploadSet.companyId == companyId) {
                    uploadSetIdsToDelete.add(uploadSet.datasetId)
                    return false
                }
                return true
            })

            // Remove upload sets from graph sets
            // TODO PG -> Update the graph set upload sets of the selected graph set
        },
        selectGraphSet : (state, action) => {
            const newGraphSetId = action.payload as number|undefined;
            state.selectedGraphSetId = newGraphSetId;

            // If selection was in a graph set, clear the selection when the selected graph set changes.
            if (state.selectedContainerType === UploadSetListType.GraphSetUploadSetList && state.selectedUploadSetIds.length > 0) {
                state.selectedUploadSetIds = new Array<number>();
            }
        },
        selectUploadSet : (state, action) => {
            const selectUploadSetPayload = action.payload as SelectUploadSetPayload;

            const selectingFromDifferentContainer = selectUploadSetPayload.selectedContainerType !== state.selectedContainerType;
            const currentlySelectedIdsFromContainer = selectingFromDifferentContainer ? new Array<number>() : state.selectedUploadSetIds;

            const newSelectedUploadSetIds = getSelectedIdsAfterSelectingUploadSet(
                currentlySelectedIdsFromContainer, 
                selectUploadSetPayload.uploadSetIdList, 
                selectUploadSetPayload.newUploadSetId, 
                selectUploadSetPayload.event);

            if (newSelectedUploadSetIds != null)
                state.selectedUploadSetIds = newSelectedUploadSetIds;

            if (selectingFromDifferentContainer)
                state.selectedContainerType = selectUploadSetPayload.selectedContainerType;
        },
        setDraggingUploadSetId : (state, action) => {
            const draggingUploadSetId = action.payload as number|undefined;
            state.draggingUploadSetId = draggingUploadSetId;
        },
        clearSelectedUploadSets : (state, action) => {
            state.selectedUploadSetIds = new Array<number>();
        },
        startUploadSetDrag: (state, action) => {
            const startUploadSetDragPayload = action.payload as StartUploadSetDragPayload;
            if (state.selectedUploadSetIds.length > 0) {
                if (state.selectedContainerType !== startUploadSetDragPayload.selectedContainerType 
                    || !state.selectedUploadSetIds.includes(startUploadSetDragPayload.uploadSetId)) {
                    state.selectedUploadSetIds = new Array<number>();
                }
                else {
                    state.numberOfUploadSetsBeingDragged = state.selectedUploadSetIds.length;
                }
            }

            state.draggingUploadSetId = startUploadSetDragPayload.uploadSetId;
        },
        endUploadSetDrag: (state, action) => {
            state.draggingUploadSetId = undefined;
            state.numberOfUploadSetsBeingDragged = 0;
        },
        moveSelectedUploadSetsInTheUI: (state, action) => {
            const {sourceGraphSetId, sourceUploadSetList, destinationGraphSetId, destinationIndex, addNewToEnd} : MoveSelectedUploadSetsProps = action.payload;
            const selectedUploadSetIdLookup = new Set<number>(state.selectedUploadSetIds);

            // Include the upload set being dragged in the lookup in case it is not selected
            if (state.draggingUploadSetId)
                selectedUploadSetIdLookup.add(state.draggingUploadSetId);

            let adjustedDestinationIndex = destinationIndex;

            // Copy the upload sets list so we can edit it several times without causing multiple state updates
            let newSelectedGraphSetUploadSets = [...state.selectedGraphSetUploadSets];

            // Save a list of graph set upload sets to add to the destination. 
            const uploadSetsToAdd:Dataset[] = [...sourceUploadSetList.filter((uploadSet:Dataset) => {
                return selectedUploadSetIdLookup.has(uploadSet.datasetId);
            })];
            let graphSetUploadSetsToAdd:GraphSetUploadSet[] = uploadSetsToAdd.map((uploadSet:Dataset) => {
                return new GraphSetUploadSet(uploadSet, undefined);
            });

            // Remove upload sets from the source, if the source is selected. 
            if (sourceGraphSetId != null && sourceGraphSetId === state.selectedGraphSetId) {
                let selectedUploadSetsBeforeDestinationIndex = 0;
                newSelectedGraphSetUploadSets = newSelectedGraphSetUploadSets.filter((gsus:GraphSetUploadSet, index:number) => {
                    const uploadSetIsSelected = selectedUploadSetIdLookup.has(gsus.uploadSet.datasetId);

                    if (uploadSetIsSelected && index < destinationIndex) {
                        selectedUploadSetsBeforeDestinationIndex++;
                    }

                    return !uploadSetIsSelected;
                });

                // If the source is the same as the destination, we may have to adjust the destination index to account for the upload sets 
                // that are removed from the list after the original destination index is calculated. 
                if (sourceGraphSetId === destinationGraphSetId && selectedUploadSetsBeforeDestinationIndex > 1) {
                    // Adjust the destination id. Subtract one to ignore the set being dragged, since the DND logic accounts for that in the 
                    // original destination index. 
                    adjustedDestinationIndex -= selectedUploadSetsBeforeDestinationIndex - 1;
                }
            }

            // If the destination is the selected graph set, add the saved upload sets at the desired index
            if (destinationGraphSetId === state.selectedGraphSetId) {
                // Do not add upload sets that are already apart of the destination list.
                const destinationUploadSetIdLookup = new Set<number>(newSelectedGraphSetUploadSets.map((gsus:GraphSetUploadSet) => gsus.uploadSet.datasetId));
                graphSetUploadSetsToAdd = graphSetUploadSetsToAdd.filter((gsus:GraphSetUploadSet) => {
                    const isDuplicate:boolean = destinationUploadSetIdLookup.has(gsus.uploadSet.datasetId);
                    return !isDuplicate;
                });

                let firstListSection = newSelectedGraphSetUploadSets.slice(0, adjustedDestinationIndex);
                let lastListSection = newSelectedGraphSetUploadSets.slice(firstListSection.length);

                if (!addNewToEnd) {
                    newSelectedGraphSetUploadSets = firstListSection.concat(graphSetUploadSetsToAdd, lastListSection);
                }
                else {
                    // If addNewToEnd is true, simply put the new upload sets after the existing sets.
                    newSelectedGraphSetUploadSets = newSelectedGraphSetUploadSets.concat(graphSetUploadSetsToAdd);
                }
            }
        
            // Re-index the new upload set list
            newSelectedGraphSetUploadSets.forEach((gsus:GraphSetUploadSet, index:number) => gsus.indexInGraphSet = index);
            state.selectedGraphSetUploadSets = newSelectedGraphSetUploadSets;

            // Update the upload set count of the source. We know the upload sets of the source will always be loaded, so counting them here is possible.
            // The upload sets in the destination may not be loaded, so for that we must go to the db. That is done after this function completes.
            if (destinationGraphSetId !== sourceGraphSetId && sourceGraphSetId !== 0) {
                const graphSet = FindGraphSetInFolder(state.rootGraphSetFolder, sourceGraphSetId);
                if (graphSet)
                    graphSet.numberOfUploadSets = state.selectedGraphSetUploadSets.length;
            }
        },
        deleteUploadSetsInTheUI: (state, action) => {
            const uploadSetIds = action.payload;
            const selectedGraphSet = FindGraphSetInFolder(state.rootGraphSetFolder, state.selectedGraphSetId ?? null);

            // Remove the sets from the upload sets list
            state.uploadSets = state.uploadSets.filter((uploadSet:Dataset) => !uploadSetIds.includes(uploadSet.datasetId));

            // Remove the sets from the selected graph set
            if (selectedGraphSet) {
                state.selectedGraphSetUploadSets = state.selectedGraphSetUploadSets.filter((gsus:GraphSetUploadSet) => !uploadSetIds.includes(gsus.uploadSet.datasetId));
                selectedGraphSet.numberOfUploadSets = state.selectedGraphSetUploadSets.length;
            }

            // Update selection
            state.selectedUploadSetIds = state.selectedUploadSetIds.filter((id:number) => !uploadSetIds.includes(id));
        },
        removeUploadSetsFromSelectedGraphSetInTheUI: (state, action) => {
            const uploadSetIds = action.payload;
            const selectedGraphSet = FindGraphSetInFolder(state.rootGraphSetFolder, state.selectedGraphSetId ?? null);

            if (selectedGraphSet) {
                state.selectedGraphSetUploadSets = state.selectedGraphSetUploadSets.filter((gsus:GraphSetUploadSet) => !uploadSetIds.includes(gsus.uploadSet.datasetId));
                selectedGraphSet.numberOfUploadSets = state.selectedGraphSetUploadSets.length;

                // Update selection
                state.selectedUploadSetIds = state.selectedUploadSetIds.filter((id:number) => !uploadSetIds.includes(id));
            }
        },
        renameUploadSetInTheUI: (state, action) => {
            const uploadSetId = action.payload.uploadSetId;
            const name = action.payload.name;

            // Rename the set in the upload sets list
            state.uploadSets.forEach((uploadSet:Dataset) => {
                if (uploadSet.datasetId === uploadSetId) {
                    uploadSet.datasetName = name;
                    return;
                }
            });

            // Rename the set in the selected graph set upload sets list
            state.selectedGraphSetUploadSets.forEach((gsus:GraphSetUploadSet) => {
                if (gsus.uploadSet.datasetId === uploadSetId) {
                    const newUploadSet = {...gsus.uploadSet};
                    newUploadSet.datasetName = name;
                    gsus.uploadSet = newUploadSet;
                    return;
                }
            });
        },
        renameGraphSetInTheUI: (state, action) => {
            const graphSetId = action.payload.graphSetId;
            const name = action.payload.name;

            const graphSetParentFolder = FindGraphSetParentFolder(state.rootGraphSetFolder, graphSetId);
            if (graphSetParentFolder) {
                graphSetParentFolder.graphSets = graphSetParentFolder.graphSets.map(i => {
                    if (i.graphSetId === graphSetId) {
                        const newGraphSet = {...i};
                        newGraphSet.name = name;
                        return newGraphSet;
                    }
                    return i;
                });
            }
        },
        deleteGraphSetInTheUI: (state, action) => {
            const graphSetId = action.payload;
            if (graphSetId === state.selectedGraphSetId) {
                // Clear selected upload sets that may no longer exist
                const graphSetUploadSetIds = state.selectedGraphSetUploadSets.map((gsus:GraphSetUploadSet) => gsus.uploadSet.datasetId);
                state.selectedUploadSetIds = state.selectedUploadSetIds.filter((id:number) => !graphSetUploadSetIds.includes(id));
                // Clear graph set selection
                state.selectedGraphSetUploadSets = new Array<GraphSetUploadSet>();
                state.selectedGraphSetId = undefined;
            }

            const graphSetParentFolder = FindGraphSetParentFolder(state.rootGraphSetFolder, graphSetId);
            if (graphSetParentFolder) {
                graphSetParentFolder.graphSets = graphSetParentFolder.graphSets.filter((graphSet:GraphSet) => graphSet.graphSetId !== graphSetId);
            }
        },
        setSelectedGraphSetFolderId: (state, action) => {
            const folderId = action.payload;
            if (folderId !== state.selectedGraphSetFolderId) {
                state.selectedGraphSetFolderId = folderId;
            }
        },
        setGraphSetSortOrder: (state, action) => {
            const sortOrder = action.payload;
            state.graphSetSortOrder = sortOrder;
        },
        setRootGraphSetFolder: (state, action) => {
            const newRootFolder = action.payload;
            state.rootGraphSetFolder = newRootFolder;
        },
        createUIFolder: (state, action) => {
            const newFolder = action.payload;
            // This will get set on the backend seperately. But we can use this date on the frontend until the page reloads.
            newFolder.dateCreated = (new Date()).toUTCString();
            newFolder.dateModified = (new Date()).toUTCString();
            const selectedFolder = getSelectedFolder(state);
            if (state.rootGraphSetFolder && selectedFolder != null) {
                const newFolderCopy = {...newFolder };
                selectedFolder.subFolders = [...selectedFolder.subFolders, newFolderCopy];
                state.rootGraphSetFolder = {...state.rootGraphSetFolder};
            }
        },
        renameUIFolder: (state, action) => {
            const folderId = action.payload.folderId;
            const newName = action.payload.newName;
            const selectedFolder = getSelectedFolder(state);
            if (state.rootGraphSetFolder && selectedFolder) {
                selectedFolder.subFolders = selectedFolder.subFolders.map((f) => {
                    if (f.id == folderId) {
                        f.name = newName;
                    }
                    return f;
                });
                state.rootGraphSetFolder = {...state.rootGraphSetFolder};
            }
        },
        deleteUIFolder: (state, action) => {
            const folderId = action.payload;
            const selectedFolder = getSelectedFolder(state);
            if (state.rootGraphSetFolder && selectedFolder) {
                selectedFolder.subFolders = selectedFolder.subFolders.filter((f) => {
                    return f.id != folderId;
                });
                state.rootGraphSetFolder = {...state.rootGraphSetFolder};
            }
        },
        moveUIFolder: (state, action) => {
            const folderId = action.payload.folderId;
            const destinationFolderId = action.payload.destinationFolderId;
            let folderToMove:GraphSetFolder|null = null; 

            if (!state.rootGraphSetFolder || folderId == null || destinationFolderId == null)
                return;

            // Remove folder from parent 
            const parentFolderId = FindFolderParentFolderId(state.rootGraphSetFolder, folderId);
            let parentFolder = FindFolderInFolder(state.rootGraphSetFolder, parentFolderId);
            if (parentFolder) {
                parentFolder.subFolders = parentFolder.subFolders.filter((f:GraphSetFolder) => {
                    if (f.id === folderId) {
                        folderToMove = f;
                        return false;
                    }
                    return true;
                });
                parentFolder = {...parentFolder};
            }

            // Add folder to destination folder     
            let destFolder = FindFolderInFolder(state.rootGraphSetFolder, destinationFolderId);
            if (destFolder && folderToMove) {
                // It is necessary to copy folders/graph sets like this before adding them to lists. Otherwise, Redux will sometimes throw
                // proxy revoked errors.
                const copyOfFolderToMove = {...(folderToMove as GraphSetFolder)};
                destFolder.subFolders = [...destFolder.subFolders, copyOfFolderToMove];
                destFolder = {...destFolder};
            }

            // Refresh root folder object
            state.rootGraphSetFolder = {...state.rootGraphSetFolder};
        },
        moveUIGraphSet: (state, action) => {
            const graphSetId = action.payload.graphSetId;
            const destinationFolderId = action.payload.destinationFolderId;
            let graphSetToMove:GraphSet|null = null;

            if (!state.rootGraphSetFolder || graphSetId == null || destinationFolderId == null)
                return;

            // Remove graph set from parent folder
            let parentFolder = FindGraphSetParentFolder(state.rootGraphSetFolder, graphSetId);
            if (parentFolder) {
                parentFolder.graphSets = [...parentFolder.graphSets.filter((g:GraphSet) => {
                    if (g.graphSetId === graphSetId) {
                        graphSetToMove = g;
                        return false;
                    }
                    return true;
                })];
                parentFolder = {...parentFolder};
            }

            // Add graph set to destination folder     
            let destFolder = FindFolderInFolder(state.rootGraphSetFolder, destinationFolderId);
            if (destFolder && graphSetToMove != null) {
                const copyOfGraphSetToMove = {...(graphSetToMove as GraphSet)};
                destFolder.graphSets = [...destFolder.graphSets, copyOfGraphSetToMove];
                destFolder = {...destFolder};
            }

            // Refresh root folder object
            state.rootGraphSetFolder = {...state.rootGraphSetFolder};
        }
    },
    extraReducers: {
        // Upload Sets
        [fetchFirstUploadSetsPacket.pending] : (state, action) => {
            state.loadingUploadSets = true;
        },
        [fetchFirstUploadSetsPacket.fulfilled] : (state, action) => {
            state.uploadSets = action.payload.uploadSets; 
            state.summaryData = action.payload.summaryData;
            state.filterConditions = action.payload.newFilterConditions;
            state.loadingUploadSets = false;
        },
        [refreshUploadSetPacket.pending] : (state, action) => {
            state.loadingUploadSets = true;
        },
        [refreshUploadSetPacket.fulfilled] : (state, action) => {
            state.uploadSets = action.payload.uploadSets; 
            state.summaryData = action.payload.summaryData;
            state.filterConditions = action.payload.newFilterConditions;
            state.loadingUploadSets = false;
        },
        [updateUploadSet.pending] : (state, action) => {
            state.loadingUploadSets = true;
        },
        [updateUploadSet.fulfilled] : (state, action) => {
            let newUploadSet = action.payload as Dataset;
            if (newUploadSet !== null) {
                // Update upload sets
                state.uploadSets = state.uploadSets.map((uploadSet:Dataset) => {
                    if (uploadSet.datasetId === newUploadSet.datasetId) {
                        return newUploadSet;
                    } else {
                        return uploadSet;
                    }
                });
                // Update selected graph set upload set
                state.selectedGraphSetUploadSets = state.selectedGraphSetUploadSets.map((gsus:GraphSetUploadSet) => {
                    if (gsus.uploadSet.datasetId === newUploadSet.datasetId) {
                        const newGsus = {...gsus} as GraphSetUploadSet;
                        newGsus.uploadSet = newUploadSet;
                        return newGsus;
                    } else {
                        return gsus;
                    }
                });
            }
            state.loadingUploadSets = false;
        },
        [appendUploadSetsPacket.pending] : (state, action) => {
            state.loadingUploadSets = true;
        },
        [appendUploadSetsPacket.fulfilled] : (state, action) => {
            // Using the ellipses operator to trigger an update event for uploadSets
            let newUploadSets = [...state.uploadSets];
            action.payload.uploadSets.forEach((set:Dataset) => {
                newUploadSets.push(set);
            });
            state.uploadSets = newUploadSets;
            state.filterConditions = action.payload.appendFilterConditions;
            state.loadingUploadSets = false;
        },
        [fetchSummaryData.fulfilled] : (state, action) => {
            let newSummaryData = action.payload
            if (newSummaryData) {
                state.summaryData = newSummaryData
            }
        },
        [getUploadSetsBySearchTerm.pending] : (state, action) => {
            state.loadingUploadSets = true;
        },
        [getUploadSetsBySearchTerm.fulfilled] : (state, action) => {
            state.uploadSets = action.payload.uploadSets; 
            state.summaryData = action.payload.summaryData;
            state.filterConditions = action.payload.searchFilterConditions;
            state.loadingUploadSets = false;
        },
        // Graph Sets
        [fetchGraphSetUploadSets.fulfilled] : (state, action) => {
            let newGraphSetUploadSets:GraphSetUploadSet[] = action.payload;

            // Sort upload sets by index
            newGraphSetUploadSets.sort((a, b) => {
                const indexA = a.indexInGraphSet;
                const indexB = b.indexInGraphSet;

                // Just sort undefined values as last in the list. There really shouldn't be any undefined values, unless migrating from a very old db.
                if (indexA == null)
                    return -1;
                if (indexB == null)
                    return 1;

                return indexA > indexB ? 1 : -1
            });
            
            state.selectedGraphSetUploadSets = newGraphSetUploadSets;
        },
        [createGraphSet.pending] : (state, action) => {
            state.loadingGraphSets = true; 
        },
        [createGraphSet.fulfilled] : (state, action) => {
            const newGraphSet = action.payload as GraphSet;
            const selectedFolder = getSelectedFolder(state);

            if (state.rootGraphSetFolder) {
                if (newGraphSet !== null && selectedFolder != null) {
                    selectedFolder.graphSets = [newGraphSet, ...selectedFolder.graphSets];
                }
                state.rootGraphSetFolder = {...state.rootGraphSetFolder};
            }
            state.loadingGraphSets = false;
        },
        [getGraphSetUploadSetCount.fulfilled] : (state, action) => {
            const result = action.payload.response as APIResponseWithStatus<number>;
            const graphSetId = action.payload.graphSetId as number;
            const selectedFolder = getSelectedFolder(state);
            if (result.type !== APIRequestStatusType.Error && selectedFolder) {
                // Update the count on the graph set object
                selectedFolder.graphSets.forEach((graphSet:GraphSet) => {
                    if (graphSet.graphSetId === graphSetId) {
                        graphSet.numberOfUploadSets = result.data ?? 0;
                    }
                });
                // Force UI update on graphSets
                selectedFolder.graphSets = [...selectedFolder.graphSets];
            }
        },
        // Filters
        [fetchUploadSetFilters.fulfilled] : (state, action) => {
            state.filterConditions = action.payload;
        }
    }
}); 

// Helper functions
const getSelectedFolder = (state:SetManagementState) => {
    return FindFolderInFolder(state.rootGraphSetFolder, state.selectedGraphSetFolderId);
}

export const SetManagementSelector = (state: RootState) => state.SetManagementReducer;
export const { 
    setFilterConditions, removeUIProgramUploadSets, removeUICompanyUploadSets,
    selectGraphSet, selectUploadSet, clearSelectedUploadSets, startUploadSetDrag, 
    endUploadSetDrag, moveSelectedUploadSetsInTheUI, deleteUploadSetsInTheUI, renameUploadSetInTheUI,
    removeUploadSetsFromSelectedGraphSetInTheUI, renameGraphSetInTheUI, deleteGraphSetInTheUI,
    setSelectedGraphSetFolderId, setGraphSetSortOrder, setRootGraphSetFolder, createUIFolder, 
    renameUIFolder, deleteUIFolder, moveUIFolder, moveUIGraphSet } = SetManagementReducer.actions;

export default SetManagementReducer.reducer;