import './OptionSelector.css';

import { useEffect, useMemo, useRef, useState } from 'react';
import { SelectBox } from 'devextreme-react';
import { OptionSelectorSection } from './OptionSelectorPopup';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';

type OptionSelectorProps = {
    optionGroup: any;
    setSelectedGroupSections: (newSections:Array<OptionSelectorSection>) => void;
    selectedSubgroupPath: string[];
}

export default function OptionGroupSelector(props: OptionSelectorProps) {
    const [selectedSubGroup, setSelectedSubGroup] = useState<string|undefined>(undefined);
    
    const shouldRenderSubgroup = selectedSubGroup && selectedSubGroup in props.optionGroup && !Array.isArray(props.optionGroup[selectedSubGroup]);

    useEffect(() => {
        if (props.optionGroup != null && Object.keys(props.optionGroup).length > 0 && (selectedSubGroup == null || !(selectedSubGroup in props.optionGroup))) {
            const defaultGroup = Object.keys(props.optionGroup).sort(optionGroupCompare)[0];
            setSelectedSubGroup(defaultGroup);
        }
    }, [props.optionGroup, selectedSubGroup]);

    useEffect(() => {
        const defaultSubgroup = props.selectedSubgroupPath.length > 0 ? props.selectedSubgroupPath[0] : undefined;
        if (defaultSubgroup !== undefined) {
            setSelectedSubGroup(defaultSubgroup);
        } 
    }, [props.selectedSubgroupPath]);

    useEffect(() => {
        if (!shouldRenderSubgroup && selectedSubGroup) {
            const groupSections = props.optionGroup[selectedSubGroup];
            if (groupSections)
                props.setSelectedGroupSections(props.optionGroup[selectedSubGroup]);
            else {
                props.setSelectedGroupSections(new Array<OptionSelectorSection>());
            }
        }
    }, [selectedSubGroup, props.optionGroup]);

    const selectedSubgroupPath = useMemo(() => {
        if (props.selectedSubgroupPath.length > 0) {
            return props.selectedSubgroupPath.slice(1, props.selectedSubgroupPath.length);
        }
        else {
            return new Array<string>();
        }
    }, [props.selectedSubgroupPath]);

    return (
        <div>
            <SelectBox
                dataSource={Object.keys(props.optionGroup).sort(optionGroupCompare)}
                value={selectedSubGroup}
                onValueChange={(val) => setSelectedSubGroup(val)}/>
            {shouldRenderSubgroup && 
                <OptionGroupSelector 
                    optionGroup={props.optionGroup[selectedSubGroup]}
                    setSelectedGroupSections={props.setSelectedGroupSections}
                    selectedSubgroupPath={selectedSubgroupPath}/>
            }
        </div>
    );
}

// Sort alphabetically by default. Use a custom sort for special keywords
function optionGroupCompare(a:string, b:string) : number {
    a = prependGroupSortValue(a);
    b = prependGroupSortValue(b);

    return naturalCompare(a, b);
}

function getGroupSortValue(group:string):number {
    let groupSortVals = {} as any;
    let currOrder = 1;

    // Top level dropdown order
    groupSortVals['Analysis Results'] = currOrder++;
    groupSortVals['Sizing Results'] = currOrder++;
    groupSortVals['Upload Metadata'] = currOrder++;
    groupSortVals['Inputs'] = currOrder++;
    groupSortVals['Structures'] = currOrder++;

    // Second level dropdowns
    // Inputs
    groupSortVals['Assigned Properties'] = currOrder++;
    groupSortVals['Stiffness Constraints'] = currOrder++;
    groupSortVals['Buckling'] = currOrder++;
    groupSortVals['Zone Settings'] = currOrder++;

    // Analysis Results
    groupSortVals['Summary'] = currOrder++;
    groupSortVals['Properties'] = currOrder++;
    groupSortVals['Panel Buckling'] = currOrder++;

    // Sizing Results
    groupSortVals['Dimensions'] = currOrder++;
    groupSortVals['Laminates'] = currOrder++;
    groupSortVals['Materials'] = currOrder++;

    // Third level dropdowns
    groupSortVals['Panel Stiffness'] = currOrder++;
    groupSortVals['Beam Stiffness'] = currOrder++;
    groupSortVals['Skin-Stiffener Ratios'] = currOrder++;
    groupSortVals['Neutral Axis'] = currOrder++;
    groupSortVals['Thermal'] = currOrder++;

    if (group in groupSortVals)
        return groupSortVals[group] as number;

    // Just return a large number here to sort other properties after the ones with a defined sort order.
    return 99;
}

function prependGroupSortValue(group:string) {
    const groupSortVal = getGroupSortValue(group);
    return getStringWithNumberPrefix(group, groupSortVal);
}

function getStringWithNumberPrefix(s:string, n:number) {
    return n.toString() + '_' + s;
}