import './UploadSetList.css'

import Moment from 'react-moment';
import { Dataset } from '../../../../Classes/Dataset';
import { v4 as uuid } from 'uuid';
import UploadSetTagBox from './UploadSetTagBox';
import { ContextMenu, Tooltip } from 'devextreme-react';
import { memo } from 'react';

export interface UploadSetListItemContextMenuItem {
    text: string;
    action: () => void;
}

interface UploadSetListItemProps {
    uploadSet:Dataset;
    selectUploadSet: (newUploadSetId: number, event: React.MouseEvent<HTMLElement>) => void;
    isSelected:boolean;
    draggingUploadSetId:number|undefined;
    getContextMenuItems: (uploadSet:Dataset) => UploadSetListItemContextMenuItem[];
    contextMenuHidden: boolean;
}

function UploadSetListItem(props:UploadSetListItemProps) {
    const elementId = `UploadSetListItem_${uuid()}`;
    const elementSelectorString = `#${elementId}`;
    const uploadSet = props.uploadSet;

    let background = props.isSelected ? '#D0E9FF' : 'white';
    let borderColor = props.isSelected ? 'black' : '#ddd';
    if (props.isSelected && props.draggingUploadSetId !== undefined && props.draggingUploadSetId !== uploadSet.datasetId) {
        background = '#e0e0eb';
        borderColor = 'gray';
    }

    return (
        <div 
            id={elementId}
            className='uploadSetListItem' 
            style={{background, borderColor}}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                props.selectUploadSet(props.uploadSet.datasetId, event);
            }}>
            <div className='uploadSetListItemTitle'>
                <b>{ uploadSet.datasetName }</b>
            </div>
            <span className='uploadSetListItemDescription'>
                uploaded by <em>{ uploadSet.userDisplayName?.length > 0 ? uploadSet.userDisplayName : 'unknown' }</em> on <Moment format='M/D/YYYY h:mm A' date={uploadSet.date}/>
            </span>
            <UploadSetTagBox tags={uploadSet.tags}/>
            {!props.contextMenuHidden &&
            <ContextMenu
                items={props.getContextMenuItems(props.uploadSet)}
                target={elementSelectorString}
                onItemClick={(e:any) => {
                    e.itemData.action();
                }}/>}
        </div>
    );
}
export default memo(UploadSetListItem);