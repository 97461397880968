import { NumberBox, SelectBox } from 'devextreme-react';
import GraphAdvancedSettingsButton from '../GraphAdvancedSettingsButton';
import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { AddDbDataSourceColumn, SetDbSignificantFigures, SetDbSolutionsShown } from '../../../../../../API/CustomChartAPI';
import useKeycloak from '../../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { showSolutionsTypes } from '../../../../CommonChartTypes';
import { useDispatch } from 'react-redux';
import { FetchZoneResultsProps, fetchZoneResultsAndOverrideCache } from '../../../../../../Reducers/ResultsReducer';
import { AggregationType } from '../../../../../../Classes/Enums/AggregationType';
import { DataSourceColumn } from '../../../../../../Classes/DataSourceColumn';

interface GraphAdvancedSettingsButtonProps {
    visible: boolean,
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void;
}

export default function DataSourceSettingsButton({visible, graphSettings, setGraphSettings}: GraphAdvancedSettingsButtonProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();
    const graphId = graphSettings.chartId;
    const { valuePrecision, showAllSolutions } = graphSettings;

    const contentUI = (
        <div className='advancedSettings'>
            <div className='settingLabel'>
                Significant Figures
            </div>
            <div className='settingSelector'>
            <NumberBox
                min={1}
                max={999}
                showSpinButtons={true}
                showClearButton={true}
                value={valuePrecision}
                onValueChange={(value) => {
                    const oldVal = valuePrecision;
                    let newVal = value;
                    if (newVal !== null) {
                        // Convert value to an int, since decimals don't make sense for sig figs.
                        newVal = Math.floor(value);
                    }

                    // Make the change in the UI
                    setGraphSettings({...graphSettings, valuePrecision: newVal});

                    // Make the change in the DB
                    SetDbSignificantFigures(token, graphId, newVal).then(response => {
                        if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                            // Revert the UI change if something goes wrong
                            setGraphSettings({...graphSettings, valuePrecision: oldVal});
                        }
                    });
                }}
                placeholder={'Unlimited'}/>
            </div>
            <div className='settingLabel'>
                HyperX Solutions Shown
            </div>
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={showSolutionsTypes}
                    value={showAllSolutions}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = showAllSolutions;

                        // Make the change in the UI
                        const newGraphSettings = {...graphSettings, showAllSolutions: newVal};
                        setGraphSettings(newGraphSettings);

                        // Make the change in the DB
                        SetDbSolutionsShown(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, showAllSolutions: oldVal});
                            }
                            else {
                                const solutionKey = 'hs_component_solution.solution_ID';
                                const doesNotHaveSolutionsColumn = graphSettings.dataSourceColumns.find(i => i.propertyKey === solutionKey) == null;

                                // Now we need to add the solution id column and make it a category if needed. Then, refresh the results.
                                const generateSolutionIdColumnIfNeeded = async () => {
                                    // Add a Solution ID column when switching to all solutions if it does not already exist.
                                    if (newVal === true && doesNotHaveSolutionsColumn) {
                                        const columnAddResp = await AddDbDataSourceColumn(token, graphId, solutionKey, AggregationType.CSV);
                                        if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(columnAddResp) && columnAddResp.data) {
                                            // Make the change in the UI
                                            const newId = columnAddResp.data;
                                            const newDSCols = [...newGraphSettings.dataSourceColumns, new DataSourceColumn(newId, solutionKey, AggregationType.CSV)];
                                            setGraphSettings({...newGraphSettings, dataSourceColumns: newDSCols});
                                        }
                                    }

                                    // Reload the cached results for the selected graph, if one exists.
                                    dispatch(fetchZoneResultsAndOverrideCache({
                                        authToken: token,
                                        graphId: graphId
                                    } as FetchZoneResultsProps));
                                }

                                // Call this async method here so we can await the db calls before moving to the next call.
                                generateSolutionIdColumnIfNeeded();
                            }
                        });
                    }}/>
            </div>
        </div>
    );

    return (
        <GraphAdvancedSettingsButton
            title='Data Source Settings' 
            content={contentUI} 
            visible={visible}/>
    )
}