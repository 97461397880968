import "./DesignPropertyPopup.css";

import { Button, CheckBox, DataGrid, NumberBox, Popover, Popup, SelectBox, TabPanel, TextBox } from "devextreme-react";
import DesignProperty, { AnalysisMode } from "../../../../../Classes/UploadSetInputs/DesignProperty/DesignProperty";
import { Column } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/accordion";
import Material, { GetMaterialCandidateTypeName, GetMaterialColor, GetMaterialTypeName } from "../../../../../Classes/UploadSetInputs/Material/Material";
import { useState } from "react";
import SizingOverridesPopup from "./SizingOverridesPopup";
import { pluralize, toSigFigs } from "../../../../../Utilities/CommonUtilities";

interface DesignPropertyPopupProps {
    design:DesignProperty|null;
    onHiding: () => void;
}

export default function DesignPropertyPopup(props:DesignPropertyPopupProps) {
    const [showOverrides, setShowOverrides] = useState<boolean>(false);

    const sigFigs = 4;

    const renderCellSigFigs = (e:any) => {
        const val = e.value;
        const roundedVal = toSigFigs(val, sigFigs) ?? "";
        return roundedVal;
    }

    const renderDimensionCell = (e:any) => {
        const val = e.value;
        return val.replace(/([A-Z])/g, " $1");
    }

    const renderAnalysisMaterialCell = (e:any) => {
        const mat = e.value;

        if (mat == null)
            return '';

        const prefix = GetMaterialTypeName(mat);
        const randIdNumber = Math.floor(Math.random() * 99999);
        const id = `materials_${randIdNumber}`;
        return (
            <div style={{color: GetMaterialColor(mat), textOverflow: 'ellipsis', overflowX: 'hidden'}} id={id}>
                {prefix != null ? `${prefix}: ${mat.name}` : mat.name}
                <Popover
                    target={`#${id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave">
                    <div style={{color: GetMaterialColor(mat)}}>
                        {prefix != null ? `${prefix}: ${mat.name}` : mat.name}
                    </div>
                </Popover>
            </div>
        );
    }

    const renderSizingMaterialsCell = (e:any) => {
        const mats = e.value;

        if (mats.length == 0)
            return '';

        const typeName = GetMaterialCandidateTypeName(mats[0]);
        const typeColor = GetMaterialColor((mats[0]));
        const text = `{${mats.length} ${typeName} candidate${mats.length > 1 ? 's' : ''}}`;
        const randIdNumber = Math.floor(Math.random() * 99999);
        const id = `materials_${randIdNumber}`;
        return (
            <div style={{color: typeColor, fontStyle: 'italic'}} id={id}>
                {text}
                <Popover
                    target={`#${id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave">
                    {mats.map((mat:Material, index:number) => {
                        const prefix = GetMaterialTypeName(mat);
                        return (
                            <div style={{color: GetMaterialColor(mat), textOverflow: 'ellipsis'}} key={`mat${index}`}>
                                {prefix != null ? `${prefix}: ${mat.name}` : mat.name}
                            </div>
                        );
                    })}
                </Popover>
            </div>
        );
    }

    const renderColumnHeader = (e:any) => {
        const text = e.column.caption;
        return (
            <div style={{color: 'black'}}>{text}</div>
        );
    }

    const renderPopupContent = () => {
        // No need to render anything if there is no design selected.
        if (props.design == null)
            return <div/>;

        const sortedDesignVars = props.design.designVariables.sort((a, b) => (a.dimension > b.dimension) ? 1 : ((b.dimension > a.dimension) ? -1 : 0));

        let zoneOrElement = null;
        let rapidOrDetailed = null;
        switch (props.design.analysisMode) {
            case AnalysisMode.ElementOptimization:
                zoneOrElement = 'Element';
                break;
            case AnalysisMode.RapidSizing:
                zoneOrElement = 'Zone';
                rapidOrDetailed = 'Rapid Sizing';
                break;
            case AnalysisMode.DetailedSizing:
                zoneOrElement = 'Zone';
                rapidOrDetailed = 'Detailed Sizing';
                break;
        }
        const isDetailedSizing = props.design.analysisMode == AnalysisMode.DetailedSizing;

        return (
            <div className="designPropertyPopup inputPropertyPopup">
                <div className="designPropertyAssignZonesDiv">
                    <h4>{pluralize(props.design.zoneIds.length, 'Zone')} Assigned:</h4>
                    <TextBox 
                        text={props.design.zoneIds.join(', ')} 
                        readOnly={true}/>
                </div>
                <div className="designPropertyPopupMainContent">
                    <TabPanel height={"100%"} defaultSelectedItem={props.design.selectedAnalysisTab}>
                        <Item title={"Sizing"}>
                            <div className={'designPropertySizingTabContent'}>
                                <div className="designPropertyAnalysisModes">
                                    {zoneOrElement &&
                                    <SelectBox dataSource={[zoneOrElement]} value={zoneOrElement} readOnly={true}/>}
                                    {rapidOrDetailed &&
                                    <SelectBox dataSource={[rapidOrDetailed]} value={rapidOrDetailed} readOnly={true}/>}
                                </div>
                                <div className={'designPropertySizingTabTable'}>
                                    <DataGrid 
                                        dataSource={sortedDesignVars} 
                                        height={"100%"} 
                                        onCellPrepared={(e) => {
                                            const useAnalysis = e.data?.useAnalysis;
                                            const header = e.column?.dataField;
                                            if (useAnalysis && header != 'dimension' && header != 'useAnalysis')
                                                e.cellElement.classList.add("designPropertyDisabledCell");
                                        }}>
                                        <Column
                                            dataField="dimension"
                                            headerCellRender={renderColumnHeader}
                                            dataType="string"
                                            cellRender={renderDimensionCell}/>
                                        <Column
                                            dataField="useAnalysis"
                                            headerCellRender={renderColumnHeader}
                                            visible={isDetailedSizing}
                                            width={'8rem'}
                                            cellRender={(e) => <CheckBox value={e.value} readOnly={true}/>}/>
                                        <Column
                                            dataField="minBound"
                                            caption={'Min'}
                                            headerCellRender={renderColumnHeader}
                                            visible={isDetailedSizing}
                                            dataType="number"
                                            cellRender={renderCellSigFigs}/>
                                        <Column
                                            dataField="maxBound"
                                            caption={'Max'}
                                            headerCellRender={renderColumnHeader}
                                            visible={isDetailedSizing}
                                            dataType="number"
                                            cellRender={renderCellSigFigs}/>
                                        <Column
                                            dataField="steps"
                                            headerCellRender={renderColumnHeader}
                                            visible={isDetailedSizing}
                                            dataType="number"/>
                                        <Column
                                            dataField="stepSize"
                                            headerCellRender={renderColumnHeader}
                                            visible={isDetailedSizing}
                                            dataType="number"
                                            cellRender={renderCellSigFigs}/>
                                        <Column
                                            dataField="sizingMaterials"
                                            caption={'Material'}
                                            width={'13rem'}
                                            headerCellRender={renderColumnHeader}
                                            dataType="string"
                                            cellRender={renderSizingMaterialsCell}/>
                                    </DataGrid>
                                </div>
                            </div>
                        </Item>
                        <Item title={"Analysis"}>
                            <DataGrid dataSource={sortedDesignVars} height={"100%"}>
                                <Column
                                    dataField="dimension"
                                    headerCellRender={renderColumnHeader}
                                    dataType="string"
                                    cellRender={renderDimensionCell}/>
                                <Column
                                    dataField="analysisValue"
                                    headerCellRender={renderColumnHeader}
                                    caption={"Value"}
                                    dataType="number"
                                    cellRender={renderCellSigFigs}/>
                                <Column
                                    dataField="analysisMaterial"
                                    caption={'Material'}
                                    headerCellRender={renderColumnHeader}
                                    dataType="string"
                                    cellRender={renderAnalysisMaterialCell}/>
                            </DataGrid>
                        </Item>
                    </TabPanel>
                </div>
                <div className="designPropertyPopupFooter">
                    <Button 
                        text="Overrides"
                        onClick={() => setShowOverrides(true)}/>
                    {props.design.desiredWorkableCombinations != null &&
                    <div className="designPropertyPopupRequestedDesigns">
                        <h4>Req. Designs:</h4>
                        <NumberBox 
                            value={props.design.desiredWorkableCombinations} 
                            readOnly={true} 
                            width={'4rem'}/>
                    </div>}
                </div>
                <SizingOverridesPopup
                    showOverrides={showOverrides}
                    design={props.design}
                    onHiding={() => setShowOverrides(false)}/>
            </div>
        );
    } 

    return (
        <Popup 
            visible={props.design != null}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={props.design?.name}
            width={'56rem'}
            height={'36rem'}>
            {renderPopupContent()}
        </Popup>
    );
}