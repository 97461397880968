import { DataSourceColumn } from "../Classes/DataSourceColumn";
import { AggregationType } from "../Classes/Enums/AggregationType";
import { naturalCompare } from "./CommonUtilities";

const panelUnitWeightPK = 'aggregate.panel_unit_weight';
const beamUnitWeightPK = 'aggregate.beam_unit_weight';
const panelWeightPK = 'component.panel_weight';
const beamWeightPK = 'component.beam_weight';
const panelAreaPK = 'component.panel_area';
const beamLengthPK = 'component.beam_length';
const marginPK = 'hs_component_solution.minimum_margin';
const graphSetIndexPK = "cloud_graph_set_upload_set.index_in_graph_set";

const keySeperator = '_&_';

export const getDataSourceTable = (zoneResults:any[], columns:DataSourceColumn[], categoryColumnIds:number[], filtersByColId:{[key:number]: string[]}) => {
    // Convert filtered out values to unfiltered values by looking at all zone values.
    const unfilteredSetByColId = new Map<number, Set<string>>();
    columns.forEach(col => {
        // Ignore columns without any filters
        if (col.id in filtersByColId) {
            const filteredColSet = new Set<string>(filtersByColId[col.id]);
            const unfilteredColSet = new Set<string>();

            zoneResults.forEach((zoneRes) => {
                const val = zoneRes[col.propertyKey] ?? 'No value';
                if (Array.isArray(val)) {
                    val.forEach(subVal => {
                        if (!filteredColSet.has(subVal)) {
                            unfilteredColSet.add(subVal);
                        }
                    });
                }
                else if (!filteredColSet.has(val)) {
                    unfilteredColSet.add(val);
                }
            });

            unfilteredSetByColId.set(col.id, unfilteredColSet);
        }
    });

    const filteredZoneResults:any[] = [];
    zoneResults.forEach(zoneRes => {
        let zonePassesFilters = true;
        if (filtersByColId != null) {
            zonePassesFilters = columns.every(col => {
                const unfilteredVals = unfilteredSetByColId.get(col.id);
                const resultsForThisZone = zoneRes[col.propertyKey];

                let columnPassesFilters = false;

                // Multi val
                if (Array.isArray(resultsForThisZone)) {
                    columnPassesFilters = unfilteredVals == null || resultsForThisZone.find((subVal) => unfilteredVals.has(subVal)) != null;
                }
                // Single val
                else {
                    columnPassesFilters = unfilteredVals == null || unfilteredVals.has(resultsForThisZone ?? 'No value');
                }
                
                return columnPassesFilters;
            });
        }
        if (zonePassesFilters) {
            filteredZoneResults.push({...zoneRes});
        }
    });

    // Try to convert string values to numerical values if possible.
    columns.forEach(col => {
        const colResults = filteredZoneResults.map(i => i[col.propertyKey]);

        const nonNullVals = colResults.filter(i => i != null);
        const allValsNumeric = nonNullVals.length > 0 && nonNullVals.every(i => !isNaN(Number(i)));

        if (allValsNumeric) {
            filteredZoneResults.forEach(result => {
                const resultVal = result[col.propertyKey];
                if (resultVal != null) {
                    result[col.propertyKey] = Number(resultVal);
                }
            });
        }
    });

    // Group the results by category
    const categoryPropKeys:string[] = [];
    categoryColumnIds.forEach(catId => {
        const catCol = columns.find(i => i.id === catId);
        if (catCol != null) {
            categoryPropKeys.push(catCol.propertyKey);
        }
    });

    const groupedResults = new Map<string, any[]>(); 
    filteredZoneResults.forEach((zoneResult, resultIndex) => {
        const catKeys = getCategoryKeys(zoneResult, categoryPropKeys, resultIndex);
        catKeys.forEach(catKey => {
            const existingGroup = groupedResults.get(catKey);
            if (existingGroup != null) {
                existingGroup.push(zoneResult);
            }
            else {
                groupedResults.set(catKey, [zoneResult]);  
            } 
        });
    });

    // Aggregate the categorized results
    const categoryColumnIdSet = new Set<number>(categoryColumnIds);
    const aggregatedResults:any[] = [];
    groupedResults.forEach((groupedResultList, groupedResultKey) => {
        const aggregateResult:any = {};
        const groupIsUnfiltered = columns.every(column => {     
            let keySection = undefined;
            const catColIndex = categoryColumnIds.findIndex(catColId => column.id === catColId);
            if (catColIndex >= 0) {
                const keySections = groupedResultKey.split(keySeperator);
                keySection = keySections[catColIndex];
            }

            const currUnfilteredSet = unfilteredSetByColId.get(column.id);

            let aggVal = getAggregateValue(column, groupedResultList, keySection, currUnfilteredSet);
            if (aggVal == null) {
                aggVal = 'No value';
            }
            else if (!isNaN(aggVal)) {
                // Try to clear out any rounding weirdness introduced by the math.
                aggVal = toFixedNumber(aggVal, 10);
            }

            if (categoryColumnIdSet.has(column.id) && currUnfilteredSet != null && !currUnfilteredSet.has(aggVal)) {
                // Filter this group out
                return false;
            }

            aggregateResult[column.id] = aggVal;
            return true;
        });
        if (groupIsUnfiltered) {
            // Add the graph set index as metadata
            const graphSetIndexes = groupedResultList.map(i => i[graphSetIndexPK]);
            const avgIndex = calcAvg(graphSetIndexes);
            aggregateResult.graphSetIndex = avgIndex;

            aggregatedResults.push(aggregateResult);
        }
    });

    return aggregatedResults;
}

const getCategoryKeys = (zoneResult:any, categoryPropKeys:string[], resultIndex:number) => {
    const getKeysStartingAtCategory = (zoneResult:any, categoryPropKeys:string[], currCategoryKey:string, currCategoryIndex:number): string[] => {
        const nextCatIndex = currCategoryIndex + 1;

        // If we've hit the end of the category list, return what we've got 
        if (currCategoryIndex >= categoryPropKeys.length) {
            if (currCategoryKey !== '')
                return [currCategoryKey];
            else 
                return [];
        }

        const currCatPropKey = categoryPropKeys[currCategoryIndex];
        const currCatValOrVals = zoneResult[currCatPropKey];
        if (Array.isArray(currCatValOrVals)) {
            const catKeys:string[] = [];
            currCatValOrVals.forEach(subVal => {
                let nextCatKey = currCategoryKey + subVal; 
                if (currCategoryIndex !== categoryPropKeys.length - 1) {
                    nextCatKey += keySeperator;
                }
                const currCatKeys = getKeysStartingAtCategory(zoneResult, categoryPropKeys, nextCatKey, nextCatIndex);
                currCatKeys.forEach(currCatKey => catKeys.push(currCatKey)); 
            });
            return catKeys;
        }
        else {
            let nextCatKey = currCategoryKey + currCatValOrVals;
            if (currCategoryIndex !== categoryPropKeys.length - 1) {
                nextCatKey += keySeperator;
            } 
            return getKeysStartingAtCategory(zoneResult, categoryPropKeys, nextCatKey, nextCatIndex);
        }
    }

    const catKeys = getKeysStartingAtCategory(zoneResult, categoryPropKeys, '', 0);

    // If no categories are selected, just make one "group" for each result.
    if (categoryPropKeys.length === 0) {
        catKeys.push(resultIndex.toString());
    }

    return catKeys;
};

const getAggregateValue = (column:DataSourceColumn, groupedResultList:any[], keySection:string|undefined, currUnfilteredSet?:Set<string>): any => {
    const columnValueList = groupedResultList.filter(result => column.propertyKey in result).map(i => i[column.propertyKey]);

    const flattenEnumerableValues = (listOfValues:any[]) => {
        const flattenedList:string[] = [];
    
        listOfValues.forEach(valueOrValues => {
            if (Array.isArray(valueOrValues)) {
                valueOrValues.forEach(subVal => {
                    if (keySection == null || keySection === subVal.toString()) {
                        flattenedList.push(subVal);
                    }
                });
            }
            else {
                flattenedList.push(valueOrValues);
            }
        });

        return flattenedList;
    }

    const flattenedList = flattenEnumerableValues(columnValueList);
    const filteredList = flattenedList.filter(val => currUnfilteredSet == null || currUnfilteredSet.has(val));

    switch (column.aggregationType) {
        case AggregationType.Total: {
            return calcSum(filteredList);
        }
        case AggregationType.Average: {
            return calcAvg(filteredList);
        }
        case AggregationType.CSV: {
            return calcCSV(filteredList);
        }
        case AggregationType.Aggregate: {
            switch (column.propertyKey) {
                case panelUnitWeightPK:
                    return calcPanelUnitWeight(groupedResultList);
                case beamUnitWeightPK:
                    return calcBeamUnitWeight(groupedResultList);
                case marginPK:
                    return calcMinMargin(groupedResultList);
            }
            return null;
        }
        case AggregationType.Count: {
            return calcCount(filteredList);
        }
        case AggregationType.First:
        default: {
            // Just grab the first value we can find.
            return filteredList.find(() => true);
        }
    }
}

const calcSum = (vals:any[]) => {
    var total = 0;
    vals.forEach(i => total += i);
    return total;
}

const calcAvg = (vals:any[]) => {
    const sum = calcSum(vals);
    return sum / vals.length;
}

const calcCSV = (vals:any[]) => {
    if (vals.length === 0) {
        return null;
    }

    // Reduce the list to only include unique values
    const uniqueValList = Array.from((new Set(vals)));

    // If there is only one value, don't convert it to a string.
    if (uniqueValList.length === 1) {
        return uniqueValList[0];
    }

    const sortedVals = uniqueValList.sort(naturalCompare);
    return sortedVals.join(", ");
}

const calcCount = (vals:any[]) => {
    // Reduce the list to only include unique values
    const uniqueValList = Array.from((new Set(vals)));
    return uniqueValList.length;
}

const calcPanelUnitWeight = (groupedResultList:any[]) => {
    let sumWeight = 0;
    let sumArea = 0;

    groupedResultList.forEach(result => {
        if (panelWeightPK in result) {
            sumWeight += result[panelWeightPK];
        }
        if (panelAreaPK in result) {
            sumArea += result[panelAreaPK];
        }
    });

    if (sumArea === 0)
        return null;

    return sumWeight / sumArea;
}

const calcBeamUnitWeight = (groupedResultList:any[]) => {
    let sumWeight = 0;
    let sumArea = 0;

    groupedResultList.forEach(result => {
        if (beamWeightPK in result) {
            sumWeight += result[beamWeightPK];
        }
        if (beamLengthPK in result) {
            sumArea += result[beamLengthPK];
        }
    });

    if (sumArea === 0)
        return null;

    return sumWeight / sumArea;
}

const calcMinMargin = (groupedResultList:any[]) => {
    let minMargin:number|null = null;

    groupedResultList.forEach(result => {
        if (marginPK in result) {
            const currMargin = result[marginPK];
            if (minMargin == null || currMargin < minMargin) {
                minMargin = currMargin;
            }
        }
    });

    return minMargin;
}

function toFixedNumber(num:number, digits:number){
    const pow = Math.pow(10, digits);
    return Math.round(num*pow) / pow;
}