import { AggregationType } from "./Enums/AggregationType";

// This class stores information about how to gather a list (or "column" in a tabular sense) of values from the uploaded data.
export class DataSourceColumn {
    // The id of this column
    public id: number;

    // A property key containing the database table and column name to select.
    public propertyKey: string;
  
    // The type of the aggregation function to be used when multiple values are found.
    public aggregationType: AggregationType;

    public customName: string|undefined;
  
    // Constructs a DataSourceColumn given a property key and an aggregation function.
    constructor(id:number, propertyKey: string, aggregationType: AggregationType) {
        this.propertyKey = propertyKey;
        this.aggregationType = aggregationType;
        this.id = id;
    }
}
  