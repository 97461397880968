import './ColumnBoxDroppable.css';

import { DataSourceColumn } from '../../../../../Classes/DataSourceColumn';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import ColumnBox from './ColumnBox';
import GraphSettingsRemoveButton from '../GraphSettingsRemoveButton/GraphSettingsRemoveButton';
import { useEffect, useRef, useState } from 'react';
import {
    dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

type ColumnBoxProps = {
    dataSourceColumn: DataSourceColumn|null;
    setDataSourceColumn: (value:DataSourceColumn) => void;
    nullable: boolean;
    removeColumn: () => void;
    readOnly: boolean;
    numericColumnIds?: Set<number>;
}

const defaultProps = {
    removeColumn: () => {},
    nullable: false,
    readOnly: false
}

export function ColumnBoxDroppable({ dataSourceColumn, setDataSourceColumn, removeColumn, nullable, readOnly, numericColumnIds }: ColumnBoxProps) {
    const [isDragHovered, setIsDragHovered] = useState(false);
    
    const dropRef = useRef<any>(null);

    useEffect(() => {
        const dndElement = dropRef.current;

        return dropTargetForElements({
                element: dndElement,
                getData: () => ({ dataSourceColumn: dataSourceColumn }),
                onDragEnter: () => {
                    setIsDragHovered(true);
                },
                onDragLeave: () => {
                    setIsDragHovered(false);
                },
                onDrop: ({ source }) => {
                    setIsDragHovered(false);

                    const dsCol = source.data.dataSourceColumn as DataSourceColumn;
                    setDataSourceColumn(dsCol);
                },
                canDrop: ({ source }) => {
                    return source.data.type === CustomChartDragTypes.DATA_SOURCE_COLUMN;
                }
            })
    }, [dataSourceColumn, setDataSourceColumn]);

    if (readOnly) {
        return (
            <div className='columnBoxDroppable'>
                {dataSourceColumn == null && <div>no type assigned</div>}
                {dataSourceColumn != null && <ColumnBox dataSourceColumn={dataSourceColumn} readOnly={true} numericColumnIds={numericColumnIds}/>}
            </div>
        );
    }

    return (
        <div
            ref={dropRef}
            className={`columnBoxDroppable ${isDragHovered ? 'columnBoxDroppableIsOver' : ''}`}>
            {dataSourceColumn == null && <div>drop type here</div>}
            {dataSourceColumn != null && <ColumnBox dataSourceColumn={dataSourceColumn} numericColumnIds={numericColumnIds}/>}
            {nullable && dataSourceColumn != null && (
                <GraphSettingsRemoveButton onClick={removeColumn}/>
            )}
        </div>
    );
}
ColumnBoxDroppable.defaultProps = defaultProps;
export default ColumnBoxDroppable;