import './ColumnFilter.css';

import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { DataSourceColumn } from '../../../../../../Classes/DataSourceColumn';
import useKeycloak from '../../../../../../Keycloak';
import { RootState } from '../../../../../../Stores/GlobalStore';
import { useSelector } from 'react-redux';
import { List } from 'devextreme-react';
import { useEffect, useMemo, useState } from 'react';
import { SetDbColumnFilters } from '../../../../../../API/CustomChartAPI';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { naturalCompare } from '../../../../../../Utilities/CommonUtilities';

type ColumnFilterProps = {
    graphSettings:CustomChartSettings;
    setGraphSettings: (newVal:CustomChartSettings) => void;
    columnToFilter:DataSourceColumn;
}

export function ColumnFilter({graphSettings, setGraphSettings, columnToFilter}: ColumnFilterProps) {
    const { token } = useKeycloak();

    const selectedGraphId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 
    const cachedZoneResults = useSelector((state:RootState) => state.results.cachedZoneResults);
    const selectedZoneResults = selectedGraphId ? cachedZoneResults[selectedGraphId] : null;

    const stringFiltersByColumnId = graphSettings.stringFiltersByColumnId ?? {};

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const colZoneResList = useMemo(() => {
        const colZoneRes = new Set<string>();
        selectedZoneResults?.forEach(i => {
            const val = i[columnToFilter.propertyKey];
            if (val != null && val !== '') {
                if (Array.isArray(val)) {
                    val.forEach(subVal => colZoneRes.add(subVal));
                }
                else {
                    colZoneRes.add(val);
                }
            }
            else {
                colZoneRes.add('No value');
            }
        });

        return Array.from(colZoneRes.values()).sort(naturalCompare);
    }, [columnToFilter.propertyKey, selectedZoneResults]);

    useEffect(() => {
        const filteredOutItems = stringFiltersByColumnId[columnToFilter.id];
        const newSelectedItems = colZoneResList.filter(i => !filteredOutItems?.includes(i));
        setSelectedItems(newSelectedItems);
    }, [columnToFilter]);

    if (colZoneResList == null || colZoneResList.length === 0) {
        return (
            <div className='columnFilterPopup'>
                No values to show.
            </div>
        );
    }

    return (
        <div className='columnFilterPopup'>
            <List
                dataSource={colZoneResList}
                showSelectionControls={true}
                selectionMode='all'
                useNativeScrolling={true}
                height={'100%'}
                selectedItems={selectedItems}
                searchEnabled={true}
                pageLoadMode='scrollBottom'
                selectAllMode='allPages'
                onSelectedItemsChange={(newItems) => {
                    setSelectedItems(newItems);

                    const valsToFilterOut = colZoneResList.filter(i => !newItems.includes(i));

                    // Change the DB
                    SetDbColumnFilters(token, columnToFilter.id, valsToFilterOut).then(response => {
                        if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                            // Change the UI
                            const newFilterDict = {...stringFiltersByColumnId};
                            newFilterDict[columnToFilter.id] = valsToFilterOut;

                            setGraphSettings({...graphSettings, stringFiltersByColumnId: newFilterDict});
                        }
                    });
                }}/>
        </div>
    );
}
export default ColumnFilter;