import { Popup, RadioGroup } from 'devextreme-react';
import React, { useEffect } from 'react';
import { getRoleName, GroupRoleType } from '../../Classes/UserGroups/GroupRole';
import { ProgramUser } from '../../Classes/UserGroups/ProgramUser';
import { useDispatch } from 'react-redux';
import { assignUserRole } from '../../Reducers/ProgramDataReducer';
import { Program } from '../../Classes/UserGroups/Program';
import useKeycloak from '../../Keycloak';

interface EditProgramUserPopupProps {
    showPopup:boolean
    program:Program|undefined;
    programUser:ProgramUser|undefined;
    onHiding:() => void;
}

type RoleTypeObject = { 
    type: GroupRoleType;
    text: string;
    description: string;
}

export function EditProgramUserPopup(props:EditProgramUserPopupProps) {

    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const roles : RoleTypeObject[] = [
        {
            type: GroupRoleType.Administrator,
            text: getRoleName(GroupRoleType.Administrator),
            description: 'Can do anything a maintainer can. Can also change the program name and delete the program.'
        },
        {
            type: GroupRoleType.Maintainer,
            text: getRoleName(GroupRoleType.Maintainer),
            description: 'Can do anything a member can, and can add or remove users from the program.'
        },
        {
            type: GroupRoleType.Member,
            text: getRoleName(GroupRoleType.Member),
            description: 'Can view who else is in the program.'
        }
    ];

    const [selectedRole, setSelectedRole] = React.useState<GroupRoleType|undefined>();

    useEffect(() => {
        setSelectedRole(roles.find(r => r.type === props.programUser?.role)?.type);
    }, [props.programUser?.role]);

    return (
        <Popup 
            title='Assign User Role'
            width='auto'
            height='auto'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
                <div style={{padding: '0.5rem'}}>
                    <RadioGroup
                        dataSource={roles}
                        value={roles.find(r => r.type === selectedRole)}
                        onValueChange={(roleObject:RoleTypeObject) => {
                            let role = roleObject.type;
                            let token = keycloak.token;

                            dispatch(assignUserRole({
                                authToken: token, 
                                programId: props.program?.id, 
                                userId: props.programUser?.id, 
                                role: role}));

                            setSelectedRole(roleObject.type);
                        }}
                        itemRender={(roleObject:RoleTypeObject) => 
                        <div>
                            <h5 style={{margin: '0vh'}}>
                                {roleObject.text}
                            </h5>
                            <p style={{margin: '0.5vh'}}>
                                {roleObject.description}
                            </p>
                        </div>}/>
                </div>
        </Popup>
    );
}