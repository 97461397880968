import './AuditPopup.css';

import { DataGrid, Popup } from 'devextreme-react';
import { Company } from '../../Classes/UserGroups/Company';
import { useEffect, useState } from 'react';
import { ToolbarItem } from 'devextreme-react/popup';
import { GetCompanyLogs } from '../../API/AuditLogAPI';
import { AuditLog } from '../../Classes/UserGroups/AuditLog';
import { Column, Selection, Export, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import { exportDataGridToExcel } from '../../Utilities/CommonUtilities';

interface AuditPopupProps {
    showPopup:boolean
    authToken:string|undefined
    company:Company|undefined;
    onHiding:() => void;
}

export function AuditPopup(props:AuditPopupProps) {
    const [logs, setLogs] = useState<AuditLog[]>(new Array<AuditLog>());

    useEffect(() => {
        if (props.showPopup && logs.length == 0)
            fetchLogs();
    }, [props.authToken, props.company, props.showPopup]);

    const fetchLogs = async () => {
        if (props.authToken && props.company) {
            const newLogs = await GetCompanyLogs(props.authToken, props.company.id);
            setLogs(newLogs);
        }
    }

    return (
        <Popup 
            title={`Audit Company Logs - ${props.company?.name}`}
            wrapperAttr={{class: 'auditPopupWrapper'}}
            width='60vw'
            height='80vh'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
                <div style={{height:'105%'}}>
                    <DataGrid 
                        height={"100%"}
                        dataSource={logs} 
                        allowColumnResizing={true}
                        onExporting={(e) => {
                            const dataGridInstance = e.component;
                            exportDataGridToExcel(dataGridInstance, props.company?.name, 'HyperX Dashboard Company Audit Logs.xlsx');
                        }}>
                        <Scrolling useNative={true}/>
                        <Selection mode='multiple'/>
                        <Export 
                            enabled={true} 
                            allowExportSelectedData={true}/>
                        <SearchPanel visible={true}/>
                        <Column 
                            dataField="date"
                            caption="Date"
                            dataType={'datetime'}
                            width={'10rem'}/>
                        <Column 
                            dataField="userName"
                            caption="User Name"
                            width={'12rem'}/>
                        <Column 
                            dataField="requestType"
                            caption="Request Type"
                            width={'8rem'}>
                        </Column>
                        <Column 
                            dataField="eventUrl"
                            caption="Event URL"/>
                    </DataGrid>
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        icon: 'refresh',
                        text: 'Refresh Logs',
                        onClick: () => {
                            fetchLogs();
                        }
                    }}/>
        </Popup>
    );
}