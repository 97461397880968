import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { User } from '../Classes/User';

export async function GetUser(authToken:string): Promise<User> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("User", "GetUser"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function UpdateUser(authToken:string, user:User): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(user);

    let url = ApiFetchUtility.getFormattedUrl("User", "PutUser"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}