import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { GraphTemplate } from '../Classes/Charts/GraphTemplates';
import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { GraphInfo } from '../Classes/Charts/GraphInfo';

export async function GetDbCustomGraphTemplates(authToken: string): Promise<APIResponseWithStatus<GraphTemplate[]>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `GetCustomTemplates`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetDbDefaultGraphTemplates(authToken: string): Promise<GraphTemplate[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `GetDefaultTemplates`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function CreateGraphTemplate(authToken: string, customChartId: number, name: string, description: string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify({
        chartId: customChartId,
        name: name,
        description: description
    });

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `Create`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function CopyToGraph(authToken: string, templateId: number, parentFolderId: number|null, 
    customName:string, graphSetIds:number[]): Promise<APIResponseWithStatus<GraphInfo>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify({
        templateId: templateId,
        customName: customName,
        graphSetIds: graphSetIds,
        parentFolderId: parentFolderId
    });

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `CopyToGraph`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function IsNameDuplicate(authToken: string, name: string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `IsNameDuplicate`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteGraphTemplate(authToken: string, graphTemplateId: number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `Delete?graphTemplateId=${graphTemplateId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetTemplateRelevanceById(authToken: string, graphSetIds: number[]): Promise<APIResponseWithStatus<any>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(graphSetIds);

    const url = ApiFetchUtility.getFormattedUrl("GraphTemplate", `GetTemplateRelevanceById`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}