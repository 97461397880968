import { GroupUser } from './GroupUser';

export enum GroupAction {
    Leave,
    Tags,
    Rename,
    Delete,
    Audit
}

export interface Group {
    id: number;
    name: string;
    users: GroupUser[];
    uploadSetCount: number;
    uploadSetUserCount: number;
}