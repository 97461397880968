import { Button } from "devextreme-react"
import { GraphSetDragTypes } from "../../../../Classes/GraphSetDragTypes"
import { GraphSetFolder } from "../../../../Classes/GraphSetFolder"
import { useEffect, useRef, useState } from "react"
import {
    dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

type FolderNavigationButtonProps = {
    folder: GraphSetFolder
    setCurrentFolder: (newFolder:GraphSetFolder) => void
}

export function FolderNavigationButton(props: FolderNavigationButtonProps) {
    const [isDragHovered, setIsDragHovered] = useState(false);

    const dropRef = useRef<any>(null);

    useEffect(() => {
        const dndElement = dropRef.current;

        return dropTargetForElements({
                element: dndElement,
                getData: () => ({ folder: props.folder }),
                onDragEnter: () => {
                    setIsDragHovered(true);
                },
                onDragLeave: () => {
                    setIsDragHovered(false);
                },
                onDrop: () => {
                    setIsDragHovered(false);
                },
                canDrop: ({ source }) => {
                    return source.data.type === GraphSetDragTypes.FOLDER || source.data.type === GraphSetDragTypes.GRAPH_SET;
                }
            })
    }, [props.folder]);

    const draggingOverClass = isDragHovered ? 'canDropInto' : '';
    return (
        <div ref={dropRef}> 
            <Button 
                className={`breadCrumbButton ${draggingOverClass}`}
                text={props.folder.name}
                onClick={() => {
                    props.setCurrentFolder(props.folder)
                }}/>
        </div>
    )
}
export default FolderNavigationButton