import { useEffect } from "react";
import useKeycloak from "../../Keycloak";

export function SignInRedirectPage() {
    const { login } = useKeycloak();

    useEffect(() => {
        // Just try to log the user in whenever this page is loaded.
        login();
    }, []);

    return <div/>;
}
export default SignInRedirectPage;