import { useEffect, useState } from "react";
import useKeycloak from "../../Keycloak";
import { GetGeneralCompanyUsageData } from "../../API/CollierAdminAPI";
import { useNavigate } from "react-router-dom";
import { Chart } from "devextreme-react";
import { Label, Title } from "devextreme-react/bar-gauge";
import { ArgumentAxis, Series, TickInterval, Tooltip, ValueAxis, ZoomAndPan } from "devextreme-react/chart";
import { UsageDataPoint } from "../../Classes/CollierAdmin/UsageDataPoint";

export default function UsageMonitor() {
    const { token, loading } = useKeycloak();
    const navigate = useNavigate();

    const [usageData, setUsageData] = useState<UsageDataPoint[]|null>(null);
    const [companyNames, setCompanyNames] = useState<string[]|null>(null);

    useEffect(() => {
        // Don't do anything if still loading auth
        if (loading)
            return;

        if (token) {
            GetGeneralCompanyUsageData(token).then((resp) => {
                if (resp == null) {
                    // Redirect to the root if the user cannot access this page.
                    navigate("/");
                }
                else {
                    // Convert response to something plottable
                    const dates = Array.from((new Set(resp.map(i => i.date))));
                    const newCompanyNames = Array.from((new Set(resp.map(i => i.companyName))));

                    setCompanyNames(newCompanyNames);

                    const plottableData = new Array<any>();
                    dates.forEach(d => {
                        let newDataItem = {} as any;

                        const dateObj = new Date(d);
                        // Clear the time as it does not matter.
                        dateObj.setHours(0);
                        dateObj.setMinutes(0);
                        dateObj.setSeconds(0);
                        dateObj.setMilliseconds(0);

                        newDataItem.date = dateObj;

                        resp?.forEach(usageDataPoint => {
                            if (usageDataPoint.date === d) {
                                newDataItem[usageDataPoint.companyName] = usageDataPoint.userCount;
                            }
                        });

                        plottableData.push(newDataItem);
                    });

                    setUsageData(plottableData);
                }
            });
        }
        else {
            // This means the auth package is not loading and the token is still null. 
            // In this case, just redirect to the main page.
            navigate("/");
        }
    }, [token, loading]);

    const legendClickHandler = (e:any) => {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }

    // Don't render anything while data is loading
    if (usageData == null)
        return <div/>;

    return (
        <div style={{padding: '5rem'}}>
            <Chart 
                dataSource={usageData} 
                height={'auto'}
                onLegendClick={legendClickHandler}>
                <Title text="Active User Count by Company" subtitle={"Last 30 Days"}/>
                <ZoomAndPan
                    dragToZoom={true}
                    panKey={'ctrl'}
                    argumentAxis="both"
                    valueAxis="both"/>
                <Tooltip 
                    enabled={true}
                    contentTemplate={(info) => {
                        const date = info.argument as Date;
                        return date.toLocaleDateString();
                    }}/>
                <ArgumentAxis argumentType="datetime">
                    <Label format={'shortDate'}/>
                    <TickInterval days={1}/>
                </ArgumentAxis>
                <ValueAxis valueType="numeric" tickInterval={1}/>
                {companyNames?.map(seriesName => (
                    <Series 
                        key={seriesName} 
                        name={seriesName} 
                        argumentField="date"
                        valueField={seriesName}
                        type="bar"/>
                ))}
            </Chart>
        </div>
    );
}