
export enum SharedGraphRoleType {
    None = 0,
    ReadOnly = 100,
    Collaborator = 200,
    Maintainer = 300,
    Admin = 400
}

export function getRoleName(role:SharedGraphRoleType) {
    switch (role) {
        case SharedGraphRoleType.Admin:
            return "Admin";
        case SharedGraphRoleType.Maintainer:
            return "Maintainer";
        case SharedGraphRoleType.Collaborator:
            return "Collaborator";
        case SharedGraphRoleType.ReadOnly:
            return "Read Only";
        default:
            return "Unknown";
    }
}

export function getRoleNamePlural(role:SharedGraphRoleType) {
    switch (role) {
        case SharedGraphRoleType.Admin:
            return "Admins";
        case SharedGraphRoleType.Maintainer:
            return "Maintainers";
        case SharedGraphRoleType.Collaborator:
            return "Collaborators";
        case SharedGraphRoleType.ReadOnly:
            return "Read Only";
        default:
            return "Unknown";
    }
}

export function getRoleDescription(role:SharedGraphRoleType) {
    switch (role) {
        case SharedGraphRoleType.Admin:
            return "This role allows users to do anything the owner of the graph can do, including deleting the graph.";
        case SharedGraphRoleType.Maintainer:
            return "This role allows users to manage graph sharing. They can also edit graph settings and notes.";
        case SharedGraphRoleType.Collaborator:
            return "This role allows users to edit graph settings and graph notes. Collaborators cannot share the graph with others.";
        case SharedGraphRoleType.ReadOnly:
            return "This role allows users to view all data associated with a graph. This role restricts edits of any kind.";
        default:
            return "This role is unknown.";
    }
}

export function canManageGraphSharing(role:SharedGraphRoleType) {
    return role >= SharedGraphRoleType.Maintainer
}

export function canEditGraphSettings(role:SharedGraphRoleType) {
    return role >= SharedGraphRoleType.Collaborator
}

export function canRenameGraph(role:SharedGraphRoleType) {
    return role >= SharedGraphRoleType.Admin
}

export function canDeleteGraph(role:SharedGraphRoleType) {
    return role >= SharedGraphRoleType.Admin
}