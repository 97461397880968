import { User } from './User'

export enum InviteCategory {
    Company = 0,
    Program = 1
}

export class Invite {
    id: Number;
    sender: User;
    recipient: User;
    inviteCategory: InviteCategory;
    companyId: Number;
    companyName: String;
    programId?: Number;
    programName?: String;

    constructor() {
        this.id = 0;
        this.sender = new User;
        this.recipient = new User;
        this.inviteCategory = InviteCategory.Company;
        this.companyId = 0;
        this.companyName = '';
    }
}