import "./FEALoadPropertyPopup.css";

import { CheckBox, NumberBox, Popup, RadioGroup, ScrollView, TextBox } from "devextreme-react";
import LoadProperty, { LoadPropertyAverageElementType, LoadPropertyPeakElementScope, LoadPropertyType, getLoadPropertyTypeName, isUserFEALoadProperty } from "../../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty";
import { useMemo } from "react";
import { FEALoadProperty } from "../../../../../Classes/UploadSetInputs/LoadProperty/FEALoadProperty";

interface FEALoadPropertyPopupProps {
    loadProperty:LoadProperty|null;
    onHiding: () => void;
}

export default function FEALoadPropertyPopup(props:FEALoadPropertyPopupProps) {
    const loadCheckBox = (loadProperty:LoadProperty, loadName:string) => {
        if (loadProperty == null)
            return;

        const val = (loadProperty as any)[loadName];
        if (val != null) {
            const displayName = loadName.replace('has','');
            return (
                <CheckBox 
                    text={displayName}
                    readOnly={true}
                    value={val}/>
            );
        }
    }

    const averageRadioButtonData = useMemo(() => {
        return [
            {
                text: 'Tension / Compression',
                value: LoadPropertyAverageElementType.TensionCompressionAverage
            },
            {
                text: 'Average',
                value: LoadPropertyAverageElementType.TrueAverage
            }
        ];
    }, []);

    const peakRadioButtonData = useMemo(() => {
        return [
            {
                text: 'Filter Design Loads (Faster)',
                value: LoadPropertyPeakElementScope.PeakDesignCase
            },
            {
                text: 'Run All Design Loads (Slower)',
                value: LoadPropertyPeakElementScope.AllDesignCases
            }
        ];
    }, []);

    const renderPopupContent = () => {
        // No need to render anything if there is no Analysis selected.
        if (props.loadProperty == null || !isUserFEALoadProperty(props.loadProperty.type))
            return <div/>;

        const feaLoadProp = props.loadProperty as FEALoadProperty;

        return (
            <div className="feaLoadPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.loadProperty.name}
                    readOnly={true}/>
                <div className='feaLoadPropertyTopOptions'>
                    {feaLoadProp.averageElementType != null &&
                    <RadioGroup 
                        dataSource={averageRadioButtonData} 
                        valueExpr={'value'}
                        value={feaLoadProp.averageElementType} 
                        readOnly={true}/>}
                    {feaLoadProp.peakElementScope != null &&
                    <RadioGroup 
                        dataSource={peakRadioButtonData} 
                        valueExpr={'value'}
                        value={feaLoadProp.peakElementScope}
                        readOnly={true}/>}
                    {feaLoadProp.nSigma != null &&
                    <div className='feaLoadPropertyNumberInput'>
                        <h4>Nσ</h4>
                        <NumberBox
                            value={feaLoadProp.nSigma}
                            placeholder="Nσ"
                            readOnly={true}/>
                    </div>}
                    {feaLoadProp.numNeighbors != null &&
                    <div className='feaLoadPropertyNumberInput'>
                        <h4>Number of Neighbors per Side</h4>
                        <NumberBox
                            value={feaLoadProp.numNeighbors}
                            placeholder="# Neighbors per side"
                            readOnly={true}/>
                    </div>}
                </div>
                <div className='feaLoadPropertyContent'>
                    <ScrollView height={'100%'}>
                        <h3>Shell Loads</h3>
                        <div className="feaLoadPropertyBox">
                            {loadCheckBox(props.loadProperty, 'hasNx')}
                            {loadCheckBox(props.loadProperty, 'hasNy')}
                            {loadCheckBox(props.loadProperty, 'hasNxy')}
                            {loadCheckBox(props.loadProperty, 'hasMx')}
                            {loadCheckBox(props.loadProperty, 'hasMy')}
                            {loadCheckBox(props.loadProperty, 'hasMxy')}
                            {loadCheckBox(props.loadProperty, 'hasQx')}
                            {loadCheckBox(props.loadProperty, 'hasQy')}
                        </div>
                        <h3>Beam Loads</h3>
                        <div className="feaLoadPropertyBox">
                            {loadCheckBox(props.loadProperty, 'hasM1a')}
                            {loadCheckBox(props.loadProperty, 'hasM1b')}
                            {loadCheckBox(props.loadProperty, 'hasM2a')}
                            {loadCheckBox(props.loadProperty, 'hasM2b')}
                            {loadCheckBox(props.loadProperty, 'hasV1')}
                            {loadCheckBox(props.loadProperty, 'hasV2')}
                            {loadCheckBox(props.loadProperty, 'hasAxial')}
                            {loadCheckBox(props.loadProperty, 'hasTorque')}
                        </div>
                        <h3>Connector Loads</h3>
                        <div className="feaLoadPropertyBox">
                            {loadCheckBox(props.loadProperty, 'hasTension')}
                            {loadCheckBox(props.loadProperty, 'hasShear')}
                            {loadCheckBox(props.loadProperty, 'hasMoment')}
                        </div>
                    </ScrollView>
                </div>
            </div>
        );
    } 

    return (
        <Popup 
            visible={props.loadProperty != null && isUserFEALoadProperty(props.loadProperty.type)}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={`Load Property - ${getLoadPropertyTypeName(props.loadProperty?.type ?? null)}`}
            width={'24rem'}
            height={'34rem'}>
            {renderPopupContent()}
        </Popup>
    );
}