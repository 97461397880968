import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { FilterConditions } from '../Classes/FilterConditions';

export async function GetFilters(authToken:string): Promise<FilterConditions> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("UploadSetFilter", "GetUploadSetFilter"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function UpdateFilters(authToken:string, filters:FilterConditions): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(filters);

    let url = ApiFetchUtility.getFormattedUrl("UploadSetFilter", "SetUploadSetFilters"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}