import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Stores/GlobalStore';

export interface CustomChartsState {
    selectedFolderId: number|null;
    selectedCustomChartId: number|null;
}

const initialState: CustomChartsState = {
    selectedFolderId: null,
    selectedCustomChartId: null
}

export const CustomChartsReducer = createSlice({
    name: 'customCharts',
    initialState,
    reducers: {
        setSelectedCustomChartId: (state, action) => {
            const newChartId = action.payload;
            // Only change the chart id if the new value is different from the current value
            if (newChartId !== state.selectedCustomChartId) {
                state.selectedCustomChartId = newChartId;
            }
        },
        setSelectedFolderId: (state, action) => {
            state.selectedFolderId = action.payload
        }
    },
    extraReducers: {
    }
}); 

export const customChartsSelector = (state: RootState) => state.customCharts;
export const { setSelectedCustomChartId, setSelectedFolderId } = CustomChartsReducer.actions;

export default CustomChartsReducer.reducer;