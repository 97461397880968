import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

export function IsNullOrUndefined(value: any): boolean {
    return (value === undefined || value === null);
}

export function IsNullOrEmpty(value: any): boolean {
    return (IsNullOrUndefined(value) || value === "");
}

export function intersection<T>(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export function not<T>(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export const naturalCompare = (x: any, y: any): number => {
    const regex = new RegExp(/([0-9]+(?:\.[0-9]+)?)/);
    const xParts: string[] = x?.toLocaleString().split(regex);
    const yParts: string[] = y?.toLocaleString().split(regex);

    if (xParts == null && yParts != null)
        return -1;
    if (xParts != null && yParts == null)
        return 1;
    if (xParts == null && yParts == null)
        return 0;
  
    // Iterate down string tokens
    for (let i = 0; i < xParts.length && i < yParts.length; i++) {
        let xNumeric = parseFloat(xParts[i]);
        let yNumeric = parseFloat(yParts[i]);
        if (!isNaN(xNumeric) && !isNaN(yNumeric)) {
            // Numeric compare
            if (xNumeric !== yNumeric) {
                return xNumeric < yNumeric ? -1 : 1;
            }
        } else {
            // Standard string comparison
            const comparison = xParts[i].localeCompare(yParts[i]);
            if (comparison !== 0) {
                return comparison;
            }
        }
    }
  
    // If no token mismatch, length compare
    return x.length - y.length;
}

export const pluralize = (count: number, noun: string, suffix = 's') => `${count.toLocaleString()} ${noun}${count !== 1 ? suffix : ''}`;

export function tryConvertFromUTCToDate(val:any, datePattern:string) {
    // If val is already a date, just return val.
    if (val instanceof Date)
        return val;

    const dateTimeObj = moment(val, datePattern, true);
    if (dateTimeObj.isValid()) {
        return dateTimeObj.utc(true).toDate();
    }
    return val;
}
export function useDebounce<T>(value: any, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [value, delay]);

    return debouncedValue;
}

export function useDebouncedFunc(func:(...args: any[]) => any, delay:number) {
    const debounceTimer = useRef<NodeJS.Timeout | undefined>();

    const debouncedFunc = (...args: any[]) => {
        clearTimeout(debounceTimer.current);

        debounceTimer.current = setTimeout(() => {
            func(...args);
        }, delay);
    }

    return debouncedFunc;
}

export function toSigFigs(value:number|null, sigFigs:number|undefined) {
    if (value != null && sigFigs != null) {
        // Use capital E instead of lower case e for scientific notation.
        return value.toPrecision(sigFigs).replace('e', 'E');
    }
    return value ?? '';
}

export function exportDataGridToExcel(dataGridComponent:any, title:string|undefined, fileName:string|undefined) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title ?? 'Main sheet');
    exportDataGrid({
        component: dataGridComponent,
        worksheet: worksheet
    }).then(function() {
        workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName ?? 'Downloaded File.xlsx');
        });
    });
}

export const simulateLinkClick = (link:string) => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', link);
    anchor.setAttribute('target', "_blank");
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}