import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { SharedGraphProgram } from '../Classes/UserGroups/SharedGraphProgram';
import { SharedGraphCompany } from '../Classes/UserGroups/SharedGraphCompany';
import { SharedGraphRoleType } from '../Classes/UserGroups/SharedGraphRole';
import { SharedGraphUser } from '../Classes/UserGroups/SharedGraphUser';

export async function GetSharedGraphCompanies(authToken:string, customChartId:number): Promise<Array<SharedGraphCompany>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `GetSharedGraphCompanies?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddSharedGraphCompanies(authToken:string, customChartId:number, sharedGraphCompanies:Array<SharedGraphCompany>): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(sharedGraphCompanies);

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `AddSharedGraphCompanies?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveSharedGraphCompany(authToken:string, customChartId:number, companyId:number): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `RemoveSharedGraphCompany?customChartId=${customChartId}&companyId=${companyId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function EditSharedGraphCompanyRole(authToken:string, customChartId:number, companyId:number, role:SharedGraphRoleType): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", 
        `EditSharedGraphCompanyRole?customChartId=${customChartId}&companyId=${companyId}&role=${role}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetSharedGraphPrograms(authToken:string, customChartId:number): Promise<Array<SharedGraphProgram>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `GetSharedGraphPrograms?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddSharedGraphPrograms(authToken:string, customChartId:number, sharedGraphPrograms:Array<SharedGraphProgram>): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(sharedGraphPrograms);

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `AddSharedGraphPrograms?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveSharedGraphProgram(authToken:string, customChartId:number, programId:number): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `RemoveSharedGraphProgram?customChartId=${customChartId}&programId=${programId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function EditSharedGraphProgramRole(authToken:string, customChartId:number, programId:number, role:SharedGraphRoleType): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", 
        `EditSharedGraphProgramRole?customChartId=${customChartId}&programId=${programId}&role=${role}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetSharedGraphUsers(authToken:string, customChartId:number): Promise<Array<SharedGraphUser>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `GetSharedGraphUsers?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddSharedGraphUsers(authToken:string, customChartId:number, sharedGraphUsers:Array<SharedGraphUser>): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(sharedGraphUsers);

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `AddSharedGraphUsers?customChartId=${customChartId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveSharedGraphUser(authToken:string, customChartId:number, userId:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", `RemoveSharedGraphUser?customChartId=${customChartId}&userId=${userId}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function EditSharedGraphUserRole(authToken:string, customChartId:number, userId:string, role:SharedGraphRoleType): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("SharedChart", 
        `EditSharedGraphUserRole?customChartId=${customChartId}&userId=${userId}&role=${role}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}