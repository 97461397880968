export enum TrendlineType
{
    None = 0,
    Linear = 1,
    Polynomial = 2,
    Exponential = 3,
    Logarithmic = 4,
    Power = 5
}

export const trendlineTypeList = [
    {
        type: TrendlineType.None,
        name: 'None'
    },
    {
        type: TrendlineType.Linear,
        name: 'Linear'
    },
    {
        type: TrendlineType.Polynomial,
        name: 'Polynomial'
    },
    {
        type: TrendlineType.Exponential,
        name: 'Exponential'
    },
    {
        type: TrendlineType.Logarithmic,
        name: 'Logarithmic'
    },
    {
        type: TrendlineType.Power,
        name: 'Power'
    }
];