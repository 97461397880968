import './GraphSettingsAddButton.css';

type GraphSettingsAddButtonProps = {
    onClick: () => void;
    id: string|undefined;
}

const defaultProps = {
    id: undefined
}

export function GraphSettingsAddButton({ onClick, id }: GraphSettingsAddButtonProps) {
    return (
        <div id={id} className='graphSettingsAddButton' onClick={onClick}>
            {plusSvg}
        </div>
    );
}
GraphSettingsAddButton.defaultProps = defaultProps;
export default GraphSettingsAddButton;

const plusSvg = (
    <svg
      width="1.2em"
      height="1.2em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="plus" stroke="white">
        <path
          id="horizontal"
          d="M5 12H19"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="vertical"
          d="M12 5V19"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
);