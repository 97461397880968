import './Dashboard.css';

import { Button, RadioGroup } from 'devextreme-react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import hyperXLogo from '../../HyperXLogo.png'
import { useNavigate, useParams } from 'react-router-dom';
import { CustomChartSettings } from '../../Classes/Charts/CustomChartSettings';
import { useEffect, useState } from 'react';
import { GetDbGraphBySharedLinkId, GetDbResultsByZone } from '../../API/ReadOnlyChartAPI';
import { UnitSystemType } from '../../Classes/User';
import CustomChart from '../Charts/CustomGraphs/CustomChart';
import { APIRequestStatus } from '../../Classes/APIRequestStatus';
import { useDispatch } from 'react-redux';
import { fetchPlottableProperties } from '../../Reducers/ResultsReducer';

type GraphParams = {
    sharedLinkId: string;
}

export function ReadOnlyDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { sharedLinkId } = useParams<GraphParams>();
    const [unitSystem, setUnitSystem] = useState<UnitSystemType>(UnitSystemType.English);

    const [graphSettings, setGraphSettings] = useState<CustomChartSettings|undefined>();
    const [zoneResults, setZoneResults] = useState<any[]|null>(null);

    useEffect(() => {
        if (sharedLinkId != null) {
            dispatch(fetchPlottableProperties(unitSystem));
            GetDbGraphBySharedLinkId(sharedLinkId, unitSystem).then(response => {
                if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                    setGraphSettings(response.data);
                }
            });
            GetDbResultsByZone(sharedLinkId, unitSystem).then((response) => {
                setZoneResults(response ?? []);
            });
        }
    }, [dispatch, sharedLinkId, unitSystem]);

    return (
        <div className='dashboardDiv'>     
            <Toolbar className='headerToolbar'>
                <ToolbarItem 
                    location="before"
                    locateInMenu="never"
                    render={() => 
                        <Button 
                            className='imageButton'
                            onClick={() => navigate('/')}>
                            <img
                                className='logoHeaderImage'
                                src={hyperXLogo}
                                alt={"HyperX"}/>
                        </Button>
                    }/>
                <ToolbarItem 
                    location="after"
                    render={() => 
                        <RadioGroup 
                            layout='horizontal'
                            style={{marginRight: '2em'}}
                            items={
                                [
                                    {
                                        text: 'English',
                                        type: UnitSystemType.English
                                    },
                                    {
                                        text: 'SI',
                                        type: UnitSystemType.SI
                                    }
                                ]
                            }
                            valueExpr={'type'}
                            value={unitSystem}
                            onValueChanged={(e:any) => setUnitSystem(e.value)}/>
                    }/>
            </Toolbar>
            <div className='belowToolbar'>
                <div style={{height: '100%'}}>
                    {graphSettings != null &&
                    <CustomChart 
                        graphSettings={graphSettings} 
                        setGraphSettings={setGraphSettings} 
                        zoneResults={zoneResults}
                        isAccessedViaPublicLink={true}
                        setSharedBy={() => {}}/>}
                    {graphSettings == null &&
                    <div style={{height: '100%'}}>
                        <div className='noGraphsMessage'>
                            <h3>No graph found.</h3>
                            <h3>Please check that your link is correct.</h3>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}
export default ReadOnlyDashboard;