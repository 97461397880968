
export const getSelectedIdsAfterSelectingUploadSet = (
        selectedUploadSetIds: number[],
        uploadSetIdList: number[], 
        newUploadSetId: number, 
        event: React.MouseEvent<HTMLElement>
        ) => {
    let newSelectedUploadSetIds = null;

    // Multi Select
    if (wasToggleInSelectionGroupKeyUsed(event)) {
        newSelectedUploadSetIds = multiSelectUploadSet(selectedUploadSetIds, newUploadSetId);
    }

    // Multi Select To
    else if (wasMultiSelectToKeyUsed(event)) {
        newSelectedUploadSetIds = multiSelectUploadSetTo(uploadSetIdList, selectedUploadSetIds, newUploadSetId);
    }
    // Single Select
    else {
        newSelectedUploadSetIds = singleSelectUploadSet(selectedUploadSetIds, newUploadSetId);
    }

    return newSelectedUploadSetIds;
};

const singleSelectUploadSet = (selectedUploadSetIds: number[], newUploadSetId: number): number[] | null => {
    const wasPreviouslySelected: boolean = selectedUploadSetIds.includes(newUploadSetId);

    // Task was not previously selected and now will be the only selected item
    if (!wasPreviouslySelected) {
        return [newUploadSetId];
    }

    // Task was part of a selected group and will now become the only selected item
    if (selectedUploadSetIds.length > 1) {
        return [newUploadSetId];
    }

    // Task was previously selected but was not in a group. We will now clear the selection
    return [];
}

const multiSelectUploadSet = (selectedUploadSetIds: number[], newUploadSetId: number): number[] | null => {
    const index: number = selectedUploadSetIds.indexOf(newUploadSetId);

    // If not selected then add it to the selected items
    if (index === -1) {
        return [...selectedUploadSetIds, newUploadSetId];
    }

    // It was previously selected and now needs to be removed from the group
    const shallowCopySelectedIds: number[] = [...selectedUploadSetIds];
    shallowCopySelectedIds.splice(index, 1);
    return shallowCopySelectedIds;
}

const multiSelectUploadSetTo = (uploadSetIdList: number[], selectedUploadSetIds: number[], newUploadSetId: number): number[] | null => {
    let lastSelectedUploadSetId = selectedUploadSetIds[selectedUploadSetIds.length - 1];

    let lastSelectedIndex = uploadSetIdList.indexOf(lastSelectedUploadSetId);
    let newSelectedIndex = uploadSetIdList.indexOf(newUploadSetId);

    // If nothing is already selected, select the new id
    if (!selectedUploadSetIds.length) {
        return [newUploadSetId];
    }

    // If selecting the last selected index, do nothing
    if (lastSelectedIndex == newSelectedIndex) {
        return null
    }

    // Select from the last selected index to the current index.
    const isSelectingForwards: boolean = newSelectedIndex > lastSelectedIndex;
    const startIndex: number = isSelectingForwards ? lastSelectedIndex : newSelectedIndex;
    const endIndex: number = isSelectingForwards ? newSelectedIndex : lastSelectedIndex;

    let idsToAdd = uploadSetIdList.slice(startIndex, endIndex + 1);

    // Do not re-add already selected ids
    idsToAdd = idsToAdd.filter((id) => !selectedUploadSetIds.includes(id));

    let sortedIdsToAdd = isSelectingForwards ? idsToAdd : [...idsToAdd].reverse();
    let combinedSelectedIds = [...selectedUploadSetIds, ...sortedIdsToAdd]

    return combinedSelectedIds;
}

// Determines if the platform specific toggle selection in group key was used
const wasToggleInSelectionGroupKeyUsed = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
};


// Determines if the multi select to key was used
const wasMultiSelectToKeyUsed = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => event.shiftKey;