import { GroupRoleType } from './GroupRole';
import { GroupUser } from './GroupUser';

export class ProgramUser implements GroupUser {
    id: string;
    displayName: string;
    email: string;
    dateAdded: string;
    role: GroupRoleType;

    constructor() {
        this.id = '';
        this.displayName = '';
        this.email = '';
        this.dateAdded = '';
        this.role = GroupRoleType.Administrator;
    }
}