import "./FailureModePopup.css";

import FailureMode from "../../../../../Classes/UploadSetInputs/AnalysisProperty/FailureMode";
import { DataGrid, Popup, TextBox } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

interface FailureModePopupProps {
    failureMode:FailureMode|null;
    onHiding: () => void;
}

export default function FailureModePopup(props:FailureModePopupProps) {

    const renderColumnHeader = (e:any) => {
        const text = e.column.caption;
        return (
            <div style={{color: 'black'}}>{text}</div>
        );
    }

    const renderPopupContent = () => {
        // No need to render anything if there is no Analysis selected.
        if (props.failureMode == null)
            return <div/>;

        return (
            <div className="failureModePopup inputPropertyPopup">
                <TextBox
                    readOnly={true}
                    value={props.failureMode.name}/>
                <div className="failureModeCriterionTable">
                    <DataGrid height={'100%'}>
                        <Column 
                            dataField={'enabled'}
                            headerCellRender={renderColumnHeader}/>
                        <Column 
                            dataField={'criterion'}
                            headerCellRender={renderColumnHeader}/>
                        <Column 
                            dataField={'requiredMS'}
                            caption={'Required MS'}
                            headerCellRender={renderColumnHeader}/>
                        <Column 
                            dataField={'limitUltimate'}
                            caption={'Limit/Ultimate'}
                            headerCellRender={renderColumnHeader}/>
                    </DataGrid>
                </div>
            </div>
        );
    }

    return (
        <Popup 
            visible={props.failureMode != null}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={`${props.failureMode?.category ?? ''} Failure Mode`}
            width={'40rem'}
            height={'30rem'}>
            {renderPopupContent()}
        </Popup>
    );
}