import './UploadSetList.css';

import { useDispatch, useSelector } from "react-redux";
import { Dataset } from "../../../../Classes/Dataset";
import UploadSetListItem, { UploadSetListItemContextMenuItem } from "./UploadSetListItem";
import { Button, TextBox } from "devextreme-react";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import VirtualListDragAndDrop from "../VirtualListDragAndDrop";
import { useCallback, useEffect, useState } from 'react';
import { appendUploadSetsPacket, getUploadSetsBySearchTerm, refreshUploadSetPacket, selectUploadSet, 
    SelectUploadSetPayload, UploadSetListType, deleteUploadSets, renameUploadSet } from '../../../../Reducers/SetManagementReducer';
import { RootState } from '../../../../Stores/GlobalStore';
import { confirm } from 'devextreme/ui/dialog';
import { SetNamePopup } from '../../../SetNamePopup';
import { UploadSetNameIsDuplicate } from '../../../../API/DatasetAPI';
import UploadSetInfo from '../UploadSetInfoPopup';
import { invalidateAll } from '../../../../Reducers/ResultsReducer';
import QuickComparePopup from '../UploadSetInputs/QuickCompare/QuickComparePopup';
import useKeycloak from '../../../../Keycloak';

interface UploadSetListProps {
}

function UploadSetList(props:UploadSetListProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const userId = useSelector((state:RootState) => state.userData.user?.id);
    const selectedContainerType = useSelector((state:RootState) => state.SetManagementReducer.selectedContainerType);
    const uploadSets = useSelector((state:RootState) => state.SetManagementReducer.uploadSets);
    const totalLoadedUploadSets = uploadSets.length;
    const selectedUploadSetIds = useSelector((state:RootState) => state.SetManagementReducer.selectedUploadSetIds);
    const selectedUploadSetCount = selectedUploadSetIds.length;
    const thisUploadSetListIsSelected = selectedContainerType === UploadSetListType.UploadSetFilterableList;
    const totalFilteredUploadSets = useSelector((state:RootState) => state.SetManagementReducer.summaryData.filteredUploadSets);
    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const draggingUploadSetId = useSelector((state:RootState) => state.SetManagementReducer.draggingUploadSetId);
    const numberOfUploadSetsBeingDragged = useSelector((state:RootState) => state.SetManagementReducer.numberOfUploadSetsBeingDragged);

    const [oldSearchTerm, setOldSearchTerm] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    
    const [uploadSetToRename, setUploadSetToRename] = useState<Dataset|undefined>(undefined);
    const [uploadSetToEdit, setUploadSetToEdit] = useState<Dataset|undefined>(undefined);
    const [quickCompareUploadSetIds, setQuickCompareUploadSetIds] = useState<number[]|null>(null);

    useEffect(() => {
        setSearchTerm(filterConditions.searchTerm);
    }, [filterConditions]);

    const search = () => {
        if (filterConditions !== undefined && keycloak.token) {
            dispatch(getUploadSetsBySearchTerm({authToken: keycloak.token, filterConditions: filterConditions, term: searchTerm}));
            setOldSearchTerm(searchTerm);
        }
    }

    const refreshUploadSets = () => {
        dispatch(refreshUploadSetPacket({ authToken: keycloak.token, filterConditions: filterConditions}));
    }

    const loadMoreUploadSets = () => {
        dispatch(appendUploadSetsPacket({ authToken: keycloak.token, filterConditions: filterConditions}));
    }

    const selectUploadSetFromUploadSetList = useCallback((newUploadSetId: number, event: React.MouseEvent<HTMLElement>) => {
        const uploadSetIds = uploadSets.map((uploadSet:Dataset) => uploadSet.datasetId);
        dispatch(selectUploadSet({
            uploadSetIdList: uploadSetIds,
            newUploadSetId: newUploadSetId,
            event: event,
            selectedContainerType: UploadSetListType.UploadSetFilterableList
        } as SelectUploadSetPayload));
    }, [dispatch, uploadSets]);

    const isUploadSetNameDuplicate = async (name:string) : Promise<boolean> => {
        return keycloak.token ? UploadSetNameIsDuplicate(keycloak.token, name) : false;
    }

    const getUploadSetContextMenuItems = useCallback((uploadSet:Dataset) => {
        const isSelected = selectedUploadSetIds.includes(uploadSet.datasetId);
        const actOnSelected = selectedUploadSetCount > 1 && isSelected;
        const showViewEditOption = !actOnSelected;
        const canRenameAndDelete = uploadSet.userId === userId;
        const showRenameOption = !actOnSelected && canRenameAndDelete;
        const showDeleteOption = canRenameAndDelete;
        const showQuickCompare = selectedUploadSetCount > 1;

        const contextMenuItems = new Array<any>();
        if (showViewEditOption) {
            contextMenuItems.push({
                text: 'View/Edit information',
                action: () => {
                    setUploadSetToEdit(uploadSet);
                }
            } as UploadSetListItemContextMenuItem);
            if (showRenameOption)
            {
                contextMenuItems.push({
                    text: 'Rename',
                    action: () => {
                        setUploadSetToRename(uploadSet);
                    }
                } as UploadSetListItemContextMenuItem);
            }
        }
    
        if (showQuickCompare) {
            contextMenuItems.push({
                text: 'Compare selected',
                action: () => {
                    setQuickCompareUploadSetIds(selectedUploadSetIds);
                }
            } as UploadSetListItemContextMenuItem);
        }

        if (showDeleteOption) {
            contextMenuItems.push({
                text: actOnSelected ? 'Delete selected' : 'Delete',
                action: () => {
                    if (actOnSelected) {
                        const names = new Array<string>();
                        uploadSets.forEach((uploadSet:Dataset) => {
                            if (selectedUploadSetIds.includes(uploadSet.datasetId))
                                names.push(uploadSet.datasetName);
                        });
                        const confirmDelete = confirm(`Are you sure you want to delete the following upload sets:<br><br>${names}`, "Confirm Delete");  
                        confirmDelete.then((deleteConfirmed) => {
                            if (deleteConfirmed && keycloak.token) { 
                                dispatch(deleteUploadSets({authToken: keycloak.token, uploadSetIds: selectedUploadSetIds}));
                            }
                        });
                    } else {
                        const confirmDelete = confirm(`Are you sure you want to delete ${uploadSet.datasetName}?`, "Confirm Delete");  
                        confirmDelete.then((deleteConfirmed) => {
                            if (deleteConfirmed && keycloak.token) { 
                                dispatch(deleteUploadSets({authToken: keycloak.token, uploadSetIds: [uploadSet.datasetId]}));
                            }
                        });
                    }
                }
            } as UploadSetListItemContextMenuItem);
        }

        return contextMenuItems;
    }, [selectedUploadSetIds, selectedUploadSetCount]);

    const rootElement = document.getElementById('root');
    const rem = rootElement ? parseFloat(getComputedStyle(rootElement).fontSize) : 0;
    return (
        <div className='uploadSetFilterableList'>
            <div className='uploadSetFilterableListHeader'>
                <h3>
                    Upload Sets
                    <Button
                        icon="refresh"
                        type='success'
                        onClick={refreshUploadSets}
                        hint='Refresh upload set list'/>
                </h3>
                <TextBox
                    className='searchTextBox'
                    placeholder='Search upload sets by name or note'
                    value={searchTerm}
                    valueChangeEvent='input'
                    onValueChange={(e) => {
                        setSearchTerm(e);
                    }}
                    onEnterKey={search}>
                    <TextBoxButton
                        name='searchButton'
                        options={{
                            type: 'normal',
                            icon: 'find',
                            stylingMode: 'outlined',
                            onClick: search,
                            disabled: searchTerm === oldSearchTerm
                        }}/>
                </TextBox>
            </div>
            <div className="uploadSetFilterableListContent">
                {totalLoadedUploadSets === 0 && 
                    <div className='setsEmptyMessage'>
                        No upload set data to show. Each upload set represents a snapshot of results from your HyperX project. Try using the Cloud Uploader form in HyperX to populate this list. 
                    </div>
                }
                <VirtualListDragAndDrop
                    listDropId={"uploadSetListDroppable"}
                    getItemDragId={(uploadSet:Dataset) => `uploadSetListDraggable${uploadSet.datasetId}`}
                    isInfiniteList={true}
                    items={uploadSets} 
                    itemHeight={5.25*rem}
                    renderRow={(uploadSet:Dataset) => (
                        <UploadSetListItem 
                            uploadSet={uploadSet}
                            selectUploadSet={selectUploadSetFromUploadSetList}
                            isSelected={thisUploadSetListIsSelected && selectedUploadSetIds.includes(uploadSet.datasetId)}
                            draggingUploadSetId={draggingUploadSetId}
                            getContextMenuItems={getUploadSetContextMenuItems}
                            contextMenuHidden={uploadSetToRename != null || uploadSetToEdit != null}/>
                    )}
                    loadMoreEnabled={true}
                    loadMoreFunction={loadMoreUploadSets}
                    totalItems={totalFilteredUploadSets ?? 0}
                    numberOfItemsBeingDragged={numberOfUploadSetsBeingDragged}/>
            </div>
            <UploadSetInfo 
                hidePopup={() => setUploadSetToEdit(undefined)}
                uploadSet={uploadSetToEdit}/>
            <SetNamePopup
                title='Rename Upload Set'
                validateForDuplicate={isUploadSetNameDuplicate}
                applyButtonName='Apply'
                oldName={uploadSetToRename?.datasetName ?? ''}
                showPopup={uploadSetToRename != null}
                hidePopup={() => setUploadSetToRename(undefined)}
                applySetName={(newName:string) => {
                    // Invalidate results, since this upload set could be used in a graph set.
                    dispatch(renameUploadSet({authToken: keycloak.token, name: newName, uploadSetId: uploadSetToRename?.datasetId})).then(() => {
                        dispatch(invalidateAll());
                    });
                }}/>
            <QuickComparePopup 
                uploadSetIds={quickCompareUploadSetIds}
                onHiding={() => setQuickCompareUploadSetIds(null)}/>
        </div>
    );
    
}
export default UploadSetList;