import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Stores/GlobalStore';
import { Invite } from '../Classes/Invite';
import { AddUserToCompany, AddUserToProgram, GetSentInvites, GetUserInvites, RemoveInvites, 
    SendCompanyUserInvites, SendProgramUserInvitesByEmail, SendProgramUserInvitesById } from '../API/InvitesAPI';

export interface InviteDataState {
    sentInvites: Invite[];
    receivedInvites: Invite[];
    inviteToastMessage: string;
}

const initialState: InviteDataState = {
    sentInvites: new Array<Invite>(),
    receivedInvites: new Array<Invite>(),
    inviteToastMessage: ''
}

export const sendCompanyUserInvites : any = createAsyncThunk(
    'InviteData/sendUserInvites',
    async ({authToken, companyId, userEmails} : { 
        authToken: string, 
        companyId: Number,
        userEmails: string[]
    }, thunkAPI) => {
        let sentInvites = await SendCompanyUserInvites(authToken, companyId, userEmails);
        return {companyId, sentInvites};
    }
);

export const sendProgramUserInvitesInternal : any = createAsyncThunk(
    'InviteData/sendUserInvites',
    async ({authToken, programId, userIds} : { 
        authToken: string, 
        programId: Number,
        userIds: string[]
    }, thunkAPI) => {
        let sentInvites = await SendProgramUserInvitesById(authToken, programId, userIds);
        return {programId, sentInvites};
    }
);

export const sendProgramUserInvitesExternal : any = createAsyncThunk(
    'InviteData/sendUserInvites',
    async ({authToken, programId, userEmails} : { 
        authToken: string, 
        programId: Number,
        userEmails: string[]
    }, thunkAPI) => {
        let sentInvites = await SendProgramUserInvitesByEmail(authToken, programId, userEmails);
        return {programId, sentInvites};
    }
);

export const getUserInvites : any = createAsyncThunk(
    'InviteData/getUserInvites',
    async (authToken:string, thunkAPI) => {
        return await GetUserInvites(authToken);
    }
);

export const getSentInvites : any = createAsyncThunk(
    'InviteData/getSentInvites',
    async (authToken:string, thunkAPI) => {
        return await GetSentInvites(authToken);
    }
);

export const removeInvites : any = createAsyncThunk(
    'InviteData/removeInvites',
    async ({authToken, inviteIds} : {
        authToken: string,
        inviteIds: Number[]
    }, thunkAPI) => {
        return await RemoveInvites(authToken, inviteIds);
    }
);

export const addUserToCompany : any = createAsyncThunk(
    'CompanyData/addUser',
    async ({authToken, invite} : {authToken:string, invite:Invite}, thunkAPI) => {
        let addedUser = await AddUserToCompany(authToken, invite.companyId);
        return {invite, addedUser};
    }
);

export const addUserToProgram: any = createAsyncThunk(
    'ProgramData/addUser',
    async ({authToken, invite} : {authToken:string, invite:Invite}, thunkAPI) => {
        if (invite.programId) {
            let addedUser = await AddUserToProgram(authToken, invite.programId);
            return {invite, addedUser};
        } else {
            return null;
        }
    }
)

export const InvitesDataReducer = createSlice({
    name: 'inviteData',
    initialState,
    reducers: {
        inviteReceived : (state, action) => {
            const invite = action.payload as Invite;

            // push onto array if not already there
            if (state.receivedInvites.find((i) => i.id == invite.id)) 
                return;

            state.receivedInvites.push(invite);
            // No need to push a notification toast message if one is already being displayed.
            if (state.inviteToastMessage === '')
                state.inviteToastMessage = `${invite.sender.displayName} sent you an invitation!`;
        },
        incomingInviteRemoved : (state, action) => {
            // removed invite from array
            if (!action.payload || !action.payload.id) { return }
            state.receivedInvites = state.receivedInvites.filter(i => i.id != action.payload.id)
        },
        outgoingInviteRemoved : (state, action) => {
            // removed invite from array
            if (!action.payload || !action.payload.id) { return }
            state.sentInvites = state.receivedInvites.filter(i => i.id != action.payload.id)
        },
        setInviteToastMessage : (state, action) => {
            state.inviteToastMessage = action.payload;
        },
        clearInviteNotification : (state, action) => {
            state.inviteToastMessage = '';
        }
    },
    extraReducers: {
        [getUserInvites.fulfilled] : (state, action) => {
            state.receivedInvites = action.payload; 
        },
        [getSentInvites.fulfilled] : (state, action) => {
            state.sentInvites = action.payload; 
        },
        [removeInvites.fulfilled] : (state, action) => {
            if (action.payload) {
                let removedInviteIds = action.payload.map((invite: Invite) => invite.id);

                // filter out removed invites
                state.receivedInvites = state.receivedInvites.filter(i => !removedInviteIds.includes(i.id));
                state.sentInvites = state.sentInvites.filter(i => !removedInviteIds.includes(i.id));
            }
        },
        [addUserToCompany.fulfilled] : (state, action) => {
            let removedInvite = action.payload.invite;

            // filter out removed invite
            if (removedInvite) {
                state.receivedInvites = state.receivedInvites.filter(i => removedInvite.id != i.id);
            }
        },
        [addUserToProgram.fulfilled] : (state, action) => {
            let removedInvite = action.payload.invite;

            // filter out removed invite
            if (removedInvite) {
                state.receivedInvites = state.receivedInvites.filter(i => removedInvite.id != i.id);
            }
        }
    }
});

export const inviteDataSelector = (state: RootState) => state.inviteData;

export default InvitesDataReducer.reducer;