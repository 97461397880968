export enum UnitSystemType {
    English = 0,
    SI = 1
}

export class User {
    id: string = '';
    first: string = '';
    last: string = '';
    displayName: string = '';
    email: string = '';
    unitSystem: UnitSystemType = UnitSystemType.English;
    isDashboardUser: boolean|undefined = undefined;
    isCompanyAdmin: boolean|undefined = undefined;
}