export class FilterTag {
    value: string;
    originKey: string;
    originValue: any;
    // This value is an empty string when the value is not a range. If nonempty, the value will be either 'start' or 'end'.
    rangeKey: string;

    constructor(value:string, originKey:string, originValue:any) {
        this.value = value;
        this.originKey = originKey;
        this.originValue = originValue;
        this.rangeKey = '';
    }
}