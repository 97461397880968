import "./UserBondedLoadPropertyPopup.css";

import { DataGrid, NumberBox, Popup, SelectBox, TextBox } from "devextreme-react";
import LoadProperty, { LoadPropertyType } from "../../../../../Classes/UploadSetInputs/LoadProperty/LoadProperty";
import { Column } from "devextreme-react/data-grid";
import UserBondedLoadProperty from "../../../../../Classes/UploadSetInputs/LoadProperty/UserBonded/UserBondedLoadProperty";
import BCLoadChoice from "../../../../../Classes/UploadSetInputs/LoadProperty/UserBonded/BCLoadChoice";

interface UserBondedLoadPropertyPopupProps {
    loadProperty:LoadProperty|null;
    onHiding: () => void;
}

export default function UserBondedLoadPropertyPopup(props:UserBondedLoadPropertyPopupProps) {
    const lightBlueColor = '#F0F8FF';

    const isUserBondedLoadProperty = (type:LoadPropertyType) => {
        return type == LoadPropertyType.UserBonded;
    }

    const renderBCLoadChoice = (l:BCLoadChoice) => {
        return (
            <table className='loadChoiceTable'>
                <tbody>
                    <tr>
                        <td style={{
                            backgroundColor: lightBlueColor
                        }}>{l.represents}</td>
                    </tr>
                    <tr>
                        <td>{l.value ?? ''}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    const renderPopupContent = () => {
        // No need to render anything if there is no load property selected, or if the selected load property is not the right type.
        if (props.loadProperty == null || !isUserBondedLoadProperty(props.loadProperty.type))
            return <div/>;

        const bondedLoadProp = props.loadProperty as UserBondedLoadProperty;
    
        return (
            <div className="bondedLoadPropertyPopup inputPropertyPopup">
                <TextBox 
                    value={props.loadProperty.name}
                    readOnly={true}/>
                <div className='bondedLoadPropertyTopOptions'>
                    <h4>Concept</h4>
                    <SelectBox 
                        dataSource={[bondedLoadProp.concept]}
                        value={bondedLoadProp.concept}
                        width={'14rem'}
                        readOnly={true}/>
                </div>
                <div className='bondedLoadPropertyTopNumberBoxes'>
                    <div className="bondedLoadPropertyTopNumberBox">
                        <h4 style={{width: '1.5rem'}}>Ex</h4>
                        <NumberBox 
                            value={bondedLoadProp.ex}
                            readOnly={true}/>
                    </div>
                    <div className="bondedLoadPropertyTopNumberBox" style={{justifyContent: 'right'}}>
                        <h4 style={{width: '6rem'}}>kx</h4>
                        <NumberBox 
                            value={bondedLoadProp.kx}
                            readOnly={true}/>
                    </div>
                    <div className="bondedLoadPropertyTopNumberBox">
                        <h4 style={{width: '1.5rem'}}>kxy</h4>
                        <NumberBox
                            value={bondedLoadProp.kxy}
                            readOnly={true}/>
                    </div>
                    <div className="bondedLoadPropertyTopNumberBox" style={{justifyContent: 'right'}}>
                        <h4 style={{width: '6rem'}}>Temperature</h4>
                        <NumberBox 
                            value={bondedLoadProp.temperature}
                            readOnly={true}/>
                    </div>
                </div>
                <div className='bondedLoadPropertyContent'>
                    <DataGrid 
                        dataSource={bondedLoadProp.boundaryConditions} 
                        wordWrapEnabled={true} 
                        height={'100%'}>
                        <Column 
                            dataField={'id'} 
                            caption={'BC'} 
                            width={'2rem'}
                            cellRender={(e) => <div style={{width: '100%', textAlign: 'center'}}>{e.value}</div>}/>
                        <Column
                            dataField={'axial'}
                            cellRender={(e) => renderBCLoadChoice(e.value)}/>
                        <Column
                            dataField={'moment'}
                            cellRender={(e) => renderBCLoadChoice(e.value)}/>
                        <Column
                            dataField={'transverse'}
                            cellRender={(e) => renderBCLoadChoice(e.value)}/>
                        <Column
                            dataField={'shear'}
                            cellRender={(e) => renderBCLoadChoice(e.value)}/>
                    </DataGrid>
                </div>
            </div>
        );
    } 

    return (
        <Popup 
            visible={props.loadProperty != null && isUserBondedLoadProperty(props.loadProperty.type)}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            title={'User Bonded Joint Load'}
            width={'32rem'}
            height={'40rem'}>
            {renderPopupContent()}
        </Popup>
    );
}