import './Dashboard.css';

import * as React from 'react';
import { Tabs, Drawer, LoadPanel, Popup, Button, DropDownButton } from 'devextreme-react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Filter from './Filter';
import FilterUsageOverview from './FilterUsageOverview';
import { Companies } from '../Groups/Companies';
import { Invites } from '../Groups/Invites';
import { Programs } from '../Groups/Programs';
import { fetchUser, userDataSelector } from '../../Reducers/UserDataReducer';
import { fetchFirstUploadSetsPacket, fetchUploadSetFilters, setGraphSetSortOrder, setRootGraphSetFolder, setSelectedGraphSetFolderId } from '../../Reducers/SetManagementReducer';
import { useDispatch, useSelector } from 'react-redux'; 
import hyperXLogo from '../../HyperXLogo.png'
import SetManager from './SetManager/SetManager';
import LandingPage from './LandingPage';
import { useSignalR } from '../../Utilities/SignalRProvider';
import { InvitesDataReducer, getUserInvites } from '../../Reducers/InvitesDataReducer';
import { RootState } from '../../Stores/GlobalStore';
import { GetFolderTree, GetSelectedFolder } from '../../API/GraphSetFolderAPI';
import { GraphSetFolder } from '../../Classes/GraphSetFolder';
import { APIResponseWithStatus } from '../../Classes/APIResponseWithStatus';
import { APIRequestStatusType } from '../../Classes/APIRequestStatus';
import InviteToast from '../Groups/InviteToast';
import { GetSortOrder } from '../../API/GraphSetAPI';
import { SortOrder } from '../../Classes/GraphSet';
import { Invite } from '../../Classes/Invite';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useKeycloak from '../../Keycloak';
import DisplayUnits from './DisplayUnits/DisplayUnits';
import { GraphsPage } from '../Charts/CustomGraphs/GraphsPage/GraphsPage';
import { fetchPlottableProperties } from '../../Reducers/ResultsReducer';
import { GetDbSelectedGraphFolderId } from '../../API/CustomChartFolderAPI';
import { setSelectedFolderId } from '../../Reducers/CustomChartsReducer';
import { GetVersion } from '../../API/ServerAPI';
import { simulateLinkClick } from '../../Utilities/CommonUtilities';

const tabsDataSource = [
    {
        text: "Data Selection",
        icon: "file", 
        path: "/"
    },
    {
        text: "Graphs",
        icon: "chart", 
        path: "/graphs"
    },
];

enum AccountButtonAction {
    Companies,
    Programs,
    Invites,
    AccountSettings,
    DisplayUnits,
    LogOut
}

enum HelpButtonAction {
    Documentation,
    PrivacyPolicy,
    TermsAndConditions,
    Security,
    Version
}

const accountButtonActions = (numInvites: number) => [
    { id: AccountButtonAction.Companies, text: 'My Companies', icon: 'group'},
    { id: AccountButtonAction.Programs, text: 'My Programs', icon: 'airplane'},
    { id: AccountButtonAction.Invites, text: 'My Invites', icon: 'email', badge: numInvites > 0 ? String(numInvites) : ''},
    { id: AccountButtonAction.DisplayUnits, text: 'Display Units', icon: 'range'},
    { id: AccountButtonAction.AccountSettings, text: 'Account Settings', icon: 'preferences'},
    { id: AccountButtonAction.LogOut, text: 'Log Out', icon: 'hidepanel'}
];

const accountButtonActionsUnauthorized = [
    { id: AccountButtonAction.LogOut, text: 'Log Out', icon: 'hidepanel'}
];

export function Dashboard() {
    const userData = useSelector(userDataSelector);
    const user = userData.user;

    const receivedInvites = useSelector((state: RootState) => state.inviteData.receivedInvites);

    const dispatch = useDispatch();
    
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [showSidebar, setShowSidebar] = React.useState(true);
    const [showToggleSidebar, setShowToggleSidebar] = React.useState(true);
    const [showCompanyPopup, setShowCompanyPopup] = React.useState(false);
    const [showProgramsPopup, setShowProgramsPopup] = React.useState(false);
    const [showInvitesPopup, setShowInvitesPopup] = React.useState(false);
    const [showDisplayUnitsPopup, setShowDisplayUnitsPopup] = React.useState(false);
    const [version, setVersion] = React.useState<string>('unknown');

    const navigate = useNavigate();
    const location = useLocation();

    const { 
        token, userId, authenticated, loading, authServerUrl, 
        login, logout 
    } = useKeycloak();

    const connection = useSignalR();

    const helpButtonActions = React.useMemo(() => [
        { id: HelpButtonAction.Documentation, text: 'Help Documentation', icon: 'help'},
        { id: HelpButtonAction.Security, text: 'Security Details', icon: 'lock'},
        { id: HelpButtonAction.PrivacyPolicy, text: 'Privacy Policy', icon: 'textdocument'},
        { id: HelpButtonAction.TermsAndConditions, text: 'Terms and Conditions', icon: 'textdocument'},
        { id: HelpButtonAction.Version, text: `Version ${version}`, icon: 'info', disabled: true},
    ], [version]);

    React.useEffect(() => {
        GetVersion().then(v => {
            React.startTransition(() => setVersion(v));
        });
    }, []);

    React.useEffect(() => {
        // Update the selected tab index if needed.
        let i = 0;
        if (window.location.pathname.includes("graphs"))
            i = 1;

        if (selectedTabIndex !== i) {
            setSelectedTabIndex(i);
            setShowToggleSidebar(true);
        }
    }, [location, selectedTabIndex]);

    React.useEffect(() => {
        if (authenticated) {
            dispatch(fetchUser(token)).then((result: any) => {
                let resultUser = result.payload;
                if (resultUser && resultUser.isDashboardUser) {
                    loadUserData();
                }
            });
        }
    }, [userId, authenticated]);

    React.useEffect(() => {
        if (user != null) {
            dispatch(fetchPlottableProperties(user.unitSystem));
        }
    }, [user?.unitSystem])

    const loadUserData = () => {
        // No need to load data if no token is loaded.
        if (!token)
            return;

        // Fetch saved filters from the server
        dispatch(fetchUploadSetFilters(token)).then((result: any) => {
            let resultFilters = result.payload;
            if (resultFilters) {
                dispatch(fetchFirstUploadSetsPacket({ authToken: token, filterConditions: resultFilters }));
            }
        });

        // Fetch graph set folder tree
        GetFolderTree(token).then((response:APIResponseWithStatus<GraphSetFolder>) => {
            if (response.type !== APIRequestStatusType.Error) {
                const rootFolder = response.data;
                dispatch(setRootGraphSetFolder(rootFolder));
            }
        });

        // Populate the user's last-selected graph set folder
        GetSelectedFolder(token).then((response) => {
            if (response.type !== APIRequestStatusType.Error && response.data != null) {
                dispatch(setSelectedGraphSetFolderId(response.data));
            }
        });
        // Populate the user's selected sort order
        GetSortOrder(token).then((response) => {
            if (response.type !== APIRequestStatusType.Error) {
                dispatch(setGraphSetSortOrder(response.data as SortOrder));
            }
        });

        // Populate the user's last-selected graph folder
        GetDbSelectedGraphFolderId(token).then((response) => {
            if (response.type !== APIRequestStatusType.Error && response.data != null) {
                dispatch(setSelectedFolderId(response.data));
            }
        });

        // Fetch any pending invites
        dispatch(getUserInvites(token)).then((result:any) => {
            const invites:Invite[] = result.payload;
            if (invites.length > 0) {
                const message = `You have ${invites.length.toLocaleString()} pending invitation${invites.length > 1 ? 's' : ''}`;
                dispatch(InvitesDataReducer.actions.setInviteToastMessage(message));
            }
        });
    }

    const dataSelectionUI = React.useMemo(() => {
        return (
            <div style={{height: "100%"}}>
                <Drawer
                    activeStateEnabled={false}
                    opened={showSidebar}
                    openedStateMode="shrink"
                    position="left"
                    height={'100%'}
                    width={'100%'}
                    revealMode="slide"
                    render={() => (
                        <Filter/>
                    )}>
                    <div className='mainDashboardContentDiv'>
                        <FilterUsageOverview/>      
                        <SetManager/>
                    </div>
                </Drawer>
            </div>
        );
    }, [showSidebar]);

    const loginButtonText = user?.displayName ?? 'Log In';
    const showLandingPage = (!loading && !authenticated) || (user != null && user.isDashboardUser === false);
    const numInvites = receivedInvites.length;

    return (
        <div className='dashboardDiv'> 
            <Toolbar className='headerToolbar'>
                <ToolbarItem 
                    location="before"
                    disabled={!showToggleSidebar}
                    visible={authenticated && user?.isDashboardUser}
                    widget="dxButton"
                    cssClass='toggleSidebarButton'
                    options={{ 
                        icon: "menu", 
                        onClick: () => {
                            setShowSidebar(!showSidebar);
                        }}} />
                <ToolbarItem 
                    location="before"
                    locateInMenu="never"
                    render={() => 
                        <Button 
                            className='imageButton'
                            onClick={() => navigate(tabsDataSource[0].path)}>
                            <img
                                className='logoHeaderImage'
                                src={hyperXLogo}
                                alt={"HyperX"}/>
                        </Button>
                    }/>
                <ToolbarItem 
                    locateInMenu="never"
                    visible={authenticated && user?.isDashboardUser}
                    render={() => 
                        <Tabs
                            dataSource={tabsDataSource}
                            selectedIndex={selectedTabIndex}
                            onSelectedIndexChange={(newIndex) => {
                                if (tabsDataSource[newIndex])
                                    navigate(tabsDataSource[newIndex].path)
                            }}/>
                    } />
                <ToolbarItem location="after">
                    <DropDownButton
                        icon="help"
                        displayExpr='text'
                        dropDownOptions={{width: '12rem'}}
                        onItemClick={ (e:any) => {
                            let itemId = e.itemData.id;
                            switch (itemId) {
                                case HelpButtonAction.Documentation:
                                    simulateLinkClick('https://support.collieraerospace.com/hc/en-us/articles/20781963125147-Dashboard-Overview');
                                    break;
                                case HelpButtonAction.PrivacyPolicy:
                                    simulateLinkClick(`${window.location.origin}/documents/privacy-policy`);
                                    break;
                                case HelpButtonAction.TermsAndConditions:
                                    simulateLinkClick(`${window.location.origin}/documents/terms-and-conditions`);
                                    break;
                                case HelpButtonAction.Security:
                                    simulateLinkClick('https://support.collieraerospace.com/hc/en-us/articles/20781943450651-Dashboard-Security-Guide');
                                    break;
                            }
                        }}
                        items={helpButtonActions}/>
                </ToolbarItem>
                {!authenticated &&
                <ToolbarItem location="after"
                    widget="dxButton"
                    options={{ 
                        icon: "user", 
                        text: loginButtonText, 
                        onClick: login}} />}
                    {
                        authenticated &&
                        <ToolbarItem location="after" cssClass='accountButton'>
                            <div>
                                {user?.isDashboardUser &&
                                <div 
                                    className='notificationWrapper'
                                    style={numInvites > 0 ? {} : {'display': 'none'}}>
                                    <div className='notificationSymbol'>
                                        {numInvites <= 9 && numInvites}
                                        {numInvites > 9 && '9+'}
                                    </div>
                                </div>}
                                <DropDownButton
                                        icon="user"
                                        text={loginButtonText}
                                        displayExpr='text'
                                        dropDownOptions={{
                                            width: '10rem',
                                            wrapperAttr: {class: 'accountButtonDropDown'}
                                        }}
                                        onItemClick={ (e:any) => {
                                            let itemId = e.itemData.id;
                                            switch (itemId) {
                                                case AccountButtonAction.Companies:
                                                    setShowCompanyPopup(true);
                                                    break;
                                                case AccountButtonAction.Programs:
                                                    setShowProgramsPopup(true);
                                                    break;
                                                case AccountButtonAction.Invites:
                                                    setShowInvitesPopup(true);
                                                    break;
                                                case AccountButtonAction.DisplayUnits:
                                                    setShowDisplayUnitsPopup(true);
                                                    break;
                                                case AccountButtonAction.AccountSettings:
                                                    if (authServerUrl)
                                                        window.location.href = authServerUrl + '/realms/hyperxcloud/account/';
                                                    break;
                                                case AccountButtonAction.LogOut:
                                                    logout();
                                                    break;
                                            }
                                        }}
                                        items={user?.isDashboardUser ? accountButtonActions(receivedInvites.length) : accountButtonActionsUnauthorized}
                                />
                            </div>
                        </ToolbarItem>
                    }
                </Toolbar>
            <div className='belowToolbar'>
                {authenticated && user?.isDashboardUser &&
                <div style={{height: '100%'}}>
                    <Routes>
                        <Route path="/graphs" element={ 
                            <GraphsPage showSidebar={showSidebar}/>
                        }/>
                        <Route path="/" element={dataSelectionUI} />
                    </Routes>
                    <Popup 
                        title='Manage Companies'
                        width='50vw'
                        visible={showCompanyPopup}
                        hideOnOutsideClick={true}
                        onHiding={() => {
                            setShowCompanyPopup(false);
                        }}>
                        <Companies popupIsOpen={showCompanyPopup}/>
                    </Popup>
                    <Popup 
                        title='Manage Programs'
                        width='60vw'
                        visible={showProgramsPopup}
                        hideOnOutsideClick={true}
                        onHiding={() => {
                            setShowProgramsPopup(false);
                        }}>
                        <Programs popupIsOpen={showProgramsPopup}/>
                    </Popup>
                    <Popup 
                        title='My Invites'
                        width='30vw'
                        visible={showInvitesPopup}
                        hideOnOutsideClick={true}
                        onHiding={() => {
                            setShowInvitesPopup(false);
                        }}>
                        <Invites popupIsOpen={showInvitesPopup}/>
                    </Popup>
                    <DisplayUnits 
                        show={showDisplayUnitsPopup} 
                        onClosing={() => setShowDisplayUnitsPopup(false)}/>
                </div>}
                {/* Show landing page for unauthenticated users */}
                {showLandingPage && <LandingPage/>}
                {user?.isDashboardUser &&
                <InviteToast viewInvites={() => setShowInvitesPopup(true)}/>}
            </div>
        </div>
    );
}
export default Dashboard;