import { Program } from '../Classes/UserGroups/Program';
import { ProgramUser } from '../Classes/UserGroups/ProgramUser';
import { GroupRoleType } from '../Classes/UserGroups/GroupRole';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { CompanyUser } from '../Classes/UserGroups/CompanyUser';

export async function CreateProgram(authToken:string, companyId:number, name:string): Promise<Program> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `CreateProgram?companyId=${companyId}&name=${name}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetPrograms(authToken:string): Promise<Program[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", "GetPrograms");
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function GetInvitableInternalUsers(authToken:string, programId:number): Promise<CompanyUser[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `GetInvitableInternalUsers?programId=${programId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RenameProgram(authToken:string, programId:number, name:string): Promise<ProgramUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `SetProgramName?programId=${programId}&name=${name}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function DeleteProgram(authToken:string, programId:number): Promise<ProgramUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `DeleteProgram?programId=${programId}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function RemoveUsers(authToken:string, programId:number, userIds:string[]): Promise<ProgramUser[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(userIds);
    
    let url = ApiFetchUtility.getFormattedUrl("Program", "RemoveUsers?programId=" + programId);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function AssignUserRole(authToken:string, programId:number, userId:string, role:GroupRoleType): Promise<ProgramUser> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("Program", `AssignUserRole?programId=${programId}&userId=${userId}&role=${role}`);
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = res.json();

    return json;
}

export async function ProgramNameIsDuplicate(authToken:string|undefined, companyId:number|undefined, name:string): Promise<boolean> {

    if (!authToken || !companyId)
        return false;

    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("Program", `IsNameDuplicate?companyId=${companyId}&name=${name}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}