import { ToolbarItem } from 'devextreme-react/popup';
import { Popup, SelectBox, TextBox } from 'devextreme-react';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProgram } from '../../Reducers/ProgramDataReducer';
import { Company } from '../../Classes/UserGroups/Company';
import { canAddProgram } from '../../Classes/UserGroups/GroupRole';
import { ProgramNameIsDuplicate } from '../../API/ProgramsAPI';
import { RootState } from '../../Stores/GlobalStore';
import { naturalCompare } from '../../Utilities/CommonUtilities';
import { Program } from '../../Classes/UserGroups/Program';
import useKeycloak from '../../Keycloak';

interface CreateProgramPopupProps {
    showPopup:boolean
    setShowPopup:(showPopup:boolean) => void;
    onProgramCreated:(program:Program) => void;
}

export function CreateProgramPopup(props:CreateProgramPopupProps) {

    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const user = useSelector((state:RootState) => state.userData.user);
    const companies = useSelector((state:RootState) => state.companyData.companies);
    const programs = useSelector((state:RootState) => state.programData.programs);

    const [selectableCompanies, setSelectableCompanies] = React.useState<Company[]>(new Array<Company>());
    const [selectedCompany, setSelectedCompany] = React.useState<Company|undefined>(undefined);

    const [name, setName] = React.useState<string>();
    const [nameErrorMessage, setNameErrorMessage] = React.useState<string | undefined>(undefined);
    const [companyErrorMessage, setCompanyErrorMessage] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        let newSelectableCompanies = new Array<Company>();
        companies.forEach((company:Company) => {
            let companyUser = company.users.find(u => u.id === user?.id);
            if (companyUser && canAddProgram(companyUser.role)) {
                newSelectableCompanies.push(company);
            }
        });
        const sortedCompanies = [...newSelectableCompanies].sort((a, b) => naturalCompare(a.name, b.name));
        setSelectableCompanies(sortedCompanies);
    }, [companies]);

    // Re-select the selected company object if the list of selectable companies changes
    useEffect(() => {
        if (selectedCompany) {
            const newSelectedCompany = selectableCompanies.find(i => i.id == selectedCompany.id);
            setSelectedCompany(newSelectedCompany);
        }
    }, [selectableCompanies]);

    useEffect(() => {
        // If there are existing programs, select the most used company.
        if (props.showPopup && selectedCompany == null && programs.length > 0 && companies.length > 0) {
            const companyUsage = new Map<string, number>();
            programs.forEach(p => {
                const newCount = (companyUsage.get(p.companyName) ?? 0) + 1;
                companyUsage.set(p.companyName, newCount);
            });
            let maxKey:string|null = null;
            let maxVal:number = 0;
            companyUsage.forEach((value, key) => {
                if (maxKey == null || value > maxVal) {
                    maxKey = key;
                    maxVal = value;
                }
            });
            if (maxKey != null) {
                const mostUsedCompany = companies.find(i => i.name == maxKey);
                if (mostUsedCompany)
                    setSelectedCompany(mostUsedCompany);
            }
        }
    }, [props.showPopup, companies, programs, selectedCompany]);

    useEffect(() => {
        setName('');
        clearErrors();
    }, [props.showPopup]);

    const clearErrors = () => {
        setNameErrorMessage(undefined);
        setCompanyErrorMessage(undefined);
    }

    const apply = async () => {
        let trimmedName = name?.trim() ?? '';
        let nameErrorMessage = undefined;
        let companyErrorMessage = undefined;

        let progNameIsDuplicate = await ProgramNameIsDuplicate(keycloak.token, selectedCompany?.id, trimmedName);

        if (trimmedName === '')
            nameErrorMessage = 'Name is a required field.';
        else if (progNameIsDuplicate)
            nameErrorMessage = 'The selected company already has a program with this name.';
        
        if (!selectedCompany)
            companyErrorMessage = 'You must select a company for this program.';

        let success = nameErrorMessage === undefined && companyErrorMessage === undefined && await applyAdd(trimmedName);

        // No errors, apply
        if (success) {
            props.setShowPopup(false);
        } else {
            setNameErrorMessage(nameErrorMessage);
            setCompanyErrorMessage(companyErrorMessage);
        }
    }

    const applyAdd = async(trimmedName:string) => {
        let token = keycloak.token;
        const createProgramResponse = await dispatch(createProgram({authToken:token, companyId:selectedCompany?.id, name:trimmedName}));
        const newProgram = createProgramResponse?.payload;
        if (newProgram) {
            props.onProgramCreated(newProgram);
        }
        return token && newProgram;
    }

    return (
        <Popup 
            title='Create Program'
            wrapperAttr={{class: 'createProgramPopupWrapper'}}
            width='24rem'
            height={'auto'}
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={() => {
                props.setShowPopup(false);
            }}>
            {/* Unload the popup to clear validation when popup is hidden */}
            {props.showPopup &&
            <div className='createProgramDiv'>
                <TextBox 
                    focusStateEnabled={true}
                    label='Name'
                    placeholder='Enter a name...'
                    value={name}
                    onEnterKey={apply}
                    isValid={nameErrorMessage === undefined}
                    validationError={
                        {message: nameErrorMessage}
                    }
                    valueChangeEvent='keyup'
                    onValueChanged={(e) => { 
                        setName(e.value);
                        clearErrors();
                    }}
                    onInitialized={(e) => {
                        // We must wait a bit here, since focusing immediately does not seem to take.
                        const waitForInit = 400;   
                        setTimeout(() => {  
                            if (e.component) {
                                e.component.focus();  
                            }
                        }, waitForInit);  
                    }}/>
                <SelectBox 
                    dataSource={selectableCompanies} 
                    label='Company'
                    displayExpr='name'
                    placeholder='Select a company...'
                    noDataText='You are not a part of any companies.'
                    value={selectedCompany}
                    isValid={companyErrorMessage === undefined}
                    validationError={
                        {message: companyErrorMessage}
                    }
                    onValueChange={(item) => {
                        setSelectedCompany(item);
                        clearErrors();
                    }}/>  
            </div>}
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                disabled={nameErrorMessage != null || companyErrorMessage != null}
                options={{
                    text: 'Add',
                    onClick: apply
                }}
            />
        </Popup>
    );
}