import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from '../Classes/User';
import { RootState } from '../Stores/GlobalStore';
import { GetUser, UpdateUser } from '../API/UserAPI';

export interface UserDataState {
    user: User|undefined;    
    loadingUser: boolean;
}

const initialState: UserDataState = {
    user: undefined,
    loadingUser: false
}

// Users
export const fetchUser : any = createAsyncThunk(
    'UserData/fetchUser',
    async (authToken:string, thunkAPI) => {
        const response = await GetUser(authToken); 
        return response;
    }
);
export const updateUser : any = createAsyncThunk(
    'UserData/updateUser',
    async ({authToken, user} : { authToken:string, user:User }, thunkAPI) => {
        await UpdateUser(authToken, user);
        return await GetUser(authToken);
    }
);

export const UserDataReducer = createSlice({
    name: 'userData',
    initialState,
    reducers: {},
    extraReducers: {
        // User
        [fetchUser.pending] : (state, action) => {
            state.loadingUser = true;
        },
        [fetchUser.fulfilled] : (state, action) => {
            state.user = action.payload;
            state.loadingUser = false;
        },
        [updateUser.pending] : (state, action) => {
            state.loadingUser = true;
        },
        [updateUser.fulfilled] : (state, action) => {
            state.user = action.payload;
            state.loadingUser = false;
        }
    }
});

export const userDataSelector = (state: RootState) => state.userData;

export default UserDataReducer.reducer;