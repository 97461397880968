import './Graph.css';

import { ChartSettings, PointLabelType } from '../../Classes/Charts/ChartSettingsTypes';
import { Chart, Popover } from 'devextreme-react';
import {
    ArgumentAxis, CommonAxisSettings, Connector, ConstantLine, Format, Grid, Label,
    Legend, Point, Series, Title, Tooltip, ValueAxis, WholeRange, ZoomAndPan
} from 'devextreme-react/chart';
import { GraphDataSource, GraphSeries } from '../../Classes/Charts/GraphDataSource';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { axisLabelOverflowModeTypes } from './CommonChartTypes';
import { getUnits, previewImageHeight, previewImageWidth } from './CommonChartUtils';
import { naturalCompare, toSigFigs } from '../../Utilities/CommonUtilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../Stores/GlobalStore';
import { LegendClickEvent } from 'devextreme/viz/chart';
import tinyColor from 'tinycolor2';
import { EventInfo } from 'devextreme/events';
import VizChart from 'devextreme/viz/chart';
import { getColumnDisplayName } from '../../Classes/ResultPropertyInfo';

type GraphProps = {
    graphSettings:ChartSettings;
    graphDataSource:GraphDataSource|null;
    setPreviewImage?: (imageBlob: Blob) => void;
    showTitle?: boolean;
    enableZoom?: boolean;
    readOnly?: boolean;
    toggleSeriesVisibility?: (seriesKey:string) => void;
    legendVerticalAlignment?: "bottom" | "top" | undefined;
    legendHorizontalAlignment?: "right" | "left" | "center" | undefined;
}

const defaultProps = {
    showTitle: true,
    enableZoom: true,
    setPreviewImage: undefined,
    readOnly: false,
    legendVerticalAlignment: "top" as  "bottom" | "top" | undefined,
    legendHorizontalAlignment: "right" as "right" | "left" | "center" | undefined
}

const Graph = forwardRef<any, GraphProps>(({ graphSettings, graphDataSource, setPreviewImage, showTitle, 
    enableZoom, readOnly, toggleSeriesVisibility, legendVerticalAlignment, legendHorizontalAlignment }: GraphProps, ref:any) => {

    const graphId = graphSettings.chartId;

    const propertyKeyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);
    const [chartUI, setChartUI] = useState<JSX.Element|null>(
        <Chart 
            ref={ref}
            width={'100%'}
            height={'100%'}
            id={'graph'}
            animation={false}/>
    );

    // This is actually a coefficient. It determines max bubble size relative to the minimum dimension of the chart component.
    const maxBubbleSize = 0.2;
    // This is not a coefficient. It is simply the size of the smallest bubble in pixels.
    const [minBubbleSize, setMinBubbleSize] = useState<number>(12);

    const seriesSortedByType = useMemo(() => {
        if (graphDataSource?.series == null) {
            return [];
        }
        const sortedSeriesList = [...graphDataSource.series];
        return sortedSeriesList.sort((a:GraphSeries,b:GraphSeries) => a.getTypeLayer() - b.getTypeLayer());
    }, [graphDataSource?.series]);

    useEffect(() => {
        const chartObj = ref?.current?.instance;
        if (chartObj) {
            const isLoading = graphDataSource == null;
            if (isLoading)
                chartObj.showLoadingIndicator();
            else 
                chartObj.hideLoadingIndicator();
        }
    }, [graphDataSource, ref]);

    const chartRenderDebounceTimer = useRef<NodeJS.Timeout | undefined>();
    const prevImageDebounceTimer = useRef<NodeJS.Timeout | undefined>();
    useEffect(() => {
        const chartRenderDelay = 80;
        const prevImageDelay = 1000;

        const debouncedUpdatePreviewImage = () => {
            clearTimeout(prevImageDebounceTimer.current);
    
            prevImageDebounceTimer.current = setTimeout(() => {
                // Check to see if this preview image needs updating.
                if (setPreviewImage != null) {
                    const chartObj = ref?.current?.instance;
        
                    if (chartObj != null) {
                        let svgString = chartObj.svg();
        
                        let dummyDiv = document.createElement('div');
                        dummyDiv.innerHTML = svgString;
                        let svgElement = dummyDiv.firstChild as SVGSVGElement;
        
                        // Remove unneeded UI
                        if (showTitle) {
                            let titleChild = svgElement.getElementsByClassName('dxc-title')[0];
                            if (titleChild && titleChild.parentNode) {
                                titleChild.parentNode.removeChild(titleChild);
                            }
                        }
        
                        // Draw svg image onto offscreen canvas
                        let canvas = document.createElement("canvas");
                        canvas.width = previewImageWidth;
                        canvas.height = previewImageHeight;
                        let context = canvas.getContext("2d");
        
                        let svgBlob = new Blob([svgElement.outerHTML], { type: 'image/svg+xml' });
                        let svgUrl = URL.createObjectURL(svgBlob);
        
                        let image = new Image();
                        image.onload = function () {
                            context?.drawImage(image, 0, 0, previewImageWidth, previewImageHeight);
        
                            canvas.toBlob((blob) => {
                                if (blob && setPreviewImage != null)
                                    setPreviewImage(blob);
                            });
                        }
                        image.src = svgUrl;
                    }
                }
            }, prevImageDelay);
        }

        const updateBubbleSizes = (chartComponent: any) => {
            // Calculate the right min bubble size
            let size = chartComponent.getSize();
            if (size && size?.height && size?.width) {
        
                // Max bubble = maxBubbleSize * min(width, height)
                // Min bubble = fixed pixel size
                // Min bubble should be -> (dataMin/dataMax)(maxBubbleSize) * min(width, height)
                let minDimension = Math.min(size.height, size.width);
        
                let minPointSize: number | null = null;
                let maxPointSize: number | null = null;
        
                chartComponent.series?.forEach((series: any) => {
                    // Only account for visible bubble series
                    if (series.isVisible() && series.type === 'bubble') {
                        let points = series.getAllPoints();
                        points.forEach((point: any) => {
                            let size = point.size;
        
                            if (minPointSize == null || size < minPointSize)
                                minPointSize = size;
                            if (maxPointSize == null || size > maxPointSize)
                                maxPointSize = size;
                        });
                    }
                });
        
                if (minPointSize && maxPointSize) {
                    let pointSizeRatio = minPointSize / maxPointSize;
                    let newMinBubbleSize = pointSizeRatio * maxBubbleSize * minDimension;
        
                    if (newMinBubbleSize !== minBubbleSize)
                        setMinBubbleSize(newMinBubbleSize);
                }
            }
        };

        const onLegendClick = (e: LegendClickEvent) => {
            // Don't let the user click the legend items when the graph is read only
            if (readOnly || toggleSeriesVisibility == null)
                return;

            const series = e.target as any;
            const seriesKey = series.tag;
            toggleSeriesVisibility(seriesKey);
        }

        const tooltipRender = (info: any) => {
            if (graphDataSource == null) {
                return <div/>;
            }
    
            const pointData = info.point.data;
    
            const allSeries = [...graphDataSource.series, ...graphDataSource.horizontalGuideIntersectionSeries, ...graphDataSource.trendlineSeries];
            const seriesAtThisPoint = allSeries.find(i => i.key in pointData && pointData[i.key] != null);
            if (seriesAtThisPoint == null) {
                return <div/>;
            }
    
            const arg = pointData.x;
            const val = pointData[seriesAtThisPoint.key];
            let size = null;
    
            if (seriesAtThisPoint.sizeKey != null && seriesAtThisPoint.sizeKey in pointData) {
                size = pointData[seriesAtThisPoint.sizeKey];
            }
    
            const xName = graphDataSource?.argumentAxisName;
            const xValue = getPointLabel(arg, graphSettings.valuePrecision, undefined);
    
            const yName = seriesAtThisPoint.axisKey;
            const yValue = getPointLabel(val, graphSettings.valuePrecision, undefined);
    
            const sizeName = seriesAtThisPoint.sizeDisplayName;
            const sizeValue = getPointLabel(size, graphSettings.valuePrecision, undefined);
    
            // Trendline metadata
            const equationUI = seriesAtThisPoint.regressionHelper?.getEquationUI(graphSettings.valuePrecision);
            const r2 = seriesAtThisPoint.regressionHelper?.getRSquaredString(graphSettings.valuePrecision);

            return (
                <div className='graphTooltip'>
                    {seriesAtThisPoint.displayName !== '' &&
                    <span className='graphTooltipHeader'>{seriesAtThisPoint.displayName}</span>}
                    {equationUI != null &&
                    <div>
                        <span className="graphTooltipLabel">Equation</span>: {equationUI}
                    </div>}
                    {r2 != null &&
                    <div>
                        <span className="graphTooltipLabel"><span>R<sup>2</sup></span></span>: {r2}
                    </div>}
                    <div>
                        <span className="graphTooltipLabel">{xName}</span>: {xValue}
                    </div>
                    <div>
                        <span className="graphTooltipLabel">{yName}</span>: {yValue}
                    </div>
                    {size != null &&
                    <div>
                        <span className="graphTooltipLabel">{sizeName}</span>: {sizeValue}
                    </div>}
                </div>
            );
        }

        const visRangeUI = (min:number|null, max:number|null) => {
            if (min == null && max == null) {
                return null;
            }
            return (
                <WholeRange
                    startValue={min}
                    endValue={max}/>
            );
        };

        let filtersApplied = false;
        for (const key in graphSettings.stringFiltersByColumnId) {
            const arr = graphSettings.stringFiltersByColumnId[key];
            if (arr.length > 0) {
                filtersApplied = true;
                break;
            }
        }

        let legendTitle:string|undefined = undefined;
        if (graphSettings.colorByColumnIds) {
            legendTitle = graphSettings.colorByColumnIds
                .map(colorByColId => {
                    const col = graphSettings.dataSourceColumns.find(i => i.id === colorByColId);
                    return col ? getColumnDisplayName(col, propertyKeyDict) : 'Unknown'
                }).join(', ');
        }

        clearTimeout(chartRenderDebounceTimer.current);
        chartRenderDebounceTimer.current = setTimeout(() => {
            const legendIsVisible = (graphDataSource?.series.length ?? 0) > 1;
            const newUI = (
                <Chart 
                    ref={ref}
                    width={'100%'}
                    height={'100%'}
                    id={'graph'}
                    animation={false}
                    stickyHovering={false}
                    maxBubbleSize={maxBubbleSize}
                    minBubbleSize={minBubbleSize}
                    dataSource={graphDataSource?.formattedResultsData}
                    onDrawn={(e: EventInfo<VizChart>) => {
                        updateBubbleSizes(e.component);
                        debouncedUpdatePreviewImage();
                    }}
                    onLegendClick={onLegendClick}
                    customizeLabel={customizePointLabel}>
                    {showTitle &&
                    <Title 
                        text={graphSettings.title}
                        subtitle={filtersApplied ? 'Note: Some data has been filtered out.' : undefined}/>}
                    {graphDataSource?.trendlineSeries.map((graphSeries) => (
                        <Series
                            key={graphSeries.key}
                            tag={graphSeries.key}
                            argumentField='x'
                            valueField={graphSeries.key}
                            name={graphSeries.displayName}
                            type={graphSeries.type as any}
                            axis={graphSeries.axisKey}
                            color={graphSeries.color}
                            selectionMode='none'
                            hoverMode='excludePoints'
                            dashStyle='dot'
                            hoverStyle={{dashStyle: 'dot'}}
                            showInLegend={false}>
                            <Point visible={false}/>
                        </Series>
                    ))}
                    {seriesSortedByType.map((graphSeries) => (
                        <Series
                            key={graphSeries.key}
                            tag={graphSeries.key}
                            argumentField='x'
                            valueField={graphSeries.key}
                            name={graphSeries.displayName}
                            type={graphSeries.type as any}
                            axis={graphSeries.axisKey}
                            sizeField={graphSeries.sizeKey}
                            color={graphSeries.color}
                            stack={graphSeries.stack}
                            visible={graphSeries.visible}>
                                <Point symbol={graphSeries.pointShape}/>
                                <Label 
                                    visible={graphSettings.pointLabelMode !== PointLabelType.None}
                                    customizeText={(point:any) => {
                                        const xAxisColumn = graphSettings.dataSourceColumns.find(i => i.id === graphSettings.xAxisColumnId);
                                        const xAxisUnits = getUnits(propertyKeyDict, xAxisColumn?.propertyKey ?? '');
                                        const xLabel = getPointLabel(point.argument, graphSettings.valuePrecision, xAxisUnits);
                                        const yLabel = getPointLabel(point.value, graphSettings.valuePrecision, graphSeries.unitsLabel);
                                        switch (graphSettings.pointLabelMode) {
                                            case PointLabelType.XAxis:
                                                return xLabel;
                                            case PointLabelType.YAxis:
                                                return yLabel;
                                            case PointLabelType.XAndYAxis:
                                                return `${xLabel}, ${yLabel}`;
                                        }
                                    }}>
                                    <Connector visible={true}/>
                                </Label>
                        </Series>
                    ))}
                    {graphDataSource?.horizontalGuideIntersectionSeries.map((graphSeries) => (
                        <Series
                            key={graphSeries.key}
                            tag={graphSeries.key}
                            argumentField='x'
                            valueField={graphSeries.key}
                            name={graphSeries.displayName}
                            type={graphSeries.type as any}
                            axis={graphSeries.axisKey}
                            sizeField={graphSeries.sizeKey}
                            showInLegend={false}
                            color={graphSeries.color}>
                                <Point symbol={'polygon'}/>
                                <Label 
                                    visible={graphSettings.showYAxisConstantLineLabels}
                                    customizeText={(point:any) => {
                                        const xLabel = getPointLabel(point.argument, graphSettings.valuePrecision, graphDataSource.argumentAxisUnitsLabel);
                                        return xLabel;
                                    }}>
                                    <Connector visible={true}/>
                                </Label>
                        </Series>
                    ))}
                    <CommonAxisSettings>
                        <Label
                            overlappingBehavior={axisLabelOverflowModeTypes.find(obj => 
                                obj.type === graphSettings.axisLabelOverflowMode)?.value as any}
                            rotationAngle={45}
                            customizeHint={(e:any) => { 
                                if (typeof(e.value) === 'string') 
                                    return e.value
                                }}
                            customizeText={(e:any) => {
                                if (typeof(e.value) === 'string' && graphSettings.axisLabelCutoff && e.valueText.length > graphSettings.axisLabelCutoff) 
                                    return e.valueText.substring(0, graphSettings.axisLabelCutoff) + '...';
                                else
                                    return e.valueText;
                                }}/>
                    </CommonAxisSettings>
                    <ArgumentAxis 
                        valueMarginsEnabled={true} 
                        discreteAxisDivisionMode="crossLabels"
                        type={graphSettings.xAxisLogScaleEnabled ? "logarithmic" : undefined}>
                        <Title text={graphDataSource?.argumentAxisName}/>
                        <Grid visible={graphSettings.showXAxisGridLines} />
                        <Format precision={graphSettings.valuePrecision}/>
                        <Label
                            overlappingBehavior={axisLabelOverflowModeTypes.find(obj => 
                                obj.type === graphSettings.axisLabelOverflowMode)?.value as any}
                            rotationAngle={45}/>
                        <Grid visible={graphSettings.showXAxisGridLines}/>
                        {visRangeUI(graphSettings.xAxisMinValue, graphSettings.xAxisMaxValue)}
                    </ArgumentAxis>
                    {graphDataSource?.valueAxes.map((valAxis, index) => (
                        <ValueAxis
                            key={valAxis.key}
                            name={valAxis.key}
                            showZero={graphSettings.yAxisShowZero}
                            position={index > 0 ? 'right' : 'left'}
                            type={graphSettings.yAxisLogScaleEnabled ? "logarithmic" : undefined}>
                            <Title text={valAxis.displayName}/>
                            <Grid visible={graphSettings.showYAxisGridLines}/>
                            {index === 0 && graphSettings.yAxisConstantLineValue != null &&
                            <ConstantLine
                                width={2}
                                value={graphSettings.yAxisConstantLineValue}
                                color="#707070"
                                dashStyle="dash">
                                <Label 
                                    position={'outside'}
                                    horizontalAlignment={'right'}
                                    text={getPointLabel(graphSettings.yAxisConstantLineValue, graphSettings.valuePrecision, valAxis.unitsLabel)}/>
                            </ConstantLine>}
                            {index === 0 &&
                            visRangeUI(graphSettings.yAxisMinValue, graphSettings.yAxisMaxValue)}
                        </ValueAxis>
                    ))}
                    <Legend 
                        visible={legendIsVisible}
                        title={legendTitle}
                        verticalAlignment={legendVerticalAlignment}
                        horizontalAlignment={legendHorizontalAlignment}
                        orientation={legendHorizontalAlignment === 'center' ? 'horizontal' : 'vertical'}
                        customizeHint={(args:any) => {
                            if (readOnly)
                                return 'You cannot toggle series visibility on a read-only graph.';
                            else
                                return `Click to toggle the visibility of ${args.seriesName}`;
                        }}
                        customizeItems={(items) => items.sort((a,b) => naturalCompare(a.text, b.text))}>
                    </Legend>
                    <Tooltip 
                        enabled={true}
                        contentRender={tooltipRender} />
                    {enableZoom &&
                    <ZoomAndPan
                        dragToZoom={true}
                        panKey={'ctrl'}
                        argumentAxis="both"
                        valueAxis="both"/>}
                </Chart>
            );
            setChartUI(newUI);
        }, chartRenderDelay);
    }, [enableZoom, graphDataSource, graphSettings, ref, showTitle, propertyKeyDict, minBubbleSize, readOnly, setPreviewImage, toggleSeriesVisibility, seriesSortedByType, legendVerticalAlignment, legendHorizontalAlignment]);

    return (
        <div className='graph'>
            <div className='chartWrapper'>
                {chartUI}
            </div>
            {enableZoom &&
            <div id={`graphHelpButton${graphId}`} className="graphHelpButton dx-icon-help">
                <Popover
                    target={`#graphHelpButton${graphId}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave">
                    <div style={{maxWidth: '16rem'}}>
                        Use the <b>scroll wheel</b> or <b>touch gestures</b> to zoom in and out. Hold <b>ctrl</b> while dragging to pan the view. You can <b>click and drag</b> a box on the graph to view the contents of that box.
                    </div>
                </Popover>
            </div>}
        </div>
    );
});

Graph.defaultProps = defaultProps;
export default Graph;

const getPointLabel = (arg:any, sigFigs:number|undefined, units:string|undefined) => {
    if (arg instanceof Date) {
        return arg.toLocaleString();
    }
    if (typeof arg != 'number') {
        return arg;
    }
    const rounded = sigFigs ? toSigFigs(arg, sigFigs) : arg;

    return units ? rounded + ' ' + units : rounded;
}

const customizePointLabel = (pointInfo: any) => {
    const s = pointInfo.series;
    const color = s.getColor();
    const tinyColorObj = tinyColor(color);
    const fontColor = tinyColorObj.isDark() ? 'white' : 'black';
    return {
        font: {
            color: fontColor
        }
    };
}