import './ShareSettings.css';

import { Popup, SelectBox } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { getRoleDescription, SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { ToolbarItem } from 'devextreme-react/popup';
import { sharedGroupRolesList } from './ShareSettings';

type ShareSettingsEditRolePopupProps = {
    currentRole:SharedGraphRoleType|undefined
    showPopup:boolean
    onHiding:() => void
    applyAdd:(selectedRole:SharedGraphRoleType) => Promise<boolean>
}

export function ShareSettingsEditRolePopup(props: ShareSettingsEditRolePopupProps) {
    const defaultRole = SharedGraphRoleType.ReadOnly
    const [selectedRole, setSelectedRole] = useState<SharedGraphRoleType|undefined>(props.currentRole)

    useEffect(() => {
        if (props.showPopup && selectedRole !== props.currentRole) {
            setSelectedRole(props.currentRole)
        }
    }, [props.showPopup, props.currentRole])

    return (
        <Popup 
            wrapperAttr={{class: 'shareGraphWithList'}}
            title={`Edit Shared Role`}
            width='30em'
            height='auto'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
                <SelectBox 
                    dataSource={sharedGroupRolesList}
                    value={selectedRole}
                    onValueChanged={(e:any) => setSelectedRole(e.value as SharedGraphRoleType)}
                    displayExpr='label'
                    valueExpr='value'/>
                <p>
                    {getRoleDescription(selectedRole ?? defaultRole)}
                </p>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    disabled={props.currentRole === selectedRole}
                    location="after"
                    options={{
                        text: 'Apply',
                        onClick: () => props.applyAdd(selectedRole ?? defaultRole)
                    }}/>
        </Popup>
    );
}
export default ShareSettingsEditRolePopup;