import './GraphSetManager.css';
import {GraphSet} from '../../../../Classes/GraphSet';
import { GraphSetUploadSet } from '../../../../Classes/GraphSetUploadSet';
import UploadSetListItem, { UploadSetListItemContextMenuItem } from '../UploadSetFilterableList/UploadSetListItem';
import { Button } from 'devextreme-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import VirtualListDragAndDrop from '../VirtualListDragAndDrop';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGraphSet, fetchGraphSetUploadSets, removeUploadSetsFromSelectedGraphSet, 
    renameGraphSet, 
    renameUploadSet, 
    selectUploadSet, SelectUploadSetPayload, UploadSetListType } from '../../../../Reducers/SetManagementReducer';
import { RootState } from '../../../../Stores/GlobalStore';
import { Dataset } from '../../../../Classes/Dataset';
import { GraphSetNameIsDuplicate } from '../../../../API/GraphSetAPI';
import { SetNamePopup } from '../../../SetNamePopup';
import { confirm } from 'devextreme/ui/dialog';
import UploadSetInfo from '../UploadSetInfoPopup';
import { UploadSetNameIsDuplicate } from '../../../../API/DatasetAPI';
import { invalidateAll } from '../../../../Reducers/ResultsReducer';
import { FindGraphSetInFolder, FindGraphSetParentFolderId } from '../../../../Classes/GraphSetFolder';
import SharedInfoPopup from './SharedInfoPopup';
import QuickComparePopup from '../UploadSetInputs/QuickCompare/QuickComparePopup';
import QuickGraphPopup from './QuickGraph/QuickGraphPopup';
import { GraphTemplate } from '../../../../Classes/Charts/GraphTemplates';
import useKeycloak from '../../../../Keycloak';
import { CopyToGraph } from '../../../../API/GraphTemplateAPI';
import { PutPreviewImage } from '../../../../API/CustomChartAPI';
import { APIRequestStatus } from '../../../../Classes/APIRequestStatus';

interface GraphSetUploadSetsProps {
}

export function GraphSetUploadSets(props:GraphSetUploadSetsProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const userId = useSelector((state:RootState) => state.userData.user?.id);
    const rootGraphSetFolder = useSelector((state:RootState) => state.SetManagementReducer.rootGraphSetFolder);
    const selectedContainerType = useSelector((state:RootState) => state.SetManagementReducer.selectedContainerType);
    const thisUploadSetListIsSelected = selectedContainerType === UploadSetListType.GraphSetUploadSetList;

    const selectedGraphSetId = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetId);
    const selectedGraphSet = FindGraphSetInFolder(rootGraphSetFolder, selectedGraphSetId ?? null);
    const parentFolderId = FindGraphSetParentFolderId(rootGraphSetFolder, selectedGraphSetId ?? null);

    const selectedGraphSetUploadSets = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetUploadSets);
    const totalGraphSetUploadSets = selectedGraphSetUploadSets.length;

    const previouslySelectedGraphSetId = useRef<number|undefined>(undefined);

    const selectedUploadSetIds = useSelector((state:RootState) => state.SetManagementReducer.selectedUploadSetIds);
    const selectedUploadSetCount = selectedUploadSetIds.length;
    const draggingUploadSetId = useSelector((state:RootState) => state.SetManagementReducer.draggingUploadSetId);
    const numberOfUploadSetsBeingDragged = useSelector((state:RootState) => state.SetManagementReducer.numberOfUploadSetsBeingDragged);
    
    const [currentRenamingGraphSetName, setCurrentRenamingGraphSetName] = useState<string|undefined>(undefined);
    const [uploadSetToRename, setUploadSetToRename] = useState<Dataset|undefined>(undefined);
    const [uploadSetToEdit, setUploadSetToEdit] = useState<Dataset|undefined>(undefined);

    const [showSharedInfo, setShowSharedInfo] = useState<boolean>(false);
    const [quickCompareUploadSetIds, setQuickCompareUploadSetIds] = useState<number[]|null>(null);
    const [quickCreateGraphSet, setQuickCreateGraphSet] = useState<GraphSet|null>(null);

    const selectedCustomGraphFolderId = useSelector((state:RootState) => state.customCharts.selectedFolderId);

    useEffect(() => {
        if (selectedGraphSet?.graphSetId && selectedGraphSet.graphSetId !== previouslySelectedGraphSetId.current && keycloak.token) {
            dispatch(fetchGraphSetUploadSets({authToken: keycloak.token, graphSetId: selectedGraphSet.graphSetId }));
            previouslySelectedGraphSetId.current = selectedGraphSet.graphSetId;
        }
    }, [selectedGraphSet])

    const selectUploadSetFromGraphSet = useCallback((newUploadSetId: number, event: React.MouseEvent<HTMLElement>) => {
        const uploadSetIds = selectedGraphSetUploadSets.map((gsus:GraphSetUploadSet) => gsus.uploadSet.datasetId);
        dispatch(selectUploadSet({
            uploadSetIdList: uploadSetIds,
            newUploadSetId: newUploadSetId,
            event: event,
            selectedContainerType: UploadSetListType.GraphSetUploadSetList
        } as SelectUploadSetPayload));
    }, [dispatch, selectedGraphSetUploadSets]);

    const getUploadSetContextMenuItems = useCallback((uploadSet:Dataset) => {
        const isSelected = selectedUploadSetIds.includes(uploadSet.datasetId);
        const actOnSelected = selectedUploadSetCount > 1 && isSelected;
        const showViewEditOption = !actOnSelected;
        const canRenameAndDelete = uploadSet.userId === userId;
        const showRenameOption = !actOnSelected && canRenameAndDelete;
        const showQuickCompare = selectedUploadSetCount > 1;

        const contextMenuItems = new Array<any>();
        if (showViewEditOption) {
            contextMenuItems.push({
                text: 'View/Edit information',
                action: () => {
                    setUploadSetToEdit(uploadSet);
                }
            } as UploadSetListItemContextMenuItem);
            if (showRenameOption)
            {
                contextMenuItems.push({
                    text: 'Rename',
                    action: () => {
                        setUploadSetToRename(uploadSet);
                    }
                } as UploadSetListItemContextMenuItem);
            }
        }
    
        if (showQuickCompare) {
            contextMenuItems.push({
                text: 'Compare selected',
                action: () => {
                    setQuickCompareUploadSetIds(selectedUploadSetIds);
                }
            } as UploadSetListItemContextMenuItem);
        }
    
        contextMenuItems.push({
            text: actOnSelected ? 'Remove selected from graph set' : 'Remove from graph set',
            action: () => {
                const uploadSetIdsToRemove = actOnSelected ? selectedUploadSetIds : [uploadSet.datasetId];
                dispatch(removeUploadSetsFromSelectedGraphSet({authToken: keycloak.token, uploadSetIds: uploadSetIdsToRemove}))
                .then(() => {
                    dispatch(invalidateAll());
                });
            }
        });

        return contextMenuItems;
    }, [selectedUploadSetIds, selectedUploadSetCount]);

    if (selectedGraphSet == null) {
        return (
            <div className='graphSetUploadSets'>
                <div className='setsEmptyMessage'>
                    Select a graph set from the list on the left to add, remove, or rearrange its upload sets.
                </div>
            </div>
        );
    }

    const isGraphSetNameDuplicate = async (name:string) : Promise<boolean> => {
        if (parentFolderId == null || keycloak.token == null)
            return false;
        return GraphSetNameIsDuplicate(keycloak.token, parentFolderId, name);
    }

    const isUploadSetNameDuplicate = async (name:string) : Promise<boolean> => {
        return keycloak.token ? UploadSetNameIsDuplicate(keycloak.token, name) : false;
    }

    const applyRenameGraphSet = (newName:string) => {
        dispatch(renameGraphSet({authToken: keycloak.token, name: newName, graphSetId: selectedGraphSetId}))
        .then(() => {
            dispatch(invalidateAll());
        });
    }

    const applyDeleteGraphSet = () => {
        const confirmDelete = confirm(`Are you sure you want to delete ${selectedGraphSet?.name}?`, "Confirm Delete");  
        confirmDelete.then((deleteConfirmed:boolean) => {
            if (deleteConfirmed && keycloak.token) { 
                dispatch(deleteGraphSet({authToken: keycloak.token, graphSetId: selectedGraphSetId}))
                .then(() => {
                    dispatch(invalidateAll());
                });
            }
        });
    }

    const rootElement = document.getElementById('root');
    const rem = rootElement ? parseFloat(getComputedStyle(rootElement).fontSize) : 0;
    return (
        <div className='graphSetUploadSets'>
            <h4 className='graphSetUploadSetsHeader'>
                {selectedGraphSet ? selectedGraphSet.name : "No graph set selected."}
                <Button
                    icon="chart"
                    type='success'
                    visible={selectedGraphSetUploadSets.length > 0}
                    onClick={() => setQuickCreateGraphSet(selectedGraphSet)}
                    hint='Create graph from template'/>
                <Button
                    icon="notequal"
                    type='normal'
                    visible={selectedGraphSetUploadSets.length > 1}
                    onClick={() => setQuickCompareUploadSetIds(selectedGraphSetUploadSets.map(i => i.uploadSet.datasetId))}
                    hint='Quick compare'/>
                <Button
                    icon="share"
                    type='normal'
                    visible={selectedGraphSet.isSharedByMe || selectedGraphSet.isSharedWithMe}
                    onClick={() => setShowSharedInfo(true)}
                    hint='Sharing information'/>
                <Button
                    icon="edit"
                    type='normal'
                    visible={!selectedGraphSet?.isSharedWithMe}
                    onClick={() => setCurrentRenamingGraphSetName(selectedGraphSet?.name)}
                    hint='Rename'/>
                <Button
                    icon="trash"
                    type='danger'
                    visible={!selectedGraphSet?.isSharedWithMe}
                    onClick={() => applyDeleteGraphSet()}
                    hint='Delete'/>
            </h4>
                <div className='graphSetUploadSetsContent'>
                    {totalGraphSetUploadSets === 0 &&
                    <div className='setsEmptyMessage'>
                        Drag upload sets here to add them to the graph set.
                    </div>
                    }
                    <VirtualListDragAndDrop
                        listDropId={"graphSetUploadSetListDroppable" + selectedGraphSet?.graphSetId}
                        getItemDragId={(gsus:GraphSetUploadSet) => `graphSetUploadSetListDraggable${gsus.uploadSet.datasetId}`}
                        isInfiniteList={false}
                        items={selectedGraphSetUploadSets}
                        itemHeight={5.25*rem}
                        renderRow={(gsus:GraphSetUploadSet) => {
                            return (
                                <UploadSetListItem 
                                    key={gsus.uploadSet.datasetId}
                                    uploadSet={gsus.uploadSet}
                                    selectUploadSet={selectUploadSetFromGraphSet}
                                    isSelected={thisUploadSetListIsSelected && selectedUploadSetIds.includes(gsus.uploadSet.datasetId)}
                                    draggingUploadSetId={draggingUploadSetId} 
                                    getContextMenuItems={getUploadSetContextMenuItems}
                                    contextMenuHidden={currentRenamingGraphSetName != null || uploadSetToRename != null || uploadSetToEdit != null}/>
                            )}}
                        loadMoreEnabled={false}
                        loadMoreFunction={() => {}}
                        totalItems={totalGraphSetUploadSets}
                        numberOfItemsBeingDragged={numberOfUploadSetsBeingDragged}/>
            </div>
            <SetNamePopup
                title='Rename Graph Set'
                validateForDuplicate={isGraphSetNameDuplicate}
                applyButtonName='Apply'
                oldName={currentRenamingGraphSetName ?? ''}
                showPopup={currentRenamingGraphSetName != null}
                hidePopup={() => setCurrentRenamingGraphSetName(undefined)}
                applySetName={applyRenameGraphSet}/>
            <UploadSetInfo 
                hidePopup={() => setUploadSetToEdit(undefined)}
                uploadSet={uploadSetToEdit}/>
            <SetNamePopup
                title='Rename Upload Set'
                validateForDuplicate={isUploadSetNameDuplicate}
                applyButtonName='Apply'
                oldName={uploadSetToRename?.datasetName ?? ''}
                showPopup={uploadSetToRename != null}
                hidePopup={() => setUploadSetToRename(undefined)}
                applySetName={(newName:string) => {
                    dispatch(renameUploadSet({authToken: keycloak.token, name: newName, uploadSetId: uploadSetToRename?.datasetId}))
                    .then(() => {
                        dispatch(invalidateAll());
                    });
                }}/>
            <SharedInfoPopup
                graphSet={selectedGraphSet}
                showPopup={showSharedInfo}
                hidePopup={() => setShowSharedInfo(false)}/>
            <QuickComparePopup 
                uploadSetIds={quickCompareUploadSetIds}
                onHiding={() => setQuickCompareUploadSetIds(null)}/>
            <QuickGraphPopup
                show={quickCreateGraphSet != null}
                graphSet={quickCreateGraphSet}
                hidePopup={() => setQuickCreateGraphSet(null)}
                createFromTemplate={(template:GraphTemplate|undefined, graphSetIds:number[], previewImageBlob:Blob|null) => {
                    if (template) {
                        const currSelectedFolderId = selectedCustomGraphFolderId;
                        CopyToGraph(keycloak.token, template.chartId, currSelectedFolderId, template.title, graphSetIds).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                const newGraphInfo = response.data;
                                if (newGraphInfo != null) {
                                    // Save the preview image to the db
                                    if (previewImageBlob != null) {
                                        PutPreviewImage(keycloak.token, newGraphInfo.id, previewImageBlob);
                                    }
                                }

                                APIRequestStatus.showToast(response);
                            }
                        });

                        setQuickCreateGraphSet(null);
                    }
                }}/>
        </div>
    );
}
export default GraphSetUploadSets;