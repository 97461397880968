export class SummaryData { 
    filteredUploadSets: number|undefined;
    filteredUsers: number|undefined;
    filterableUsers: any;
    filteredCompanies: number|undefined;
    filterableCompanies: any;
    filteredPrograms: number|undefined;
    filterablePrograms: any;
    filteredTags: number|undefined;
    filterableTags: any;
    filteredDatabases: number|undefined;
    filterableDatabases: any;
    filteredProjects: number|undefined;
    filterableProjects: any;
    filteredStructures: number|undefined;
    filterableStructures: any;
    filteredDisplaySets: number|undefined;
    filterableDisplaySets: any;
    filteredDesigns: number|undefined;
    filterableDesigns: any;
    filteredMaterials: number|undefined;
    filterableMaterials: any;
    filteredZones: number|undefined;
    filterableZones: any;

    constructor() {
        this.filteredUploadSets = undefined;
        this.filteredUsers = undefined;
        this.filterableUsers = undefined;
        this.filteredCompanies = undefined;
        this.filterableCompanies = undefined;
        this.filteredPrograms = undefined;
        this.filterablePrograms = undefined;
        this.filteredTags = undefined;
        this.filterableTags = undefined;
        this.filteredDatabases = undefined;
        this.filterableDatabases = undefined;
        this.filteredProjects = undefined;
        this.filterableProjects = undefined;
        this.filteredStructures = undefined;
        this.filterableStructures = undefined;
        this.filteredDisplaySets = undefined;
        this.filterableDisplaySets = undefined;
        this.filteredDesigns = undefined;
        this.filterableDesigns = undefined;
        this.filteredMaterials = undefined;
        this.filterableMaterials = undefined;
        this.filteredZones = undefined;
        this.filterableZones = undefined;
    }
}