import * as ApiFetchUtility from '../Utilities/APIFetchUtility';
import { CustomChartSettings } from '../Classes/Charts/CustomChartSettings';
import { UnitSystemType } from '../Classes/User';
import { APIRequestStatus } from '../Classes/APIRequestStatus';
import { APIResponseWithStatus } from '../Classes/APIResponseWithStatus';
import { AggregationType } from '../Classes/Enums/AggregationType';
import { AxisOrderType, ChartType, PointLabelType } from '../Classes/Charts/ChartSettingsTypes';
import { GraphInfo } from '../Classes/Charts/GraphInfo';
import { TrendlineType } from '../Classes/Enums/TrendlineType';

export async function CreateDbGraph(authToken:string, name:string, parentFolderId:number|null): Promise<APIResponseWithStatus<GraphInfo>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    let queryString = ``;
    if (parentFolderId) {
        queryString = `?parentFolderId=${parentFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `CreateGraph${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function PutCustomChart(authToken:string, customChart:CustomChartSettings): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(customChart);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", "PutCustomChart"); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetPreviewImageBlob(authToken:string, customChartId:number): Promise<Blob|undefined> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", "GetPreviewImage?customChartId=" + customChartId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const blob = await res.blob();

    return blob.size > 0 ? blob : undefined;
}

export async function PutPreviewImage(authToken:string, customChartId:number, imageBlob:Blob): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken, 'image/png');
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = imageBlob;

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", "PutPreviewImage?customChartId=" + customChartId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteCustomChart(authToken:string, customChartId:number): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", "DeleteCustomChart?customChartId=" + customChartId); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function CustomChartNameIsDuplicate(authToken:string|undefined, name:string, parentFolderId:number|null): Promise<boolean> {
    if (!authToken)
        return false;

    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';
    fetchOptions['body'] = JSON.stringify(name);

    let parentQueryString = ``;
    if (parentFolderId) {
        parentQueryString = `?parentFolderId=${parentFolderId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `IsNameDuplicate${parentQueryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SelectDbGraph(authToken:string, graphId:number|null): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let queryString = '';
    if (graphId != null) {
        queryString = `?graphId=${graphId}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SelectGraph${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DuplicateDbGraph(authToken:string, graphId:number): Promise<APIResponseWithStatus<GraphInfo>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `DuplicateGraph?graphId=${graphId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SaveCustomChartNotes(authToken:string, customChartId:number, notes:string): Promise<boolean> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(notes);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SaveCustomChartNotes?customChartId=${customChartId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetCustomChartSharedToPublic(authToken:string, customChartId:number, isSharedToPublic:boolean): Promise<string> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetChartSharedToPublic?customChartId=${customChartId}&isSharedToPublic=${isSharedToPublic}`);

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function GetHorizontalGuideValue(authToken:string, customChartId:number, unitSystem:UnitSystemType): Promise<APIResponseWithStatus<number|undefined>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `GetHorizontalGuideValue?customChartId=${customChartId}&unitSystem=${unitSystem}`); 
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function PutHorizontalGuideValue(authToken:string, customChartId:number, unitSystem:UnitSystemType, value:number|undefined): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    // Can't send a null value, so call a different method instead
    if (value == null) {
        let url = ApiFetchUtility.getFormattedUrl("CustomChart", `ClearHorizontalGuideValue?customChartId=${customChartId}`); 
        const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
        const json = await res.json();
        return json;
    }

    // If value is not null, send normal API call
    fetchOptions['body'] = JSON.stringify(value);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `PutHorizontalGuideValue?customChartId=${customChartId}&unitSystem=${unitSystem}`); 
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SelectDbGraphSets(authToken:string, graphId:number, graphSetIds:number[]): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(graphSetIds);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SelectGraphSets?graphId=${graphId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}


export async function GetDbGraph(authToken:string, graphId:number): Promise<APIResponseWithStatus<CustomChartSettings>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `GetGraph?graphId=${graphId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RenameDbGraph(authToken:string, graphId:number, name:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(name);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `RenameGraph?graphId=${graphId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddDbDataSourceColumn(authToken:string, graphId:number, propertyKey:string, aggregationType:AggregationType): Promise<APIResponseWithStatus<number>> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `AddDataSourceColumn?graphId=${graphId}&propertyKey=${propertyKey}&aggregationType=${aggregationType}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function DeleteDbDataSourceColumn(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `DeleteDataSourceColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function AddDbDataSourceCategory(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'post';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `AddDataSourceCategory?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveDbDataSourceCategory(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `RemoveDataSourceCategory?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbBubbleSizeColumn(authToken:string, dataSourceColumnId:number|null): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetBubbleSizeColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbXAxisColumn(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetXAxisColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisColumn(authToken:string, dataSourceColumnId:number, dataSourceColumnIdToReplace:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisColumn?dataSourceColumnId=${dataSourceColumnId}&dataSourceColumnIdToReplace=${dataSourceColumnIdToReplace}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveDbYAxisColumn(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `RemoveYAxisColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisGraphType(authToken:string, dataSourceColumnId:number, graphType:ChartType): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisGraphType?dataSourceColumnId=${dataSourceColumnId}&graphType=${graphType}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbColorByColumn(authToken:string, dataSourceColumnId:number, dataSourceColumnIdToReplace:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetColorByColumn?dataSourceColumnId=${dataSourceColumnId}&dataSourceColumnIdToReplace=${dataSourceColumnIdToReplace}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveDbColorByColumn(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `RemoveColorByColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbStackByColumn(authToken:string, dataSourceColumnId:number, dataSourceColumnIdToReplace:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetStackByColumn?dataSourceColumnId=${dataSourceColumnId}&dataSourceColumnIdToReplace=${dataSourceColumnIdToReplace}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function RemoveDbStackByColumn(authToken:string, dataSourceColumnId:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'delete';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `RemoveStackByColumn?dataSourceColumnId=${dataSourceColumnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbAxisOrder(authToken:string, graphId:number, newValue:AxisOrderType): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetAxisOrder?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbAxisLabelOverflowMode(authToken:string, graphId:number, newValue:AxisOrderType): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetAxisLabelOverflowMode?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbSignificantFigures(authToken:string, graphId:number, newValue:number|null): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(newValue);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetSignificantFigures?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbSolutionsShown(authToken:string, graphId:number, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetSolutionsShown?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisShowZero(authToken:string, graphId:number, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisShowZero?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisTryCombineAxes(authToken:string, graphId:number, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisTryCombineAxes?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbShowXAxisGridLines(authToken:string, graphId:number, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetShowXAxisGridLines?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbShowYAxisGridLines(authToken:string, graphId:number, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetShowYAxisGridLines?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbPointLabelMode(authToken:string, graphId:number, newValue:PointLabelType): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetPointLabelMode?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbAxisLabelCutoff(authToken:string, graphId:number, newValue:number|undefined): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}`;
    if (newValue != null) {
        queryString += `&newValue=${newValue}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetAxisLabelCutoff${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbHorizontalGuideValue(authToken:string, graphId:number, newValue:number|undefined): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}`;
    if (newValue != null) {
        queryString += `&newValue=${newValue}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetHorizontalGuideValue${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbShowHorizontalGuideLabels(authToken:string, graphId:number, newValue:number|undefined): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetShowHorizontalGuideLabels?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbSeriesVisibility(authToken:string, graphId:number, seriesKey:string, newValue:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(seriesKey);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetSeriesVisibility?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbSeriesColor(authToken:string, graphId:number, seriesKey:string, newValue:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(seriesKey);

    // Strip the hash from the hex value before sending it
    let formattedValue = null;
    if (newValue != null && newValue.length === 9) {
        formattedValue = newValue.slice(1);
    }

    let queryString = `?graphId=${graphId}`;
    if (formattedValue != null) {
        queryString += `&newValue=${formattedValue}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetSeriesColor${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbTrendlineType(authToken:string, graphId:number, newValue:TrendlineType): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetTrendlineType?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbTrendlineOrder(authToken:string, graphId:number, newValue:number): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    
    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetTrendlineOrder?graphId=${graphId}&newValue=${newValue}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbColumnFilters(authToken:string, columnId:number, filterValues:string[]): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(filterValues);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetColumnFilters?columnId=${columnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbColumnCustomName(authToken:string, columnId:number, customName?:string): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';
    fetchOptions['body'] = JSON.stringify(customName);

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetColumnCustomName?columnId=${columnId}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbXAxisRange(authToken:string, graphId:number, newMinValue:number|null, newMaxValue:number|null): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}`;
    if (newMinValue != null) {
        queryString += `&newMinValue=${newMinValue}`;
    }
    if (newMaxValue != null) {
        queryString += `&newMaxValue=${newMaxValue}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetXAxisRange${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisRange(authToken:string, graphId:number, newMinValue:number|null, newMaxValue:number|null): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}`;
    if (newMinValue != null) {
        queryString += `&newMinValue=${newMinValue}`;
    }
    if (newMaxValue != null) {
        queryString += `&newMaxValue=${newMaxValue}`;
    }

    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisRange${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbXAxisLogScale(authToken:string, graphId:number, newVal:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}&&logEnabled=${newVal}`;
    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetXAxisLogScale${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}

export async function SetDbYAxisLogScale(authToken:string, graphId:number, newVal:boolean): Promise<APIRequestStatus> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'put';

    let queryString = `?graphId=${graphId}&&logEnabled=${newVal}`;
    let url = ApiFetchUtility.getFormattedUrl("CustomChart", `SetYAxisLogScale${queryString}`); 

    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}