import { memo } from "react";

type DocumentProps = {
    data:any
}

function Document(props:DocumentProps) {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}>
            <object data={props.data} type="application/pdf" width="100%" height="100%">
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '1.2rem'
                }}>
                    <p>Oops! Your browser doesn't support PDFs.</p>
                    <a href={props.data}>Click here to download the PDF file.</a>
                </div>
            </object>
        </div>
    );
}
export default memo(Document);