import { DataSourceColumn } from "../DataSourceColumn";
import { ResultPropertyInfo, getColumnDisplayName } from "../ResultPropertyInfo";
import { AxisOrderType, ChartSettings, ChartType } from "./ChartSettingsTypes";
import { chartTypes } from "../../Components/Charts/CommonChartTypes";
import { generateColors } from "devextreme/viz/palette";
import { generateColorGradient } from "../../Utilities/SeriesColorUtils";
import { naturalCompare } from "../../Utilities/CommonUtilities";
import { TrendlineType } from "../Enums/TrendlineType";
import regression from 'regression';
import RegressionHelper from "./RegressionHelper";

// This is used to initialize a series color before it is set later on in the code.
const blankColor = '#000000';

export class GraphSeries {
    key: string;
    sizeKey: string|undefined;
    displayName: string;
    sizeDisplayName: string|undefined;
    // This type field is in a format that the DevExtreme chart expects, which is a string.
    type: string;
    axisKey: string;
    color: string;
    firstColorCategoryValueString: string;
    unitsLabel: string;
    pointShape: "circle" | "polygon" | "cross" | "square" | "triangle" | "triangleDown" | "triangleUp" | undefined;
    visible: boolean = true;
    stack: string|undefined;
    isNumeric: boolean = false;
    regressionHelper: RegressionHelper|null = null;

    constructor(key:string, displayName:string, type:ChartType, axisKey:string, unitsLabel:string) {
        this.key = key;
        this.displayName = displayName;
        this.type = (chartTypes.find(i => i.type === type)?.value) ?? 'scatter';
        this.axisKey = axisKey;
        this.color = blankColor;
        this.firstColorCategoryValueString = '';
        this.unitsLabel = unitsLabel;
    }

    // Returns a number signifying the order in which to render the series (e.g. bars should be rendered before lines).
    getTypeLayer() {
        const lowerType = this.type.toLowerCase();
        if (lowerType.includes('area'))
            return 0;
        if (lowerType.includes('bar'))
            return 1;
        if (lowerType.includes('bubble'))
            return 2;

        return 3;
    }
}

export class GraphValueAxis {
    key: string;
    displayName: string;
    unitsLabel: string;
    
    constructor(key:string, displayName:string, unitsLabel:string) {
        this.key = key;
        this.displayName = displayName;
        this.unitsLabel = unitsLabel;
    }
}

// If the user puts the seperator in their data, there's a chance that the series logic could be disrupted.
const seriesKeySeperator = '_&_';

export class GraphDataSource {
    formattedResultsData: any[] = [];
    series: GraphSeries[] = [];
    horizontalGuideIntersectionSeries: GraphSeries[] = [];
    trendlineSeries: GraphSeries[] = [];
    argumentAxisName: string = '';
    argumentAxisUnitsLabel: string = '';
    valueAxes: GraphValueAxis[] = [];
    numericColumnIds: Set<number> = new Set<number>();

    constructor(dataSourceTable:any[], propertyKeyDictionary:{[propertyKey: string]: ResultPropertyInfo}, graphSettings:ChartSettings) {
        const columns = graphSettings.dataSourceColumns;
        const xAxisColumnId = graphSettings.xAxisColumnId;
        const yAxes = graphSettings.yAxes;
        const colorCategoryIds = graphSettings.colorByColumnIds;

        // Get the ids of columns that are actually numeric. 
        graphSettings.dataSourceColumns.forEach(col => {
            // If we know this value is numeric given its property type, we don't need to check the actual values.
            const propInfo = propertyKeyDictionary[col.propertyKey];
            if (propInfo != null && propInfo.isNumeric) {
                this.numericColumnIds.add(col.id);
            }
            else if (col.id !== 0){
                const colResults = dataSourceTable.map(row => row[col.id]);
                const allValsNumeric = colResults.every(i => {
                    // Just return true if item does not have a value.
                    if (i == null) {
                        return true;
                    }
                    return !isNaN(i);
                });
                if (allValsNumeric) {
                    this.numericColumnIds.add(col.id);
                }
            }
        });

        // Set the X axis name
        const xColumn = xAxisColumnId ? columns.find(i => i.id === xAxisColumnId) : null;
        let xIsNumeric = false;
        if (xColumn != null && xColumn.propertyKey in propertyKeyDictionary) {
            const xPropInfo = propertyKeyDictionary[xColumn.propertyKey];
            this.argumentAxisName = getColumnDisplayName(xColumn, propertyKeyDictionary);;
            if (xPropInfo.unitLabel) {
                this.argumentAxisName += ` (${xPropInfo.unitLabel})`;
                this.argumentAxisUnitsLabel = xPropInfo.unitLabel;
            }
            xIsNumeric = this.numericColumnIds.has(xColumn.id);
        }
        
        // Build a lookup of y axis column ids by units. This is used to combine y axes with equal types if needed. 
        const yColumnNamesByUnits = new Map<string, string[]>();
        if (graphSettings.yAxisTryCombineAxes) {
            yAxes.forEach((yAxis) => {
                const yColumn = columns.find(i => i.id === yAxis.dataSourceColumnId);
                if (yColumn && yColumn.propertyKey in propertyKeyDictionary) {
                    const propInfo = propertyKeyDictionary[yColumn.propertyKey];
                    const displayName = getColumnDisplayName(yColumn, propertyKeyDictionary);
                    if (yColumnNamesByUnits.has(propInfo.unitLabel)) {
                        yColumnNamesByUnits.get(propInfo.unitLabel)?.push(displayName);
                    }
                    else {
                        yColumnNamesByUnits.set(propInfo.unitLabel, [displayName]);
                    }
                }
            });
        }

        const seriesKeys = new Set<string>();
        const valueAxesByKey = new Map<string, GraphValueAxis>();
        yAxes.forEach((yAxis) => {
            const yColumn = columns.find(i => i.id === yAxis.dataSourceColumnId);
            const sizeColumn = columns.find(i => i.id === graphSettings.bubbleSizeColumnId);
            if (yColumn && yColumn.propertyKey in propertyKeyDictionary) {
                const propInfo = propertyKeyDictionary[yColumn.propertyKey];

                // Create one value axis per y axis object. 
                let axisKey:string = 'Unknown';
                if (graphSettings.yAxisTryCombineAxes) {
                    const axesColumnNames = yColumnNamesByUnits.get(propInfo.unitLabel);
                    if (axesColumnNames != null) {
                        let axisName = axesColumnNames?.join(', ');
                        if (propInfo.unitLabel) {
                            axisName += ` (${propInfo.unitLabel})`;
                        }
                        axisKey = axisName;
                        if (!valueAxesByKey.has(axisKey)) {
                            valueAxesByKey.set(axisKey, new GraphValueAxis(axisKey, axisName, propInfo.unitLabel));
                        }
                    }
                }
                else {
                    let axisName = getColumnDisplayName(yColumn, propertyKeyDictionary);
                    if (propInfo.unitLabel) {
                        axisName += ` (${propInfo.unitLabel})`;
                    }
                    axisKey = axisName;
                    valueAxesByKey.set(axisKey, new GraphValueAxis(axisKey, axisName, propInfo.unitLabel));
                }

                const columnDataPoints:any[] = [];
                const columnSeries:GraphSeries[] = [];
                dataSourceTable.forEach((row) => {
                    const seriesKey = getSeriesKey(row, yAxis.dataSourceColumnId, colorCategoryIds);
                    const sizeKey = seriesKey + '_size';
                    const stackKey = getStackKey(row, graphSettings.stackColumnIds);

                    // Keep track of the series objects
                    if (!seriesKeys.has(seriesKey)) {
                        seriesKeys.add(seriesKey);
                        const hasMultipleYAxes = yAxes.length > 1;
                        const displayName = getSeriesDisplayName(row, yColumn, colorCategoryIds, hasMultipleYAxes, propertyKeyDictionary);

                        const newSeries = new GraphSeries(seriesKey, displayName, yAxis.chartType, axisKey, propInfo.unitLabel);
                        newSeries.stack = stackKey;
                        newSeries.isNumeric = this.numericColumnIds.has(yColumn.id);

                        if (sizeColumn != null) {
                            newSeries.sizeKey = sizeKey;
                            newSeries.sizeDisplayName = getColumnDisplayName(sizeColumn, propertyKeyDictionary);
                            const sizePropInfo = propertyKeyDictionary[sizeColumn.propertyKey];
                            if (sizePropInfo?.unitLabel) {
                                newSeries.sizeDisplayName += ` (${sizePropInfo.unitLabel})`;
                            }
                        }

                        if (seriesKey in graphSettings.customSeriesDataByKey) {
                            const customSeriesData = graphSettings.customSeriesDataByKey[seriesKey];
                            newSeries.visible = customSeriesData.visible;
                            newSeries.color = customSeriesData.color ?? blankColor;
                        }

                        // We'll use this data later on to assign series colors
                        newSeries.firstColorCategoryValueString = getFirstCategoryValueString(row, yAxis.dataSourceColumnId, colorCategoryIds, yAxes.length);

                        columnSeries.push(newSeries);
                    }

                    const newColumnPoint = {
                        x: xAxisColumnId ? row[xAxisColumnId] : null,
                        [seriesKey]: row[yAxis.dataSourceColumnId],
                        graphSetIndex: row.graphSetIndex
                    };

                    // Set the bubble size if needed
                    if (sizeKey && graphSettings.bubbleSizeColumnId && yAxis.chartType === ChartType.Bubble) {
                        newColumnPoint[sizeKey] = row[graphSettings.bubbleSizeColumnId];
                    }

                    // Create a point for this axis at this row
                    columnDataPoints.push(newColumnPoint);
                });
                this.series = this.series.concat(columnSeries);

                this.formattedResultsData = [...this.formattedResultsData, ...columnDataPoints];
            }
        });

        // Sort by x axis values. Always sort alphabetically, then by graph set if needed.
        this.formattedResultsData.sort((point1, point2) => naturalCompare(point1.x, point2.x))
        if (graphSettings.axisOrder === AxisOrderType.GraphSet) {
            this.formattedResultsData.sort((point1, point2) => naturalCompare(point1.graphSetIndex, point2.graphSetIndex))
        }

        // Set axes objects using the lookup we just generated.
        this.valueAxes = Array.from(valueAxesByKey.values());

        // Sort the series by name
        this.series.sort((a,b) => naturalCompare(a.displayName, b.displayName));

        // Generate colors for series.
        this.populateSeriesColors();

        // Generate trendline series if needed
        if (graphSettings.trendlineType !== TrendlineType.None && xIsNumeric) {
            this.populateTrendlineSeries(graphSettings);
        }

        // Add horizontal guide intersections if needed.
        if (graphSettings.yAxisConstantLineValue != null) {
            const allSeriesToIntersect = [...this.series, ...this.trendlineSeries];
            const seriesIntersectionVals = getHorizontalGuideIntersections(graphSettings.yAxisConstantLineValue, this.formattedResultsData, 
                allSeriesToIntersect, this.valueAxes);

            if (seriesIntersectionVals != null && seriesIntersectionVals.length > 0) {
                const intersectionSeriesKey = 'yIntersection';

                const firstAxis = this.valueAxes[0];
                const newIntersectionSeries = new GraphSeries(intersectionSeriesKey, 'Horizontal Guide Intersection', ChartType.Scatter, 
                    firstAxis.key, firstAxis.unitsLabel);

                newIntersectionSeries.color = '#707070';

                seriesIntersectionVals.forEach(xVal => {
                    const newIntersectionPoint = {
                        x: xVal,
                        [intersectionSeriesKey]: graphSettings.yAxisConstantLineValue
                    };
                    this.formattedResultsData.push(newIntersectionPoint);
                });

                this.horizontalGuideIntersectionSeries.push(newIntersectionSeries);
            }
        }
    } 
    
    // Generate a list of colors for each series. Use distinct colors for the first category and a gradient for other categories. 
    populateSeriesColors() {
        // Get a mapping of root category value to a root color.
        const rootColorByVal = new Map<string, string>();
        const rootValues = new Set<string>();
        this.series.forEach((currSeries) => {
            rootValues.add(currSeries.firstColorCategoryValueString);
        });
        const rootValArray = Array.from(rootValues.values()).sort(naturalCompare);
        const palette = "Carmine";
        const outerColorCollection = generateColors(palette as any, rootValArray.length, {});
        rootValArray.forEach((val, index) => {
            rootColorByVal.set(val, outerColorCollection[index]);
        });

        // For each root value, iternate over series with that root value and generate derived colors
        rootValArray.forEach((rootVal) => {
            const seriesAtThisValue:GraphSeries[] = [];
            this.series.forEach((currSeries) => {
                if (rootVal === currSeries.firstColorCategoryValueString) {
                    seriesAtThisValue.push(currSeries);
                }
            });

            // Get one derived color for each series in this root value
            const baseColor = rootColorByVal.get(rootVal) ?? blankColor;
            const currRootDerivedColors = generateColorGradient(baseColor, seriesAtThisValue.length);
            seriesAtThisValue.forEach((currSeries, index) => {
                // Don't bother setting the color if one already exists
                if (currSeries.color === blankColor) {
                    currSeries.color = currRootDerivedColors[index];
                }
            });
        });
    }

    populateTrendlineSeries(graphSettings:ChartSettings) {
        // Trendline generation settings
        const maxPointCount = 300;
        const basePointPoint = 75;
        const orderPointMultiplier = 3;
        const orderPointExpon = 2.3;

        const trendlineSeriesList:GraphSeries[] = [];
        const trendlinePoints:any = [];

        const xVals = new Array<number>();
        this.formattedResultsData.forEach((point)=> {
            // Only consider x axis values that are numbers
            if (!isNaN(point.x)) {
                xVals.push(point.x);
            }
        });

        const xValsForMinAndMax = [...xVals];
        if (graphSettings.xAxisMinValue != null) {
            xValsForMinAndMax.push(graphSettings.xAxisMinValue);
        }
        if (graphSettings.xAxisMaxValue != null) {
            xValsForMinAndMax.push(graphSettings.xAxisMaxValue);
        }

        const minX = Math.min(...xValsForMinAndMax);
        const maxX = Math.max(...xValsForMinAndMax);

        this.series.forEach((series) => {
            const shouldShowTrendline = series.isNumeric && series.visible;
            if (!shouldShowTrendline) {
                return;
            }

            // Just treat order as 1 if the trendline type is unrelated to order.
            let order = 1;
            if (graphSettings.trendlineType === TrendlineType.Polynomial) {
                order = graphSettings.trendlineOrder;
            }
            // Start with a base number of points for lines, then add the multiplier for each order after that.
            const trendPointCount = Math.min(basePointPoint + (orderPointMultiplier * Math.pow(order - 1, orderPointExpon)), maxPointCount);
            const increment = (maxX - minX) / (trendPointCount - 1);

            const seriesPoints: regression.DataPoint[] = [];
            this.formattedResultsData.forEach((point) => {
                if (series.key in point && !isNaN(point[series.key])) {
                    const xVal = point.x as number;
                    const yVal = point[series.key];
                    if (yVal != null && !isNaN(yVal)) {
                        seriesPoints.push([xVal, yVal] as regression.DataPoint);
                    }
                }
            });

            const regOptions = {
                precision: 50,
                order: graphSettings.trendlineOrder
            } as regression.Options;

            const trendKey = series.key + '_trendline';
            const regressionHelper = RegressionHelper.getRegressionHelper(graphSettings.trendlineType, seriesPoints, regOptions);
            if (regressionHelper != null) {
                for (let pointIndex = 0; pointIndex < trendPointCount; pointIndex++) {
                    const currX = minX + (increment * pointIndex);
                    const regPoint = regressionHelper.predict(currX);
                    const trendPoint = {
                        x: currX,
                        [trendKey]: regPoint[1]
                    };
                    trendlinePoints.push(trendPoint);
                }
            }

            let trendDisplayName = 'Trendline';
            if (series.displayName !== '') {
                trendDisplayName = series.displayName + ' (Trendline)';
            }
            const trendlineSeries = new GraphSeries(trendKey, trendDisplayName, ChartType.Line, series.axisKey, series.unitsLabel);
            trendlineSeries.regressionHelper = regressionHelper;
            trendlineSeries.color = series.color;

            trendlineSeriesList.push(trendlineSeries);
        });

        this.trendlineSeries = trendlineSeriesList;
        this.formattedResultsData = [...this.formattedResultsData, ...trendlinePoints];
    }
}

export const getSeriesKey = (rowData:any, yColumnId:number, colorCategoryColumnIds:number[]) => {
    const colorByList:string[] = [];
    colorCategoryColumnIds?.forEach(colorCategoryColumnId => {
        colorByList.push(rowData[colorCategoryColumnId]?.toString());
    });
    const listToKey:any[] = [yColumnId, ...colorByList];
    let key = listToKey.join(seriesKeySeperator);

    // Constrain series key to 250 characters. Add an ellipses after 250 to signify this limit. The database field for series keys
    // is limited to 255 characters. This could cause problems where two series have the same key. However, this shouldn't be a 
    // significant concern, since most series names will be well below this character limit.
    const maxKeyLength = 250;
    if (key.length > maxKeyLength) {
        key = key.slice(0, maxKeyLength) + '...';
    }

    return key;
};

export const getStackKey = (rowData:any, stackColumnIds:number[]) => {
    const stackValList:string[] = [];
    stackColumnIds?.forEach(stackColumnId => {
        stackValList.push(rowData[stackColumnId]?.toString());
    });
    let key = stackValList.join(seriesKeySeperator);

    if (stackValList.length === 0) {
        return undefined;
    }

    // Constrain key to 250 characters. Add an ellipses after 250 to signify this limit. 
    const maxKeyLength = 250;
    if (key.length > maxKeyLength) {
        key = key.slice(0, maxKeyLength) + '...';
    }

    return key;
};

const getFirstCategoryValueString = (rowData:any, yColumnId:number, colorCategoryColumnIds:number[], yAxisCount:number) => {
    if (yAxisCount > 1)
        return yColumnId.toString();
    
    if (colorCategoryColumnIds?.length > 0)
        return rowData[colorCategoryColumnIds[0]]?.toString();

    return '';
};

export const getSeriesDisplayName = (rowData:any, yColumn:DataSourceColumn, colorCategoryColumnIds:number[], hasMultipleYAxes:boolean, 
    propertyKeyDictionary:{[propertyKey: string]: ResultPropertyInfo}) => {
    const nameSections:any[] = [];

    if (hasMultipleYAxes) {
        const colDisplayName = getColumnDisplayName(yColumn, propertyKeyDictionary);
        nameSections.push(colDisplayName);
    }

    colorCategoryColumnIds?.forEach(colorCategoryColumnId => {
        nameSections.push(rowData[colorCategoryColumnId]);
    });
    const displayName = nameSections.join(', ');

    return displayName;
};

const getHorizontalGuideIntersections = (constVal:number, formattedResultsData:any[], seriesList:GraphSeries[], valueAxes:GraphValueAxis[]) => {
    let xValuesOfIntersections = new Array<number>();
    
    // Do not continue if we are missing the required data
    if (valueAxes == null || seriesList == null || valueAxes.length === 0 || seriesList.length === 0) {
        return xValuesOfIntersections;
    }

    const firstAxis = valueAxes[0];
    const allowedChartTypes = chartTypes.filter(i => {
        switch(i.type) {
            case ChartType.Line:
            case ChartType.Area:
                return true;
            default:
                return false;
        }
    }).map(i => i.value);
    const firstAxisSeriesList = seriesList.filter(i => i.axisKey === firstAxis.key && allowedChartTypes.includes(i.type) && i.visible);

    // Get series points
    const seriesPointMap = new Map<string, any[]>();
    formattedResultsData.forEach((point:any) => {
        firstAxisSeriesList.forEach((seriesItem:GraphSeries) => {
            const seriesKey = seriesItem.key;
            if (seriesKey in point) {
                if (seriesPointMap.has(seriesKey)) {
                    const pointList = seriesPointMap.get(seriesKey) as any[];
                    pointList.push(point);
                }
                else {
                    seriesPointMap.set(seriesKey, new Array<any>());
                    const pointList = seriesPointMap.get(seriesKey) as any[];
                    pointList.push(point);
                }
            }
        });
    });

    for(let key of Array.from(seriesPointMap.keys()) ) {
        const seriesPoints = seriesPointMap.get(key);
        if (seriesPoints) {
            const seriesInterValues = getSeriesHorizontalGuideIntersections(constVal, seriesPoints, key);
            xValuesOfIntersections = xValuesOfIntersections.concat(seriesInterValues);
        }
    }

    return xValuesOfIntersections;
}

const getSeriesHorizontalGuideIntersections = (constVal:number, seriesPoints:any[], seriesKey:string) => {
    const xValuesOfIntersections = new Array<number>();

    const points = seriesPoints.sort((a,b) => a.x - b.x);
    let lastArg:number|undefined = undefined;
    let lastVal:number|undefined = undefined;

    points?.forEach((point) => {
        // Just ignore any values/arguments that aren't numbers
        if (typeof point.x !== 'number' || typeof point[seriesKey] !== 'number') {
            return;
        }

        const arg = point.x as number;
        const val = point[seriesKey] as number;

        // Intersection detected
        if (lastArg != null && lastVal != null && ((lastVal <= constVal && val >= constVal) || (lastVal >= constVal && val <= constVal))) {
            if (arg - lastArg !== 0) {
                const slope = (val - lastVal)/(arg - lastArg);
                const yIntercept = val - (slope * arg);
                const intersectionArg = (constVal - yIntercept)/slope;
                xValuesOfIntersections.push(intersectionArg);
            }
            else {
                xValuesOfIntersections.push(arg);
            }
        }
        
        lastArg = arg;
        lastVal = val;
    });

    return xValuesOfIntersections;
}