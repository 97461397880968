import './ShareSettings.css';

import { List, Popup, SelectBox } from 'devextreme-react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRoleDescription, SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { Company } from '../../../../../Classes/UserGroups/Company';
import { ToolbarItem } from 'devextreme-react/popup';
import { SharedGraphCompany } from '../../../../../Classes/UserGroups/SharedGraphCompany';
import { sharedGroupRolesList } from './ShareSettings';
import { RootState } from '../../../../../Stores/GlobalStore';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';

type ShareSettingsAddCompaniesPopupProps = {
    sharedGraphCompanies:SharedGraphCompany[]|undefined;
    showPopup:boolean;
    onHiding:() => void;
    applyAdd:(selectedCompanies:Array<Company>, selectedRole:SharedGraphRoleType) => Promise<boolean>;
}

export function ShareSettingsAddCompaniesPopup(props: ShareSettingsAddCompaniesPopupProps) {
    const showPopup = props.showPopup;
    const sharedGraphCompanies = props.sharedGraphCompanies;

    const companies = useSelector((state:RootState) => state.companyData.companies);
    const [selectedCompanies, setSelectedCompanies] = useState<Array<Company>>(new Array<Company>());
    const [selectedRole, setSelectedRole] = useState<SharedGraphRoleType>(SharedGraphRoleType.ReadOnly);

    const selectableCompanies:Array<Company> = useMemo(() => {
        if (showPopup) {
            const sharedGraphIds = sharedGraphCompanies?.map((sharedGraphCompany) => sharedGraphCompany.companyId);
            const newSelectableCompanies = companies.filter((company:Company) => {
                return !sharedGraphIds?.includes(company.id)
            });
            return newSelectableCompanies.sort((a,b) => naturalCompare(a.name, b.name));
        }
        else {
            return new Array<Company>();
        }
    }, [showPopup, sharedGraphCompanies, companies]);

    // Clear the selected companies when the selectable companies change
    useEffect(() => {
        setSelectedCompanies(new Array<Company>());
    }, [selectableCompanies]);

    return (
        <Popup 
            wrapperAttr={{class: 'shareGraphWithList'}}
            title={`Share With Companies`}
            width='34rem'
            height='auto'
            maxHeight='40rem'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
            <div className='shareGraphWithPopupContent'>
                <SelectBox 
                    dataSource={sharedGroupRolesList}
                    selectedItem={selectedRole}
                    onValueChanged={(e:any) => setSelectedRole(e.value as SharedGraphRoleType)}
                    displayExpr='label'
                    valueExpr='value'
                    defaultValue={SharedGraphRoleType.ReadOnly}/>
                <p>
                    {getRoleDescription(selectedRole)}
                </p>
                <List
                    dataSource={selectableCompanies}
                    searchEnabled={selectableCompanies.length > 5}
                    searchExpr={'name'}
                    selectionMode='multiple'
                    showSelectionControls={true}
                    selectedItems={selectedCompanies}
                    onSelectedItemsChange={(newItems) => setSelectedCompanies(newItems)}
                    displayExpr='name'
                    noDataText='No companies to display'
                    useNativeScrolling={true}
                    itemRender={(item:Company) => (
                        <div className='sharingListItem'>
                            <div className='sharingListItemLeftDiv'>
                                {item.name}
                            </div>
                        </div>
                    )}
                    pageLoadMode='scrollBottom'/>
            </div>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                disabled={selectedCompanies.length === 0}
                options={{
                    text: 'Share',
                    onClick: () => props.applyAdd(selectedCompanies, selectedRole)
                }}/>
        </Popup>
    );
}
export default ShareSettingsAddCompaniesPopup;