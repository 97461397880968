import { Company } from "./Company";
import { SharedGraphRoleType } from "./SharedGraphRole";

export class SharedGraphCompany {
    companyId: number
    role: SharedGraphRoleType
    companyName: string
    dateAdded: string

    constructor() {
        this.companyId = 0
        this.role = SharedGraphRoleType.ReadOnly
        this.companyName = ''
        this.dateAdded = ''
    }

    static constructFromCompany(company:Company, role:SharedGraphRoleType) {
        let newObject = new SharedGraphCompany()
        newObject.companyId = company.id
        newObject.companyName = company.name
        newObject.dateAdded = (new Date()).toUTCString()
        newObject.role = role;

        return newObject;
    }
}