import './DisplayUnits.css';

import { Popup, SelectBox } from 'devextreme-react';
import { UnitSystemType, User } from '../../../Classes/User';
import { useDispatch, useSelector } from 'react-redux';
import { FetchZoneResultsProps, fetchZoneResultsAndOverrideCache, invalidateAll } from '../../../Reducers/ResultsReducer';
import { updateUser } from '../../../Reducers/UserDataReducer';
import { unitSystemTypes } from '../../Charts/CommonChartTypes';
import { RootState } from '../../../Stores/GlobalStore';
import useKeycloak from '../../../Keycloak';
import { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';

type DisplayUnitsProps = {
    show: boolean;
    onClosing: () => void;
}

export function DisplayUnits(props: DisplayUnitsProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();

    const user = useSelector((state:RootState) => state.userData.user);
    const selectedCustomChartId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 

    const [unitSystem, setUnitSystem] = useState(user?.unitSystem);

    useEffect(() => {
        if (props.show) {
            setUnitSystem(user?.unitSystem);
        }
    }, [props.show, user?.unitSystem]);

    const saveUnitSystem = (newUnitSystem:UnitSystemType) => {
        const somethingWentWrong = () => notify('Something went wrong while trying to change the display unit system. Please refresh the page.', 'error');

        if (user == null) {
            somethingWentWrong();
            return;
        }

        // Make the change in the UI
        setUnitSystem(newUnitSystem);

        // Actually make the change in the database
        let newUser = {...user};
        newUser.unitSystem = newUnitSystem;
        dispatch(updateUser({authToken: token, user: newUser}))
        .then((responseUser:User|null) => {
            // Show an error if something went wrong
            if (responseUser == null) {
                somethingWentWrong();
            }

            // Invalidate all results, which now must be re-generated since the units have changed.
            dispatch(invalidateAll());

            // Reload the cached results for the selected graph, if one exists.
            if (selectedCustomChartId != null) {
                dispatch(fetchZoneResultsAndOverrideCache({
                    authToken: token,
                    graphId: selectedCustomChartId
                } as FetchZoneResultsProps));
            }
        });
    };

    return (
        <Popup 
            visible={props.show}
            onHiding={props.onClosing}
            title='Display Units'
            width='22em'
            height='auto'
            hideOnOutsideClick={true}>
            <div className='displayUnitsPopupDiv'>
                <SelectBox
                    dataSource={unitSystemTypes}
                    valueExpr='type'
                    displayExpr='text'
                    value={unitSystemTypes.find(obj => obj.type === unitSystem)?.type}
                    onValueChange={(args) => {
                        saveUnitSystem(args);
                    }}/>
                <p>
                    All uploaded data is stored using English units. This setting determines what units you see in the interface.
                </p>
            </div>
        </Popup>
    );
}
export default DisplayUnits;
