import { NumberBox, SelectBox } from 'devextreme-react';
import GraphAdvancedSettingsButton from '../GraphAdvancedSettingsButton';
import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { SetDbPointLabelMode, SetDbTrendlineOrder, SetDbTrendlineType, } from '../../../../../../API/CustomChartAPI';
import useKeycloak from '../../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { pointLabelTypes } from '../../../../CommonChartTypes';
import { TrendlineType, trendlineTypeList } from '../../../../../../Classes/Enums/TrendlineType';

interface GraphAdvancedSettingsButtonProps {
    visible: boolean,
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void,
    numericColSet?:Set<number>,
}

export default function GraphTypeSettingsButton({visible, graphSettings, setGraphSettings, numericColSet}: GraphAdvancedSettingsButtonProps) {
    const { token } = useKeycloak();
    const graphId = graphSettings.chartId;
    const { pointLabelMode, trendlineType, trendlineOrder } = graphSettings;

    let xAxisIsNumericType = false;

    const xAxisCol = graphSettings.dataSourceColumns.find(i => i.id === graphSettings.xAxisColumnId);
    if (xAxisCol && numericColSet != null) {
        xAxisIsNumericType = numericColSet.has(xAxisCol.id);
    }

    const showTrendline = xAxisIsNumericType;
    const showTrendlineOrder = showTrendline && trendlineType === TrendlineType.Polynomial;

    const contentUI = (
        <div className='advancedSettings'>
            <div className='settingLabel'>
                Point Labels
            </div>
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={pointLabelTypes}
                    value={pointLabelMode}
                    valueExpr='type'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = pointLabelMode;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, pointLabelMode: newVal});

                        // Make the change in the DB
                        SetDbPointLabelMode(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, pointLabelMode: oldVal});
                            }
                        });
                    }}/>
            </div>
            {showTrendline &&
            <div className='settingLabel'>
                Trendlines
            </div>}
            {showTrendline &&
            <div className='settingSelector'>
                <SelectBox
                    dataSource={trendlineTypeList}
                    value={trendlineType}
                    displayExpr='name'
                    valueExpr='type'
                    onValueChange={(newVal) => {
                        const oldVal = trendlineType;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, trendlineType: newVal});

                        // Make the change in the DB
                        SetDbTrendlineType(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, trendlineType: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {showTrendlineOrder &&
            <div className='settingLabel'>
                Polynomial Order
            </div>}
            {showTrendlineOrder &&
            <div className='settingSelector'>
                <NumberBox
                    value={trendlineOrder}
                    min={2}
                    max={200}
                    showSpinButtons={true}
                    onValueChanged={(e) => {
                        const value = e.value;
                        const newVal = Math.floor(value);

                        const oldVal = trendlineOrder;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, trendlineOrder: newVal});

                        // Make the change in the DB
                        SetDbTrendlineOrder(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, trendlineOrder: oldVal});
                            }
                        });
                    }}/>
            </div>}
        </div>
    );

    return (
        <GraphAdvancedSettingsButton
            title='Graph Settings' 
            content={contentUI} 
            visible={visible}/>
    )
}