import './Filter.css';import DateBox from 'devextreme-react/date-box';

import { ScrollView } from 'devextreme-react';
import { FilterCategory } from './FilterCategory'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFirstUploadSetsPacket, setFilterConditions } from '../../Reducers/SetManagementReducer';
import { RootState } from '../../Stores/GlobalStore';
import useKeycloak from '../../Keycloak';

export function Filter() {

    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const summaryData = useSelector((state:RootState) => state.SetManagementReducer.summaryData);
    const loadingUploadSets = useSelector((state:RootState) => state.SetManagementReducer.loadingUploadSets);
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const selectedConditionsChanged = (args:any, filterKey:string) => {
        let filterCategory = [...(filterConditions as any)[filterKey]];
        let filterCategoryChanged = false;

        args.addedItems.forEach((item:any) => {
            // Only add filters to conditions if they do not already exist
            if (!filterCategory.includes(item)) {
                filterCategory.push(item);
                filterCategoryChanged = true;
            }
        });
        args.removedItems.forEach((item:any) => {
            filterCategory = filterCategory.filter((otherItem:any) => {
                if (otherItem !== item)
                    return true;
                else {
                    filterCategoryChanged = true;
                    return false;
                }
            });
        });

        let filter = {...filterConditions};

        // Using a spread operator here to trigger a state change on filterConditions when new items are added
        if (filterCategoryChanged && keycloak.token) {
            (filter as any)[filterKey] = [...filterCategory];
            dispatch(setFilterConditions({authToken: keycloak.token, newFilterConditions: filter}));
            dispatch(fetchFirstUploadSetsPacket({authToken: keycloak.token, filterConditions: filter}));
        }
    }

    const updateFilterDateAfter = (value: any) => {
        var filter = {...filterConditions};

        filter.date = {...filter.date}
        filter.date.start = value != undefined ? (new Date(value)).toUTCString() : undefined;
        if (keycloak.token) {
            dispatch(setFilterConditions({authToken: keycloak.token, newFilterConditions: filter}));
            dispatch(fetchFirstUploadSetsPacket({authToken: keycloak.token, filterConditions: filter}));
        }
    }

    const updateFilterDateBefore = (value: any) => {
        var filter = {...filterConditions};

        filter.date = {...filter.date}
        filter.date.end = value != undefined ? (new Date(value)).toUTCString() : undefined;
        if (keycloak.token) {
            dispatch(setFilterConditions({authToken: keycloak.token, newFilterConditions: filter}));
            dispatch(fetchFirstUploadSetsPacket({authToken: keycloak.token, filterConditions: filter}));
        }
    }

    return (
        <div className='mainFilterDiv dx-theme-accent-as-background-color'>
            <ScrollView className='scrollViewer' useNative={true}>
                <div className='filterCategory'>
                    <h5 className='filterCategoryTitle'>Uploaded After</h5>
                    <DateBox 
                        disabled={loadingUploadSets}
                        value={filterConditions.date.start} 
                        type='date' 
                        onValueChange={updateFilterDateAfter} 
                        showClearButton={true} />
                </div>
                <div className='filterCategory'>
                    <h5 className='filterCategoryTitle'>Uploaded Before</h5>
                    <DateBox 
                        disabled={loadingUploadSets}
                        value={filterConditions.date.end} 
                        type='date' 
                        onValueChange={updateFilterDateBefore} 
                        showClearButton={true} />
                </div>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableUsers}
                    selectedItemList={filterConditions.users} 
                    title='User' 
                    filterListKey='users'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableCompanies}
                    selectedItemList={filterConditions.companies}  
                    title='Company' 
                    filterListKey='companies'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterablePrograms} 
                    selectedItemList={filterConditions.programs} 
                    title='Program' 
                    filterListKey='programs'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableTags} 
                    selectedItemList={filterConditions.tags} 
                    title='Tag' 
                    filterListKey='tags'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableDatabases} 
                    selectedItemList={filterConditions.databases} 
                    title='Database' 
                    filterListKey='databases'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableProjects} 
                    selectedItemList={filterConditions.projects} 
                    title='Project' 
                    filterListKey='projects'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableStructures} 
                    selectedItemList={filterConditions.structures} 
                    title='Structure' 
                    filterListKey='structures'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableDisplaySets} 
                    selectedItemList={filterConditions.displaySets} 
                    title='Display Set' 
                    filterListKey='displaySets'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableDesigns} 
                    selectedItemList={filterConditions.designs} 
                    title='Design' 
                    filterListKey='designs'
                    selectedConditionsChanged={selectedConditionsChanged}/>
                <FilterCategory 
                    filterOptionsObject={summaryData.filterableMaterials} 
                    selectedItemList={filterConditions.materials} 
                    title='Material' 
                    filterListKey='materials'
                    selectedConditionsChanged={selectedConditionsChanged}/> 
            </ScrollView>
        </div>
    );
}

export default Filter;