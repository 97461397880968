import './MobileViewer.css';
import './MobileReadOnlyGraph.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnitSystemType } from '../../Classes/User';
import { CustomChartSettings } from '../../Classes/Charts/CustomChartSettings';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlottableProperties } from '../../Reducers/ResultsReducer';
import { GetDbGraphBySharedLinkId, GetDbResultsByZone } from '../../API/ReadOnlyChartAPI';
import { APIRequestStatus } from '../../Classes/APIRequestStatus';
import Graph from '../Charts/Graph';
import { Chart, RadioGroup } from 'devextreme-react';
import { GraphDataSource } from '../../Classes/Charts/GraphDataSource';
import { getDataSourceTable } from '../../Utilities/ZoneResultAggregator';
import { RootState } from '../../Stores/GlobalStore';
import { CustomSeriesData } from '../../Classes/Charts/CustomSeriesData';

type GraphParams = {
    sharedLinkId: string;
}

interface MobileReadOnlyGraphProps {
    isLandscape:boolean|undefined;
}

export default function MobileReadOnlyGraph({ isLandscape }: MobileReadOnlyGraphProps) {
    const dispatch = useDispatch();

    const { sharedLinkId } = useParams<GraphParams>();
    
    const chartRef = useRef<Chart>(null);

    const [unitSystem, setUnitSystem] = useState<UnitSystemType>(UnitSystemType.English);

    const [graphSettings, setGraphSettings] = useState<CustomChartSettings|undefined>();
    const [graphDataSource, setGraphDataSource] = useState<GraphDataSource|null>(null);
    const [zoneResults, setZoneResults] = useState<any[]|null>(null);

    const propertyKeyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);

    useEffect(() => {
        if (sharedLinkId != null) {
            dispatch(fetchPlottableProperties(unitSystem));
            GetDbGraphBySharedLinkId(sharedLinkId, unitSystem).then(response => {
                if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                    setGraphSettings(response.data);
                }
            });
            GetDbResultsByZone(sharedLinkId, unitSystem).then((response) => {
                setZoneResults(response ?? []);
            });
        }
    }, [dispatch, sharedLinkId, unitSystem]);

    // Recompute the graph results whenever the graph settings change or the results change.
    useEffect(() => {
        if (graphSettings && zoneResults) {
            const cols = graphSettings.dataSourceColumns;
            const cats = graphSettings.dataSourceCategoryIds;
            const filters = graphSettings.stringFiltersByColumnId;
            const newTableDataSource = getDataSourceTable(zoneResults, cols, cats, filters);
            const newGraphDataSource = new GraphDataSource(newTableDataSource, propertyKeyDict, graphSettings);
            setGraphDataSource(newGraphDataSource);
        }
        else {
            setGraphDataSource(null);
        }
    }, [graphSettings, propertyKeyDict, zoneResults]);

    const toggleSeriesVisibility = useCallback((seriesKey:string) => {
        if (graphSettings) {
            const oldCustomSeriesDataByKey = {...graphSettings.customSeriesDataByKey};
            const newCustomSeriesDataByKey = {...oldCustomSeriesDataByKey};
            let newValue = newCustomSeriesDataByKey[seriesKey]?.visible ?? false;

            const currSeriesData = newCustomSeriesDataByKey[seriesKey];
            if (currSeriesData == null) {
                const newCustSeriesData = new CustomSeriesData();
                newCustSeriesData.visible = newValue;
                newCustomSeriesDataByKey[seriesKey] = newCustSeriesData;
            }
            else {
                newValue = !currSeriesData.visible;
                currSeriesData.visible = newValue;
            }

            // Make the change in the UI
            setGraphSettings({...graphSettings, customSeriesDataByKey: newCustomSeriesDataByKey});
        }
    }, [graphSettings]);

    return (
        <div className="mobileViewer">
            <div className='mobileViewerHeader mobileReadOnlyGraphHeader'>
                <span>{graphSettings?.title}</span>
                <div className='mobileReadOnlyGraphRadioButtons'>
                    <RadioGroup 
                        layout='horizontal'
                        items={
                            [
                                {
                                    text: 'English',
                                    type: UnitSystemType.English
                                },
                                {
                                    text: 'SI',
                                    type: UnitSystemType.SI
                                }
                            ]
                        }
                        valueExpr={'type'}
                        value={unitSystem}
                        onValueChanged={(e:any) => setUnitSystem(e.value)}/>
                </div>
            </div>
            <div className="mobileViewerBody">
                {graphSettings != null &&
                <Graph 
                    ref={chartRef}
                    graphSettings={graphSettings}
                    graphDataSource={graphDataSource}
                    showTitle={false}
                    enableZoom={false}
                    setPreviewImage={undefined}
                    toggleSeriesVisibility={toggleSeriesVisibility}
                    legendHorizontalAlignment={isLandscape ? 'right' : 'center'}/>}
                {graphSettings == null &&
                <div style={{height: '100%'}}>
                    <div className='noGraphsMessage'>
                        <h3>No graph found.</h3>
                        <h3>Please check that your link is correct.</h3>
                    </div>
                </div>}
            </div>
        </div>
    );
}