import { AxisLabelOverflowMode, AxisOrderType, ChartType, PointLabelType } from "../../Classes/Charts/ChartSettingsTypes"
import { UnitSystemType } from "../../Classes/User"
import scatterImg from '../../Icons/scatter.png';
import lineImg from '../../Icons/line.png';
import barImg from '../../Icons/bar.png';
import stackedBarImg from '../../Icons/stackedBar.png';
import fullStackedBarImg from '../../Icons/fullStackedBar.png';
import areaImg from '../../Icons/area.png';
import stackedAreaImg from '../../Icons/stackedArea.png';
import fullStackedAreaImg from '../../Icons/fullStackedArea.png';
import splineImg from '../../Icons/spline.png';
import splineAreaImg from '../../Icons/splineArea.png';
import bubbleImg from '../../Icons/bubble.png';

export type ChartTypeObject = {
    type: ChartType;
    text: string;
    value: string;
    icon: string|undefined;
}

export type UnitSystemTypeObject = {
    type: UnitSystemType;
    text: string;
}

export type AxisLabelOverflowModeObject = {
    type: AxisLabelOverflowMode;
    text: string;
    value: string;
}

export type AxisOrderObject = {
    type: AxisOrderType;
    text: string;
}

export type PointLabelObject = {
    type: PointLabelType;
    text: string;
}

export const chartTypes = [
    {type: ChartType.Line, text: 'Line', value: 'line', icon: lineImg} as ChartTypeObject,
    {type: ChartType.Spline, text: 'Spline', value: 'spline', icon: splineImg} as ChartTypeObject,
    {type: ChartType.Scatter, text: 'Scatter', value: 'scatter', icon: scatterImg} as ChartTypeObject,
    {type: ChartType.Bubble, text: 'Bubble', value: 'bubble', icon: bubbleImg} as ChartTypeObject,
    {type: ChartType.Bar, text: 'Bar', value: 'bar', icon: barImg} as ChartTypeObject,
    {type: ChartType.StackedBar, text: 'Stacked Bar', value: 'stackedbar', icon: stackedBarImg} as ChartTypeObject,
    {type: ChartType.FullStackedBar, text: 'Full Stacked Bar', value: 'fullstackedbar', icon: fullStackedBarImg} as ChartTypeObject,
    {type: ChartType.Area, text: 'Area', value: 'area', icon: areaImg} as ChartTypeObject,
    {type: ChartType.SplineArea, text: 'Spline Area', value: 'splinearea', icon: splineAreaImg} as ChartTypeObject,
    {type: ChartType.StackedArea, text: 'Stacked Area', value: 'stackedarea', icon: stackedAreaImg} as ChartTypeObject,
    {type: ChartType.FullStackedArea, text: 'Full Stacked Area', value: 'fullstackedarea', icon: fullStackedAreaImg} as ChartTypeObject,
];

export const unitSystemTypes = [
    {type: UnitSystemType.English, text: 'English'} as UnitSystemTypeObject,
    {type: UnitSystemType.SI, text: 'SI'} as UnitSystemTypeObject
];

export const axisLabelOverflowModeTypes = [
    {type: AxisLabelOverflowMode.Stagger, value: 'stagger', text: 'Stagger'} as AxisLabelOverflowModeObject,
    {type: AxisLabelOverflowMode.Rotate, value: 'rotate', text: 'Rotate'} as AxisLabelOverflowModeObject
];

export const axisOrderTypes = [
    {type: AxisOrderType.Alphabetical, text: 'Alphabetical'} as AxisOrderObject,
    {type: AxisOrderType.GraphSet, text: 'Order in Graph Set'} as AxisOrderObject
];

export const yAxisShowZeroTypes = [
    { value: true, text: 'Always Show Zero' },
    { value: false, text: 'Fit To Data' }
];

export const showSolutionsTypes = [
    { value: true, text: 'All Solutions' },
    { value: false, text: 'Optimal Solution' }
];

export const axisGridLineTypes = [
    { value: true, text: 'Show' },
    { value: false, text: 'Hide' }
];

export const showHorizontalGuideLabelsTypes = [
    { value: true, text: 'Show' },
    { value: false, text: 'Hide' }
];

export const yAxisCombineTypes = [
    { value: true, text: 'With Equal Units' },
    { value: false, text: 'Never' }
];

export const pointLabelTypes = [
    {type: PointLabelType.None, text: 'None'} as PointLabelObject,
    {type: PointLabelType.XAxis, text: 'X Axis'} as PointLabelObject,
    {type: PointLabelType.YAxis, text: 'Y Axis'} as PointLabelObject,
    {type: PointLabelType.XAndYAxis, text: 'X and Y Axis'} as PointLabelObject
];

export const axisTypes = [
    { value: false, text: 'Linear' },
    { value: true, text: 'Logarithmic' }
];