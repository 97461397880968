import { Program } from "./Program";
import { SharedGraphRoleType } from "./SharedGraphRole";

export class SharedGraphProgram {
    programId: number;
    role: SharedGraphRoleType;
    programName: string;
    companyName: string;
    dateAdded: string;

    constructor() {
        this.programId = 0;
        this.role = SharedGraphRoleType.ReadOnly;
        this.programName = '';
        this.companyName = '';
        this.dateAdded = '';
    }

    static constructFromProgram(program:Program, role:SharedGraphRoleType) {
        let newObject = new SharedGraphProgram();
        newObject.programId = program.id;
        newObject.programName = program.name;
        newObject.companyName = program.companyName;
        newObject.dateAdded = (new Date()).toUTCString();
        newObject.role = role;

        return newObject;
    }
}