import { Group } from "devextreme-react/diagram";

export enum GroupRoleType {
    Administrator = 0,
    Maintainer = 100,
    Member = 200,
    External = 300,
    Invited = 400
}

export function getRoleName(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
            return "Administrator";
        case GroupRoleType.Maintainer:
            return "Maintainer";
        case GroupRoleType.Member:
            return "Member";
        case GroupRoleType.External:
            return "External";
        case GroupRoleType.Invited:
            return "Invited";
        default:
            return "Unknown";
    }
}

export function getRoleNamePlural(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
            return "Administrators";
        case GroupRoleType.Maintainer:
            return "Maintainers";
        case GroupRoleType.Member:
            return "Members";
        case GroupRoleType.External:
            return "External Users";
        case GroupRoleType.Invited:
            return "Invited Users";
        default:
            return "Unknown";
    }
}

export function canAddRemoveUsers(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
        case GroupRoleType.Maintainer:
            return true;
        default:
            return false;
    }
}

export function canAssignRoles(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}

// Company roles
export function canDeleteCompany(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}

export function canRenameCompany(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}

export function canRenameCompanyTags(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
        case GroupRoleType.Maintainer:
        case GroupRoleType.Member:
            return true;
        default:
            return false;
    }
}

export function canRemoveCompanyTags(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
        case GroupRoleType.Maintainer:
            return true;
        default:
            return false;
    }
}

export function canAuditCompany(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}

// Program roles
export function canAddProgram(role:GroupRoleType) {
    switch (role) {
        case GroupRoleType.Administrator:
        case GroupRoleType.Maintainer:
        case GroupRoleType.Member:
            return true;
        default:
            return false;
    }
}

export function canDeleteProgram(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}

export function canRenameProgram(role:GroupRoleType) {
    return role == GroupRoleType.Administrator;
}