import './ShareSettings.css';

import { Button, List, Tooltip } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import { canManageGraphSharing, getRoleName, SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { confirm } from 'devextreme/ui/dialog';
import { SharedGraphUser } from '../../../../../Classes/UserGroups/SharedGraphUser';
import DataSource from 'devextreme/data/data_source';
import { AddSharedGraphUsers, EditSharedGraphUserRole, GetSharedGraphUsers, RemoveSharedGraphUser } from '../../../../../API/GraphSharingAPI';
import ShareSettingsEditRolePopup from './ShareSettingsEditRolePopup';
import ShareSettingsAddUsersPopup from './ShareSettingsAddUsersPopup';
import { GroupUser } from '../../../../../Classes/UserGroups/GroupUser';
import useKeycloak from '../../../../../Keycloak';

type ShareSettingsUsersProps = {
    chartSettings: CustomChartSettings | undefined;
    sharedGraphUsers: Array<SharedGraphUser>|undefined;
    setSharedGraphUsers: (newSharedGraphCompanies:Array<SharedGraphUser>|undefined) => void;
    sharedGraphRole: SharedGraphRoleType;
    updateSharedByMe: (companies:SharedGraphUser[]|undefined) => void;
}

export function ShareSettingsUsers(props: ShareSettingsUsersProps) {
    const keycloak = useKeycloak()

    const [showShareSettingsAddUsersPopup, setShowShareSettingsAddUsersPopup] = useState<boolean>(false)
    const [selectedSharedUser, setSelectedSharedUser] = useState<SharedGraphUser|undefined>(undefined)
    const [sharedGraphUsersDS, setSharedGraphUsersDS] = useState<DataSource<SharedGraphUser>>()

    useEffect(() => {
        if (props.sharedGraphUsers) {
            let dataSource = new DataSource(props.sharedGraphUsers)
            dataSource.group('role')
            setSharedGraphUsersDS(dataSource)
        }
    }, [props.sharedGraphUsers])

    useEffect(() => {
        updateSharedGraphUsers()
    }, [props.chartSettings?.chartId])

    const updateSharedGraphUsers = async () => {
        let token = keycloak.token;
        if (token && props.chartSettings) {
            let newSharedGraphUsers = await GetSharedGraphUsers(token, props.chartSettings?.chartId)
            props.setSharedGraphUsers(newSharedGraphUsers)
        }
    }

    let sharedUsersDataSource = sharedGraphUsersDS
    let sharedUsers = props.sharedGraphUsers
    let sharedUsersCount = sharedUsers?.length ?? 0
    return (
        <div>
            <List
                searchEnabled={sharedUsers ? sharedUsersCount > 5 : false}
                dataSource={sharedUsersDataSource}
                displayExpr='userName'
                grouped={true}
                collapsibleGroups={true}
                groupTemplate={(item) => getRoleName(item.key as SharedGraphRoleType)}
                noDataText='This graph has not been shared with any users.'
                itemRender={(item:SharedGraphUser) => 
                    <div className='sharingListItem' id={`sharingListItem${item.userId}`}>
                        <div className='sharingListItemLeftDiv'>
                            {item.userDisplayName}
                        </div>
                        <div className='sharingListItemRightDiv'>
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='edit'
                                onClick={() => setSelectedSharedUser(item)}/>}
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='remove'
                                onClick={async () => {
                                    let confirmDelete = await confirm(`Are you sure you want to remove ${item.userDisplayName}?`, "Confirm Remove");
                                    if (confirmDelete && props.chartSettings && keycloak.token) {
                                        let deleteWasSuccessful = await RemoveSharedGraphUser(keycloak.token, props.chartSettings.chartId, item.userId);
                                        if (deleteWasSuccessful) {
                                            let newSharedGraphPrograms = props.sharedGraphUsers?.filter(i => i.userId !== item.userId);
                                            props.setSharedGraphUsers(newSharedGraphPrograms);
                                            props.updateSharedByMe(newSharedGraphPrograms);
                                        }
                                    }
                                }}
                            />}
                            <Tooltip 
                                target={`#sharingListItem${item.userId}`}
                                showEvent="dxhoverstart" 
                                hideEvent="dxhoverend" 
                                position='right' 
                                contentRender={() => 
                                    <div>
                                        {item.userEmail}
                                    </div>
                                }/>
                        </div>
                    </div>
                }/>
            {canManageGraphSharing(props.sharedGraphRole) &&
            <Button
                className='graphSharingAddButton'
                text='Add User'
                icon="add" 
                type='success'
                onClick={() => {
                    setShowShareSettingsAddUsersPopup(true)
                }}
                style={{float: 'right'}}/>}
            <ShareSettingsAddUsersPopup 
                sharedGraphUsers={props.sharedGraphUsers} 
                showPopup={showShareSettingsAddUsersPopup} 
                onHiding={() => setShowShareSettingsAddUsersPopup(false)}
                applyAdd={async (users: Array<GroupUser>, selectedRole: SharedGraphRoleType) => {
                    if (sharedUsers && keycloak.token && props.chartSettings && props.sharedGraphUsers) {
                        let sharedGraphUsersToAdd = users.map((user:GroupUser) => {
                            return SharedGraphUser.constructFromGroupUser(user, selectedRole);
                        })
                        let postWasSuccessful = await AddSharedGraphUsers(keycloak.token, props.chartSettings.chartId, sharedGraphUsersToAdd);
                        if (postWasSuccessful) {
                            const newUsers = [...props.sharedGraphUsers, ...sharedGraphUsersToAdd];
                            props.setSharedGraphUsers(newUsers);
                            props.updateSharedByMe(newUsers);
                        }

                        setShowShareSettingsAddUsersPopup(false);
                        return true;
                    }
                    return false;
                }}/>
            <ShareSettingsEditRolePopup 
                currentRole={selectedSharedUser?.role} 
                showPopup={selectedSharedUser != undefined} 
                onHiding={() => setSelectedSharedUser(undefined)}
                applyAdd={async (selectedRole: SharedGraphRoleType) => {
                    if (keycloak.token && selectedSharedUser != undefined && props.chartSettings && props.sharedGraphUsers) {
                        let putWasSuccessful = await EditSharedGraphUserRole(keycloak.token, props.chartSettings.chartId, selectedSharedUser.userId, selectedRole)
                        if (putWasSuccessful) {
                            selectedSharedUser.role = selectedRole
                            setSelectedSharedUser(undefined)
                            props.setSharedGraphUsers([...props.sharedGraphUsers])
                            return true
                        }
                    }
                    return false
                }}/>
        </div>)
}
export default ShareSettingsUsers;