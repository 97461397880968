import './TagsPopup.css';

import { Button, List, Popup } from 'devextreme-react';
import { Company } from '../../Classes/UserGroups/Company';
import { canRemoveCompanyTags, canRenameCompanyTags, GroupRoleType } from '../../Classes/UserGroups/GroupRole';
import { GetTagUploadSetCount, RenameCompanyTag, TagNameIsDuplicate } from '../../API/CompaniesAPI';
import React, { useEffect, useMemo } from 'react';
import { SetNamePopup } from '../SetNamePopup';
import { useDispatch, useSelector } from 'react-redux';
import { createTag, deleteTag, getCompanies } from '../../Reducers/CompanyDataReducer';
import { confirm } from 'devextreme/ui/dialog';  
import { ToolbarItem } from 'devextreme-react/popup';
import { fetchFirstUploadSetsPacket } from '../../Reducers/SetManagementReducer';
import { RootState } from '../../Stores/GlobalStore';
import { APIRequestStatusType } from '../../Classes/APIRequestStatus';
import { pluralize } from '../../Utilities/CommonUtilities';

interface TagsPopupProps {
    showPopup:boolean
    authToken:string|undefined
    company:Company|undefined;
    onHiding:() => void;
    userRole:GroupRoleType;
}

export function TagsPopup(props:TagsPopupProps) {

    const dispatch = useDispatch();
    const filterConditions = useSelector((state:RootState) => state.SetManagementReducer.filterConditions);
    const [tagToRename, setTagToRename] = React.useState<string|undefined>(undefined);
    const [tagToRenameUploadSets, setTagToRenameUploadSets] = React.useState<number|undefined>(undefined);
    const [showCreateTagPopup, setShowCreateTagPopup] = React.useState<boolean>(false);

    useEffect(() => {
        if (props.authToken && props.company?.id && tagToRename != null) {
            GetTagUploadSetCount(props.authToken, props.company.id, tagToRename).then(response => {
                if (response.type !== APIRequestStatusType.Error) {
                    setTagToRenameUploadSets(response.data);
                }
            });
        }
        else {
            setTagToRenameUploadSets(undefined);
        }
    }, [props.authToken, props.company?.id, tagToRename])

    const sortedTags = useMemo(() => {
        if (props.company == null) {
            return [];
        }
        let newTags = [...props.company.tags];
        newTags.sort();
        return newTags;
    }, [props.company?.tags]); 

    return (
        <Popup 
            title='Company Tags'
            width='30rem'
            height='36rem'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}
            onContentReady={(e) => {
                var html = e.component.content();  
                html.style.paddingBottom = '0rem';
            }}>
            <div style={{height:'100%'}}>
                <List 
                    dataSource={sortedTags}
                    searchEnabled={true}
                    useNativeScrolling={true}
                    noDataText='No tags could be found.'
                    itemRender={(tag:string) => 
                        <div className='tagListItem'>
                            <div className='userListItemLeftDiv'>
                                {tag}
                            </div>
                            <div className='userListItemRightDiv'>
                                {canRenameCompanyTags(props.userRole) &&
                                <Button 
                                    icon='edit'
                                    onClick={() => setTagToRename(tag)}/>}
                                {canRemoveCompanyTags(props.userRole) &&
                                <Button 
                                    icon='trash'
                                    onClick={() => {
                                        if (props.authToken && props.company) {
                                            GetTagUploadSetCount(props.authToken, props.company.id, tag).then(response => {
                                                if (response.type != APIRequestStatusType.Error && response.data != null) {
                                                    const result = confirm(`Delete tag "${tag}" from ${props.company?.name}? This tag is associated with ${pluralize(response.data, 'upload set')}.`, "Confirm Delete");  
                                                    result.then((confirmed:boolean) => {
                                                        if (confirmed && props.authToken && props.company) {
                                                            dispatch(deleteTag({authToken: props.authToken, companyId: props.company.id, tag: tag}));
                                                            dispatch(fetchFirstUploadSetsPacket({authToken: props.authToken, filterConditions: filterConditions}));
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    }}/>}
                            </div>
                        </div>
                    }/>
                {props.company?.tags &&
                <SetNamePopup
                    title='Rename tag'
                    validateForDuplicate={(name:string) => TagNameIsDuplicate(props.authToken, props.company?.id, name)}
                    applyButtonName='Apply'
                    oldName={tagToRename ?? ''}
                    showPopup={tagToRename !== undefined && tagToRenameUploadSets != null}
                    hidePopup={() => setTagToRename(undefined)}
                    applySetName={(newName:string) => {
                        if (props.company && tagToRename != null && props.authToken) {
                            RenameCompanyTag(props.authToken, props.company?.id, tagToRename, newName).then((result:any) => {
                                if (result && props.authToken) {
                                    dispatch(getCompanies(props.authToken));
                                    dispatch(fetchFirstUploadSetsPacket({authToken: props.authToken, filterConditions: filterConditions}));
                                }
                            });
                        }
                    }}
                    infoText={tagToRenameUploadSets != null ? `This tag is associated with ${pluralize(tagToRenameUploadSets, 'upload set')}.` : undefined}/>}
                <SetNamePopup
                    title='Create Tag'
                    validateForDuplicate={(name:string) => TagNameIsDuplicate(props.authToken, props.company?.id, name)}
                    applyButtonName='Create'
                    oldName={''}
                    showPopup={showCreateTagPopup}
                    hidePopup={() => setShowCreateTagPopup(false)}
                    applySetName={(tag:string) => {
                        if (props.authToken && props.company)
                            dispatch(createTag({authToken:props.authToken, companyId: props.company.id, tag:tag}));
                    }}/>
            </div>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    icon: 'add',
                    type: 'success',
                    text: 'Add Tag',
                    onClick: () => {
                        setShowCreateTagPopup(true);
                    }
                }}/>
        </Popup>
    );
}