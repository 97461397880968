import { NumberBox, SelectBox } from 'devextreme-react';
import GraphAdvancedSettingsButton from '../GraphAdvancedSettingsButton';
import { CustomChartSettings } from '../../../../../../Classes/Charts/CustomChartSettings';
import { SetDbAxisLabelCutoff, SetDbAxisLabelOverflowMode, SetDbAxisOrder, SetDbShowXAxisGridLines, SetDbXAxisLogScale, SetDbXAxisRange } from '../../../../../../API/CustomChartAPI';
import useKeycloak from '../../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../../Classes/APIRequestStatus';
import { axisGridLineTypes, axisLabelOverflowModeTypes, axisOrderTypes, axisTypes } from '../../../../CommonChartTypes';

interface GraphAdvancedSettingsButtonProps {
    visible: boolean,
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void,
    numericColSet?:Set<number>,
}

export default function XAxisSettingsButton({visible, graphSettings, setGraphSettings, numericColSet}: GraphAdvancedSettingsButtonProps) {
    const { token } = useKeycloak();
    const graphId = graphSettings.chartId;
    const { axisOrder, axisLabelOverflowMode, showXAxisGridLines, axisLabelCutoff, xAxisMinValue, xAxisMaxValue, 
        xAxisLogScaleEnabled } = graphSettings;
    
    let xAxisIsNumericType = false;

    const xAxisCol = graphSettings.dataSourceColumns.find(i => i.id === graphSettings.xAxisColumnId);
    if (xAxisCol && numericColSet != null) {
        xAxisIsNumericType = numericColSet.has(xAxisCol.id);
    }

    const contentUI = (
        <div className='advancedSettings'>
            <div className='settingLabel'>
                Vertical Grid Lines
            </div>
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisGridLineTypes}
                    value={showXAxisGridLines}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = showXAxisGridLines;

                        // // Make the change in the UI
                        setGraphSettings({...graphSettings, showXAxisGridLines: newVal});

                        // // Make the change in the DB
                        SetDbShowXAxisGridLines(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, showXAxisGridLines: oldVal});
                            }
                        });
                    }}/>
            </div>
            {!xAxisIsNumericType &&
            <div className='settingLabel'>
                Ordering
            </div>}
            {!xAxisIsNumericType &&
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisOrderTypes}
                    value={axisOrder}
                    valueExpr='type'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = axisOrder;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, axisOrder: newVal});

                        // Make the change in the DB
                        SetDbAxisOrder(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, axisOrder: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {!xAxisIsNumericType &&
            <div className='settingLabel'>
                Text Overflow Behavior
            </div>}
            {!xAxisIsNumericType &&
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisLabelOverflowModeTypes}
                    value={axisLabelOverflowMode}
                    valueExpr='type'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = axisLabelOverflowMode;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, axisLabelOverflowMode: newVal});

                        // Make the change in the DB
                        SetDbAxisLabelOverflowMode(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, axisLabelOverflowMode: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {!xAxisIsNumericType &&
            <div className='settingLabel'>
                Axis Label Cutoff
            </div>}
            {!xAxisIsNumericType &&
            <div className='settingSelector'>
                <NumberBox
                    min={1}
                    max={999}
                    showSpinButtons={true}
                    showClearButton={true}
                    placeholder={'No cutoff'}
                    value={axisLabelCutoff}
                    onValueChange={(newVal) => {
                        const oldVal = axisLabelCutoff;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, axisLabelCutoff: newVal});

                        // Make the change in the DB
                        SetDbAxisLabelCutoff(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, axisLabelCutoff: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {xAxisIsNumericType &&
            <div className='settingLabel'>
                Axis Type
            </div>}
            {xAxisIsNumericType &&
            <div className='settingSelector'>
                <SelectBox 
                    dataSource={axisTypes}
                    value={xAxisLogScaleEnabled}
                    valueExpr='value'
                    displayExpr='text'
                    onValueChange={(newVal) => {
                        const oldVal = xAxisLogScaleEnabled;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, xAxisLogScaleEnabled: newVal});

                        // Make the change in the DB
                        SetDbXAxisLogScale(token, graphId, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, xAxisLogScaleEnabled: oldVal});
                            }
                        });
                    }}/>
            </div>}
            {xAxisIsNumericType &&
            <div className='settingLabel'>
                Visible Range
            </div>}
            {xAxisIsNumericType &&
            <div className='settingSelector'>
                <NumberBox 
                    width={'6em'}
                    placeholder='no min'
                    value={xAxisMinValue ?? undefined}
                    onValueChange={(newVal) => {
                        const oldVal = xAxisMinValue;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, xAxisMinValue: newVal});

                        // Make the change in the DB
                        SetDbXAxisRange(token, graphId, newVal, xAxisMaxValue).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, xAxisMinValue: oldVal});
                            }
                        });
                    }}/>
                to
                <NumberBox 
                    width={'6em'}
                    placeholder='no max'
                    value={xAxisMaxValue ?? undefined}
                    onValueChange={(newVal) => {
                        const oldVal = xAxisMaxValue;

                        // Make the change in the UI
                        setGraphSettings({...graphSettings, xAxisMaxValue: newVal});

                        // Make the change in the DB
                        SetDbXAxisRange(token, graphId, xAxisMinValue, newVal).then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the UI change if something goes wrong
                                setGraphSettings({...graphSettings, xAxisMaxValue: oldVal});
                            }
                        });
                    }}/>
            </div>}
        </div>
    );

    return (
        <GraphAdvancedSettingsButton
            title='X Axis Settings' 
            content={contentUI} 
            visible={visible}/>
    )
}