import './GraphSetManager.css';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFolder, FolderNameIsDuplicate, RenameFolder, SelectFolder } from '../../../../API/GraphSetFolderAPI';
import { APIRequestStatus, APIRequestStatusType } from '../../../../Classes/APIRequestStatus';
import { GraphSet, SortOrder } from '../../../../Classes/GraphSet';
import { CountFoldersInFolder, CountGraphSetsInFolder, FindFolderParentFolderId, GraphSetFolder, GraphSetSearchResult, SearchGraphSets } from '../../../../Classes/GraphSetFolder';
import { deleteGraphSet, deleteUIFolder, renameGraphSet, renameUIFolder, selectGraphSet, setSelectedGraphSetFolderId } from '../../../../Reducers/SetManagementReducer';
import { RootState } from '../../../../Stores/GlobalStore';
import { SetNamePopup } from '../../../SetNamePopup';
import GraphSetFolderListItem from './GraphSetFolderListItem';
import GraphSetListItem from './GraphSetListItem';
import { confirm } from 'devextreme/ui/dialog';
import { GraphSetNameIsDuplicate } from '../../../../API/GraphSetAPI';
import { List, TextBox } from 'devextreme-react';
import useKeycloak from '../../../../Keycloak';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';

interface GraphSetsListProps {
    selectedFolder:GraphSetFolder;
    sortOrder:SortOrder;
}

export default function GraphSetsList(props:GraphSetsListProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const graphSets = props.selectedFolder.graphSets;
    const subFolders = props.selectedFolder.subFolders;

    const rootGraphSetFolder = useSelector((state:RootState) => state.SetManagementReducer.rootGraphSetFolder);
    const selectedGraphSetId = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetId);
    const selectedFolderId = useSelector((state:RootState) => state.SetManagementReducer.selectedGraphSetFolderId);
    const totalGraphSets = graphSets.length;
    const [folderToEdit, setFolderToEdit] = useState<GraphSetFolder|undefined>(undefined);
    const [graphSetToEdit, setGraphSetToEdit] = useState<GraphSet|undefined>(undefined);

    const [searchText, setSearchText] = useState<string>('');
    const [searchResults, setSearchResults] = useState<any[]|null>(null);
    const debounceTime = 400;
    const debounceTimer = useRef<any>();

    const dndRef = useRef<any>(null);

    // Init dnd scroll element on component load
    useEffect(() => {
        const dndElement = dndRef.current;
        return autoScrollForElements({
            element: dndElement,
        });
    }, []);

    useEffect(() => {
        if (searchText) {
            if (debounceTimer !== undefined)
            clearTimeout(debounceTimer.current);

            debounceTimer.current = setTimeout(() => {
                // Search through the folder
                const newSearchResults = SearchGraphSets(props.selectedFolder.id, props.selectedFolder, searchText);
                // Sort the results 
                newSearchResults.sort((a, b) => a.pathName.localeCompare(b.pathName));
                setSearchResults(newSearchResults); 
        }, debounceTime);
        }
        else if (searchResults) {
            // Clear search results when search text is cleared
            setSearchResults(null);
        }
    }, [searchText]);

    const folderContextMenuItems = (folder:GraphSetFolder) => [
        {
            text: 'Rename',
            action: () => {
                setFolderToEdit(folder);
            }
        },
        {
            text: 'Delete',
            action: () => {
                let numFoldersInFolder = CountFoldersInFolder(folder);
                let numChartsInFolder = CountGraphSetsInFolder(folder);
                let result = confirm(`Are you sure you want to delete ${folder.name}? All graph sets (${numChartsInFolder}) and folders (${numFoldersInFolder}) within this folder will be deleted.`, "Confirm Delete");  
                result.then((confirmDelete) => {
                    if (confirmDelete && keycloak.token && folder.id) { 
                        DeleteFolder(keycloak.token, folder.id).then((response:APIRequestStatus) => {
                            if (response.type != APIRequestStatusType.Success) {
                                APIRequestStatus.showToast(response);
                            }
                            if (response.type != APIRequestStatusType.Error) {
                                dispatch(deleteUIFolder(folder.id));
                            }
                        });
                    }
                });
            }
        }
    ];

    const graphSetContextMenuItems = (graphSet:GraphSet) => {
        let contextMenuItems = new Array<any>();
        if (!graphSet.isSharedWithMe) {
            contextMenuItems.push({
                text: 'Rename',
                action: () => {
                    setGraphSetToEdit(graphSet);
                }
            });
            contextMenuItems.push({
                text: 'Delete',
                action: () => {
                    if (keycloak.token && graphSet) {
                        applyDeleteGraphSet(graphSet);
                    }
                }
            });
        }
        return contextMenuItems;
    };

    const isGraphSetNameDuplicate = async (name:string) : Promise<boolean> => {
        if (selectedFolderId == null || keycloak.token == null)
            return false;
        return GraphSetNameIsDuplicate(keycloak.token, selectedFolderId, name);
    }

    const applyRenameGraphSet = (newName:string) => {
        if (graphSetToEdit)
            dispatch(renameGraphSet({authToken: keycloak.token, name: newName, graphSetId: graphSetToEdit.graphSetId}));
    }

    const applyDeleteGraphSet = (graphSet:GraphSet) => {
        const confirmDelete = confirm(`Are you sure you want to delete ${graphSet?.name}?`, "Confirm Delete");  
        confirmDelete.then((deleteConfirmed:boolean) => {
            if (deleteConfirmed && keycloak.token) { 
                dispatch(deleteGraphSet({authToken: keycloak.token, graphSetId: graphSet.graphSetId}));
            }
        });
    }

    const totalFolders = subFolders.length;
    const renderGraphSetListContent = useMemo(() => {
        return (
            <div 
                ref={dndRef}
                className='graphSetListContent' 
                key={'graphSetListContent'}>
                {totalGraphSets === 0 && totalFolders === 0 &&
                    <div className='setsEmptyMessage'>
                        There is no graph set data to show. A graph set is a way to define a group of upload sets that you want to plot. Click the + button above to create a graph set.
                    </div>
                }
                {getSortedGraphSetFolders(subFolders, props.sortOrder).map((folder:GraphSetFolder) => {
                    return (
                        <GraphSetFolderListItem 
                            folder={folder}
                            setSelectedFolderId={(newId:number) => {
                                dispatch(setSelectedGraphSetFolderId(newId));
                                if (keycloak.token)
                                    SelectFolder(keycloak.token, newId);
                            }}
                            folderContextMenuItems={folderContextMenuItems(folder)}
                            key={folder.id}/>
                    );
                })}
                {getSortedGraphSets(graphSets, props.sortOrder).map((graphSet:GraphSet) => {
                    return (
                        <GraphSetListItem
                            graphSet={graphSet}
                            isSelected={graphSet.graphSetId === selectedGraphSetId}
                            contextMenuItems={graphSetContextMenuItems(graphSet)}
                            key={graphSet.graphSetId}/>
                    );
                })}
            </div>);
    }, [totalGraphSets, totalFolders, subFolders, props.sortOrder, graphSets, folderContextMenuItems, dispatch, keycloak.token, selectedGraphSetId, graphSetContextMenuItems]);

    const renderGraphSetListSearchResults = useMemo(() => {
        return (
            <div className='graphSetListContent graphSetSearchResults'>
                {searchResults != null && searchResults.length == 0 &&
                    <div className='setsEmptyMessage'>
                        No graph sets were found within this folder using that search term.
                    </div>
                }
                {searchResults != null &&
                <List
                    dataSource={searchResults}
                    displayExpr={'pathName'}
                    noDataText={''}
                    selectedItem={searchResults.find((i) => i.id == selectedGraphSetId)}
                    selectionMode='single'
                    onSelectedItemsChange={(selectedItems:any) => {
                        if (selectedItems.length > 0) {
                            const result = selectedItems[0] as GraphSetSearchResult;
                            if (result) {
                                dispatch(selectGraphSet(result.id));
                            }
                        }
                    }}/>}
            </div>);
    }, [searchResults, selectedGraphSetId]);

    return (
        <div className='graphSetList'>
            <div className='graphSetListHeader'>
                {props.selectedFolder.graphSets.length + props.selectedFolder.subFolders.length > 0 &&
                <TextBox 
                    mode='search' 
                    placeholder={selectedFolderId == 0 ? 'Search all graph sets...' : `Search graph sets in ${props.selectedFolder.name}...`}
                    value={searchText} 
                    onValueChange={(val) => setSearchText(val)}
                    valueChangeEvent='keyup'/>}
            </div>
            {searchText ? renderGraphSetListSearchResults : renderGraphSetListContent}
            <SetNamePopup
                title='Rename Folder'
                validateForDuplicate={(name:string) => {
                    if (folderToEdit != null) {
                        const parentId = FindFolderParentFolderId(rootGraphSetFolder, folderToEdit.id);
                        if (parentId !== null)
                            return FolderNameIsDuplicate(keycloak.token, name, parentId);
                    }
                    return Promise.resolve(false);
                }} 
                applyButtonName='Apply'
                oldName={folderToEdit ? folderToEdit.name : ''}
                showPopup={folderToEdit != null}
                hidePopup={() => setFolderToEdit(undefined)}
                applySetName={(newName:string) => {
                    if (keycloak.token && folderToEdit) {
                        RenameFolder(keycloak.token, folderToEdit.id, newName).then((response:APIRequestStatus) => {
                            if (response.type != APIRequestStatusType.Success) {
                                APIRequestStatus.showToast(response);
                            }
                            if (response.type != APIRequestStatusType.Error) {
                                dispatch(renameUIFolder({folderId: folderToEdit.id, newName: newName}));
                            }
                        });
                    }
                }}/>
            <SetNamePopup
                title='Rename Graph Set'
                validateForDuplicate={isGraphSetNameDuplicate}
                applyButtonName='Apply'
                oldName={graphSetToEdit?.name ?? ''}
                showPopup={graphSetToEdit != null}
                hidePopup={() => setGraphSetToEdit(undefined)}
                applySetName={applyRenameGraphSet}/>
        </div>
    );
}

export const getSortedGraphSets = (graphSets:GraphSet[], sortOrder:SortOrder) => {
    if (graphSets == null)
        return new Array<GraphSet>();

    if (sortOrder == SortOrder.DateModified) {
        return [...graphSets].sort((a:GraphSet, b:GraphSet) => {
            const aDateString = a.dateModified;
            const bDateString = b.dateModified;

            if (!aDateString)
                return 1;
            if (!bDateString)
                return -1;

            return Date.parse(aDateString + ' UTC') < Date.parse(bDateString + ' UTC') ? 1 : -1;
        });
    }
    else {
        // Assume alphabetical by default
        return [...graphSets].sort((a:GraphSet, b:GraphSet) => a.name.localeCompare(b.name));
    }
}

export const getSortedGraphSetFolders = (folders:GraphSetFolder[], sortOrder:SortOrder) => {
    if (folders == null)
        return new Array<GraphSetFolder>();

    if (sortOrder == SortOrder.DateModified) {
        return [...folders].sort((a:GraphSetFolder, b:GraphSetFolder) => {
            const aDateString = a.dateModified;
            const bDateString = b.dateModified;

            if (!aDateString)
                return 1;
            if (!bDateString)
                return -1;

            return Date.parse(aDateString + ' UTC') < Date.parse(bDateString + ' UTC') ? 1 : -1;
        });
    }
    else {
        // Assume alphabetical by default
        return [...folders].sort((a:GraphSetFolder, b:GraphSetFolder) => a.name.localeCompare(b.name));
    }
}