export enum AggregationType
{
    First = 0,
    Total = 1,
    Average = 2,
    CSV = 3,
    Count = 4,
    Aggregate = 5
}

export interface AggregationTypeListItem {
    type: AggregationType;
    name: string;
    numericOnly: boolean;
    showByDefault: boolean;
    alwaysProducesNumericResult: boolean;
    description: string;
    prefix: string;
    postfix: string;
}

export const aggregationTypeDict: Map<AggregationType, AggregationTypeListItem> = new Map<AggregationType, AggregationTypeListItem>([
    [AggregationType.Average, {
        type: AggregationType.Average,
        name: 'Average',
        numericOnly: true,
        alwaysProducesNumericResult: true,
        showByDefault: true,
        description: 'The average value of all zones is shown.',
        prefix: 'Avg.',
        postfix: ''
    }],
    [AggregationType.Total, {
        type: AggregationType.Total,
        name: 'Total',
        numericOnly: true,
        alwaysProducesNumericResult: true,
        showByDefault: true,
        description: 'The total value of all zones is shown.',
        prefix: 'Total',
        postfix: ''
    }],
    [AggregationType.CSV, {
        type: AggregationType.CSV,
        name: 'List of Unique Values',
        numericOnly: false,
        alwaysProducesNumericResult: false,
        showByDefault: true,
        description: 'A comma-seperated list of all unique zone values is shown.',
        prefix: '',
        postfix: ''
    }],
    [AggregationType.Count, {
        type: AggregationType.Count,
        name: 'Number of Unique Values',
        numericOnly: false,
        alwaysProducesNumericResult: true,
        showByDefault: true,
        description: 'The number of unique zone values is shown.',
        prefix: '',
        postfix: 'Count'
    }],
    [AggregationType.Aggregate, {
        type: AggregationType.Aggregate,
        name: 'Custom Aggregation',
        numericOnly: true,
        alwaysProducesNumericResult: true,
        showByDefault: false,
        description: '',
        prefix: '',
        postfix: ''
    }],
    [AggregationType.First, {
        type: AggregationType.First,
        name: 'First',
        numericOnly: false,
        alwaysProducesNumericResult: false,
        showByDefault: false,
        description: 'The first zone value found is shown',
        prefix: 'First',
        postfix: ''
    }],
]);

export const aggregationTypeList:AggregationTypeListItem[] = Array.from(aggregationTypeDict.entries()).map((entry) => {
    const val = entry[1];
    return val;
});