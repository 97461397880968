import './ShareSettings.css';

import { Button, List } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import { getRoleName, SharedGraphRoleType, canManageGraphSharing } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { confirm } from 'devextreme/ui/dialog';
import { Company } from '../../../../../Classes/UserGroups/Company';
import { SharedGraphCompany } from '../../../../../Classes/UserGroups/SharedGraphCompany';
import DataSource from 'devextreme/data/data_source';
import { AddSharedGraphCompanies, EditSharedGraphCompanyRole, GetSharedGraphCompanies, RemoveSharedGraphCompany } from '../../../../../API/GraphSharingAPI';
import ShareSettingsEditRolePopup from './ShareSettingsEditRolePopup';
import ShareSettingsAddCompaniesPopup from './ShareSettingsAddCompaniesPopup';
import { companyDataSelector } from '../../../../../Reducers/CompanyDataReducer';
import { useSelector } from 'react-redux';
import useKeycloak from '../../../../../Keycloak';

type ShareSettingsCompaniesProps = {
    chartSettings: CustomChartSettings | undefined;
    sharedGraphCompanies: Array<SharedGraphCompany>|undefined;
    setSharedGraphCompanies: (newSharedGraphCompanies:Array<SharedGraphCompany>|undefined) => void;
    sharedGraphRole: SharedGraphRoleType;
    updateSharedByMe: (companies:SharedGraphCompany[]|undefined) => void;
}

export function ShareSettingsCompanies(props: ShareSettingsCompaniesProps) {
    const keycloak = useKeycloak();
    const companySelectorObject = useSelector(companyDataSelector)

    const [showShareSettingsAddCompaniesPopup, setShowShareSettingsAddCompaniesPopup] = useState<boolean>(false);
    const [selectedSharedCompany, setSelectedSharedCompany] = useState<SharedGraphCompany|undefined>(undefined);
    const [sharedGraphCompaniesDS, setSharedGraphCompaniesDS] = useState<DataSource<SharedGraphCompany>>()

    useEffect(() => {
        if (props.sharedGraphCompanies) {
            let dataSource = new DataSource(props.sharedGraphCompanies)
            dataSource.group('role')
            setSharedGraphCompaniesDS(dataSource)
        }
    }, [props.sharedGraphCompanies])

    useEffect(() => {
        updateSharedGraphCompanies()
    }, [props.chartSettings?.chartId])

    const updateSharedGraphCompanies = async () => {
        let token = keycloak.token;
        if (token && props.chartSettings) {
            let newSharedGraphCompanies = await GetSharedGraphCompanies(token, props.chartSettings?.chartId)
            props.setSharedGraphCompanies(newSharedGraphCompanies)
        }
    }

    let sharedGraphCompaniesCount = props.sharedGraphCompanies?.length ?? 0
    return (
        <div>
            <List
                searchEnabled={sharedGraphCompaniesCount > 5}
                dataSource={sharedGraphCompaniesDS}
                displayExpr='companyName'
                grouped={true}
                collapsibleGroups={true}
                groupTemplate={(item) => getRoleName(item.key as SharedGraphRoleType)}
                noDataText='This graph has not been shared with any companies.'
                itemRender={(item:SharedGraphCompany) => 
                    <div className='sharingListItem'>
                        <div className='sharingListItemLeftDiv'>
                            {item.companyName}
                        </div>
                        <div className='sharingListItemRightDiv'>
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='edit'
                                onClick={() => setSelectedSharedCompany(item)}/>}
                            {canManageGraphSharing(props.sharedGraphRole) &&
                            <Button 
                                icon='remove'
                                onClick={async () => {
                                    let confirmDelete = await confirm(`Are you sure you want to remove ${item.companyName}?`, "Confirm Remove");
                                    if (confirmDelete && props.chartSettings && keycloak.token) {
                                        let deleteWasSuccessful = await RemoveSharedGraphCompany(keycloak.token, props.chartSettings.chartId, item.companyId);
                                        if (deleteWasSuccessful) {
                                            let newSharedGraphCompanies = props.sharedGraphCompanies?.filter(i => i.companyId !== item.companyId);
                                            props.setSharedGraphCompanies(newSharedGraphCompanies);
                                            props.updateSharedByMe(newSharedGraphCompanies);
                                        }
                                    }
                                }}
                            />}
                        </div>
                    </div>
                }/>
            {canManageGraphSharing(props.sharedGraphRole) && 
            <Button
                className='graphSharingAddButton'
                text='Add Company'
                icon="add" 
                disabled={companySelectorObject.companies.length - sharedGraphCompaniesCount == 0}
                type='success'
                onClick={() => {
                    setShowShareSettingsAddCompaniesPopup(true)
                }}
                style={{float: 'right'}}/>}
            <ShareSettingsAddCompaniesPopup 
                sharedGraphCompanies={props.sharedGraphCompanies} 
                showPopup={showShareSettingsAddCompaniesPopup} 
                onHiding={() => setShowShareSettingsAddCompaniesPopup(false)}
                applyAdd={async (sharedCompanies: Array<Company>, selectedRole: SharedGraphRoleType) => {
                    if (sharedCompanies && keycloak.token && props.chartSettings && props.sharedGraphCompanies) {
                        let SharedGraphCompanysToAdd = sharedCompanies.map((company:Company) => {
                            return SharedGraphCompany.constructFromCompany(company, selectedRole);
                        })
                        let postWasSuccessful = await AddSharedGraphCompanies(keycloak.token, props.chartSettings.chartId, SharedGraphCompanysToAdd);
                        if (postWasSuccessful) {
                            const newCompanies = [...props.sharedGraphCompanies, ...SharedGraphCompanysToAdd];
                            props.setSharedGraphCompanies(newCompanies);
                            props.updateSharedByMe(newCompanies);
                        }

                        setShowShareSettingsAddCompaniesPopup(false);
                        return true;
                    }
                    return false;
                }}/>
            <ShareSettingsEditRolePopup 
                currentRole={selectedSharedCompany?.role} 
                showPopup={selectedSharedCompany != undefined} 
                onHiding={() => setSelectedSharedCompany(undefined)}
                applyAdd={async (selectedRole: SharedGraphRoleType) => {
                    if (keycloak.token && selectedSharedCompany != undefined && props.chartSettings && props.sharedGraphCompanies) {
                        let putWasSuccessful = await EditSharedGraphCompanyRole(keycloak.token, props.chartSettings.chartId, selectedSharedCompany.companyId, selectedRole)
                        if (putWasSuccessful) {
                            selectedSharedCompany.role = selectedRole
                            setSelectedSharedCompany(undefined)
                            props.setSharedGraphCompanies([...props.sharedGraphCompanies])
                            return true
                        }
                    }
                    return false
                }}/>
        </div>)
}
export default ShareSettingsCompanies;