import { AuditLog } from '../Classes/UserGroups/AuditLog';
import * as ApiFetchUtility from '../Utilities/APIFetchUtility';

export async function GetCompanyLogs(authToken:string, companyId:number): Promise<AuditLog[]> {
    let fetchOptions = ApiFetchUtility.GetFetchOptions(authToken);
    fetchOptions['method'] = 'get';

    let url = ApiFetchUtility.getFormattedUrl("AuditLog", `GetCompanyLogs?companyId=${companyId}`); 
    const res = await ApiFetchUtility.FetchApi(url, fetchOptions);
    const json = await res.json();

    return json;
}