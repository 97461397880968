import './GraphSetFolderBreadcrumbs.css';

import { Button } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { setSelectedGraphSetFolderId } from '../../../../Reducers/SetManagementReducer';
import FolderNavigationButton from './FolderNavigationButton';
import { SelectFolder } from '../../../../API/GraphSetFolderAPI';
import useKeycloak from '../../../../Keycloak';

interface GraphSetFolderBreadcrumbsProps {
    rootFolder: GraphSetFolder;
    currentFolderId: number|null;
}

export function GraphSetFolderBreadcrumbs(props:GraphSetFolderBreadcrumbsProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const [path, setPath] = useState<Array<GraphSetFolder>>(new Array<GraphSetFolder>());

    useEffect(() => {
        if (props.rootFolder != null && props.currentFolderId != null) {
            let newPath = getPath(props.rootFolder, props.currentFolderId)
            if (newPath)
                setPath(newPath)
        }
    }, [props.rootFolder, props.currentFolderId])

    const getPath = (folder:GraphSetFolder, targetId:number|null) => {
        // If the folder is the target, just return the folder
        if (folder.id == targetId) {
            return [folder]
        }
        // If there are no more subfolders to search, and the folder is not the target, return null indicating that this path does not contain the target folder.
        if (folder.subFolders.length == 0) {
            return null
        }
        // Otherwise, search subfolders for the path
        let path = null
        folder.subFolders.forEach((subFolder:GraphSetFolder) => {
            let subPath = getPath(subFolder, targetId)
            if (subPath != null) {
                path = [folder, ...subPath]
                // Break out of foreach once target path is found
                return
            }
        })
        return path
    }

    const setCurrentFolder = (newFolder:GraphSetFolder) => {
        dispatch(setSelectedGraphSetFolderId(newFolder.id));
        if (keycloak.token)
            SelectFolder(keycloak.token, newFolder.id);
    }

    const renderItem = (folder:GraphSetFolder) => {
        return (
            <div key={folder.id} style={{display: 'flex'}}>
                {folder.id != props.rootFolder.id &&
                <Button
                    className='breadCrumbArrow'
                    icon='spinright'
                    hoverStateEnabled={false}
                    disabled={true}/>}
                <FolderNavigationButton 
                    folder={folder} 
                    setCurrentFolder={setCurrentFolder}/>
            </div>
        );
    }

    return (
        <div className='graphSetFolderBreadcrumbs'>
             {path.map((folder:GraphSetFolder) => renderItem(folder))}
        </div>
    );
}
export default GraphSetFolderBreadcrumbs;