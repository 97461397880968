import { DataSourceColumn } from "./DataSourceColumn"
import { AggregationType, aggregationTypeDict } from "./Enums/AggregationType"

export class ResultPropertyInfo {
    displayName: string
    defaultGroupName: string
    unitLabel: string
    beamUnitLabel: string|undefined
    propertyRecommendations: PropertyRecommendation[]
    isNumeric: boolean
    isDate: boolean

    // Metadata used to organize the plot options selector
    groupPath: string[];
    sectionName: string;
    shortName: string;

    // Describes how a property is aggregated for many zones. For example, panel unit weight for many zones is aggregated using a formula 
    // like this: total zone weight / total zone area.
    specialAggregationDescription: string|null = null;
    defaultAggregationType:AggregationType = AggregationType.First;

    constructor(displayName:string, defaultGroupName:string, unitLabel:string, propertyRecommendations:PropertyRecommendation[], 
        isNumeric:boolean, isDate:boolean, groupPath:string[], sectionName:string, shortName:string) {
        this.displayName = displayName
        this.defaultGroupName = defaultGroupName
        this.unitLabel = unitLabel
        this.propertyRecommendations = propertyRecommendations
        this.isNumeric = isNumeric
        this.isDate = isDate
        this.groupPath = groupPath
        this.sectionName = sectionName
        this.shortName = shortName
    }
}

export interface PropertyRecommendation {
    recommendedTypes: RecommendedType[]
}

export const getPropDisplayName = (propertyKey:string, aggregationType:AggregationType|undefined, propertyKeyDictionary:{[key: string]: ResultPropertyInfo}|undefined) => {
    if (propertyKeyDictionary == null)
        return '';

    const propertyInfo = propertyKeyDictionary[propertyKey];
    if (propertyInfo) {
        const aggregationTypeInfo = aggregationTypeDict.get(aggregationType ?? AggregationType.First);

        if (aggregationType && aggregationTypeInfo && aggregationTypeInfo.prefix)
            return formatResultName(`${aggregationTypeInfo.prefix} ${propertyInfo.displayName}`);
        if (aggregationType && aggregationTypeInfo && aggregationTypeInfo.postfix)
            return formatResultName(`${propertyInfo.displayName} ${aggregationTypeInfo.postfix}`);

        return propertyInfo.displayName;
    }

    return 'Unknown';
}

export const getColumnDisplayName = (dataSourceColumn:DataSourceColumn, propertyKeyDictionary:{[key: string]: ResultPropertyInfo}|undefined) => {
    if (dataSourceColumn.customName != null)
        return dataSourceColumn.customName;

    if (propertyKeyDictionary == null)
        return '';

    const propertyInfo = propertyKeyDictionary[dataSourceColumn.propertyKey];
    if (propertyInfo) {
        const aggregationTypeInfo = aggregationTypeDict.get(dataSourceColumn.aggregationType ?? AggregationType.First);

        if (dataSourceColumn.aggregationType && aggregationTypeInfo && aggregationTypeInfo.prefix)
            return formatResultName(`${aggregationTypeInfo.prefix} ${propertyInfo.displayName}`);
        if (dataSourceColumn.aggregationType && aggregationTypeInfo && aggregationTypeInfo.postfix)
            return formatResultName(`${propertyInfo.displayName} ${aggregationTypeInfo.postfix}`);

        return propertyInfo.displayName;
    }

    return 'Unknown';
}

const formatResultName = (valueString:string) => {
    if (valueString !== null && valueString.length > 0) {
        return valueString
            .replace(new RegExp('alpha', "ig"), '\u03B1')
            .replace(new RegExp('delta', "ig"), '\u03B4')
            .replace(new RegExp('theta', "ig"), '\u03B8')
            .replace(new RegExp('gamma', "ig"), '\u03B3');
    } else {
        return valueString
    }
};

enum RecommendedType {
    XAxis = 0,
    YAxis = 1,
    Size = 2,
    GroupSeriesBy = 3
}