import { AuthProviderProps, useAuth } from 'react-oidc-context';

const isProd = process.env.NODE_ENV === 'production';
const authUrl = (document.getElementById(isProd ? "authUrl" : 'devAuthUrl') as HTMLInputElement).value; 
const keycloakConfig = {
   url: authUrl,
   realm: 'hyperxcloud', 
   clientId: 'dashboard'
}

export const oidcConfig:AuthProviderProps = {
   authority: keycloakConfig.url + '/realms/' + keycloakConfig.realm,
   client_id: keycloakConfig.clientId,
   redirect_uri: window.location.origin,
   post_logout_redirect_uri: window.location.origin,
   silent_redirect_uri: window.location.origin + '/silent-check-sso.html',
   automaticSilentRenew: true,
   revokeTokensOnSignout: true,
   onSigninCallback: (): void => {
       window.history.replaceState({}, document.title, window.location.pathname);
   }
};

export interface KeycloakAuthProps {
   token:string;
   userId:string;
   authenticated:boolean;
   loading:boolean;
   authServerUrl:string;
   login: () => void;
   logout: () => void;
   createRegisterUrl: () => string;
}

export function createRegisterUrl():string {
   const url = keycloakConfig.url;
   const realm = keycloakConfig.realm;
   const clientId = keycloakConfig.clientId;
   const redirectUrl = window.location.origin + '/sign-in';
   const lang = 'en';

   return `${url}/realms/${realm}/protocol/openid-connect`
      + `/registrations?client_id=${clientId}&response_type=code&scope=openid email`
      + `&redirect_uri=${redirectUrl}&kc_locale=${lang}`;
}

// Create a custom wrapper around the useAuth function so we can change syntax from a central location later 
// on if needed.
export default function useKeycloak():KeycloakAuthProps {
   const auth = useAuth();

   function logout() {
      auth.signoutRedirect();
   }

   return {
      token: auth.user?.access_token,
      userId: auth.user?.profile.sub,
      authenticated: auth.isAuthenticated,
      loading: auth.isLoading,
      authServerUrl: keycloakConfig.url,
      login: () => auth.signinRedirect(),
      logout: logout,
      createRegisterUrl: createRegisterUrl
   } as KeycloakAuthProps;
}