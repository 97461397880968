import notify from 'devextreme/ui/notify';

export enum APIRequestStatusType {
    Success = 0,
    Warning = 1,
    Error = 2
}

export class APIRequestStatus {
    type: APIRequestStatusType;
    description: string;

    constructor() {
        this.type = APIRequestStatusType.Success;
        this.description = "";
    }

    static ensureNoErrorAndToastIfNotSuccess = (status:APIRequestStatus) => {
        const isSuccess = status.type === APIRequestStatusType.Success;
        const isError = status.type === APIRequestStatusType.Error;
        if (!isSuccess) {
            APIRequestStatus.showToast(status);
        }
        return !isError;
    }

    static showToast = (status:APIRequestStatus) => {
        switch (status.type) {
            case APIRequestStatusType.Error:
                notify(status.description, 'error');
                break;
            case APIRequestStatusType.Warning:
                notify(status.description, 'warning');
                break;
            case APIRequestStatusType.Success:
                notify(status.description, 'success');
                break;
        }
    }
}