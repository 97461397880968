import { DataSourceColumn } from "../DataSourceColumn";
import { TrendlineType } from "../Enums/TrendlineType";
import { 
    AxisLabelOverflowMode,
    AxisOrderType,
    ChartSettings,
    PointLabelType
} from './ChartSettingsTypes';
import { CustomSeriesData } from "./CustomSeriesData";
import { YAxis } from "./YAxis";

export class GraphTemplate implements ChartSettings {
    chartId: number = 0;
    graphSetIds: number[] = [];
    dataSourceColumns: DataSourceColumn[] = [];
    dataSourceCategoryIds: number[] = [];
    stackColumnIds: number[] = [];
    showAllSolutions: boolean = false;
    valuePrecision: number | undefined = 4;
    showXAxisGridLines: boolean = true;
    showYAxisGridLines: boolean = true;
    pointLabelMode: PointLabelType = PointLabelType.None;
    yAxisConstantLineValue:number|null = null;
    showYAxisConstantLineLabels: boolean = true;
    axisLabelCutoff: number | undefined = 50;
    axisLabelOverflowMode: AxisLabelOverflowMode | undefined = AxisLabelOverflowMode.Rotate;
    axisOrder: AxisOrderType | undefined = AxisOrderType.GraphSet;
    yAxisShowZero: boolean = true;
    yAxisTryCombineAxes: boolean = true;
    title: string = '';
    description: string = '';
    xAxisColumnId: number | null = null;
    yAxes: YAxis[] = [];
    bubbleSizeColumnId: number|null = null;
    colorByColumnIds: number[] = [];
    relevanceToData: GraphTemplateRelevance = new GraphTemplateRelevance(0);
    customSeriesDataByKey: {[key:string]: CustomSeriesData} = {};
    trendlineType: TrendlineType = TrendlineType.None;
    trendlineOrder: number = 2;
    xAxisLogScaleEnabled: boolean = false;
    yAxisLogScaleEnabled: boolean = false;
    xAxisMinValue: number|null = null;
    xAxisMaxValue: number|null = null;
    yAxisMinValue: number|null = null;
    yAxisMaxValue: number|null = null;
    stringFiltersByColumnId: {[key:number]: string[]} = {};
}

export class GraphTemplateRelevance {
    static goodRelevanceGrade: number = 80;
    static badRelevanceGrade: number = 40;
    grade: number;
    categoryNumber: number;
    categoryName: string;
    categoryColor: string;
    categoryClassName: string;

    constructor(grade: number) {
        this.grade = grade;

        if (grade >= GraphTemplateRelevance.goodRelevanceGrade) {
            this.categoryNumber = 0;
            this.categoryName = 'fully';
            this.categoryColor = '#006400';
            this.categoryClassName = 'fullyRelevantGraphTemplate';
        }
        else if (grade > GraphTemplateRelevance.badRelevanceGrade) {
            this.categoryNumber = 1;
            this.categoryName = 'partially';
            this.categoryColor = '#D2691E';
            this.categoryClassName = 'partiallyRelevantGraphTemplate';
        }
        else {
            this.categoryNumber = 2;
            this.categoryName = 'not';
            this.categoryColor = '#A52A2A';
            this.categoryClassName = 'notRelevantGraphTemplate';
        }
    }
}