import { GraphInfo } from "./GraphInfo";

export class CustomChartFolder {
    id:number|null
    name:string
    subFolders: Array<CustomChartFolder>
    graphInfos: Array<GraphInfo>

    constructor() {
        this.id = null
        this.name = ""
        this.subFolders = new Array<CustomChartFolder>()
        this.graphInfos = new Array<GraphInfo>()
    }  
}

export function FindFolderInFolder(folder:CustomChartFolder|undefined, targetFolderId:number|null|undefined) {
    if (folder == null) {
        return null
    }
    if (folder.id === targetFolderId) {
        return folder
    }
    if (folder.subFolders.length === 0) {
        return null
    }

    let foundTarget = null
    folder.subFolders.forEach((subFolder:CustomChartFolder) => {
        let tryFindTarget = FindFolderInFolder(subFolder, targetFolderId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget
        }
        return
    })
    return foundTarget ?? null
}

export function FindChartInFolder(folder:CustomChartFolder|undefined, targetChartId:number|null|undefined) {
    if (folder == null) {
        return null
    }
    let tryFindCustomChart = folder.graphInfos.find((chart:GraphInfo) => chart.id === targetChartId)
    if (tryFindCustomChart) {
        return tryFindCustomChart
    }
    if (folder.subFolders.length === 0) {
        return null
    }
    let foundTarget = null
    folder.subFolders.forEach((subFolder:CustomChartFolder) => {
        let tryFindTarget = FindChartInFolder(subFolder, targetChartId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget
        }
        return
    })
    return foundTarget ?? null
}

export function FindChartParentFolder(folder:CustomChartFolder|undefined, targetChartId:number|null|undefined) {
    if (folder == null) {
        return null;
    }
    let tryFindChart = folder.graphInfos.find((graphInfo:GraphInfo) => graphInfo.id === targetChartId);
    if (tryFindChart) {
        return folder;
    }
    if (folder.subFolders.length === 0) {
        return null;
    }
    let foundTarget = null;
    folder.subFolders.forEach((subFolder:CustomChartFolder) => {
        let tryFindTarget = FindChartParentFolder(subFolder, targetChartId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    })
    return foundTarget ?? null;
}

export function FindFolderParentFolder(folder:CustomChartFolder|undefined, targetFolderId:number|null|undefined) {
    if (folder == null) {
        return null;
    }
    let tryFindFolder = folder.subFolders.find((folder) => folder.id === targetFolderId);
    if (tryFindFolder) {
        return folder;
    }
    if (folder.subFolders.length === 0) {
        return null;
    }
    let foundTarget = null;
    folder.subFolders.forEach((subFolder:CustomChartFolder) => {
        let tryFindTarget = FindFolderParentFolder(subFolder, targetFolderId)
        if (tryFindTarget != null) {
            foundTarget = tryFindTarget;
        }
        return;
    })
    return foundTarget ?? null;
}

export function CountFoldersInFolder(folder:CustomChartFolder|undefined) {
    let count = 0
    if (folder != undefined) {
        count += folder.subFolders.length
        folder.subFolders.forEach((subFolder:CustomChartFolder) => {
            count += CountFoldersInFolder(subFolder)
        })
    }
    return count
}

export function CountChartsInFolder(folder:CustomChartFolder|undefined) {
    let count = 0
    if (folder != undefined) {
        count += folder.graphInfos.length
        folder.subFolders.forEach((subFolder:CustomChartFolder) => {
            count += CountChartsInFolder(subFolder)
        })
    }
    return count
}

export function MoveGraphToFolderWithinRoot(rootFolder:CustomChartFolder, graphId:number, destinationFolderId:number|null|undefined) {
    const newRootFolder = {...rootFolder};

    const graphToMove = FindChartInFolder(newRootFolder, graphId);
    const destinationFolder = FindFolderInFolder(newRootFolder, destinationFolderId) ?? rootFolder;
    const parentFolderOfGraphToMove = FindChartParentFolder(newRootFolder, graphId);

    if (parentFolderOfGraphToMove != null && graphToMove != null && destinationFolder != null) {
        // Remove the graph from the current folder
        parentFolderOfGraphToMove.graphInfos = [...parentFolderOfGraphToMove.graphInfos.filter(i => i.id !== graphId)];

        // Add the graph to the destination folder
        destinationFolder.graphInfos = [...destinationFolder.graphInfos, graphToMove];
        return newRootFolder;
    }

    // If something goes wrong, just return null.
    return null;
}

export function MoveFolderToFolderWithinRoot(rootFolder:CustomChartFolder, folderId:number, destinationFolderId:number|null|undefined) {
    const newRootFolder = {...rootFolder};

    const folderToMove = FindFolderInFolder(newRootFolder, folderId);
    const destinationFolder = FindFolderInFolder(newRootFolder, destinationFolderId) ?? rootFolder;
    const parentFolderOfFolderToMove = FindFolderParentFolder(newRootFolder, folderId);

    if (parentFolderOfFolderToMove != null && folderToMove != null && destinationFolder != null) {
        // Remove the folder from the current folder
        parentFolderOfFolderToMove.subFolders = [...parentFolderOfFolderToMove.subFolders.filter(i => i.id !== folderId)];

        // Add the folder to the destination folder
        destinationFolder.subFolders = [...destinationFolder.subFolders, folderToMove];
        return newRootFolder;
    }

    // If something goes wrong, just return null.
    return null;
}