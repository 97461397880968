import './ShareSettings.css';

import { Accordion, Button, ScrollView, Switch, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomChartSettings } from '../../../../../Classes/Charts/CustomChartSettings';
import notify from 'devextreme/ui/notify';
import { useDispatch, useSelector } from 'react-redux';
import { companyDataSelector, getCompanies } from '../../../../../Reducers/CompanyDataReducer';
import { getPrograms, programDataSelector } from '../../../../../Reducers/ProgramDataReducer';
import { confirm } from 'devextreme/ui/dialog';
import { Item } from 'devextreme-react/accordion';
import ShareSettingsPrograms from './ShareSettingsPrograms';
import ShareSettingsCompanies from './ShareSettingsCompanies';
import { SharedGraphCompany } from '../../../../../Classes/UserGroups/SharedGraphCompany';
import { SharedGraphProgram } from '../../../../../Classes/UserGroups/SharedGraphProgram';
import { GetSharedGraphCompanies, GetSharedGraphPrograms, GetSharedGraphUsers } from '../../../../../API/GraphSharingAPI';
import ShareSettingsUsers from './ShareSettingsUsers';
import { SharedGraphUser } from '../../../../../Classes/UserGroups/SharedGraphUser';
import { SharedGraphRoleType, canManageGraphSharing, getRoleName } from '../../../../../Classes/UserGroups/SharedGraphRole';
import useKeycloak from '../../../../../Keycloak';
import { SetCustomChartSharedToPublic } from '../../../../../API/CustomChartAPI';

type ShareSettingsProps = {
    chartSettings: CustomChartSettings | undefined;
    exportToFile: (fileType:'svg'|'png') => void;
    setSharedBy: (newVal:boolean) => void;
    setPublicLink: (newVal:string) => void;
}

export function ShareSettings({chartSettings, exportToFile, setSharedBy, setPublicLink}: ShareSettingsProps) {

    const [switchState, setSwitchState] = useState<boolean|undefined>(undefined);
    const keycloak = useKeycloak();
    const dispatch = useDispatch();
    const companySelectorObject = useSelector(companyDataSelector);
    const programSelectorObject = useSelector(programDataSelector);

    const [sharedGraphCompanies, setSharedGraphCompanies] = useState<Array<SharedGraphCompany>>()
    const [sharedGraphPrograms, setSharedGraphPrograms] = useState<Array<SharedGraphProgram>>()
    const [sharedGraphUsers, setSharedGraphUsers] = useState<Array<SharedGraphUser>>()

    const userRole = chartSettings?.userRole ?? SharedGraphRoleType.None;

    // Load programs and companies that the user belongs to
    useEffect(() => {
        let token = keycloak.token;
        if (token) {
            if (companySelectorObject.companies.length == 0)
                dispatch(getCompanies(token));
            if (programSelectorObject.programs.length == 0)
                dispatch(getPrograms(token));
        }
    }, []);

    useEffect(() => {
        updateSharedGraphCompanies()
        updateSharedGraphPrograms()
        updateSharedGraphUsers()
        setSwitchState(isSharedtoPublic())
    }, [chartSettings?.chartId])

    useEffect(() => {
        if (switchState !== undefined)
            updatePublicLink()
    }, [switchState])

    useEffect(() => {
        if (keycloak.token && chartSettings && switchState === undefined) {
            setSwitchState(isSharedtoPublic())
        }
    }, [chartSettings?.sharedLinkId])

    // Update the "shared by me" state of the chart as the shared settings change.
    const updateSharedByMe = useCallback((sharedLinkActive:boolean|undefined, companies:SharedGraphCompany[]|undefined, 
        programs:SharedGraphProgram[]|undefined, users:SharedGraphUser[]|undefined) => {
        // Do not proceed if there is no chart id loaded.
        if (chartSettings?.chartId != null) {
            const hasCompanies = companies != null && companies.length > 0;
            const hasPrograms = programs != null && programs.length > 0;
            const hasUsers = users != null && users.length > 0; 

            const newSharedByMe = sharedLinkActive || hasCompanies || hasPrograms || hasUsers;
            if (chartSettings?.isSharedByMe !== newSharedByMe) {
                setSharedBy(newSharedByMe);
            }
        }
    }, [chartSettings?.chartId, chartSettings?.isSharedByMe, setSharedBy]);

    const updateSharedGraphCompanies = async () => {
        let token = keycloak.token;
        if (token && chartSettings) {
            let newSharedGraphCompanies = await GetSharedGraphCompanies(token, chartSettings?.chartId)
            setSharedGraphCompanies(newSharedGraphCompanies)
        }
    }

    const updateSharedGraphPrograms = async () => {
        let token = keycloak.token;
        if (token && chartSettings) {
            let newSharedGraphPrograms = await GetSharedGraphPrograms(token, chartSettings?.chartId)
            setSharedGraphPrograms(newSharedGraphPrograms)
        }
    }

    const updateSharedGraphUsers = async () => {
        let token = keycloak.token;
        if (token && chartSettings) {
            let newSharedGraphUsers = await GetSharedGraphUsers(token, chartSettings?.chartId)
            setSharedGraphUsers(newSharedGraphUsers)
        }
    }

    const updatePublicLink = async () => {
        let shouldClearLink = !switchState && isSharedtoPublic()
        let shouldEnableLink = switchState && !isSharedtoPublic()

        //Show a confirm popup if user is turning off shared link.
        if (shouldClearLink) {
            let confirmResult = await confirm(`Are you sure you want to disable the public link? The current link will be permanently deactivated.`, "Confirm Link Deactivation");
            if (!confirmResult) {
                setSwitchState(true);
                updateSharedByMe(true, sharedGraphCompanies, sharedGraphPrograms, sharedGraphUsers);
                return;
            }
        }

        if (keycloak.token && chartSettings && (switchState !== undefined) && (shouldClearLink || shouldEnableLink)) {
            SetCustomChartSharedToPublic(keycloak.token, chartSettings.chartId, switchState).then(newPublicId => {
                setPublicLink(newPublicId);
                updateSharedByMe(switchState, sharedGraphCompanies, sharedGraphPrograms, sharedGraphUsers);
            });
        }
    }

    const getGraphSharingUrl = () => {
        let locationBaseUrl = `${window.location.protocol}//${window.location.host}`
        let graphSharingId = 'your-graph-sharing-id'
        if (isSharedtoPublic()) {
            graphSharingId = chartSettings!.sharedLinkId!
        }
        return `${locationBaseUrl}/graph/${graphSharingId}`
    }

    const isSharedtoPublic = ():boolean => {
        return !!chartSettings?.sharedLinkId
    }

    const switchValueChanged = async (e:any) => {
        const newSwitchState = e.value;
        if (newSwitchState !== switchState && switchState !== undefined) {
            setSwitchState(e.value);
        }
    }

    const renderCompanies = useMemo(() => {
        return (
            <ShareSettingsCompanies 
                sharedGraphCompanies={sharedGraphCompanies}
                setSharedGraphCompanies={setSharedGraphCompanies}
                chartSettings={chartSettings}
                sharedGraphRole={userRole}
                updateSharedByMe={(companies:SharedGraphCompany[]|undefined) => 
                    updateSharedByMe(switchState, companies, sharedGraphPrograms, sharedGraphUsers)}/>
        )
    }, [sharedGraphCompanies, chartSettings, userRole, updateSharedByMe, switchState, sharedGraphPrograms, sharedGraphUsers])

    const renderPrograms = useMemo(() => {
        return (
            <ShareSettingsPrograms 
                sharedGraphPrograms={sharedGraphPrograms}
                setSharedGraphPrograms={setSharedGraphPrograms}
                chartSettings={chartSettings}
                sharedGraphRole={userRole}
                updateSharedByMe={(programs:SharedGraphProgram[]|undefined) => 
                    updateSharedByMe(switchState, sharedGraphCompanies, programs, sharedGraphUsers)}/>
        )
    }, [sharedGraphPrograms, chartSettings, userRole, updateSharedByMe, switchState, sharedGraphCompanies, sharedGraphUsers])

    const renderUsers = useMemo(() => {
        return (
            <ShareSettingsUsers
                sharedGraphUsers={sharedGraphUsers}
                setSharedGraphUsers={setSharedGraphUsers}
                chartSettings={chartSettings}
                sharedGraphRole={userRole}
                updateSharedByMe={(users:SharedGraphUser[]|undefined) => 
                    updateSharedByMe(switchState, sharedGraphCompanies, sharedGraphPrograms, users)}/>
        )
    }, [sharedGraphUsers, chartSettings, userRole, updateSharedByMe, switchState, sharedGraphCompanies, sharedGraphPrograms])

    return (
        <div className='graphSettingsWrapper'>
            <div className='graphSettings'>
                <ScrollView useNative={true}>
                    {getSectionUI("Export image file", null, (
                        <div className='exportImageFileSettings'>
                            <Button
                                text='Download SVG'
                                onClick={() => {
                                    exportToFile('svg');
                                }}/>
                            <Button
                                text='Download PNG'
                                onClick={() => {
                                    exportToFile('png');
                                }}/>
                        </div>
                    ))}
                    {getSectionUI("Share via public link", null, (
                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '1em' }}>
                            <Switch
                                value={switchState}
                                onValueChanged={switchValueChanged}
                                disabled={!canManageGraphSharing(userRole)}
                            />
                            <div style={{ width: '0.5rem' }} />
                            <TextBox readOnly={true}
                                hoverStateEnabled={false}
                                disabled={!isSharedtoPublic()}
                                value={getGraphSharingUrl()}
                                style={{display: 'flex', flexGrow: 1}}
                            />
                            <div style={{ width: '0.5rem' }} />
                            <Button
                                className='copySettingsButton'
                                icon='copy'
                                hint='Copy Link'
                                disabled={!isSharedtoPublic()}
                                onClick={() => {
                                    navigator.clipboard.writeText(getGraphSharingUrl()).then(function () {
                                        notify('Link copied to clipboard', 'success')
                                    }, function (err) {
                                        notify('Failed to copy', 'error')
                                    });
                                }} />
                        </div>
                    ))}
                    {getSectionUI("Share to", null, (
                        <div className='graphShareToCategory'>
                            <Accordion collapsible={true} multiple={false} defaultSelectedIndex={2}>
                                <Item title={`Companies (${sharedGraphCompanies?.length ?? 0})`}>
                                    {renderCompanies}
                                </Item>
                                <Item title={`Programs (${sharedGraphPrograms?.length ?? 0})`}>
                                    {renderPrograms}
                                </Item>
                                <Item title={`Users (${sharedGraphUsers?.length ?? 0})`}>
                                    {renderUsers}
                                </Item>
                            </Accordion>
                        </div>
                    ))}
                </ScrollView>
            </div>
        </div>
    );
}
export default ShareSettings;

export const getSharedGroupRoleObject = (role:SharedGraphRoleType) => {
    return {
        value: role,
        label: getRoleName(role)
    }
}

export const sharedGroupRolesList = [
    getSharedGroupRoleObject(SharedGraphRoleType.ReadOnly),
    getSharedGroupRoleObject(SharedGraphRoleType.Collaborator),
    getSharedGroupRoleObject(SharedGraphRoleType.Maintainer),
    getSharedGroupRoleObject(SharedGraphRoleType.Admin)
]

const getSectionUI = (name:string, headerContent:any, content:any) => {
    return (
        <div className='graphSettingsSection'>
            <div className='graphSettingsSectionLabel'>
                {name}
                {headerContent}
            </div>
            <div className='graphSettingsSectionContent'>
                {content}
            </div>
        </div>
    );
}
