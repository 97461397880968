import './ShareSettings.css';

import { List, Popup, SelectBox } from 'devextreme-react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRoleDescription, SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';
import { ToolbarItem } from 'devextreme-react/popup';
import { SharedGraphUser } from '../../../../../Classes/UserGroups/SharedGraphUser';
import { GroupUser } from '../../../../../Classes/UserGroups/GroupUser';
import { Company } from '../../../../../Classes/UserGroups/Company';
import { Program } from '../../../../../Classes/UserGroups/Program';
import { sharedGroupRolesList } from './ShareSettings';
import { naturalCompare } from '../../../../../Utilities/CommonUtilities';
import { RootState } from '../../../../../Stores/GlobalStore';

type ShareSettingsAddUsersPopupProps = {
    sharedGraphUsers:SharedGraphUser[]|undefined
    showPopup:boolean
    onHiding:() => void
    applyAdd:(selectedUsers:Array<GroupUser>, selectedRole:SharedGraphRoleType) => Promise<boolean>
}

export function ShareSettingsAddUsersPopup(props: ShareSettingsAddUsersPopupProps) {
    const showPopup = props.showPopup;
    const sharedGraphUsers = props.sharedGraphUsers;

    const userId = useSelector((state:RootState) => state.userData.user?.id);
    const companies = useSelector((state:RootState) => state.companyData.companies);
    const programs = useSelector((state:RootState) => state.programData.programs);

    const [selectedUsers, setSelectedUsers] = useState<Array<GroupUser>>(new Array<GroupUser>());
    const [selectedRole, setSelectedRole] = useState<SharedGraphRoleType>(SharedGraphRoleType.ReadOnly);

    const usersListSearchExpr = useMemo(() => ['displayName', 'email'], []);

    const selectableUsers:Array<GroupUser> = useMemo(() => {
        if (showPopup) {
            const selectableUsersMap = new Map<string, GroupUser>();
            companies.forEach((company:Company) => {
                company.users.forEach((companyUser:GroupUser) => {
                    if (!selectableUsersMap.has(companyUser.id) && companyUser.id !== userId)
                        selectableUsersMap.set(companyUser.id, companyUser);
                })
            });
            programs.forEach((program:Program) => {
                program.users.forEach((programUser:GroupUser) => {
                    if (!selectableUsersMap.has(programUser.id) && programUser.id !== userId)
                        selectableUsersMap.set(programUser.id, programUser);
                })
            });

            const sharedGraphUserIds = sharedGraphUsers?.map((sharedGraphUser:SharedGraphUser) => sharedGraphUser.userId);
            const newSelectableUsers = Array.from(selectableUsersMap.values()).filter((user:GroupUser) => !sharedGraphUserIds?.includes(user.id));

            return newSelectableUsers.sort((a,b) => naturalCompare(a.displayName, b.displayName));
        }
        else {
            return new Array<GroupUser>();
        }
    }, [showPopup, sharedGraphUsers, companies, programs, userId]);

    // Clear the selected users when the selectable users change
    useEffect(() => {
        setSelectedUsers(new Array<GroupUser>());
    }, [selectableUsers]);

    return (
        <Popup 
            wrapperAttr={{class: 'shareGraphWithList'}}
            title={`Share With Users`}
            width='34rem'
            height='auto'
            maxHeight='40rem'
            visible={props.showPopup}
            hideOnOutsideClick={true}
            onHiding={props.onHiding}>
                <div className='shareGraphWithPopupContent'>
                    <SelectBox 
                        dataSource={sharedGroupRolesList}
                        selectedItem={selectedRole}
                        onValueChanged={(e:any) => setSelectedRole(e.value as SharedGraphRoleType)}
                        displayExpr='label'
                        valueExpr='value'
                        defaultValue={SharedGraphRoleType.ReadOnly}/>
                    <p>
                        {getRoleDescription(selectedRole)}
                    </p>
                    <List
                        dataSource={selectableUsers}
                        searchEnabled={selectableUsers.length > 5}
                        searchExpr={usersListSearchExpr}
                        selectionMode='multiple'
                        showSelectionControls={true}
                        selectedItems={selectedUsers}
                        onSelectedItemsChange={(newItems) => setSelectedUsers(newItems)}
                        displayExpr='displayName'
                        noDataText='No users to display'
                        useNativeScrolling={true}
                        itemRender={(item:GroupUser) => (
                            <div className='sharingListItem'>
                                <div className='sharingListItemLeftDiv'>
                                    {item.displayName}
                                </div>
                                <div className='sharingListItemRightDiv' style={{fontStyle: 'italic'}}>
                                    {item.email}
                                </div>
                            </div>
                        )}
                        pageLoadMode='scrollBottom'/>
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={selectedUsers.length === 0}
                    options={{
                        text: 'Share',
                        onClick: () => props.applyAdd(selectedUsers, selectedRole)
                    }}/>
        </Popup>
    );
}
export default ShareSettingsAddUsersPopup;