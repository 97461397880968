import { configureStore } from '@reduxjs/toolkit';
//import monitorReducersEnhancer from '../Enhancers/MonitorReducers';
//import loggerMiddleware from '../Middleware/logger';
import rootReducer from '../Reducers/RootReducer';



export default function configureAppStore() {

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // getDefaultMiddleware().concat(loggerMiddleware),
      // TODO PG -> Putting this here for now to increase performance in debug mode.
      //  It would be good to find a way to make all store values serializable since that is a Redux best practice.
      getDefaultMiddleware({
        serializableCheck: false
      })
    //enhancers: [monitorReducersEnhancer],
  });

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    (module as any).hot.accept('../Reducers/RootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}